'use client';
import React, { FC } from 'react';
import { UnexpectedErrorPage } from '@/common/errors/UnexpectedErrorPage';

/**
 * Catches and handles unexpected errors, that passed the ErrorBoundary
 * @returns
 */
const ErrorPage: FC = () => <UnexpectedErrorPage />;

export default ErrorPage;
