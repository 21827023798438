import { defaultWatchQueryOptions } from '../constants';
import { IBaseColors } from '../theme/colors';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDate: { input: string; output: string };
  AWSDateTime: { input: string; output: string };
  AWSEmail: { input: string; output: string };
  AWSJSON: { input: string; output: string };
  AWSTimestamp: { input: number; output: number };
};

export enum AclAction {
  All = 'all',
  AnswerQuestionByGpt = 'answerQuestionByGPT',
  ArchiveProject = 'archive_project',
  ArchivedProjectsView = 'archived_projects_view',
  ArticleDetailsEdit = 'article_details_edit',
  ArticleStatusEdit = 'article_status_edit',
  ArticleTitleEdit = 'article_title_edit',
  ArticlesEdit = 'articles_edit',
  ArticlesTileView = 'articles_tile_view',
  ArticlesView = 'articles_view',
  BriefEdit = 'brief_edit',
  BriefView = 'brief_view',
  BrokenLinksTileView = 'broken_links_tile_view',
  BulkExport = 'bulkExport',
  BulkWordPressExport = 'bulkWordPressExport',
  CompareArticleVersions = 'compareArticleVersions',
  ContinueTextByGpt = 'continueTextByGPT',
  Create = 'create',
  CreateAnalyticsLog = 'createAnalyticsLog',
  CreateArticle = 'createArticle',
  CreateArticleByNeuraverse = 'createArticleByNeuraverse',
  CreateArticleComment = 'createArticleComment',
  CreateArticleHeadlineRef = 'createArticleHeadlineRef',
  CreateArticleHeadlineRefs = 'createArticleHeadlineRefs',
  CreateArticlePost = 'createArticlePost',
  CreateArticleVersion = 'createArticleVersion',
  CreateGscToken = 'createGSCToken',
  CreateInvitations = 'createInvitations',
  CreateLinkedinToken = 'createLinkedinToken',
  CreateLogin = 'createLogin',
  CreateOneClickAnalysis = 'createOneClickAnalysis',
  CreateOrganization = 'createOrganization',
  CreateProjectBriefDocument = 'createProjectBriefDocument',
  CreateProjectLabel = 'createProjectLabel',
  CreateTopicInspirations = 'createTopicInspirations',
  Delete = 'delete',
  DeleteApiConnection = 'deleteApiConnection',
  DeleteArticle = 'deleteArticle',
  DeleteArticleComment = 'deleteArticleComment',
  DeleteArticleHeadline = 'deleteArticleHeadline',
  DeleteArticleHeadlineRef = 'deleteArticleHeadlineRef',
  DeleteArticleHeadlines = 'deleteArticleHeadlines',
  DeleteArticleKeyword = 'deleteArticleKeyword',
  DeleteArticlePost = 'deleteArticlePost',
  DeleteArticleQuestion = 'deleteArticleQuestion',
  DeleteArticleVersion = 'deleteArticleVersion',
  DeleteInvitation = 'deleteInvitation',
  DeleteProjectBriefDocument = 'deleteProjectBriefDocument',
  DeleteProjectLabel = 'deleteProjectLabel',
  DeleteUser = 'deleteUser',
  DetailpaneView = 'detailpane_view',
  DiscoverEdit = 'discover_edit',
  DiscoverView = 'discover_view',
  DivideSentenceByGpt = 'divideSentenceByGPT',
  Dummy = 'dummy',
  ExploreEdit = 'explore_edit',
  ExploreView = 'explore_view',
  ExportAllNVeTopics = 'exportAllNVeTopics',
  FiltersBulkView = 'filters_bulk_view',
  GenerateArticleByGpt = 'generateArticleByGPT',
  GenerateConclusionByGpt = 'generateConclusionByGPT',
  GenerateContentByGpt = 'generateContentByGPT',
  GenerateFocusKeywordsByGpt = 'generateFocusKeywordsByGPT',
  GenerateHeadlineInOutlineByGpt = 'generateHeadlineInOutlineByGPT',
  GenerateHeadlineInWriteByGpt = 'generateHeadlineInWriteByGPT',
  GenerateHeadlineSuggestionsInOutlineByGpt = 'generateHeadlineSuggestionsInOutlineByGPT',
  GenerateImageByDallE = 'generateImageByDallE',
  GenerateKeywordVariationsByGpt = 'generateKeywordVariationsByGPT',
  GenerateLinkedInPostByGpt = 'generateLinkedInPostByGPT',
  GenerateMetadataByGpt = 'generateMetadataByGPT',
  GenerateOutlineByGpt = 'generateOutlineByGPT',
  Get = 'get',
  GetArticleComment = 'getArticleComment',
  GetArticlePost = 'getArticlePost',
  GetAssignee = 'getAssignee',
  GetBriefExportAsDocx = 'getBriefExportAsDocx',
  GetBriefExportAsPdf = 'getBriefExportAsPdf',
  GetGscArticleAnalytics = 'getGSCArticleAnalytics',
  GetGscConsentScreenUri = 'getGSCConsentScreenUri',
  GetGscProjectAnalytics = 'getGSCProjectAnalytics',
  GetGlobalPermissionsTree = 'getGlobalPermissionsTree',
  GetGlobalRoleDefinitions = 'getGlobalRoleDefinitions',
  GetGoogleSearch = 'getGoogleSearch',
  GetGoogleTopRankings = 'getGoogleTopRankings',
  GetGscConnectionStatus = 'getGscConnectionStatus',
  GetHtmlExport = 'getHtmlExport',
  GetIsProjectMonitored = 'getIsProjectMonitored',
  GetKeywordVolumes = 'getKeywordVolumes',
  GetLastProductUpdate = 'getLastProductUpdate',
  GetLinkedinConnectionStatus = 'getLinkedinConnectionStatus',
  GetLinkedinConsentScreenUri = 'getLinkedinConsentScreenUri',
  GetNVeFilterData = 'getNVeFilterData',
  GetNVeMetadata = 'getNVeMetadata',
  GetNVeTopic = 'getNVeTopic',
  GetOneClickAnalysis = 'getOneClickAnalysis',
  GetOutlineHtmlExport = 'getOutlineHtmlExport',
  GetProjectBriefDocumentUsageCount = 'getProjectBriefDocumentUsageCount',
  GetProjectLabelUsageCount = 'getProjectLabelUsageCount',
  GetSimilarPhrasesByGpt = 'getSimilarPhrasesByGPT',
  GetSimilarPhrasesByNeuraverse = 'getSimilarPhrasesByNeuraverse',
  GetSimilarQuestions = 'getSimilarQuestions',
  GetTurnServerCredentials = 'getTURNServerCredentials',
  GetUploadUrl = 'getUploadUrl',
  GetUser = 'getUser',
  GetUserByEmail = 'getUserByEmail',
  GetUserOrganization = 'getUserOrganization',
  GetUserRoleAssignments = 'getUserRoleAssignments',
  GetVersion = 'getVersion',
  GetWordPressConnection = 'getWordPressConnection',
  GrantUserRoles = 'grantUserRoles',
  GscTileView = 'gsc_tile_view',
  ImportAllNVeTopics = 'importAllNVeTopics',
  ImportArticle = 'importArticle',
  IndexedTileView = 'indexed_tile_view',
  LinkNeuraverseToProject = 'linkNeuraverseToProject',
  List = 'list',
  ListArticleComments = 'listArticleComments',
  ListArticleHeadlines = 'listArticleHeadlines',
  ListArticleImages = 'listArticleImages',
  ListArticleKeywords = 'listArticleKeywords',
  ListArticlePosts = 'listArticlePosts',
  ListArticleQuestions = 'listArticleQuestions',
  ListArticles = 'listArticles',
  ListArticlesByProjectId = 'listArticlesByProjectId',
  ListArticlesToConfirmPublication = 'listArticlesToConfirmPublication',
  ListArticlesWithSameFocusKeyword = 'listArticlesWithSameFocusKeyword',
  ListBrokenArticleUrls = 'listBrokenArticleUrls',
  ListBrokenLinksInArticle = 'listBrokenLinksInArticle',
  ListBrokenLinksInProject = 'listBrokenLinksInProject',
  ListGscArticlesByKeyword = 'listGSCArticlesByKeyword',
  ListGscArticlesByProject = 'listGSCArticlesByProject',
  ListGscDomains = 'listGSCDomains',
  ListGscKeywordsByArticle = 'listGSCKeywordsByArticle',
  ListGscKeywordsByProject = 'listGSCKeywordsByProject',
  ListGscTopChangingArticles = 'listGSCTopChangingArticles',
  ListInvitations = 'listInvitations',
  ListLatestIndexedArticles = 'listLatestIndexedArticles',
  ListLinkedinOrganizations = 'listLinkedinOrganizations',
  ListNVeCompanies = 'listNVeCompanies',
  ListNVeTopicArticles = 'listNVeTopicArticles',
  ListNVeTopicSearchTerms = 'listNVeTopicSearchTerms',
  ListNVeTopicTop20Articles = 'listNVeTopicTop20Articles',
  ListNVeTopics = 'listNVeTopics',
  ListNVeWebArticles = 'listNVeWebArticles',
  ListProjectMembers = 'listProjectMembers',
  ListUserOrganizations = 'listUserOrganizations',
  ListUserProjects = 'listUserProjects',
  ListUsers = 'listUsers',
  ListUsersWithProjects = 'listUsersWithProjects',
  ListVersions = 'listVersions',
  ListWordPressCategories = 'listWordPressCategories',
  MatchKeywordsAndQuestionsByGpt = 'matchKeywordsAndQuestionsByGPT',
  MembersEdit = 'members_edit',
  MembersView = 'members_view',
  MonitorArticlesEdit = 'monitor_articles_edit',
  MonitorArticlesView = 'monitor_articles_view',
  MonitorDomainEdit = 'monitor_domain_edit',
  MonitorDomainView = 'monitor_domain_view',
  MonitorEdit = 'monitor_edit',
  MonitorKeywordsEdit = 'monitor_keywords_edit',
  MonitorKeywordsView = 'monitor_keywords_view',
  MonitorView = 'monitor_view',
  NeuraverseTileView = 'neuraverse_tile_view',
  NewNeuraverseBannerTileView = 'new_neuraverse_banner_tile_view',
  NewProjectTipsTileView = 'new_project_tips_tile_view',
  NewRellifyUpdateTileView = 'new_rellify_update_tile_view',
  OnUserRoleAssignments = 'onUserRoleAssignments',
  OverviewEdit = 'overview_edit',
  OverviewView = 'overview_view',
  PostView = 'post_view',
  PreferencesEdit = 'preferences_edit',
  PreferencesView = 'preferences_view',
  ProfileEdit = 'profile_edit',
  ProfileView = 'profile_view',
  ProjectMembersEdit = 'project_members_edit',
  ProjectMembersView = 'project_members_view',
  ProjectProgressTileView = 'project_progress_tile_view',
  ProjectsEdit = 'projects_edit',
  ProjectsView = 'projects_view',
  PublishArticlePost = 'publishArticlePost',
  RankingsTileView = 'rankings_tile_view',
  ReadNotifications = 'readNotifications',
  ResetChannel = 'resetChannel',
  RestoreArticleVersion = 'restoreArticleVersion',
  RevokeUserRoles = 'revokeUserRoles',
  SettingsEdit = 'settings_edit',
  SettingsView = 'settings_view',
  ShortenSentenceByGpt = 'shortenSentenceByGPT',
  SubscribeOnCreate = 'subscribeOnCreate',
  SubscribeOnDelete = 'subscribeOnDelete',
  SubscribeOnDeleteById = 'subscribeOnDeleteById',
  SubscribeOnUpdate = 'subscribeOnUpdate',
  SubscribeOnUpdateById = 'subscribeOnUpdateById',
  TipOfTheWeekTileView = 'tip_of_the_week_tile_view',
  TriggerRScore = 'triggerRScore',
  Update = 'update',
  UpdateArticleComment = 'updateArticleComment',
  UpdateArticleDocument = 'updateArticleDocument',
  UpdateArticleGuidelines = 'updateArticleGuidelines',
  UpdateArticleHeadlines = 'updateArticleHeadlines',
  UpdateArticleKeywords = 'updateArticleKeywords',
  UpdateArticleMetadata = 'updateArticleMetadata',
  UpdateArticlePost = 'updateArticlePost',
  UpdateArticleQuestions = 'updateArticleQuestions',
  UpdateArticleRScore = 'updateArticleRScore',
  UpdateArticleRootData = 'updateArticleRootData',
  UpdateAuth0Role = 'updateAuth0Role',
  UpdateNVeCompanyPriority = 'updateNVeCompanyPriority',
  UpdateNVeTopicArticlePriority = 'updateNVeTopicArticlePriority',
  UpdateNVeTopicPriority = 'updateNVeTopicPriority',
  UpdateNVeTopicSaved = 'updateNVeTopicSaved',
  UpdateProjectBriefDocument = 'updateProjectBriefDocument',
  UpdateProjectBriefDocuments = 'updateProjectBriefDocuments',
  UpdateProjectBriefMetadata = 'updateProjectBriefMetadata',
  UpdateProjectImageGuidelines = 'updateProjectImageGuidelines',
  UpdateProjectLabel = 'updateProjectLabel',
  UpdateProjectMetadata = 'updateProjectMetadata',
  UpdateUser = 'updateUser',
  UpdateUserArticleData = 'updateUserArticleData',
  UpdateUserProjectData = 'updateUserProjectData',
  UpsertArticleHeadline = 'upsertArticleHeadline',
  UpsertArticleHeadlines = 'upsertArticleHeadlines',
  UpsertArticleKeyword = 'upsertArticleKeyword',
  UpsertArticleKeywords = 'upsertArticleKeywords',
  UpsertArticleQuestion = 'upsertArticleQuestion',
  UpsertArticleQuestions = 'upsertArticleQuestions',
  UpsertWordPressConnection = 'upsertWordPressConnection',
  VersionspaneEdit = 'versionspane_edit',
  VersionspaneView = 'versionspane_view',
  WeeklyPlanTileView = 'weekly_plan_tile_view',
  WpExport = 'wpExport',
  WriteEdit = 'write_edit',
  WriteView = 'write_view',
}

export type AclGlobalPermissionTree = {
  __typename?: 'ACLGlobalPermissionTree';
  data?: Maybe<Array<Maybe<AclGlobalPermissionTreeNode>>>;
  id: Scalars['ID']['output'];
};

export type AclGlobalPermissionTreeNode = {
  __typename?: 'ACLGlobalPermissionTreeNode';
  actions: Array<AclAction>;
  children?: Maybe<Array<Maybe<AclGlobalPermissionTreeNode>>>;
  level: AclLevel;
};

export type AclGlobalRoleDefinition = {
  __typename?: 'ACLGlobalRoleDefinition';
  name?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  statements: Array<AclStatement>;
};

export type AclGlobalRoleDefinitions = {
  __typename?: 'ACLGlobalRoleDefinitions';
  data: Array<AclGlobalRoleDefinition>;
  id: Scalars['ID']['output'];
};

export enum AclLevel {
  ApiConnection = 'ApiConnection',
  ApiKey = 'ApiKey',
  Article = 'Article',
  ArticleBrief = 'ArticleBrief',
  GoogleTopRanking = 'GoogleTopRanking',
  NVeTopicRelation = 'NVeTopicRelation',
  NeuraverseTopic = 'NeuraverseTopic',
  Notification = 'Notification',
  Organization = 'Organization',
  Project = 'Project',
  UiView = 'UiView',
  User = 'User',
}

export type AclRoleAssignment = {
  __typename?: 'ACLRoleAssignment';
  data: Array<AclRoleAssignmentData>;
  id: Scalars['ID']['output'];
};

export type AclRoleAssignmentData = {
  __typename?: 'ACLRoleAssignmentData';
  entityId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type AclStatement = {
  __typename?: 'ACLStatement';
  actions: Array<AclAction>;
  effect: AclStatementEffect;
  inheritance?: Maybe<Scalars['Boolean']['output']>;
  level: AclLevel;
};

export enum AclStatementEffect {
  Allow = 'allow',
  Deny = 'deny',
}

export type AclUserRoles = {
  __typename?: 'ACLUserRoles';
  name?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export enum AnalyticsLogType {
  DailyActiveUser = 'DAILY_ACTIVE_USER',
}

export type AnswerQuestionByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

export type ApiConnection = {
  __typename?: 'ApiConnection';
  connectionData?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  serviceName: ApiConnectionService;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ApiConnectionDelete = {
  __typename?: 'ApiConnectionDelete';
  id: Scalars['ID']['output'];
};

export enum ApiConnectionService {
  GoogleSearchConsole = 'googleSearchConsole',
  Linkedin = 'linkedin',
  WordPress = 'wordPress',
}

export type ApiConnectionSubscription = {
  __typename?: 'ApiConnectionSubscription';
  connectionData?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  serviceName: ApiConnectionService;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ApiConnectionSubscriptionDelete = {
  __typename?: 'ApiConnectionSubscriptionDelete';
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ApiConnectionSubscriptionInput = {
  connectionData?: InputMaybe<Scalars['AWSJSON']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  serviceName: ApiConnectionService;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Article = {
  __typename?: 'Article';
  advices?: Maybe<Scalars['AWSJSON']['output']>;
  articleId?: Maybe<Scalars['String']['output']>;
  articlePostType?: Maybe<ArticlePostType>;
  assigneeId?: Maybe<Scalars['ID']['output']>;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  audienceExpertiseId?: Maybe<Scalars['ID']['output']>;
  audiencePersonaId?: Maybe<Scalars['ID']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  commentId?: Maybe<Scalars['String']['output']>;
  companyKnowledgeIds?: Maybe<Array<Scalars['ID']['output']>>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  completedDate?: Maybe<Scalars['AWSDateTime']['output']>;
  content?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  directReaderAddressing?: Maybe<DirectReaderAddressing>;
  doneAdvices?: Maybe<Array<Scalars['String']['output']>>;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  exploreInputs?: Maybe<Explore>;
  firstPersonPerspective?: Maybe<FirstPersonPerspective>;
  focusKeyword?: Maybe<Scalars['String']['output']>;
  guidelines?: Maybe<Scalars['AWSJSON']['output']>;
  hasTopRankings?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageGuidelines?: Maybe<ArticleImageGuidelines>;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  labelIds?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  neuraverseTopicData?: Maybe<ArticleNeuraverseTopicData>;
  pk1?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Scalars['AWSJSON']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  publicationDate?: Maybe<Scalars['AWSDateTime']['output']>;
  readingResources?: Maybe<Array<Scalars['String']['output']>>;
  requestOrigin?: Maybe<Scalars['String']['output']>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  rscore?: Maybe<Scalars['AWSJSON']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  sk2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ArticleState>;
  title?: Maybe<Scalars['String']['output']>;
  toneAndVoiceId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ArticleType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  urlIndexedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  urlPath?: Maybe<Scalars['String']['output']>;
  urlStatus?: Maybe<ArticleUrlStatus>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId?: Maybe<Scalars['Int']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
  wordCountPerArticle?: Maybe<Scalars['String']['output']>;
};

export type ArticleBrief = {
  __typename?: 'ArticleBrief';
  answered?: Maybe<Scalars['Boolean']['output']>;
  articleId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  keywordVariations?: Maybe<ArticleKeywordVariations>;
  keywords?: Maybe<Array<ArticleKeyword>>;
  level?: Maybe<Scalars['Int']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<ArticleQuestion>>;
  relevance?: Maybe<Scalars['Float']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ArticleBriefType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  volumes?: Maybe<Array<Volume>>;
};

export type ArticleBriefDelete = {
  __typename?: 'ArticleBriefDelete';
  id: Scalars['ID']['output'];
};

export type ArticleBriefSubscription = {
  __typename?: 'ArticleBriefSubscription';
  answered?: Maybe<Scalars['Boolean']['output']>;
  articleId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  keywordVariations?: Maybe<ArticleKeywordVariations>;
  keywords?: Maybe<Array<ArticleKeyword>>;
  level?: Maybe<Scalars['Int']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<ArticleQuestion>>;
  relevance?: Maybe<Scalars['Float']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ArticleBriefType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  volumes?: Maybe<Array<Volume>>;
};

export type ArticleBriefSubscriptionDelete = {
  __typename?: 'ArticleBriefSubscriptionDelete';
  pk1?: Maybe<Scalars['String']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ArticleBriefSubscriptionInput = {
  answered?: InputMaybe<Scalars['Boolean']['input']>;
  articleId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  keywordVariations?: InputMaybe<ArticleKeywordVariationsInput>;
  keywords?: InputMaybe<Array<ArticleKeywordInput>>;
  level?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<ArticleQuestionInput>>;
  relevance?: InputMaybe<Scalars['Float']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ArticleBriefType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<VolumeInput>>;
};

export enum ArticleBriefType {
  Headline = 'HEADLINE',
  HeadlineKeyword = 'HEADLINE_KEYWORD',
  HeadlineQuestion = 'HEADLINE_QUESTION',
  Keyword = 'KEYWORD',
  Question = 'QUESTION',
}

export type ArticleByGptInput = {
  assigneeId: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  isUserCreatedDirection?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  neuraverseTopicData: ArticleNeuraverseTopicDataInput;
  projectId: Scalars['String']['input'];
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleComment = {
  __typename?: 'ArticleComment';
  articleId: Scalars['String']['output'];
  commentCreator?: Maybe<User>;
  commentId?: Maybe<Scalars['String']['output']>;
  content: Scalars['AWSJSON']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  sk1: Scalars['String']['output'];
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ArticleCommentDelete = {
  __typename?: 'ArticleCommentDelete';
  id: Scalars['ID']['output'];
};

export type ArticleCountByState = {
  __typename?: 'ArticleCountByState';
  BRIEF: Scalars['Int']['output'];
  DONE: Scalars['Int']['output'];
  ONHOLD: Scalars['Int']['output'];
  REVIEW: Scalars['Int']['output'];
  TODO: Scalars['Int']['output'];
  WRITE: Scalars['Int']['output'];
};

export type ArticleDelete = {
  __typename?: 'ArticleDelete';
  id: Scalars['ID']['output'];
};

export type ArticleDocument = {
  __typename?: 'ArticleDocument';
  content?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  sk1: Scalars['String']['output'];
  sk2?: Maybe<Scalars['String']['output']>;
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId: Scalars['Int']['output'];
  versionName?: Maybe<Scalars['String']['output']>;
};

export type ArticleGuidelines = {
  __typename?: 'ArticleGuidelines';
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  guidelines?: Maybe<Scalars['AWSJSON']['output']>;
  id: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  sk1: Scalars['String']['output'];
  sk2?: Maybe<Scalars['String']['output']>;
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId: Scalars['Int']['output'];
  versionName?: Maybe<Scalars['String']['output']>;
};

export type ArticleHeadline = {
  __typename?: 'ArticleHeadline';
  articleId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  keywords: Array<ArticleKeyword>;
  level: Scalars['Int']['output'];
  questions: Array<ArticleQuestion>;
  title: Scalars['String']['output'];
  type: ArticleBriefType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ArticleHeadlineDelete = {
  __typename?: 'ArticleHeadlineDelete';
  id: Scalars['ID']['output'];
};

export type ArticleHeadlineInput = {
  articleId: Scalars['String']['input'];
  createdAt: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  keywords: Array<ArticleKeywordInput>;
  level: Scalars['Int']['input'];
  questions: Array<ArticleQuestionInput>;
  title: Scalars['String']['input'];
  type: ArticleBriefType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export enum ArticleHeadlineRefType {
  Keyword = 'KEYWORD',
  Question = 'QUESTION',
}

export type ArticleImageGuidelines = {
  __typename?: 'ArticleImageGuidelines';
  allowGenAI: Scalars['Boolean']['output'];
  customFields?: Maybe<Array<CustomField>>;
  imageType: ImageType;
  moodAndTone: ImageMoodAndTone;
  orientation: ImageOrientation;
  style: ImageStyle;
};

export type ArticleImageGuidelinesInput = {
  allowGenAI: Scalars['Boolean']['input'];
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  imageType: ImageType;
  moodAndTone: ImageMoodAndTone;
  orientation: ImageOrientation;
  style: ImageStyle;
};

export type ArticleKeyword = {
  __typename?: 'ArticleKeyword';
  articleId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  keywordVariations?: Maybe<ArticleKeywordVariations>;
  relevance?: Maybe<Scalars['Float']['output']>;
  required: Scalars['Boolean']['output'];
  type: ArticleBriefType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  volumes?: Maybe<Array<Volume>>;
};

export type ArticleKeywordDelete = {
  __typename?: 'ArticleKeywordDelete';
  id: Scalars['ID']['output'];
};

export type ArticleKeywordInput = {
  articleId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  relevance?: InputMaybe<Scalars['Float']['input']>;
  required: Scalars['Boolean']['input'];
  type?: InputMaybe<ArticleBriefType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<VolumeInput>>;
};

export type ArticleKeywordVariations = {
  __typename?: 'ArticleKeywordVariations';
  abbreviationsOrAcronyms: Array<Scalars['String']['output']>;
  closelyRelatedCompoundNouns: Array<Scalars['String']['output']>;
  rootForms: Array<Scalars['String']['output']>;
  separationsByHyphens: Array<Scalars['String']['output']>;
  singularAndPlural: Array<Scalars['String']['output']>;
  superlatives: Array<Scalars['String']['output']>;
  synonyms: Array<Scalars['String']['output']>;
  synonymsSingularAndPlural: Array<Scalars['String']['output']>;
  variationsUsingConnectingPrepositionOptions: Array<
    Scalars['String']['output']
  >;
  verbsAndNouns: Array<Scalars['String']['output']>;
  verbsAndNounsSingularAndPlural: Array<Scalars['String']['output']>;
};

export type ArticleKeywordVariationsInput = {
  abbreviationsOrAcronyms: Array<Scalars['String']['input']>;
  closelyRelatedCompoundNouns: Array<Scalars['String']['input']>;
  rootForms: Array<Scalars['String']['input']>;
  separationsByHyphens: Array<Scalars['String']['input']>;
  singularAndPlural: Array<Scalars['String']['input']>;
  superlatives: Array<Scalars['String']['input']>;
  synonyms: Array<Scalars['String']['input']>;
  synonymsSingularAndPlural: Array<Scalars['String']['input']>;
  variationsUsingConnectingPrepositionOptions: Array<
    Scalars['String']['input']
  >;
  verbsAndNouns: Array<Scalars['String']['input']>;
  verbsAndNounsSingularAndPlural: Array<Scalars['String']['input']>;
};

export type ArticleMetadata = {
  __typename?: 'ArticleMetadata';
  articleDocument?: Maybe<ArticleDocument>;
  articleGuidelines?: Maybe<ArticleGuidelines>;
  articleRScore?: Maybe<ArticleRScore>;
  articleRootData?: Maybe<ArticleRootData>;
  assignee?: Maybe<User>;
  assigneeId: Scalars['ID']['output'];
  audienceExpertiseId?: Maybe<Scalars['ID']['output']>;
  audiencePersonaId?: Maybe<Scalars['ID']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  companyKnowledgeIds?: Maybe<Array<Scalars['ID']['output']>>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  completedDate?: Maybe<Scalars['AWSDateTime']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  directReaderAddressing: DirectReaderAddressing;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  exploreInputs?: Maybe<Explore>;
  firstPersonPerspective?: Maybe<FirstPersonPerspective>;
  focusKeyword?: Maybe<Scalars['String']['output']>;
  hasTopRankings?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageGuidelines: ArticleImageGuidelines;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  neuraverseTopicData?: Maybe<ArticleNeuraverseTopicData>;
  pk1: Scalars['String']['output'];
  project?: Maybe<ProjectMetadata>;
  projectId: Scalars['ID']['output'];
  publicationDate?: Maybe<Scalars['AWSDateTime']['output']>;
  readingResources?: Maybe<Array<Scalars['String']['output']>>;
  requestOrigin?: Maybe<Scalars['String']['output']>;
  selectedProjectBriefDocuments?: Maybe<SelectedProjectBriefDocuments>;
  selectedProjectLabels?: Maybe<Array<ProjectLabel>>;
  sk1: Scalars['String']['output'];
  sk2?: Maybe<Scalars['String']['output']>;
  state: ArticleState;
  title?: Maybe<Scalars['String']['output']>;
  toneAndVoiceId?: Maybe<Scalars['ID']['output']>;
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  urlPath?: Maybe<Scalars['String']['output']>;
  userArticle?: Maybe<UserArticleData>;
  versionCreator?: Maybe<User>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId: Scalars['Int']['output'];
  versionName?: Maybe<Scalars['String']['output']>;
  wordCountPerArticle?: Maybe<Scalars['String']['output']>;
};

export type ArticleNeuraverseTopicData = {
  __typename?: 'ArticleNeuraverseTopicData';
  articleDirection?: Maybe<Scalars['String']['output']>;
  articleLengthSuggestion?: Maybe<Scalars['Int']['output']>;
  topicId: Scalars['String']['output'];
  topicNames: Array<Scalars['String']['output']>;
};

export type ArticleNeuraverseTopicDataInput = {
  articleDirection?: InputMaybe<Scalars['String']['input']>;
  articleLengthSuggestion?: InputMaybe<Scalars['Int']['input']>;
  topicId: Scalars['String']['input'];
  topicNames: Array<Scalars['String']['input']>;
};

export type ArticleNotificationSettings = {
  __typename?: 'ArticleNotificationSettings';
  email: Scalars['Boolean']['output'];
  inapp: Scalars['Boolean']['output'];
  notifyAll: Scalars['Boolean']['output'];
  notifyIfAssignee: Scalars['Boolean']['output'];
  notifyIfCreator: Scalars['Boolean']['output'];
};

export type ArticleNotificationSettingsInput = {
  email: Scalars['Boolean']['input'];
  inapp: Scalars['Boolean']['input'];
  notifyAll: Scalars['Boolean']['input'];
  notifyIfAssignee: Scalars['Boolean']['input'];
  notifyIfCreator: Scalars['Boolean']['input'];
};

export type ArticlePost = {
  __typename?: 'ArticlePost';
  advices?: Maybe<ArticlePostAdvices>;
  articlePostType: ArticlePostType;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  content?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  sk1: Scalars['String']['output'];
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ArticlePostAdvices = {
  __typename?: 'ArticlePostAdvices';
  advices?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  sk1: Scalars['String']['output'];
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ArticlePostDelete = {
  __typename?: 'ArticlePostDelete';
  id: Scalars['ID']['output'];
};

export enum ArticlePostType {
  LinkedIn = 'LinkedIn',
}

export type ArticleQuestion = {
  __typename?: 'ArticleQuestion';
  answered?: Maybe<Scalars['Boolean']['output']>;
  articleId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  type: ArticleBriefType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ArticleQuestionDelete = {
  __typename?: 'ArticleQuestionDelete';
  id: Scalars['ID']['output'];
};

export type ArticleQuestionInput = {
  answered?: InputMaybe<Scalars['Boolean']['input']>;
  articleId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  type?: InputMaybe<ArticleBriefType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleRScore = {
  __typename?: 'ArticleRScore';
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  doneAdvices?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  requestOrigin?: Maybe<Scalars['String']['output']>;
  rscore?: Maybe<Scalars['AWSJSON']['output']>;
  sk1: Scalars['String']['output'];
  sk2?: Maybe<Scalars['String']['output']>;
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId: Scalars['Int']['output'];
  versionName?: Maybe<Scalars['String']['output']>;
};

export type ArticleRootData = {
  __typename?: 'ArticleRootData';
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labelIds?: Maybe<Array<Scalars['ID']['output']>>;
  pk1: Scalars['String']['output'];
  priority?: Maybe<Scalars['Boolean']['output']>;
  requestOrigin?: Maybe<Scalars['String']['output']>;
  sk1: Scalars['String']['output'];
  sk2?: Maybe<Scalars['String']['output']>;
  type: ArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  urlIndexedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  urlStatus?: Maybe<ArticleUrlStatus>;
};

export enum ArticleState {
  Brief = 'BRIEF',
  Done = 'DONE',
  Onhold = 'ONHOLD',
  Review = 'REVIEW',
  Todo = 'TODO',
  Write = 'WRITE',
}

export type ArticleStatistics = {
  __typename?: 'ArticleStatistics';
  articleCountByState: ArticleCountByState;
  articleCountInProgress: Scalars['Int']['output'];
  completedArticlesThisMonth: Scalars['Int']['output'];
};

export type ArticleSubscription = {
  __typename?: 'ArticleSubscription';
  advices?: Maybe<Scalars['AWSJSON']['output']>;
  articleId?: Maybe<Scalars['String']['output']>;
  articlePostType?: Maybe<ArticlePostType>;
  assigneeId?: Maybe<Scalars['ID']['output']>;
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  audienceExpertiseId?: Maybe<Scalars['ID']['output']>;
  audiencePersonaId?: Maybe<Scalars['ID']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  commentId?: Maybe<Scalars['String']['output']>;
  companyKnowledgeIds?: Maybe<Array<Scalars['ID']['output']>>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  completedDate?: Maybe<Scalars['AWSDateTime']['output']>;
  content?: Maybe<Scalars['AWSJSON']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  directReaderAddressing?: Maybe<DirectReaderAddressing>;
  doneAdvices?: Maybe<Array<Scalars['String']['output']>>;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  exploreInputs?: Maybe<Explore>;
  filter?: Maybe<Scalars['String']['output']>;
  firstPersonPerspective?: Maybe<FirstPersonPerspective>;
  focusKeyword?: Maybe<Scalars['String']['output']>;
  guidelines?: Maybe<Scalars['AWSJSON']['output']>;
  hasTopRankings?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageGuidelines?: Maybe<ArticleImageGuidelines>;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  labelIds?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  neuraverseTopicData?: Maybe<ArticleNeuraverseTopicData>;
  pk1?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Scalars['AWSJSON']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  publicationDate?: Maybe<Scalars['AWSDateTime']['output']>;
  readingResources?: Maybe<Array<Scalars['String']['output']>>;
  requestOrigin?: Maybe<Scalars['String']['output']>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  rscore?: Maybe<Scalars['AWSJSON']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  sk2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ArticleState>;
  title?: Maybe<Scalars['String']['output']>;
  toneAndVoiceId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ArticleType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  urlIndexedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  urlPath?: Maybe<Scalars['String']['output']>;
  urlStatus?: Maybe<ArticleUrlStatus>;
  userId?: Maybe<Scalars['String']['output']>;
  versionCreatorId?: Maybe<Scalars['ID']['output']>;
  versionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionId?: Maybe<Scalars['Int']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
  wordCountPerArticle?: Maybe<Scalars['String']['output']>;
};

export type ArticleSubscriptionDelete = {
  __typename?: 'ArticleSubscriptionDelete';
  id?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ArticleSubscriptionInput = {
  advices?: InputMaybe<Scalars['AWSJSON']['input']>;
  articleId?: InputMaybe<Scalars['String']['input']>;
  articlePostType?: InputMaybe<ArticlePostType>;
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  audienceExpertiseId?: InputMaybe<Scalars['ID']['input']>;
  audiencePersonaId?: InputMaybe<Scalars['ID']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  commentId?: InputMaybe<Scalars['String']['input']>;
  companyKnowledgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  completedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  doneAdvices?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exploreInputs?: InputMaybe<ExploreInput>;
  filter?: InputMaybe<Scalars['String']['input']>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  focusKeyword?: InputMaybe<Scalars['String']['input']>;
  guidelines?: InputMaybe<Scalars['AWSJSON']['input']>;
  hasTopRankings?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageGuidelines?: InputMaybe<ArticleImageGuidelinesInput>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseTopicData?: InputMaybe<ArticleNeuraverseTopicDataInput>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<Scalars['AWSJSON']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  readingResources?: InputMaybe<Array<Scalars['String']['input']>>;
  requestOrigin?: InputMaybe<Scalars['String']['input']>;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  rscore?: InputMaybe<Scalars['AWSJSON']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  sk2?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ArticleState>;
  title?: InputMaybe<Scalars['String']['input']>;
  toneAndVoiceId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ArticleType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  urlIndexedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  urlStatus?: InputMaybe<ArticleUrlStatus>;
  userId?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
  wordCountPerArticle?: InputMaybe<Scalars['String']['input']>;
};

export enum ArticleType {
  Comment = 'COMMENT',
  CommentReply = 'COMMENT_REPLY',
  Document = 'DOCUMENT',
  Guidelines = 'GUIDELINES',
  Metadata = 'METADATA',
  Post = 'POST',
  PostAdvices = 'POST_ADVICES',
  RootData = 'ROOT_DATA',
  Rscore = 'RSCORE',
}

export enum ArticleUrlStatus {
  Error = 'ERROR',
  Indexed = 'INDEXED',
  Monitored = 'MONITORED',
  Online = 'ONLINE',
}

export type ArticlesWithSameFocusKeyword = {
  __typename?: 'ArticlesWithSameFocusKeyword';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum AudienceExpertise {
  Expert = 'EXPERT',
  General = 'GENERAL',
  Informed = 'INFORMED',
}

export type Auth0 = {
  __typename?: 'Auth0';
  id: Scalars['String']['output'];
  role: Auth0Role;
};

export type Auth0Input = {
  id: Scalars['String']['input'];
  role: Auth0Role;
};

export type Auth0Organization = {
  __typename?: 'Auth0Organization';
  branding?: Maybe<Auth0OrganizationBranding>;
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Auth0OrganizationBranding = {
  __typename?: 'Auth0OrganizationBranding';
  colors?: Maybe<Auth0OrganizationBrandingColors>;
  logo_url?: Maybe<Scalars['String']['output']>;
};

export type Auth0OrganizationBrandingColors = {
  __typename?: 'Auth0OrganizationBrandingColors';
  page_background?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
};

export type Auth0OrganizationBrandingInput = {
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  pageBackgroundColor: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
};

export enum Auth0Role {
  Admin = 'Admin',
  Member = 'Member',
}

export type BriefExportInput = {
  articleId: Scalars['String']['input'];
  exportId?: InputMaybe<Scalars['String']['input']>;
};

export type BrokenArticleUrl = {
  __typename?: 'BrokenArticleUrl';
  articleId: Scalars['String']['output'];
  articleTitle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type BrokenArticleUrlsOutput = {
  __typename?: 'BrokenArticleUrlsOutput';
  items: Array<BrokenArticleUrl>;
};

export type BrokenLink = {
  __typename?: 'BrokenLink';
  articleId: Scalars['String']['output'];
  articleTitle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  pk1: Scalars['String']['output'];
  sk1: Scalars['String']['output'];
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type BrokenLinksInArticle = {
  __typename?: 'BrokenLinksInArticle';
  items?: Maybe<Array<Scalars['String']['output']>>;
};

export type BrokenLinksInProjectOutput = {
  __typename?: 'BrokenLinksInProjectOutput';
  items: Array<BrokenLink>;
};

export type BulkExportInput = {
  articleIds: Array<Scalars['String']['input']>;
  exportId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  type: BulkExportInputType;
};

export enum BulkExportInputType {
  ArticleHtml = 'ArticleHtml',
  BriefDocx = 'BriefDocx',
  BriefPdf = 'BriefPdf',
}

export type BulkWordPressExportInput = {
  articleIds: Array<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  exportId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  saveAs: WordPressType;
};

export type CompareVersionsInput = {
  articleId1: Scalars['String']['input'];
  articleId2: Scalars['String']['input'];
};

export type CompareVersionsOutput = {
  __typename?: 'CompareVersionsOutput';
  comparedHtml: Scalars['String']['output'];
};

export type ConclusionByGptInput = {
  articleId: Scalars['String']['input'];
};

export type ContentByGptInput = {
  articleId: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export enum ContentCategoryEnum {
  Cc_01 = 'cc_01',
  Cc_02 = 'cc_02',
  Cc_03 = 'cc_03',
  Cc_04 = 'cc_04',
  Cc_05 = 'cc_05',
  Cc_06 = 'cc_06',
  Cc_07 = 'cc_07',
  Cc_08 = 'cc_08',
  Cc_09 = 'cc_09',
  Cc_10 = 'cc_10',
  Cc_11 = 'cc_11',
  Cc_12 = 'cc_12',
  Cc_13 = 'cc_13',
  Cc_14 = 'cc_14',
  Cc_15 = 'cc_15',
  Cc_16 = 'cc_16',
  Cc_17 = 'cc_17',
  Cc_18 = 'cc_18',
  Cc_19 = 'cc_19',
  Cc_20 = 'cc_20',
  Cc_21 = 'cc_21',
  Cc_22 = 'cc_22',
  Cc_23 = 'cc_23',
  Cc_24 = 'cc_24',
  Cc_25 = 'cc_25',
  Cc_26 = 'cc_26',
  Cc_27 = 'cc_27',
  Cc_28 = 'cc_28',
  Cc_29 = 'cc_29',
  Cc_30 = 'cc_30',
  Cc_31 = 'cc_31',
}

export type ContinueTextByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type CoreWebVital = {
  __typename?: 'CoreWebVital';
  value: Scalars['Float']['output'];
};

export type CoreWebVitals = {
  __typename?: 'CoreWebVitals';
  cumulativeLayoutShift: CoreWebVital;
  firstContentfulPaint: CoreWebVital;
  interactive: CoreWebVital;
  largestContentfulPaint: CoreWebVital;
};

export type CreateAnalyticsLogInput = {
  logType: AnalyticsLogType;
};

export type CreateApiConnectionInput = {
  connectionData?: InputMaybe<Scalars['AWSJSON']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  serviceName: ApiConnectionService;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticleBriefInput = {
  answered?: InputMaybe<Scalars['Boolean']['input']>;
  articleId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  keywordVariations?: InputMaybe<ArticleKeywordVariationsInput>;
  keywords: Array<ArticleKeywordInput>;
  level: Scalars['Int']['input'];
  questions: Array<ArticleQuestionInput>;
  relevance?: InputMaybe<Scalars['Float']['input']>;
  required: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  type: ArticleBriefType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<VolumeInput>>;
};

export type CreateArticleByNeuraverseInput = {
  assigneeId: Scalars['ID']['input'];
  audienceExpertiseId?: InputMaybe<Scalars['ID']['input']>;
  audiencePersonaId?: InputMaybe<Scalars['ID']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  companyKnowledgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  completedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing: DirectReaderAddressing;
  doneAdvices?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exploreInputs?: InputMaybe<ExploreInput>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  focusKeyword: Scalars['String']['input'];
  guidelines?: InputMaybe<Scalars['AWSJSON']['input']>;
  hasTopRankings?: InputMaybe<Scalars['Boolean']['input']>;
  imageGuidelines?: InputMaybe<ArticleImageGuidelinesInput>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  isUserCreatedDirection?: InputMaybe<Scalars['Boolean']['input']>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  neuraverseTopicData: ArticleNeuraverseTopicDataInput;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  readingResources?: InputMaybe<Array<Scalars['String']['input']>>;
  rscore?: InputMaybe<Scalars['AWSJSON']['input']>;
  state: ArticleState;
  title?: InputMaybe<Scalars['String']['input']>;
  toneAndVoiceId?: InputMaybe<Scalars['ID']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  urlIndexedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId: Scalars['Int']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
  wordCountPerArticle?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticleCommentInput = {
  articleId: Scalars['String']['input'];
  commentId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['AWSJSON']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticleHeadlineRefInput = {
  headlineId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  type: ArticleHeadlineRefType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticleHeadlineRefsInput = {
  headlineId: Scalars['String']['input'];
  items: Array<CreateArticleHeadlineRefsItemInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticleHeadlineRefsItemInput = {
  id: Scalars['String']['input'];
  type: ArticleHeadlineRefType;
};

export type CreateArticleInput = {
  assigneeId: Scalars['ID']['input'];
  audienceExpertiseId?: InputMaybe<Scalars['ID']['input']>;
  audiencePersonaId?: InputMaybe<Scalars['ID']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  companyKnowledgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  completedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing: DirectReaderAddressing;
  doneAdvices?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exploreInputs?: InputMaybe<ExploreInput>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  focusKeyword?: InputMaybe<Scalars['String']['input']>;
  guidelines?: InputMaybe<Scalars['AWSJSON']['input']>;
  hasTopRankings?: InputMaybe<Scalars['Boolean']['input']>;
  imageGuidelines?: InputMaybe<ArticleImageGuidelinesInput>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseTopicData?: InputMaybe<ArticleNeuraverseTopicDataInput>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  readingResources?: InputMaybe<Array<Scalars['String']['input']>>;
  rscore?: InputMaybe<Scalars['AWSJSON']['input']>;
  state: ArticleState;
  title?: InputMaybe<Scalars['String']['input']>;
  toneAndVoiceId?: InputMaybe<Scalars['ID']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  urlIndexedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId: Scalars['Int']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
  wordCountPerArticle?: InputMaybe<Scalars['String']['input']>;
};

export type CreateArticlePostInput = {
  articleId: Scalars['String']['input'];
  articlePostType: ArticlePostType;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvitationInput = {
  email: Scalars['String']['input'];
  projectInvitations: Array<InputMaybe<ProjectInvite>>;
  role: Auth0Role;
};

export type CreateInvitationsInput = {
  invitations: Array<CreateInvitationInput>;
  inviter: Scalars['String']['input'];
};

export type CreateLinkedinTokenInput = {
  code: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type CreateNeuraverseProjectInput = {
  name: Scalars['String']['input'];
  neuraverseId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type CreateNotificationInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  data?: InputMaybe<Scalars['AWSJSON']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  initiatedBy?: InputMaybe<Scalars['String']['input']>;
  notificationAction: NotificationAction;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  notificationStatus: NotificationStatus;
  notificationType: NotificationType;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CreateOneClickAnalysisInput = {
  projectId: Scalars['String']['input'];
};

export type CreateOrganizationInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Auth0OrganizationBrandingInput>;
  displayName: Scalars['String']['input'];
  inviteeEmails: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  project?: InputMaybe<CreateOrganizationProjectNeuraverseInput>;
};

export type CreateOrganizationProjectNeuraverseInput = {
  name: Scalars['String']['input'];
  neuraverseId: Scalars['String']['input'];
};

export type CreateProjectBriefDocumentInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  type: ProjectType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  gscConnected?: InputMaybe<Scalars['Boolean']['input']>;
  gscConnectionId?: InputMaybe<Scalars['ID']['input']>;
  linkedinConnected?: InputMaybe<Scalars['Boolean']['input']>;
  linkedinConnectionId?: InputMaybe<Scalars['ID']['input']>;
  linkedinOrgId?: InputMaybe<Scalars['String']['input']>;
  locale: Locale;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  topicInspirations?: InputMaybe<Array<Scalars['String']['input']>>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  wpConnected?: InputMaybe<Scalars['Boolean']['input']>;
  wpConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProjectLabelInput = {
  color: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUiViewInput = {
  calendarVisibility?: InputMaybe<Scalars['AWSJSON']['input']>;
  companyPriority?: InputMaybe<Scalars['Boolean']['input']>;
  entityId: Scalars['ID']['input'];
  expanded?: InputMaybe<Scalars['AWSJSON']['input']>;
  filtering?: InputMaybe<Scalars['AWSJSON']['input']>;
  index: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  showWinnersLosers?: InputMaybe<Scalars['Boolean']['input']>;
  sorting?: InputMaybe<Scalars['AWSJSON']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
  tableId: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type CreateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastReadNewsFeedPubDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  locale: Locale;
  nickname: Scalars['String']['input'];
  notificationSettings: NotificationSettingsInput;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userOrgData?: InputMaybe<UserOrgDataInput>;
};

export type CreateVersionInput = {
  articleId: Scalars['String']['input'];
};

export type CreatedOrganization = {
  __typename?: 'CreatedOrganization';
  auth0Organization?: Maybe<Auth0Organization>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  inviteeEmails?: Maybe<Invitations>;
  name: Scalars['String']['output'];
};

export type CustomField = {
  __typename?: 'CustomField';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomFieldInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteApiConnectionInput = {
  projectId: Scalars['ID']['input'];
  serviceName: ApiConnectionService;
};

export type DeleteArticleBriefInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticleCommentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticleHeadlineInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticleHeadlineRefInput = {
  headlineId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteArticleHeadlinesInput = {
  articleId: Scalars['String']['input'];
  headlines: Array<DeleteArticleHeadlineInput>;
};

export type DeleteArticleInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticleKeywordInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticlePostInput = {
  id: Scalars['ID']['input'];
};

export type DeleteArticleQuestionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteInvitationInput = {
  invitationId: Scalars['String']['input'];
};

export type DeleteNotificationInput = {
  id: Scalars['ID']['input'];
  notificationType: NotificationType;
};

export type DeleteOrganizationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectBriefDocumentInput = {
  id: Scalars['ID']['input'];
  type: ProjectType;
};

export type DeleteProjectInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectLabelInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUiViewInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteVersionInput = {
  articleId: Scalars['String']['input'];
};

export enum DirectReaderAddressing {
  Du = 'DU',
  No = 'NO',
  Sie = 'SIE',
  Yes = 'YES',
}

export enum EngineLanguage {
  CustomNeuraVerse = 'customNeuraVerse',
  Gpt = 'gpt',
}

export type Explore = {
  __typename?: 'Explore';
  contentCategory?: Maybe<Scalars['String']['output']>;
  currentTab?: Maybe<TabKey>;
  engineLanguage: EngineLanguage;
  searchTerms?: Maybe<Array<Scalars['String']['output']>>;
  searchedOnce?: Maybe<Scalars['Boolean']['output']>;
  subcategory?: Maybe<Scalars['String']['output']>;
  timeRange?: Maybe<Scalars['Int']['output']>;
  variety?: Maybe<Scalars['Float']['output']>;
};

export type ExploreInput = {
  contentCategory?: InputMaybe<Scalars['String']['input']>;
  currentTab?: InputMaybe<TabKey>;
  engineLanguage: EngineLanguage;
  searchTerms?: InputMaybe<Array<Scalars['String']['input']>>;
  searchedOnce?: InputMaybe<Scalars['Boolean']['input']>;
  subcategory?: InputMaybe<Scalars['String']['input']>;
  timeRange?: InputMaybe<Scalars['Int']['input']>;
  variety?: InputMaybe<Scalars['Float']['input']>;
};

export enum FirstPersonPerspective {
  NoPreference = 'NO_PREFERENCE',
  Plural = 'PLURAL',
  Singular = 'SINGULAR',
}

export type FocusKeywordsByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId: Scalars['String']['input'];
};

export enum FrequencyAggregation {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type GscAnalytics = {
  __typename?: 'GSCAnalytics';
  averageGoogleRanking?: Maybe<GscData>;
  averageGoogleRankingChange?: Maybe<Scalars['Int']['output']>;
  clicks?: Maybe<GscData>;
  ctr?: Maybe<GscData>;
  impressions?: Maybe<GscData>;
  items?: Maybe<GscData>;
  previousAverageGoogleRanking?: Maybe<GscData>;
  publishedArticles?: Maybe<GscPublishedArticles>;
  searchVolume?: Maybe<GscData>;
  updatesGoogle?: Maybe<GscGoogleUpdates>;
};

export type GscArticleAnalyticsInput = {
  articleId: Scalars['ID']['input'];
  end: Scalars['AWSDate']['input'];
  locale: Locale;
  projectId: Scalars['ID']['input'];
  start: Scalars['AWSDate']['input'];
};

export type GscArticleByKeyword = {
  __typename?: 'GSCArticleByKeyword';
  articleId?: Maybe<Scalars['ID']['output']>;
  averageGoogleRanking?: Maybe<GscData>;
  clicks?: Maybe<GscData>;
  ctr?: Maybe<GscData>;
  impressions?: Maybe<GscData>;
  searchVolume?: Maybe<GscKeywordSearchVolume>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GscArticleByProject = {
  __typename?: 'GSCArticleByProject';
  articleId?: Maybe<Scalars['ID']['output']>;
  averageGoogleRanking?: Maybe<GscData>;
  averageGoogleRankingChange?: Maybe<Scalars['Int']['output']>;
  clicks?: Maybe<GscData>;
  ctr?: Maybe<GscData>;
  id: Scalars['ID']['output'];
  impressions?: Maybe<GscData>;
  keywords?: Maybe<Scalars['Int']['output']>;
  previousAverageGoogleRanking?: Maybe<GscData>;
  publicationDate?: Maybe<Scalars['AWSDateTime']['output']>;
  rscore?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GscArticleKeyword = {
  __typename?: 'GSCArticleKeyword';
  averageGoogleRanking?: Maybe<GscData>;
  clicks?: Maybe<GscData>;
  ctr?: Maybe<GscData>;
  impressions?: Maybe<GscData>;
  keyword: Scalars['String']['output'];
};

export type GscArticlesByKeyword = {
  __typename?: 'GSCArticlesByKeyword';
  items?: Maybe<Array<Maybe<GscArticleByKeyword>>>;
};

export type GscArticlesByKeywordInput = {
  articleIds: Array<Scalars['ID']['input']>;
  end: Scalars['AWSDate']['input'];
  keyword: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  start: Scalars['AWSDate']['input'];
};

export type GscArticlesByProject = {
  __typename?: 'GSCArticlesByProject';
  items?: Maybe<Array<Maybe<GscArticleByProject>>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type GscArticlesByProjectInput = {
  end: Scalars['AWSDate']['input'];
  locale: Locale;
  projectId: Scalars['ID']['input'];
  start: Scalars['AWSDate']['input'];
};

export type GscConsentScreenUri = {
  __typename?: 'GSCConsentScreenUri';
  signInUri: Scalars['String']['output'];
};

export type GscConsentScreenUriInput = {
  state: Scalars['AWSJSON']['input'];
};

export type GscCreateTokenInput = {
  code: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type GscData = {
  __typename?: 'GSCData';
  data: Array<GscDataPoint>;
  resolution?: Maybe<GscResolution>;
  total: Scalars['Float']['output'];
  trend: Trend;
};

export type GscDataPoint = {
  __typename?: 'GSCDataPoint';
  date: Scalars['AWSDate']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type GscDomain = {
  __typename?: 'GSCDomain';
  permissionLevel: Scalars['String']['output'];
  siteUrl: Scalars['String']['output'];
};

export type GscDomains = {
  __typename?: 'GSCDomains';
  items?: Maybe<Array<GscDomain>>;
};

export type GscDomainsInput = {
  projectId: Scalars['ID']['input'];
};

export type GscGoogleUpdateDataPoint = {
  __typename?: 'GSCGoogleUpdateDataPoint';
  date: Scalars['AWSDate']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Language;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GscGoogleUpdates = {
  __typename?: 'GSCGoogleUpdates';
  data?: Maybe<Array<GscGoogleUpdateDataPoint>>;
};

export type GscKeywordSearchVolume = {
  __typename?: 'GSCKeywordSearchVolume';
  growth?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
  trend: Trend;
};

export type GscKeywordsByArticle = {
  __typename?: 'GSCKeywordsByArticle';
  items?: Maybe<Array<Maybe<GscArticleKeyword>>>;
};

export type GscKeywordsByArticleInput = {
  articleId: Scalars['ID']['input'];
  end: Scalars['AWSDate']['input'];
  start: Scalars['AWSDate']['input'];
};

export type GscKeywordsByProject = {
  __typename?: 'GSCKeywordsByProject';
  items?: Maybe<Array<Maybe<GscProjectKeyword>>>;
};

export type GscKeywordsByProjectInput = {
  articleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  end: Scalars['AWSDate']['input'];
  projectId: Scalars['ID']['input'];
  scope?: InputMaybe<GscScope>;
  start: Scalars['AWSDate']['input'];
};

export type GscProjectAnalyticsInput = {
  end: Scalars['AWSDate']['input'];
  locale: Locale;
  projectId: Scalars['ID']['input'];
  scope?: InputMaybe<GscScope>;
  start: Scalars['AWSDate']['input'];
};

export type GscProjectKeyword = {
  __typename?: 'GSCProjectKeyword';
  articleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  averageGoogleRanking?: Maybe<GscData>;
  clicks?: Maybe<GscData>;
  ctr?: Maybe<GscData>;
  impressions?: Maybe<GscData>;
  keyword: Scalars['String']['output'];
  searchVolume?: Maybe<GscKeywordSearchVolume>;
};

export type GscPublishedArticleDataPoint = {
  __typename?: 'GSCPublishedArticleDataPoint';
  date: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type GscPublishedArticles = {
  __typename?: 'GSCPublishedArticles';
  data?: Maybe<Array<GscPublishedArticleDataPoint>>;
};

export enum GscResolution {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum GscScope {
  All = 'ALL',
  Rellified = 'RELLIFIED',
}

export type GscTopChangingArticle = {
  __typename?: 'GSCTopChangingArticle';
  articleId: Scalars['ID']['output'];
  currentAverageGoogleRanking: Scalars['Int']['output'];
  previousAverageGoogleRanking: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type GscTopChangingArticles = {
  __typename?: 'GSCTopChangingArticles';
  items?: Maybe<Array<Maybe<GscTopChangingArticle>>>;
};

export enum GscTopChangingArticlesChange {
  Dropps = 'Dropps',
  Gains = 'Gains',
}

export type GenerateHeadlineByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};

export type GenerateHeadlineSuggestionsByGptInput = {
  articleId: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  level: Scalars['Int']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  outline: Array<ArticleHeadlineInput>;
};

export type GenerateImageByDallEInput = {
  articleId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateKeywordVariationsByGptInput = {
  articleTitle: Scalars['String']['input'];
  keywordId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateMetadataByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
};

export type GetArticleCommentInput = {
  id: Scalars['ID']['input'];
};

export type GetArticlePostInput = {
  id: Scalars['ID']['input'];
};

export type GetGscConnectionStatusInput = {
  projectId: Scalars['String']['input'];
};

export type GetGoogleTopRankingsInput = {
  articleId: Scalars['String']['input'];
  searchTerm: Scalars['String']['input'];
};

export type GetLinkedinConnectionStatusInput = {
  projectId: Scalars['String']['input'];
};

export type GetNVeFilterDataInput = {
  competitorTopics?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  myTopics?: InputMaybe<Scalars['Boolean']['input']>;
  onlySaved?: InputMaybe<Scalars['Boolean']['input']>;
  opportunities?: InputMaybe<Scalars['Boolean']['input']>;
  strengths?: InputMaybe<Scalars['Boolean']['input']>;
  topicGaps?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetNVeMetadataInput = {
  id: Scalars['String']['input'];
};

export type GetNVeTopicInput = {
  id: Scalars['String']['input'];
};

export type GetNotificationInput = {
  id: Scalars['ID']['input'];
  notificationType: NotificationType;
};

export type GetOneClickAnalysisInput = {
  projectId: Scalars['String']['input'];
  type: OcaDevice;
};

export type GetProjectBriefDocumentUsageCountInput = {
  id: Scalars['String']['input'];
  type: ProjectType;
};

export type GetProjectLabelUsageCountInput = {
  id: Scalars['ID']['input'];
};

export type GetTurnServerCredentialsInput = {
  articleId: Scalars['String']['input'];
};

export type GetTurnServerCredentialsResponse = {
  __typename?: 'GetTURNServerCredentialsResponse';
  iceServers: Array<IceServer>;
};

export type GetWordpressConnectionInput = {
  projectId: Scalars['String']['input'];
};

export type GetWordpressConnectionResponse = {
  __typename?: 'GetWordpressConnectionResponse';
  endpoint: Scalars['String']['output'];
  errors?: Maybe<Array<Maybe<WordpressConnectionError>>>;
  username: Scalars['String']['output'];
};

export type GoogleSearchResult = {
  __typename?: 'GoogleSearchResult';
  outline?: Maybe<Array<Maybe<GoogleSearchResultHeadline>>>;
  position: Scalars['Int']['output'];
  status: TopRankingStatus;
  summary?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GoogleSearchResultHeadline = {
  __typename?: 'GoogleSearchResultHeadline';
  level: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type GoogleSearchUpdate = {
  __typename?: 'GoogleSearchUpdate';
  date: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GoogleTopRanking = {
  __typename?: 'GoogleTopRanking';
  averageWordCount?: Maybe<Scalars['Int']['output']>;
  locale?: Maybe<Locale>;
  searchResults?: Maybe<Array<GoogleSearchResult>>;
  searchTerm: Scalars['String']['output'];
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  urlCount?: Maybe<Scalars['Int']['output']>;
};

export type GscConnectionCredentials = {
  __typename?: 'GscConnectionCredentials';
  domain: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum GscConnectionStatus {
  Connected = 'connected',
  NotConnected = 'notConnected',
  PermissionError = 'permissionError',
  TokenError = 'tokenError',
}

export type GscConnectionStatusResponse = {
  __typename?: 'GscConnectionStatusResponse';
  status: GscConnectionStatus;
};

export enum HomeDashboardTileName {
  Gsc = 'GSC',
  NeuraverseBanner = 'NeuraverseBanner',
  NewProjectTips = 'NewProjectTips',
}

export type HtmlExport = {
  __typename?: 'HtmlExport';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type HtmlExportInput = {
  articleId: Scalars['ID']['input'];
  type: HtmlExportInputType;
};

export enum HtmlExportInputType {
  Content = 'CONTENT',
  Guidelines = 'GUIDELINES',
}

export type IceServer = {
  __typename?: 'ICEServer';
  credential?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum ImageMoodAndTone {
  Dark = 'dark',
  Humorous = 'humorous',
  Inspirational = 'inspirational',
  Light = 'light',
  Playful = 'playful',
  Professional = 'professional',
  Serious = 'serious',
}

export enum ImageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square',
}

export enum ImageStyle {
  Natural = 'natural',
  Vivid = 'vivid',
}

export enum ImageType {
  Abstract = 'abstract',
  Comic = 'comic',
  Illustration = 'illustration',
  Photography = 'photography',
}

export type ImportArticleInput = {
  assigneeId: Scalars['String']['input'];
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  neuraverseTopicData: ArticleNeuraverseTopicDataInput;
  projectId: Scalars['String']['input'];
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type IndexedArticle = {
  __typename?: 'IndexedArticle';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  urlIndexedAt: Scalars['String']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  app_metadata?: Maybe<Scalars['AWSJSON']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  connection_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitation_url?: Maybe<Scalars['String']['output']>;
  invitee?: Maybe<InvitationInvitee>;
  inviter?: Maybe<InvitationInviter>;
  organization_id?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ticket_id?: Maybe<Scalars['String']['output']>;
  user_metadata?: Maybe<Scalars['AWSJSON']['output']>;
};

export type InvitationInvitee = {
  __typename?: 'InvitationInvitee';
  email: Scalars['String']['output'];
};

export type InvitationInviter = {
  __typename?: 'InvitationInviter';
  name: Scalars['String']['output'];
};

export type Invitations = {
  __typename?: 'Invitations';
  items?: Maybe<Array<Invitation>>;
};

export type KeywordVolume = {
  __typename?: 'KeywordVolume';
  articleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt: Scalars['AWSDate']['output'];
  id: Scalars['ID']['output'];
  keyword?: Maybe<Scalars['String']['output']>;
  locale: Locale;
  ttl: Scalars['AWSTimestamp']['output'];
  updatedAt: Scalars['AWSDate']['output'];
  volumes?: Maybe<Array<Maybe<VolumeElement>>>;
};

export enum Language {
  De = 'de',
  En = 'en',
}

export type LastProductUpdate = {
  __typename?: 'LastProductUpdate';
  excerpt: Scalars['String']['output'];
  pubDate: Scalars['AWSDateTime']['output'];
  url: Scalars['String']['output'];
};

export type LatestIndexedArticles = {
  __typename?: 'LatestIndexedArticles';
  articles: Array<IndexedArticle>;
};

export type LinkNeuraverseToProjectInput = {
  id: Scalars['String']['input'];
  neuraverseId: Scalars['String']['input'];
};

export type LinkedInPostByGptInput = {
  articleId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  selectedText?: InputMaybe<Scalars['String']['input']>;
};

export enum LinkedinConnectionStatus {
  Connected = 'connected',
  NotConnected = 'notConnected',
  PermissionError = 'permissionError',
  TokenError = 'tokenError',
}

export type LinkedinConnectionStatusResponse = {
  __typename?: 'LinkedinConnectionStatusResponse';
  status: LinkedinConnectionStatus;
};

export type LinkedinConsentScreenUri = {
  __typename?: 'LinkedinConsentScreenUri';
  signInUri: Scalars['String']['output'];
};

export type LinkedinConsentScreenUriInput = {
  state: Scalars['AWSJSON']['input'];
};

export type LinkedinOrganization = {
  __typename?: 'LinkedinOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ListApiConnections = {
  __typename?: 'ListApiConnections';
  items: Array<ApiConnection>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListArticleBriefs = {
  __typename?: 'ListArticleBriefs';
  items: Array<ArticleBrief>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListArticleCommentInput = {
  articleId: Scalars['ID']['input'];
};

export type ListArticleHeadlinesType = {
  __typename?: 'ListArticleHeadlinesType';
  items: Array<ArticleHeadline>;
};

export type ListArticleImagesInput = {
  articleId: Scalars['ID']['input'];
};

export type ListArticlePostsInput = {
  articleId: Scalars['ID']['input'];
};

export type ListArticlePostsOutput = {
  __typename?: 'ListArticlePostsOutput';
  items: Array<ArticlePost>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListArticles = {
  __typename?: 'ListArticles';
  items: Array<ArticleMetadata>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListArticlesInputFilter = {
  assigneeId?: InputMaybe<ModelIdFilterInput>;
  audienceExpertiseId?: InputMaybe<ModelIdFilterInput>;
  audiencePersonaId?: InputMaybe<ModelIdFilterInput>;
  avoid?: InputMaybe<ModelStringFilterInput>;
  companyKnowledgeIds?: InputMaybe<ModelIdFilterInput>;
  competitorsUrls?: InputMaybe<ModelStringFilterInput>;
  completedDate?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  createdBy?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  directReaderAddressing?: InputMaybe<ModelStringFilterInput>;
  dueDate?: InputMaybe<ModelStringFilterInput>;
  firstPersonPerspective?: InputMaybe<ModelStringFilterInput>;
  focusKeyword?: InputMaybe<ModelStringFilterInput>;
  hasTopRankings?: InputMaybe<ModelBooleanFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  inputTopics?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  pk1?: InputMaybe<ModelStringFilterInput>;
  projectId?: InputMaybe<ModelIdFilterInput>;
  publicationDate?: InputMaybe<ModelStringFilterInput>;
  readingResources?: InputMaybe<ModelStringFilterInput>;
  requestOrigin?: InputMaybe<ModelStringFilterInput>;
  sk1?: InputMaybe<ModelStringFilterInput>;
  sk2?: InputMaybe<ModelStringFilterInput>;
  state?: InputMaybe<ModelStringFilterInput>;
  title?: InputMaybe<ModelStringFilterInput>;
  toneAndVoiceId?: InputMaybe<ModelIdFilterInput>;
  type?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  updatedBy?: InputMaybe<ModelStringFilterInput>;
  urlPath?: InputMaybe<ModelStringFilterInput>;
  versionCreatorId?: InputMaybe<ModelIdFilterInput>;
  versionDate?: InputMaybe<ModelStringFilterInput>;
  versionId?: InputMaybe<ModelIntFilterInput>;
  versionName?: InputMaybe<ModelStringFilterInput>;
  wordCountPerArticle?: InputMaybe<ModelStringFilterInput>;
};

export type ListArticlesWithSameFocusKeyword = {
  __typename?: 'ListArticlesWithSameFocusKeyword';
  items: Array<ArticlesWithSameFocusKeyword>;
};

export type ListArticlesWithSameFocusKeywordInput = {
  articleId: Scalars['ID']['input'];
  focusKeyword: Scalars['String']['input'];
};

export type ListBrokenArticleUrlsInput = {
  projectId: Scalars['ID']['input'];
};

export type ListBrokenLinksInArticleInput = {
  articleId: Scalars['ID']['input'];
};

export type ListBrokenLinksInProjectInput = {
  projectId: Scalars['ID']['input'];
};

export type ListCommentsOutput = {
  __typename?: 'ListCommentsOutput';
  items: Array<ArticleComment>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListGscTopChangingArticlesInput = {
  change: GscTopChangingArticlesChange;
  projectId: Scalars['String']['input'];
};

export type ListLatestIndexedArticlesInput = {
  projectId: Scalars['String']['input'];
};

export type ListLinkedinOrganizationsInput = {
  projectId: Scalars['String']['input'];
};

export type ListNVeCompaniesInput = {
  id: Scalars['String']['input'];
};

export type ListNVeTopicArticlesInput = {
  id: Scalars['String']['input'];
  lastEvaluatedKey?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  onlySaved?: InputMaybe<Scalars['Boolean']['input']>;
  searchLocale?: InputMaybe<Locale>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  types: Array<NVeTopicArticleType>;
};

export type ListNVeTopicCompanyDataInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ListNVeTopicsInput = {
  competitorTopics?: InputMaybe<Scalars['Boolean']['input']>;
  existingCustomerArticlesTopicTypes?: InputMaybe<
    Array<InputMaybe<NVeTopicArticleType>>
  >;
  id: Scalars['String']['input'];
  myTopics?: InputMaybe<Scalars['Boolean']['input']>;
  onlySaved?: InputMaybe<Scalars['Boolean']['input']>;
  opportunities?: InputMaybe<Scalars['Boolean']['input']>;
  strengths?: InputMaybe<Scalars['Boolean']['input']>;
  topicGaps?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListNotifications = {
  __typename?: 'ListNotifications';
  items: Array<Notification>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListOrganizations = {
  __typename?: 'ListOrganizations';
  items: Array<Organization>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListProjects = {
  __typename?: 'ListProjects';
  items: Array<ProjectMetadata>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListProjectsInputFilter = {
  archived?: InputMaybe<ModelBooleanFilterInput>;
  avatar?: InputMaybe<ModelStringFilterInput>;
  color?: InputMaybe<ModelStringFilterInput>;
  country?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  createdBy?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  domain?: InputMaybe<ModelStringFilterInput>;
  gscConnected?: InputMaybe<ModelBooleanFilterInput>;
  gscConnectionId?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelStringFilterInput>;
  linkedinConnected?: InputMaybe<ModelBooleanFilterInput>;
  linkedinConnectionId?: InputMaybe<ModelIdFilterInput>;
  linkedinOrgId?: InputMaybe<ModelStringFilterInput>;
  locale?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  neuraverseId?: InputMaybe<ModelStringFilterInput>;
  organizationId?: InputMaybe<ModelIdFilterInput>;
  pk1?: InputMaybe<ModelStringFilterInput>;
  sk1?: InputMaybe<ModelStringFilterInput>;
  topicInspirations?: InputMaybe<ModelStringFilterInput>;
  ttl?: InputMaybe<ModelIntFilterInput>;
  type?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  updatedBy?: InputMaybe<ModelStringFilterInput>;
  wpConnected?: InputMaybe<ModelBooleanFilterInput>;
  wpConnectionId?: InputMaybe<ModelIdFilterInput>;
};

export type ListUiViews = {
  __typename?: 'ListUiViews';
  items: Array<UiView>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListUsers = {
  __typename?: 'ListUsers';
  items: Array<User>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ListVersionsInput = {
  articleId: Scalars['String']['input'];
};

export type ListWordpressCategoriesInput = {
  projectId: Scalars['ID']['input'];
};

export type ListWordpressCategoriesResponse = {
  __typename?: 'ListWordpressCategoriesResponse';
  items?: Maybe<Array<WordpressCategory>>;
};

export enum Locale {
  DeAt = 'de_AT',
  DeCh = 'de_CH',
  DeDe = 'de_DE',
  EnGb = 'en_GB',
  EnIe = 'en_IE',
  EnUs = 'en_US',
}

export type LoginInput = {
  auth0: Auth0Input;
  email: Scalars['AWSEmail']['input'];
  locale: Locale;
  nickname: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  projectInvitations?: InputMaybe<Array<ProjectInvite>>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchKeywordsAndQuestionsByGptInput = {
  articleId: Scalars['String']['input'];
  notificationId?: InputMaybe<Scalars['String']['input']>;
};

export type ModelApiConnectionFilterInput = {
  connectionData?: InputMaybe<ModelStringFilterInput>;
  organizationId?: InputMaybe<ModelIdFilterInput>;
  serviceName?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
};

export type ModelArticleBriefFilterInput = {
  answered?: InputMaybe<ModelBooleanFilterInput>;
  articleId?: InputMaybe<ModelStringFilterInput>;
  content?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  createdBy?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  hidden?: InputMaybe<ModelBooleanFilterInput>;
  id?: InputMaybe<ModelStringFilterInput>;
  index?: InputMaybe<ModelIntFilterInput>;
  level?: InputMaybe<ModelIntFilterInput>;
  relevance?: InputMaybe<ModelFloatFilterInput>;
  required?: InputMaybe<ModelBooleanFilterInput>;
  title?: InputMaybe<ModelStringFilterInput>;
  type?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  updatedBy?: InputMaybe<ModelStringFilterInput>;
};

export type ModelArticleFilterInput = {
  advices?: InputMaybe<ModelStringFilterInput>;
  articleId?: InputMaybe<ModelStringFilterInput>;
  articlePostType?: InputMaybe<ModelStringFilterInput>;
  assigneeId?: InputMaybe<ModelIdFilterInput>;
  attachments?: InputMaybe<ModelStringFilterInput>;
  audienceExpertiseId?: InputMaybe<ModelIdFilterInput>;
  audiencePersonaId?: InputMaybe<ModelIdFilterInput>;
  avoid?: InputMaybe<ModelStringFilterInput>;
  commentId?: InputMaybe<ModelStringFilterInput>;
  companyKnowledgeIds?: InputMaybe<ModelIdFilterInput>;
  competitorsUrls?: InputMaybe<ModelStringFilterInput>;
  completedDate?: InputMaybe<ModelStringFilterInput>;
  content?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  createdBy?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  directReaderAddressing?: InputMaybe<ModelStringFilterInput>;
  doneAdvices?: InputMaybe<ModelStringFilterInput>;
  dueDate?: InputMaybe<ModelStringFilterInput>;
  firstPersonPerspective?: InputMaybe<ModelStringFilterInput>;
  focusKeyword?: InputMaybe<ModelStringFilterInput>;
  guidelines?: InputMaybe<ModelStringFilterInput>;
  hasTopRankings?: InputMaybe<ModelBooleanFilterInput>;
  id?: InputMaybe<ModelIdFilterInput>;
  inputTopics?: InputMaybe<ModelStringFilterInput>;
  labelIds?: InputMaybe<ModelIdFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  preferences?: InputMaybe<ModelStringFilterInput>;
  priority?: InputMaybe<ModelBooleanFilterInput>;
  projectId?: InputMaybe<ModelIdFilterInput>;
  publicationDate?: InputMaybe<ModelStringFilterInput>;
  readingResources?: InputMaybe<ModelStringFilterInput>;
  requestOrigin?: InputMaybe<ModelStringFilterInput>;
  resolved?: InputMaybe<ModelBooleanFilterInput>;
  rscore?: InputMaybe<ModelStringFilterInput>;
  sk2?: InputMaybe<ModelStringFilterInput>;
  state?: InputMaybe<ModelStringFilterInput>;
  title?: InputMaybe<ModelStringFilterInput>;
  toneAndVoiceId?: InputMaybe<ModelIdFilterInput>;
  type?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  updatedBy?: InputMaybe<ModelStringFilterInput>;
  urlIndexedAt?: InputMaybe<ModelStringFilterInput>;
  urlPath?: InputMaybe<ModelStringFilterInput>;
  urlStatus?: InputMaybe<ModelStringFilterInput>;
  versionCreatorId?: InputMaybe<ModelIdFilterInput>;
  versionDate?: InputMaybe<ModelStringFilterInput>;
  versionId?: InputMaybe<ModelIntFilterInput>;
  versionName?: InputMaybe<ModelStringFilterInput>;
  wordCountPerArticle?: InputMaybe<ModelStringFilterInput>;
};

export type ModelBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelFloatFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
};

export type ModelFloatKeyConditionInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
};

export type ModelIdFilterInput = {
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
};

export type ModelIdKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
};

export type ModelIntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelIntKeyConditionInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelNotificationFilterInput = {
  data?: InputMaybe<ModelStringFilterInput>;
  entityId?: InputMaybe<ModelStringFilterInput>;
  id?: InputMaybe<ModelStringFilterInput>;
  initiatedBy?: InputMaybe<ModelStringFilterInput>;
  notificationAction?: InputMaybe<ModelStringFilterInput>;
  notificationId?: InputMaybe<ModelStringFilterInput>;
  notificationStatus?: InputMaybe<ModelStringFilterInput>;
  notificationType?: InputMaybe<ModelStringFilterInput>;
  read?: InputMaybe<ModelBooleanFilterInput>;
  ttl?: InputMaybe<ModelIntFilterInput>;
};

export type ModelOrganizationFilterInput = {
  auth0Id?: InputMaybe<ModelStringFilterInput>;
  avatar?: InputMaybe<ModelStringFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
};

export type ModelProjectFilterInput = {
  allowGenAI?: InputMaybe<ModelBooleanFilterInput>;
  archived?: InputMaybe<ModelBooleanFilterInput>;
  avatar?: InputMaybe<ModelStringFilterInput>;
  avoid?: InputMaybe<ModelStringFilterInput>;
  color?: InputMaybe<ModelStringFilterInput>;
  competitorsUrls?: InputMaybe<ModelStringFilterInput>;
  content?: InputMaybe<ModelStringFilterInput>;
  country?: InputMaybe<ModelStringFilterInput>;
  createdAt?: InputMaybe<ModelStringFilterInput>;
  createdBy?: InputMaybe<ModelStringFilterInput>;
  custom?: InputMaybe<ModelBooleanFilterInput>;
  description?: InputMaybe<ModelStringFilterInput>;
  directReaderAddressing?: InputMaybe<ModelStringFilterInput>;
  domain?: InputMaybe<ModelStringFilterInput>;
  dueDate?: InputMaybe<ModelStringFilterInput>;
  firstPersonPerspective?: InputMaybe<ModelStringFilterInput>;
  gscConnected?: InputMaybe<ModelBooleanFilterInput>;
  gscConnectionId?: InputMaybe<ModelIdFilterInput>;
  id?: InputMaybe<ModelStringFilterInput>;
  imageType?: InputMaybe<ModelStringFilterInput>;
  includedInArticleBrief?: InputMaybe<ModelStringFilterInput>;
  inputTopics?: InputMaybe<ModelStringFilterInput>;
  linkedinConnected?: InputMaybe<ModelBooleanFilterInput>;
  linkedinConnectionId?: InputMaybe<ModelIdFilterInput>;
  linkedinOrgId?: InputMaybe<ModelStringFilterInput>;
  locale?: InputMaybe<ModelStringFilterInput>;
  moodAndTone?: InputMaybe<ModelStringFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  neuraverseId?: InputMaybe<ModelStringFilterInput>;
  organizationId?: InputMaybe<ModelIdFilterInput>;
  orientation?: InputMaybe<ModelStringFilterInput>;
  planedArticles?: InputMaybe<ModelIntFilterInput>;
  planedArticlesPerMonth?: InputMaybe<ModelIntFilterInput>;
  projectId?: InputMaybe<ModelIdFilterInput>;
  selected?: InputMaybe<ModelBooleanFilterInput>;
  startDate?: InputMaybe<ModelStringFilterInput>;
  style?: InputMaybe<ModelStringFilterInput>;
  topicInspirations?: InputMaybe<ModelStringFilterInput>;
  ttl?: InputMaybe<ModelIntFilterInput>;
  type?: InputMaybe<ModelStringFilterInput>;
  updateToken?: InputMaybe<ModelStringFilterInput>;
  updatedAt?: InputMaybe<ModelStringFilterInput>;
  updatedBy?: InputMaybe<ModelStringFilterInput>;
  wpConnected?: InputMaybe<ModelBooleanFilterInput>;
  wpConnectionId?: InputMaybe<ModelIdFilterInput>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ModelStringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUiViewFilterInput = {
  calendarVisibility?: InputMaybe<ModelStringFilterInput>;
  companyPriority?: InputMaybe<ModelBooleanFilterInput>;
  entityId?: InputMaybe<ModelStringFilterInput>;
  expanded?: InputMaybe<ModelStringFilterInput>;
  filtering?: InputMaybe<ModelStringFilterInput>;
  index?: InputMaybe<ModelIntFilterInput>;
  name?: InputMaybe<ModelStringFilterInput>;
  priority?: InputMaybe<ModelBooleanFilterInput>;
  searchTerm?: InputMaybe<ModelStringFilterInput>;
  showWinnersLosers?: InputMaybe<ModelBooleanFilterInput>;
  sorting?: InputMaybe<ModelStringFilterInput>;
  tab?: InputMaybe<ModelStringFilterInput>;
  tableId?: InputMaybe<ModelStringFilterInput>;
  visibility?: InputMaybe<ModelStringFilterInput>;
};

export type ModelUserFilterInput = {
  avatar?: InputMaybe<ModelStringFilterInput>;
  color?: InputMaybe<ModelStringFilterInput>;
  email?: InputMaybe<ModelStringFilterInput>;
  firstName?: InputMaybe<ModelStringFilterInput>;
  lastName?: InputMaybe<ModelStringFilterInput>;
  lastReadNewsFeedPubDate?: InputMaybe<ModelStringFilterInput>;
  locale?: InputMaybe<ModelStringFilterInput>;
  nickname?: InputMaybe<ModelStringFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkExport: Scalars['String']['output'];
  bulkWordPressExport: Scalars['String']['output'];
  createAnalyticsLog: Scalars['Boolean']['output'];
  createArticle: ArticleMetadata;
  createArticleByNeuraverse: Notification;
  createArticleComment: ArticleComment;
  createArticleHeadlineRef: ArticleHeadline;
  createArticleHeadlineRefs: ArticleHeadline;
  createArticlePost: ArticlePost;
  createArticleVersion: ArticleMetadata;
  createGSCToken: ProjectMetadata;
  createInvitations?: Maybe<Invitations>;
  createLinkedinToken: ProjectMetadata;
  createLogin?: Maybe<User>;
  createNeuraverseProject: ProjectMetadata;
  createOneClickAnalysis?: Maybe<OneClickAnalysisStatus>;
  createOrganization?: Maybe<CreatedOrganization>;
  createProject: ProjectMetadata;
  createProjectBriefDocument: ProjectBriefDocument;
  createProjectLabel: ProjectLabel;
  createTopicInspirations?: Maybe<Scalars['String']['output']>;
  createUiView?: Maybe<UiView>;
  deleteApiConnection: ProjectMetadata;
  deleteArticle: ArticleDelete;
  deleteArticleComment: ArticleCommentDelete;
  deleteArticleHeadline?: Maybe<ArticleHeadlineDelete>;
  deleteArticleHeadlineRef: ArticleHeadline;
  deleteArticleHeadlines: Array<ArticleHeadlineDelete>;
  deleteArticleKeyword?: Maybe<ArticleKeywordDelete>;
  deleteArticlePost: ArticlePostDelete;
  deleteArticleQuestion?: Maybe<ArticleQuestionDelete>;
  deleteArticleVersion: ArticleMetadata;
  deleteInvitation?: Maybe<Invitation>;
  deleteNotification: NotificationDelete;
  deleteOrganization?: Maybe<OrganizationDelete>;
  deleteProject: ProjectDelete;
  deleteProjectBriefDocument: ProjectBriefDocumentDelete;
  deleteProjectLabel: ProjectLabelDelete;
  deleteUiView?: Maybe<UiViewDelete>;
  deleteUser: User;
  generateArticleByGPT: Notification;
  generateContentByGPT: Notification;
  generateMetadataByGPT: Notification;
  generateOutlineByGPT: Notification;
  grantUserRoles?: Maybe<AclRoleAssignment>;
  importArticle: Notification;
  linkNeuraverseToProject: Scalars['Boolean']['output'];
  matchKeywordsAndQuestionsByGPT: Notification;
  onApiConnectionCreateMutation?: Maybe<ApiConnectionSubscription>;
  onApiConnectionDeleteMutation?: Maybe<ApiConnectionSubscriptionDelete>;
  onApiConnectionUpdateMutation?: Maybe<ApiConnectionSubscription>;
  onArticleBriefCreateMutation?: Maybe<ArticleBriefSubscription>;
  onArticleBriefDeleteMutation?: Maybe<ArticleBriefSubscriptionDelete>;
  onArticleBriefUpdateMutation?: Maybe<ArticleBriefSubscription>;
  onArticleCreateMutation?: Maybe<ArticleSubscription>;
  onArticleDeleteMutation?: Maybe<ArticleSubscriptionDelete>;
  onArticleUpdateMutation?: Maybe<ArticleSubscription>;
  onNVePriorityUpdateMutation?: Maybe<NVePriorityUpdateSubscription>;
  onNVeTopicSavedUpdateMutation?: Maybe<NVeTopicSavedUpdateSubscription>;
  onNotificationCreateMutation?: Maybe<NotificationSubscription>;
  onNotificationDeleteMutation?: Maybe<NotificationSubscriptionDelete>;
  onNotificationUpdateMutation?: Maybe<NotificationSubscription>;
  onOrganizationCreateMutation?: Maybe<OrganizationSubscription>;
  onOrganizationDeleteMutation?: Maybe<OrganizationSubscriptionDelete>;
  onOrganizationUpdateMutation?: Maybe<OrganizationSubscription>;
  onProjectCreateMutation?: Maybe<ProjectSubscription>;
  onProjectDeleteMutation?: Maybe<ProjectSubscriptionDelete>;
  onProjectUpdateMutation?: Maybe<ProjectSubscription>;
  onUiViewCreateMutation?: Maybe<UiViewSubscription>;
  onUiViewDeleteMutation?: Maybe<UiViewSubscriptionDelete>;
  onUiViewUpdateMutation?: Maybe<UiViewSubscription>;
  onUserCreateMutation?: Maybe<UserSubscription>;
  onUserDeleteMutation?: Maybe<UserSubscriptionDelete>;
  onUserUpdateMutation?: Maybe<UserSubscription>;
  publishArticlePost: PublishArticlePostOutput;
  readNotifications: Array<Notification>;
  resetChannel?: Maybe<Scalars['Boolean']['output']>;
  restoreArticleVersion: ArticleMetadata;
  revokeUserRoles?: Maybe<AclRoleAssignment>;
  triggerRScore?: Maybe<Scalars['Boolean']['output']>;
  updateArticleComment: ArticleComment;
  updateArticleDocument: ArticleDocument;
  updateArticleGuidelines: ArticleGuidelines;
  updateArticleHeadlines: Array<ArticleHeadline>;
  updateArticleKeywords: Array<ArticleKeyword>;
  updateArticleMetadata: ArticleMetadata;
  updateArticlePost: ArticlePost;
  updateArticleQuestions: Array<ArticleQuestion>;
  updateArticleRScore: ArticleRScore;
  updateArticleRootData: ArticleRootData;
  updateAuth0Role: Auth0Role;
  updateNVeCompanyPriority: NVeCompany;
  updateNVeTopicArticlePriority: NVeTopicArticle;
  updateNVeTopicPriority: NVeTopic;
  updateNVeTopicSaved: NVeTopic;
  updateOrganization?: Maybe<Organization>;
  updateProjectBriefDocument: ProjectBriefDocument;
  updateProjectBriefDocuments: Array<ProjectBriefDocument>;
  updateProjectBriefMetadata: ProjectBriefMetadata;
  updateProjectImageGuidelines: ProjectImageGuidelines;
  updateProjectLabel: ProjectLabel;
  updateProjectMetadata: ProjectMetadata;
  updateUiView?: Maybe<UiView>;
  updateUser?: Maybe<User>;
  updateUserArticleData?: Maybe<UserArticleData>;
  updateUserProjectData?: Maybe<UserProjectData>;
  upsertArticleHeadline: ArticleHeadline;
  upsertArticleHeadlines: Array<ArticleHeadline>;
  upsertArticleKeyword: ArticleKeyword;
  upsertArticleKeywords: Array<ArticleKeyword>;
  upsertArticleQuestion: ArticleQuestion;
  upsertArticleQuestions: Array<ArticleQuestion>;
  upsertWordPressConnection?: Maybe<UpsertWordpressConnectionResponse>;
  wpExport: Scalars['String']['output'];
};

export type MutationBulkExportArgs = {
  input: BulkExportInput;
};

export type MutationBulkWordPressExportArgs = {
  input: BulkWordPressExportInput;
};

export type MutationCreateAnalyticsLogArgs = {
  input: CreateAnalyticsLogInput;
};

export type MutationCreateArticleArgs = {
  input: CreateArticleInput;
};

export type MutationCreateArticleByNeuraverseArgs = {
  input: CreateArticleByNeuraverseInput;
};

export type MutationCreateArticleCommentArgs = {
  input: CreateArticleCommentInput;
};

export type MutationCreateArticleHeadlineRefArgs = {
  input: CreateArticleHeadlineRefInput;
};

export type MutationCreateArticleHeadlineRefsArgs = {
  input: CreateArticleHeadlineRefsInput;
};

export type MutationCreateArticlePostArgs = {
  input: CreateArticlePostInput;
};

export type MutationCreateArticleVersionArgs = {
  input: CreateVersionInput;
};

export type MutationCreateGscTokenArgs = {
  input: GscCreateTokenInput;
};

export type MutationCreateInvitationsArgs = {
  input: CreateInvitationsInput;
};

export type MutationCreateLinkedinTokenArgs = {
  input: CreateLinkedinTokenInput;
};

export type MutationCreateLoginArgs = {
  input: LoginInput;
};

export type MutationCreateNeuraverseProjectArgs = {
  input: CreateNeuraverseProjectInput;
};

export type MutationCreateOneClickAnalysisArgs = {
  input: CreateOneClickAnalysisInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateProjectBriefDocumentArgs = {
  input: CreateProjectBriefDocumentInput;
};

export type MutationCreateProjectLabelArgs = {
  input: CreateProjectLabelInput;
};

export type MutationCreateTopicInspirationsArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationCreateUiViewArgs = {
  input: CreateUiViewInput;
};

export type MutationDeleteApiConnectionArgs = {
  input: DeleteApiConnectionInput;
};

export type MutationDeleteArticleArgs = {
  input: DeleteArticleInput;
};

export type MutationDeleteArticleCommentArgs = {
  input: DeleteArticleCommentInput;
};

export type MutationDeleteArticleHeadlineArgs = {
  input: DeleteArticleHeadlineInput;
};

export type MutationDeleteArticleHeadlineRefArgs = {
  input: DeleteArticleHeadlineRefInput;
};

export type MutationDeleteArticleHeadlinesArgs = {
  input: DeleteArticleHeadlinesInput;
};

export type MutationDeleteArticleKeywordArgs = {
  input: DeleteArticleKeywordInput;
};

export type MutationDeleteArticlePostArgs = {
  input: DeleteArticlePostInput;
};

export type MutationDeleteArticleQuestionArgs = {
  input: DeleteArticleQuestionInput;
};

export type MutationDeleteArticleVersionArgs = {
  input: DeleteVersionInput;
};

export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};

export type MutationDeleteNotificationArgs = {
  input: DeleteNotificationInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};

export type MutationDeleteProjectBriefDocumentArgs = {
  input: DeleteProjectBriefDocumentInput;
};

export type MutationDeleteProjectLabelArgs = {
  input: DeleteProjectLabelInput;
};

export type MutationDeleteUiViewArgs = {
  input: DeleteUiViewInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationGenerateArticleByGptArgs = {
  input: ArticleByGptInput;
};

export type MutationGenerateContentByGptArgs = {
  input: ContentByGptInput;
};

export type MutationGenerateMetadataByGptArgs = {
  input: GenerateMetadataByGptInput;
};

export type MutationGenerateOutlineByGptArgs = {
  input: OutlineByGptInput;
};

export type MutationGrantUserRolesArgs = {
  input: UserRoleInput;
};

export type MutationImportArticleArgs = {
  input: ImportArticleInput;
};

export type MutationLinkNeuraverseToProjectArgs = {
  input: LinkNeuraverseToProjectInput;
};

export type MutationMatchKeywordsAndQuestionsByGptArgs = {
  input: MatchKeywordsAndQuestionsByGptInput;
};

export type MutationOnApiConnectionCreateMutationArgs = {
  input?: InputMaybe<ApiConnectionSubscriptionInput>;
};

export type MutationOnApiConnectionDeleteMutationArgs = {
  input?: InputMaybe<ApiConnectionSubscriptionInput>;
};

export type MutationOnApiConnectionUpdateMutationArgs = {
  input?: InputMaybe<ApiConnectionSubscriptionInput>;
};

export type MutationOnArticleBriefCreateMutationArgs = {
  input?: InputMaybe<ArticleBriefSubscriptionInput>;
};

export type MutationOnArticleBriefDeleteMutationArgs = {
  input?: InputMaybe<ArticleBriefSubscriptionInput>;
};

export type MutationOnArticleBriefUpdateMutationArgs = {
  input?: InputMaybe<ArticleBriefSubscriptionInput>;
};

export type MutationOnArticleCreateMutationArgs = {
  input?: InputMaybe<ArticleSubscriptionInput>;
};

export type MutationOnArticleDeleteMutationArgs = {
  input?: InputMaybe<ArticleSubscriptionInput>;
};

export type MutationOnArticleUpdateMutationArgs = {
  input?: InputMaybe<ArticleSubscriptionInput>;
};

export type MutationOnNVePriorityUpdateMutationArgs = {
  input?: InputMaybe<NVePriorityUpdateSubscriptionInput>;
};

export type MutationOnNVeTopicSavedUpdateMutationArgs = {
  input?: InputMaybe<NVeTopicSavedUpdateSubscriptionInput>;
};

export type MutationOnNotificationCreateMutationArgs = {
  input?: InputMaybe<NotificationSubscriptionInput>;
};

export type MutationOnNotificationDeleteMutationArgs = {
  input?: InputMaybe<NotificationSubscriptionInput>;
};

export type MutationOnNotificationUpdateMutationArgs = {
  input?: InputMaybe<NotificationSubscriptionInput>;
};

export type MutationOnOrganizationCreateMutationArgs = {
  input?: InputMaybe<OrganizationSubscriptionInput>;
};

export type MutationOnOrganizationDeleteMutationArgs = {
  input?: InputMaybe<OrganizationSubscriptionInput>;
};

export type MutationOnOrganizationUpdateMutationArgs = {
  input?: InputMaybe<OrganizationSubscriptionInput>;
};

export type MutationOnProjectCreateMutationArgs = {
  input?: InputMaybe<ProjectSubscriptionInput>;
};

export type MutationOnProjectDeleteMutationArgs = {
  input?: InputMaybe<ProjectSubscriptionInput>;
};

export type MutationOnProjectUpdateMutationArgs = {
  input?: InputMaybe<ProjectSubscriptionInput>;
};

export type MutationOnUiViewCreateMutationArgs = {
  input?: InputMaybe<UiViewSubscriptionInput>;
};

export type MutationOnUiViewDeleteMutationArgs = {
  input?: InputMaybe<UiViewSubscriptionInput>;
};

export type MutationOnUiViewUpdateMutationArgs = {
  input?: InputMaybe<UiViewSubscriptionInput>;
};

export type MutationOnUserCreateMutationArgs = {
  input?: InputMaybe<UserSubscriptionInput>;
};

export type MutationOnUserDeleteMutationArgs = {
  input?: InputMaybe<UserSubscriptionInput>;
};

export type MutationOnUserUpdateMutationArgs = {
  input?: InputMaybe<UserSubscriptionInput>;
};

export type MutationPublishArticlePostArgs = {
  input: PublishArticlePostInput;
};

export type MutationReadNotificationsArgs = {
  input: ReadNotificationsInput;
};

export type MutationResetChannelArgs = {
  input: ResetChannelHandlerInput;
};

export type MutationRestoreArticleVersionArgs = {
  input: RestoreVersionInput;
};

export type MutationRevokeUserRolesArgs = {
  input: UserRoleInput;
};

export type MutationTriggerRScoreArgs = {
  articleId: Scalars['ID']['input'];
};

export type MutationUpdateArticleCommentArgs = {
  input: UpdateArticleCommentInput;
};

export type MutationUpdateArticleDocumentArgs = {
  input: UpdateArticleDocumentInput;
};

export type MutationUpdateArticleGuidelinesArgs = {
  input: UpdateArticleGuidelinesInput;
};

export type MutationUpdateArticleHeadlinesArgs = {
  input: UpdateArticleHeadlinesInput;
};

export type MutationUpdateArticleKeywordsArgs = {
  input: UpdateArticleKeywordsInput;
};

export type MutationUpdateArticleMetadataArgs = {
  input: UpdateArticleMetadataInput;
};

export type MutationUpdateArticlePostArgs = {
  input: UpdateArticlePostInput;
};

export type MutationUpdateArticleQuestionsArgs = {
  input: UpdateArticleQuestionsInput;
};

export type MutationUpdateArticleRScoreArgs = {
  input: UpdateArticleRScoreInput;
};

export type MutationUpdateArticleRootDataArgs = {
  input: UpdateArticleRootDataInput;
};

export type MutationUpdateAuth0RoleArgs = {
  input: UpdateAuth0UserRoleInput;
};

export type MutationUpdateNVeCompanyPriorityArgs = {
  input: UpdateNVePriorityInput;
};

export type MutationUpdateNVeTopicArticlePriorityArgs = {
  input: UpdateNVeTopicArticlePriorityInput;
};

export type MutationUpdateNVeTopicPriorityArgs = {
  input: UpdateNVePriorityInput;
};

export type MutationUpdateNVeTopicSavedArgs = {
  input: UpdateNVeTopicSavedInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateProjectBriefDocumentArgs = {
  input: UpdateProjectBriefDocumentInput;
};

export type MutationUpdateProjectBriefDocumentsArgs = {
  input: UpdateProjectBriefDocumentsInput;
};

export type MutationUpdateProjectBriefMetadataArgs = {
  input: UpdateProjectBriefMetadataInput;
};

export type MutationUpdateProjectImageGuidelinesArgs = {
  input: UpdateProjectImageGuidelinesInput;
};

export type MutationUpdateProjectLabelArgs = {
  input: UpdateProjectLabelInput;
};

export type MutationUpdateProjectMetadataArgs = {
  input: UpdateProjectMetadataInput;
};

export type MutationUpdateUiViewArgs = {
  input: UpdateUiViewInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserArticleDataArgs = {
  input: UpdateUserArticleDataInput;
};

export type MutationUpdateUserProjectDataArgs = {
  input: UpdateUserProjectDataInput;
};

export type MutationUpsertArticleHeadlineArgs = {
  input: UpsertArticleHeadlineInput;
};

export type MutationUpsertArticleHeadlinesArgs = {
  input: UpsertArticleHeadlinesInput;
};

export type MutationUpsertArticleKeywordArgs = {
  input: ArticleKeywordInput;
};

export type MutationUpsertArticleKeywordsArgs = {
  input: UpsertArticleKeywordsInput;
};

export type MutationUpsertArticleQuestionArgs = {
  input: ArticleQuestionInput;
};

export type MutationUpsertArticleQuestionsArgs = {
  input: UpsertArticleQuestionsInput;
};

export type MutationUpsertWordPressConnectionArgs = {
  input?: InputMaybe<UpsertWordpressConnectionInput>;
};

export type MutationWpExportArgs = {
  input: WordPressExportInput;
};

export type NVeCompany = {
  __typename?: 'NVeCompany';
  articles: Scalars['Int']['output'];
  articlesInTop20: Scalars['Int']['output'];
  avgRanking: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['Boolean']['output']>;
  searchTerms: Scalars['Int']['output'];
  traffic: Scalars['Int']['output'];
  trafficShare: Scalars['Float']['output'];
  updateToken?: Maybe<Scalars['String']['output']>;
};

export type NVeFilterData = {
  __typename?: 'NVeFilterData';
  id: Scalars['String']['output'];
  maxArticlesCount: Scalars['Float']['output'];
  maxAverageRanking: Scalars['Float']['output'];
  maxChildTopicCount: Scalars['Float']['output'];
  maxSearchTermsCount: Scalars['Float']['output'];
  maxSearchVolume: Scalars['Float']['output'];
  maxTop20ArticlesCount: Scalars['Float']['output'];
  maxTraffic: Scalars['Float']['output'];
  minArticlesCount: Scalars['Float']['output'];
  minAverageRanking: Scalars['Float']['output'];
  minChildTopicCount: Scalars['Float']['output'];
  minSearchTermsCount: Scalars['Float']['output'];
  minSearchVolume: Scalars['Float']['output'];
  minTop20ArticlesCount: Scalars['Float']['output'];
  minTraffic: Scalars['Float']['output'];
};

export type NVeMetadata = {
  __typename?: 'NVeMetadata';
  competitors: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customer: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: NeuraverseVersion;
};

export type NVePriorityUpdateSubscription = {
  __typename?: 'NVePriorityUpdateSubscription';
  id: Scalars['ID']['output'];
  pk1?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<NVeTopicRelationType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type NVePriorityUpdateSubscriptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<NVeTopicRelationType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type NVeTopic = {
  __typename?: 'NVeTopic';
  articlesCount: Scalars['Int']['output'];
  childTopicCount: Scalars['Int']['output'];
  companyData?: Maybe<Array<NVeTopicCompanyData>>;
  competitorTopic: Scalars['Boolean']['output'];
  depth: Scalars['Int']['output'];
  difficulty: Scalars['Float']['output'];
  domainData?: Maybe<Array<NVeTopicDomainData>>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSaved?: Maybe<Scalars['Boolean']['output']>;
  myTopic: Scalars['Boolean']['output'];
  priority?: Maybe<Scalars['Boolean']['output']>;
  relevance: Scalars['Int']['output'];
  searchTermsCount: Scalars['Int']['output'];
  searchVolume: Scalars['Int']['output'];
  topKeywords: Array<Scalars['String']['output']>;
  topicGap: Scalars['Boolean']['output'];
  updateToken?: Maybe<Scalars['String']['output']>;
};

export type NVeTopicArticle = {
  __typename?: 'NVeTopicArticle';
  articleMetadata?: Maybe<ArticleMetadata>;
  direction?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  priority: Scalars['Boolean']['output'];
  rScore?: Maybe<Scalars['Int']['output']>;
  type: NVeTopicArticleType;
  updateToken?: Maybe<Scalars['String']['output']>;
  webArticle?: Maybe<NVeTopicWebArticle>;
};

export type NVeTopicArticleList = {
  __typename?: 'NVeTopicArticleList';
  items: Array<NVeTopicArticle>;
  lastEvaluatedKey?: Maybe<Scalars['String']['output']>;
};

export enum NVeTopicArticleType {
  ArticleMetadata = 'ARTICLE_METADATA',
  Direction = 'DIRECTION',
  WebArticle = 'WEB_ARTICLE',
}

export type NVeTopicCompanyData = {
  __typename?: 'NVeTopicCompanyData';
  articlesCount: Scalars['Int']['output'];
  averageRanking: Scalars['Int']['output'];
  marketShare: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  searchTermsCount: Scalars['Int']['output'];
  top20ArticlesCount: Scalars['Int']['output'];
  traffic: Scalars['Int']['output'];
  visibilityShare: Scalars['Float']['output'];
};

export type NVeTopicDomainData = {
  __typename?: 'NVeTopicDomainData';
  articlesCount: Scalars['Int']['output'];
  averageRanking: Scalars['Int']['output'];
  hostname: Scalars['String']['output'];
  marketShare: Scalars['Float']['output'];
  searchTermsCount: Scalars['Int']['output'];
  top20ArticlesCount: Scalars['Int']['output'];
  traffic: Scalars['Int']['output'];
  visibilityShare: Scalars['Float']['output'];
};

export enum NVeTopicRelationType {
  Company = 'COMPANY',
  Topic = 'TOPIC',
  TopicArticle = 'TOPIC_ARTICLE',
}

export type NVeTopicSavedUpdate = {
  __typename?: 'NVeTopicSavedUpdate';
  id: Scalars['String']['output'];
  isSaved: Scalars['Boolean']['output'];
  type: NVeTopicRelationType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type NVeTopicSavedUpdateSubscription = {
  __typename?: 'NVeTopicSavedUpdateSubscription';
  id?: Maybe<Scalars['String']['output']>;
  isSaved?: Maybe<Scalars['Boolean']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NVeTopicRelationType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type NVeTopicSavedUpdateSubscriptionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isSaved?: InputMaybe<Scalars['Boolean']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NVeTopicRelationType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type NVeTopicSearchTerm = {
  __typename?: 'NVeTopicSearchTerm';
  content: Scalars['String']['output'];
  ranking: Scalars['Int']['output'];
  searchVolume: Scalars['Int']['output'];
};

export type NVeTopicWebArticle = {
  __typename?: 'NVeTopicWebArticle';
  preScore?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  traffic?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type NVeTopicsExport = {
  __typename?: 'NVeTopicsExport';
  key: Scalars['ID']['output'];
};

export type NVeTopicsExportInput = {
  id: Scalars['ID']['input'];
};

export type NVeTopicsImportInput = {
  id: Scalars['ID']['input'];
};

export enum NeuraverseVersion {
  NVe1 = 'NVe1',
  NVm1 = 'NVm1',
}

export type Notification = {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  data?: Maybe<Scalars['AWSJSON']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  initiatedBy?: Maybe<Scalars['String']['output']>;
  notificationAction?: Maybe<NotificationAction>;
  notificationId?: Maybe<Scalars['String']['output']>;
  notificationStatus?: Maybe<NotificationStatus>;
  notificationType?: Maybe<NotificationType>;
  pk1?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum NotificationAction {
  AnswerQuestionByGpt = 'answerQuestionByGPT',
  ArticleAssigneeChanged = 'articleAssigneeChanged',
  ArticleConflictDetected = 'articleConflictDetected',
  ArticleDeleted = 'articleDeleted',
  ArticleDueDateChanged = 'articleDueDateChanged',
  ArticlePublicationDateChanged = 'articlePublicationDateChanged',
  ArticleStatusChanged = 'articleStatusChanged',
  BriefExportDocx = 'briefExportDocx',
  BriefExportPdf = 'briefExportPdf',
  BulkExport = 'bulkExport',
  ChangedArticleState = 'changedArticleState',
  CommentAdded = 'commentAdded',
  CommentMentioned = 'commentMentioned',
  CommentReopened = 'commentReopened',
  CommentReply = 'commentReply',
  CommentResolved = 'commentResolved',
  ContinueTextByGpt = 'continueTextByGPT',
  CreateArticleByNeuraverse = 'createArticleByNeuraverse',
  DivideSentenceByGpt = 'divideSentenceByGPT',
  GenerateArticleByGpt = 'generateArticleByGPT',
  GenerateConclusionByGpt = 'generateConclusionByGPT',
  GenerateContentByGpt = 'generateContentByGPT',
  GenerateFocusKeywordsByGpt = 'generateFocusKeywordsByGPT',
  GenerateHeadlineInOutlineByGpt = 'generateHeadlineInOutlineByGPT',
  GenerateHeadlineInWriteByGpt = 'generateHeadlineInWriteByGPT',
  GenerateHeadlineSuggestionsInOutlineByGpt = 'generateHeadlineSuggestionsInOutlineByGPT',
  GenerateImageByDallE = 'generateImageByDallE',
  GenerateKeywordVariationsByGpt = 'generateKeywordVariationsByGPT',
  GenerateLinkedInPostByGpt = 'generateLinkedInPostByGPT',
  GenerateMetadataByGpt = 'generateMetadataByGPT',
  GenerateOutlineByGpt = 'generateOutlineByGPT',
  GoogleTopRankingsChanged = 'googleTopRankingsChanged',
  ImportArticle = 'importArticle',
  Maintenance = 'maintenance',
  MatchKeywordsAndQuestionsByGpt = 'matchKeywordsAndQuestionsByGPT',
  NeuraverseCreated = 'neuraverseCreated',
  PlatformUpdate = 'platformUpdate',
  ProjectAddedMe = 'projectAddedMe',
  ProjectDeleted = 'projectDeleted',
  ProjectDomainChanged = 'projectDomainChanged',
  ProjectOverviewExport = 'projectOverviewExport',
  ProjectRemovedMe = 'projectRemovedMe',
  PublicationDetection = 'publicationDetection',
  PublishedArticleNotOnline = 'publishedArticleNotOnline',
  PublishedArticleUrlMissing = 'publishedArticleUrlMissing',
  ShortenSentenceByGpt = 'shortenSentenceByGPT',
  SimilarPhrasesByGpt = 'similarPhrasesByGPT',
  SimilarPhrasesByNeuraverse = 'similarPhrasesByNeuraverse',
  SimilarQuestions = 'similarQuestions',
  UpdateGscData = 'updateGscData',
  WordPressBulkExport = 'wordPressBulkExport',
  WordPressExport = 'wordPressExport',
}

export type NotificationDelete = {
  __typename?: 'NotificationDelete';
  id: Scalars['ID']['output'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  article: ArticleNotificationSettings;
  project: ProjectNotificationSettings;
};

export type NotificationSettingsInput = {
  article: ArticleNotificationSettingsInput;
  project: ProjectNotificationSettingsInput;
};

export enum NotificationStatus {
  Done = 'Done',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export type NotificationSubscription = {
  __typename?: 'NotificationSubscription';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  data?: Maybe<Scalars['AWSJSON']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  initiatedBy?: Maybe<Scalars['String']['output']>;
  notificationAction?: Maybe<NotificationAction>;
  notificationId?: Maybe<Scalars['String']['output']>;
  notificationStatus?: Maybe<NotificationStatus>;
  notificationType?: Maybe<NotificationType>;
  pk1?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type NotificationSubscriptionDelete = {
  __typename?: 'NotificationSubscriptionDelete';
  filter?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type NotificationSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  data?: InputMaybe<Scalars['AWSJSON']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  initiatedBy?: InputMaybe<Scalars['String']['input']>;
  notificationAction?: InputMaybe<NotificationAction>;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  notificationStatus?: InputMaybe<NotificationStatus>;
  notificationType?: InputMaybe<NotificationType>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum NotificationType {
  Entity = 'ENTITY',
  Event = 'EVENT',
  Ui = 'UI',
}

export enum OcaDevice {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export type OcaFailedReport = {
  __typename?: 'OCAFailedReport';
  error: Scalars['String']['output'];
  url: Scalars['Int']['output'];
};

export type OcaRecommendation = {
  __typename?: 'OCARecommendation';
  id: Scalars['String']['output'];
  urls: Array<Scalars['Int']['output']>;
};

export type OcaRecommendations = {
  __typename?: 'OCARecommendations';
  critical: Array<OcaRecommendation>;
  low: Array<OcaRecommendation>;
  medium: Array<OcaRecommendation>;
};

export type OneClickAnalysis = {
  __typename?: 'OneClickAnalysis';
  createdAt: Scalars['AWSDateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  finishedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  report?: Maybe<OneClickAnalysisReport>;
  status: OneClickAnalysisStatus;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type OneClickAnalysisReport = {
  __typename?: 'OneClickAnalysisReport';
  coreWebVitals?: Maybe<CoreWebVitals>;
  failedReports?: Maybe<Array<OcaFailedReport>>;
  recommendations: OcaRecommendations;
  score?: Maybe<Scalars['Float']['output']>;
  urls: Array<Scalars['String']['output']>;
};

export enum OneClickAnalysisStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Requested = 'REQUESTED',
  Running = 'RUNNING',
}

export type Organization = {
  __typename?: 'Organization';
  auth0Id?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  users: UserItems;
};

export type OrganizationDelete = {
  __typename?: 'OrganizationDelete';
  id: Scalars['ID']['output'];
};

export type OrganizationItems = {
  __typename?: 'OrganizationItems';
  items: Array<Maybe<Organization>>;
};

export type OrganizationSubscription = {
  __typename?: 'OrganizationSubscription';
  auth0Id?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationSubscriptionDelete = {
  __typename?: 'OrganizationSubscriptionDelete';
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationSubscriptionInput = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type OutlineByGptInput = {
  articleId: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type OutlineHtmlExport = {
  __typename?: 'OutlineHtmlExport';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OutlineHtmlExportInput = {
  articleId: Scalars['ID']['input'];
};

export type PlatformVersion = {
  __typename?: 'PlatformVersion';
  BE: Scalars['String']['output'];
  FE: Scalars['String']['output'];
  ML: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  allowGenAI: Scalars['Boolean']['output'];
  archived?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  color?: Maybe<keyof IBaseColors>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  content: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  custom?: Maybe<Scalars['Boolean']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  directReaderAddressing: DirectReaderAddressing;
  domain?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  firstPersonPerspective: FirstPersonPerspective;
  gscConnected?: Maybe<Scalars['Boolean']['output']>;
  gscConnectionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['String']['output'];
  imageType: ImageType;
  includedInArticleBrief?: Maybe<Array<Scalars['String']['output']>>;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  linkedinConnected?: Maybe<Scalars['Boolean']['output']>;
  linkedinConnectionId?: Maybe<Scalars['ID']['output']>;
  linkedinOrgId?: Maybe<Scalars['String']['output']>;
  locale: Locale;
  moodAndTone: ImageMoodAndTone;
  name: Scalars['String']['output'];
  neuraverseId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  orientation: ImageOrientation;
  pk1: Scalars['String']['output'];
  planedArticles?: Maybe<Scalars['Int']['output']>;
  planedArticlesPerMonth?: Maybe<Scalars['Int']['output']>;
  projectId: Scalars['ID']['output'];
  selected?: Maybe<Scalars['Boolean']['output']>;
  sk1: Scalars['String']['output'];
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  style: ImageStyle;
  topicInspirations?: Maybe<Array<Scalars['String']['output']>>;
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  wpConnected?: Maybe<Scalars['Boolean']['output']>;
  wpConnectionId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectBrief = {
  __typename?: 'ProjectBrief';
  audienceExpertises: Array<ProjectBriefDocument>;
  audiencePersonas: Array<ProjectBriefDocument>;
  companyKnowledges: Array<ProjectBriefDocument>;
  imageGuidelines: ProjectImageGuidelines;
  projectBriefMetadata: ProjectBriefMetadata;
  toneAndVoices: Array<ProjectBriefDocument>;
};

export type ProjectBriefDocument = {
  __typename?: 'ProjectBriefDocument';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pk1: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  selected?: Maybe<Scalars['Boolean']['output']>;
  sk1: Scalars['String']['output'];
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ProjectBriefDocumentDelete = {
  __typename?: 'ProjectBriefDocumentDelete';
  id: Scalars['ID']['output'];
  type: ProjectType;
};

export type ProjectBriefMetadata = {
  __typename?: 'ProjectBriefMetadata';
  avoid?: Maybe<Scalars['String']['output']>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  directReaderAddressing: DirectReaderAddressing;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  firstPersonPerspective: FirstPersonPerspective;
  id: Scalars['String']['output'];
  includedInArticleBrief?: Maybe<Array<Scalars['String']['output']>>;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  pk1: Scalars['String']['output'];
  planedArticles?: Maybe<Scalars['Int']['output']>;
  planedArticlesPerMonth?: Maybe<Scalars['Int']['output']>;
  sk1: Scalars['String']['output'];
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ProjectDelete = {
  __typename?: 'ProjectDelete';
  id: Scalars['ID']['output'];
  type: ProjectType;
};

export type ProjectImageGuidelines = {
  __typename?: 'ProjectImageGuidelines';
  allowGenAI: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  id: Scalars['String']['output'];
  imageType: ImageType;
  moodAndTone: ImageMoodAndTone;
  orientation: ImageOrientation;
  pk1: Scalars['String']['output'];
  sk1: Scalars['String']['output'];
  style: ImageStyle;
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ProjectInvite = {
  projectId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type ProjectLabel = {
  __typename?: 'ProjectLabel';
  color: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  custom?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pk1: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  sk1: Scalars['String']['output'];
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ProjectLabelDelete = {
  __typename?: 'ProjectLabelDelete';
  id: Scalars['ID']['output'];
  type: ProjectType;
};

export type ProjectMember = {
  __typename?: 'ProjectMember';
  aclRoles: Array<AclUserRoles>;
  id: Scalars['ID']['output'];
  user: User;
};

export type ProjectMetadata = {
  __typename?: 'ProjectMetadata';
  archived?: Maybe<Scalars['Boolean']['output']>;
  articleStatistics?: Maybe<ArticleStatistics>;
  avatar?: Maybe<Scalars['String']['output']>;
  brief?: Maybe<ProjectBrief>;
  color?: Maybe<keyof IBaseColors>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  gscConnected?: Maybe<Scalars['Boolean']['output']>;
  gscConnectionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['String']['output'];
  labels?: Maybe<Array<ProjectLabel>>;
  linkedinConnected?: Maybe<Scalars['Boolean']['output']>;
  linkedinConnectionId?: Maybe<Scalars['ID']['output']>;
  linkedinOrgId?: Maybe<Scalars['String']['output']>;
  locale: Locale;
  name?: Maybe<Scalars['String']['output']>;
  neuraverseId?: Maybe<Scalars['String']['output']>;
  neuraverseVersion?: Maybe<NeuraverseVersion>;
  organizationId: Scalars['ID']['output'];
  pk1: Scalars['String']['output'];
  sk1: Scalars['String']['output'];
  topicInspirations?: Maybe<Array<Scalars['String']['output']>>;
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  type: ProjectType;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
  userProject?: Maybe<UserProjectData>;
  wpConnected?: Maybe<Scalars['Boolean']['output']>;
  wpConnectionId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectNotificationSettings = {
  __typename?: 'ProjectNotificationSettings';
  email: Scalars['Boolean']['output'];
  inapp: Scalars['Boolean']['output'];
};

export type ProjectNotificationSettingsInput = {
  email: Scalars['Boolean']['input'];
  inapp: Scalars['Boolean']['input'];
};

export type ProjectSubscription = {
  __typename?: 'ProjectSubscription';
  allowGenAI?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  avoid?: Maybe<Scalars['String']['output']>;
  color?: Maybe<keyof IBaseColors>;
  competitorsUrls?: Maybe<Array<Scalars['String']['output']>>;
  content?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  custom?: Maybe<Scalars['Boolean']['output']>;
  customFields?: Maybe<Array<CustomField>>;
  description?: Maybe<Scalars['String']['output']>;
  directReaderAddressing?: Maybe<DirectReaderAddressing>;
  domain?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  firstPersonPerspective?: Maybe<FirstPersonPerspective>;
  gscConnected?: Maybe<Scalars['Boolean']['output']>;
  gscConnectionId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<ImageType>;
  includedInArticleBrief?: Maybe<Array<Scalars['String']['output']>>;
  inputTopics?: Maybe<Array<Scalars['String']['output']>>;
  linkedinConnected?: Maybe<Scalars['Boolean']['output']>;
  linkedinConnectionId?: Maybe<Scalars['ID']['output']>;
  linkedinOrgId?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
  moodAndTone?: Maybe<ImageMoodAndTone>;
  name?: Maybe<Scalars['String']['output']>;
  neuraverseId?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  orientation?: Maybe<ImageOrientation>;
  pk1?: Maybe<Scalars['String']['output']>;
  planedArticles?: Maybe<Scalars['Int']['output']>;
  planedArticlesPerMonth?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  sk1?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  style?: Maybe<ImageStyle>;
  topicInspirations?: Maybe<Array<Scalars['String']['output']>>;
  ttl?: Maybe<Scalars['AWSTimestamp']['output']>;
  type?: Maybe<ProjectType>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wpConnected?: Maybe<Scalars['Boolean']['output']>;
  wpConnectionId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectSubscriptionDelete = {
  __typename?: 'ProjectSubscriptionDelete';
  id?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProjectSubscriptionInput = {
  allowGenAI?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  custom?: InputMaybe<Scalars['Boolean']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  domain?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  gscConnected?: InputMaybe<Scalars['Boolean']['input']>;
  gscConnectionId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageType?: InputMaybe<ImageType>;
  includedInArticleBrief?: InputMaybe<Array<Scalars['String']['input']>>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedinConnected?: InputMaybe<Scalars['Boolean']['input']>;
  linkedinConnectionId?: InputMaybe<Scalars['ID']['input']>;
  linkedinOrgId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
  moodAndTone?: InputMaybe<ImageMoodAndTone>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  orientation?: InputMaybe<ImageOrientation>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  planedArticles?: InputMaybe<Scalars['Int']['input']>;
  planedArticlesPerMonth?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  style?: InputMaybe<ImageStyle>;
  topicInspirations?: InputMaybe<Array<Scalars['String']['input']>>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  type?: InputMaybe<ProjectType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  wpConnected?: InputMaybe<Scalars['Boolean']['input']>;
  wpConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ProjectType {
  AudienceExpertise = 'AUDIENCE_EXPERTISE',
  AudiencePersona = 'AUDIENCE_PERSONA',
  BriefMetadata = 'BRIEF_METADATA',
  CompanyKnowledge = 'COMPANY_KNOWLEDGE',
  ImageGuidelines = 'IMAGE_GUIDELINES',
  Label = 'LABEL',
  Metadata = 'METADATA',
  ToneAndVoice = 'TONE_AND_VOICE',
}

export type PublishArticlePostInput = {
  id: Scalars['ID']['input'];
};

export type PublishArticlePostOutput = {
  __typename?: 'PublishArticlePostOutput';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  statusCode: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  answerQuestionByGPT: Notification;
  compareArticleVersions?: Maybe<CompareVersionsOutput>;
  continueTextByGPT: Notification;
  dummy?: Maybe<KeywordVolume>;
  exportAllNVeTopics: NVeTopicsExport;
  generateConclusionByGPT: Notification;
  generateFocusKeywordsByGPT: Notification;
  generateHeadlineInOutlineByGPT: Notification;
  generateHeadlineInWriteByGPT: Notification;
  generateHeadlineSuggestionsInOutlineByGPT: Notification;
  generateImageByDallE: Notification;
  generateKeywordVariationsByGPT: Notification;
  generateLinkedInPostByGPT: Notification;
  getApiConnection?: Maybe<ApiConnection>;
  getArticle: ArticleMetadata;
  getArticleComment: ArticleComment;
  getArticlePost: ArticlePost;
  getBriefExportAsDocx: Scalars['String']['output'];
  getBriefExportAsPdf: Scalars['String']['output'];
  getGSCArticleAnalytics?: Maybe<GscAnalytics>;
  getGSCConsentScreenUri?: Maybe<GscConsentScreenUri>;
  getGSCProjectAnalytics?: Maybe<GscAnalytics>;
  getGlobalPermissionsTree?: Maybe<AclGlobalPermissionTree>;
  getGlobalRoleDefinitions?: Maybe<AclGlobalRoleDefinitions>;
  getGoogleTopRankings?: Maybe<GoogleTopRanking>;
  getGscConnectionStatus: GscConnectionStatusResponse;
  getHtmlExport?: Maybe<HtmlExport>;
  getIsProjectMonitored: Scalars['Boolean']['output'];
  getLastProductUpdate?: Maybe<LastProductUpdate>;
  getLinkedinConnectionStatus: LinkedinConnectionStatusResponse;
  getLinkedinConsentScreenUri?: Maybe<LinkedinConsentScreenUri>;
  getNVeFilterData: NVeFilterData;
  getNVeMetadata: NVeMetadata;
  getNVeTopic: NVeTopic;
  getNotification?: Maybe<Notification>;
  getOneClickAnalysis?: Maybe<OneClickAnalysis>;
  getOrganization?: Maybe<Organization>;
  getOutlineHtmlExport?: Maybe<OutlineHtmlExport>;
  getProject: ProjectMetadata;
  getProjectBriefDocumentUsageCount: Scalars['Int']['output'];
  getProjectLabelUsageCount: Scalars['Int']['output'];
  getSimilarPhrasesByGPT?: Maybe<SimilarPhrases>;
  getSimilarPhrasesByNeuraverse?: Maybe<SimilarPhrases>;
  getSimilarQuestions?: Maybe<SimilarQuestions>;
  getTURNServerCredentials: GetTurnServerCredentialsResponse;
  getUiView?: Maybe<UiView>;
  getUploadUrl?: Maybe<UploadUrlOutput>;
  getUser?: Maybe<User>;
  getUserRoleAssignments?: Maybe<AclRoleAssignment>;
  getVersion?: Maybe<PlatformVersion>;
  getWordPressConnection?: Maybe<GetWordpressConnectionResponse>;
  importAllNVeTopics: UploadUrlOutput;
  listApiConnections?: Maybe<ListApiConnections>;
  listArticleComments: ListCommentsOutput;
  listArticleHeadlines: ListArticleHeadlinesType;
  listArticleKeywords: Array<ArticleKeyword>;
  listArticlePosts: ListArticlePostsOutput;
  listArticleQuestions: Array<ArticleQuestion>;
  listArticles: ListArticles;
  listArticlesWithSameFocusKeyword: ListArticlesWithSameFocusKeyword;
  listBrokenArticleUrls: BrokenArticleUrlsOutput;
  listBrokenLinksInArticle?: Maybe<BrokenLinksInArticle>;
  listBrokenLinksInProject: BrokenLinksInProjectOutput;
  listGSCArticlesByKeyword?: Maybe<GscArticlesByKeyword>;
  listGSCArticlesByProject?: Maybe<GscArticlesByProject>;
  listGSCDomains?: Maybe<GscDomains>;
  listGSCKeywordsByArticle?: Maybe<GscKeywordsByArticle>;
  listGSCKeywordsByProject?: Maybe<GscKeywordsByProject>;
  listGSCTopChangingArticles?: Maybe<GscTopChangingArticles>;
  listInvitations?: Maybe<Invitations>;
  listLatestIndexedArticles: LatestIndexedArticles;
  listLinkedinOrganizations: Array<LinkedinOrganization>;
  listNVeCompanies: Array<NVeCompany>;
  listNVeTopicArticles: NVeTopicArticleList;
  listNVeTopicSearchTerms: Array<NVeTopicSearchTerm>;
  listNVeTopicTop20Articles: Array<NVeTopicWebArticle>;
  listNVeTopics: Array<NVeTopic>;
  listNVeWebArticles: Array<NVeTopicWebArticle>;
  listNotifications?: Maybe<ListNotifications>;
  listOrganizations?: Maybe<ListOrganizations>;
  listProjectMembers: Array<ProjectMember>;
  listProjects: ListProjects;
  listUiViews?: Maybe<ListUiViews>;
  listUserProjects?: Maybe<UserProjectsWithRoles>;
  listUsers?: Maybe<UserItems>;
  listUsersWithProjects?: Maybe<Array<UserWithProjects>>;
  listVersions: ListArticles;
  listWordPressCategories?: Maybe<ListWordpressCategoriesResponse>;
  shortenSentenceByGPT: Notification;
};

export type QueryAnswerQuestionByGptArgs = {
  input: AnswerQuestionByGptInput;
};

export type QueryCompareArticleVersionsArgs = {
  input?: InputMaybe<CompareVersionsInput>;
};

export type QueryContinueTextByGptArgs = {
  input: ContinueTextByGptInput;
};

export type QueryExportAllNVeTopicsArgs = {
  input: NVeTopicsExportInput;
};

export type QueryGenerateConclusionByGptArgs = {
  input: ConclusionByGptInput;
};

export type QueryGenerateFocusKeywordsByGptArgs = {
  input: FocusKeywordsByGptInput;
};

export type QueryGenerateHeadlineInOutlineByGptArgs = {
  input: GenerateHeadlineByGptInput;
};

export type QueryGenerateHeadlineInWriteByGptArgs = {
  input: GenerateHeadlineByGptInput;
};

export type QueryGenerateHeadlineSuggestionsInOutlineByGptArgs = {
  input: GenerateHeadlineSuggestionsByGptInput;
};

export type QueryGenerateImageByDallEArgs = {
  input: GenerateImageByDallEInput;
};

export type QueryGenerateKeywordVariationsByGptArgs = {
  input: GenerateKeywordVariationsByGptInput;
};

export type QueryGenerateLinkedInPostByGptArgs = {
  input: LinkedInPostByGptInput;
};

export type QueryGetApiConnectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetArticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetArticleCommentArgs = {
  input: GetArticleCommentInput;
};

export type QueryGetArticlePostArgs = {
  input: GetArticlePostInput;
};

export type QueryGetBriefExportAsDocxArgs = {
  input: BriefExportInput;
};

export type QueryGetBriefExportAsPdfArgs = {
  input: BriefExportInput;
};

export type QueryGetGscArticleAnalyticsArgs = {
  input: GscArticleAnalyticsInput;
};

export type QueryGetGscConsentScreenUriArgs = {
  input: GscConsentScreenUriInput;
};

export type QueryGetGscProjectAnalyticsArgs = {
  input: GscProjectAnalyticsInput;
};

export type QueryGetGoogleTopRankingsArgs = {
  input: GetGoogleTopRankingsInput;
};

export type QueryGetGscConnectionStatusArgs = {
  input: GetGscConnectionStatusInput;
};

export type QueryGetHtmlExportArgs = {
  input: HtmlExportInput;
};

export type QueryGetIsProjectMonitoredArgs = {
  projectId: Scalars['String']['input'];
};

export type QueryGetLinkedinConnectionStatusArgs = {
  input: GetLinkedinConnectionStatusInput;
};

export type QueryGetLinkedinConsentScreenUriArgs = {
  input: LinkedinConsentScreenUriInput;
};

export type QueryGetNVeFilterDataArgs = {
  input: GetNVeFilterDataInput;
};

export type QueryGetNVeMetadataArgs = {
  input: GetNVeMetadataInput;
};

export type QueryGetNVeTopicArgs = {
  input: GetNVeTopicInput;
};

export type QueryGetNotificationArgs = {
  input: GetNotificationInput;
};

export type QueryGetOneClickAnalysisArgs = {
  input: GetOneClickAnalysisInput;
};

export type QueryGetOutlineHtmlExportArgs = {
  input: OutlineHtmlExportInput;
};

export type QueryGetProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetProjectBriefDocumentUsageCountArgs = {
  input: GetProjectBriefDocumentUsageCountInput;
};

export type QueryGetProjectLabelUsageCountArgs = {
  input: GetProjectLabelUsageCountInput;
};

export type QueryGetSimilarPhrasesByGptArgs = {
  input: SimilarPhrasesByGptInput;
};

export type QueryGetSimilarPhrasesByNeuraverseArgs = {
  input: SimilarPhrasesByNeuraverseInput;
};

export type QueryGetSimilarQuestionsArgs = {
  input: SimilarQuestionsInput;
};

export type QueryGetTurnServerCredentialsArgs = {
  input: GetTurnServerCredentialsInput;
};

export type QueryGetUiViewArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUploadUrlArgs = {
  entity: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type QueryGetWordPressConnectionArgs = {
  input?: InputMaybe<GetWordpressConnectionInput>;
};

export type QueryImportAllNVeTopicsArgs = {
  input: NVeTopicsImportInput;
};

export type QueryListApiConnectionsArgs = {
  filter?: InputMaybe<ModelApiConnectionFilterInput>;
  id?: InputMaybe<ModelIdKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryListArticleCommentsArgs = {
  input: ListArticleCommentInput;
};

export type QueryListArticleHeadlinesArgs = {
  articleId: Scalars['String']['input'];
};

export type QueryListArticleKeywordsArgs = {
  articleId: Scalars['String']['input'];
  onlyMatched?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryListArticlePostsArgs = {
  input: ListArticlePostsInput;
};

export type QueryListArticleQuestionsArgs = {
  articleId: Scalars['String']['input'];
};

export type QueryListArticlesArgs = {
  filter?: InputMaybe<ListArticlesInputFilter>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryListArticlesWithSameFocusKeywordArgs = {
  input: ListArticlesWithSameFocusKeywordInput;
};

export type QueryListBrokenArticleUrlsArgs = {
  input: ListBrokenArticleUrlsInput;
};

export type QueryListBrokenLinksInArticleArgs = {
  input: ListBrokenLinksInArticleInput;
};

export type QueryListBrokenLinksInProjectArgs = {
  input: ListBrokenLinksInProjectInput;
};

export type QueryListGscArticlesByKeywordArgs = {
  input: GscArticlesByKeywordInput;
};

export type QueryListGscArticlesByProjectArgs = {
  input: GscArticlesByProjectInput;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListGscDomainsArgs = {
  input: GscDomainsInput;
};

export type QueryListGscKeywordsByArticleArgs = {
  input: GscKeywordsByArticleInput;
};

export type QueryListGscKeywordsByProjectArgs = {
  input: GscKeywordsByProjectInput;
};

export type QueryListGscTopChangingArticlesArgs = {
  input: ListGscTopChangingArticlesInput;
};

export type QueryListLatestIndexedArticlesArgs = {
  input: ListLatestIndexedArticlesInput;
};

export type QueryListLinkedinOrganizationsArgs = {
  input: ListLinkedinOrganizationsInput;
};

export type QueryListNVeCompaniesArgs = {
  input: ListNVeCompaniesInput;
};

export type QueryListNVeTopicArticlesArgs = {
  input: ListNVeTopicArticlesInput;
};

export type QueryListNVeTopicSearchTermsArgs = {
  input: ListNVeTopicSearchTermsInput;
};

export type QueryListNVeTopicTop20ArticlesArgs = {
  input: ListNVeTopicCompanyDataInput;
};

export type QueryListNVeTopicsArgs = {
  input: ListNVeTopicsInput;
};

export type QueryListNVeWebArticlesArgs = {
  input: ListNVeTopicCompanyDataInput;
};

export type QueryListNotificationsArgs = {
  filter?: InputMaybe<ModelNotificationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sk1?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryListProjectMembersArgs = {
  projectId: Scalars['String']['input'];
};

export type QueryListProjectsArgs = {
  filter?: InputMaybe<ListProjectsInputFilter>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type QueryListUiViewsArgs = {
  id: Scalars['String']['input'];
};

export type QueryListUserProjectsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryListVersionsArgs = {
  input: ListVersionsInput;
};

export type QueryListWordPressCategoriesArgs = {
  input: ListWordpressCategoriesInput;
};

export type QueryShortenSentenceByGptArgs = {
  input: ShortenOrDivideSentenceByGptInput;
};

export type ReadNotificationsInput = {
  notificationIds: Array<Scalars['ID']['input']>;
};

export type ResetChannelHandlerInput = {
  articleId: Scalars['String']['input'];
};

export enum RestoreMode {
  Addandrestore = 'ADDANDRESTORE',
  Deleteandrestore = 'DELETEANDRESTORE',
}

export type RestoreVersionInput = {
  articleId: Scalars['String']['input'];
  mode: RestoreMode;
};

export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  Editor = 'Editor',
  ExternalWritter = 'ExternalWritter',
}

export type SelectedProjectBriefDocuments = {
  __typename?: 'SelectedProjectBriefDocuments';
  audienceExpertise?: Maybe<ProjectBriefDocument>;
  audiencePersona?: Maybe<ProjectBriefDocument>;
  companyKnowledges?: Maybe<Array<ProjectBriefDocument>>;
  toneAndVoice?: Maybe<ProjectBriefDocument>;
};

export type ShortenOrDivideSentenceByGptInput = {
  articleId: Scalars['String']['input'];
  keywords: Array<Scalars['String']['input']>;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  sentence: Scalars['String']['input'];
};

export type SimilarPhraseElement = {
  __typename?: 'SimilarPhraseElement';
  phrase: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  score: Scalars['Float']['output'];
  volumes?: Maybe<Array<Maybe<Volume>>>;
};

export type SimilarPhraseVariety = {
  __typename?: 'SimilarPhraseVariety';
  phrases: Array<Maybe<Scalars['Int']['output']>>;
  variety: Scalars['Float']['output'];
};

export type SimilarPhrases = {
  __typename?: 'SimilarPhrases';
  items?: Maybe<Array<Maybe<SimilarPhraseElement>>>;
  varieties?: Maybe<Array<SimilarPhraseVariety>>;
};

export type SimilarPhrasesByGptInput = {
  contentCategory?: InputMaybe<ContentCategoryEnum>;
  contentSubCategory?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  locale: Locale;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  searchTerms: Array<Scalars['String']['input']>;
  timeFrame: Scalars['Int']['input'];
};

export type SimilarPhrasesByNeuraverseInput = {
  articleDirection?: InputMaybe<Scalars['String']['input']>;
  articleLengthSuggestion?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  notificationId: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchTerms: Array<Scalars['String']['input']>;
  topicId: Scalars['String']['input'];
  topicNames: Array<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SimilarQuestionElement = {
  __typename?: 'SimilarQuestionElement';
  question: Scalars['String']['output'];
};

export type SimilarQuestions = {
  __typename?: 'SimilarQuestions';
  items?: Maybe<Array<Maybe<SimilarQuestionElement>>>;
};

export type SimilarQuestionsInput = {
  locale: Locale;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onApiConnectionCreate?: Maybe<ApiConnectionSubscription>;
  onApiConnectionDelete?: Maybe<ApiConnectionSubscriptionDelete>;
  onApiConnectionDeleteById?: Maybe<ApiConnectionSubscriptionDelete>;
  onApiConnectionUpdate?: Maybe<ApiConnectionSubscription>;
  onApiConnectionUpdateById?: Maybe<ApiConnectionSubscription>;
  onArticleBriefCreate?: Maybe<ArticleBriefSubscription>;
  onArticleBriefDelete?: Maybe<ArticleBriefSubscriptionDelete>;
  onArticleBriefDeleteById?: Maybe<ArticleBriefSubscriptionDelete>;
  onArticleBriefUpdate?: Maybe<ArticleBriefSubscription>;
  onArticleBriefUpdateById?: Maybe<ArticleBriefSubscription>;
  onArticleCreate?: Maybe<ArticleSubscription>;
  onArticleDelete?: Maybe<ArticleSubscriptionDelete>;
  onArticleDeleteById?: Maybe<ArticleSubscriptionDelete>;
  onArticleUpdate?: Maybe<ArticleSubscription>;
  onArticleUpdateById?: Maybe<ArticleSubscription>;
  onNVePriorityUpdate?: Maybe<NVePriorityUpdateSubscription>;
  onNVeTopicSavedUpdate?: Maybe<NVeTopicSavedUpdateSubscription>;
  onNotificationCreate?: Maybe<NotificationSubscription>;
  onNotificationDelete?: Maybe<NotificationSubscriptionDelete>;
  onNotificationDeleteById?: Maybe<NotificationSubscriptionDelete>;
  onNotificationUpdate?: Maybe<NotificationSubscription>;
  onNotificationUpdateById?: Maybe<NotificationSubscription>;
  onOrganizationCreate?: Maybe<OrganizationSubscription>;
  onOrganizationDelete?: Maybe<OrganizationSubscriptionDelete>;
  onOrganizationDeleteById?: Maybe<OrganizationSubscriptionDelete>;
  onOrganizationUpdate?: Maybe<OrganizationSubscription>;
  onOrganizationUpdateById?: Maybe<OrganizationSubscription>;
  onProjectCreate?: Maybe<ProjectSubscription>;
  onProjectDelete?: Maybe<ProjectSubscriptionDelete>;
  onProjectDeleteById?: Maybe<ProjectSubscriptionDelete>;
  onProjectUpdate?: Maybe<ProjectSubscription>;
  onProjectUpdateById?: Maybe<ProjectSubscription>;
  onUiViewCreate?: Maybe<UiViewSubscription>;
  onUiViewDelete?: Maybe<UiViewSubscriptionDelete>;
  onUiViewDeleteById?: Maybe<UiViewSubscriptionDelete>;
  onUiViewUpdate?: Maybe<UiViewSubscription>;
  onUiViewUpdateById?: Maybe<UiViewSubscription>;
  onUserCreate?: Maybe<UserSubscription>;
  onUserDelete?: Maybe<UserSubscriptionDelete>;
  onUserDeleteById?: Maybe<UserSubscriptionDelete>;
  onUserRoleAssignments?: Maybe<AclRoleAssignment>;
  onUserUpdate?: Maybe<UserSubscription>;
  onUserUpdateById?: Maybe<UserSubscription>;
};

export type SubscriptionOnApiConnectionCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnApiConnectionDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnApiConnectionDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnApiConnectionUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnApiConnectionUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleBriefCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubscriptionOnArticleBriefDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubscriptionOnArticleBriefDeleteByIdArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  sk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleBriefUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubscriptionOnArticleBriefUpdateByIdArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  sk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnArticleUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnNVePriorityUpdateArgs = {
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubscriptionOnNVeTopicSavedUpdateArgs = {
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SubscriptionOnNotificationCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnNotificationDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnNotificationDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  sk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnNotificationUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnNotificationUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  sk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnOrganizationCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnOrganizationDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnOrganizationDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnOrganizationUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnOrganizationUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnProjectCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnProjectDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnProjectDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnProjectUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnProjectUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUiViewCreateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUiViewDeleteArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUiViewDeleteByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUiViewUpdateArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUiViewUpdateByIdArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUserCreateArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUserDeleteArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUserDeleteByIdArgs = {
  id: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUserRoleAssignmentsArgs = {
  id: Scalars['String']['input'];
};

export type SubscriptionOnUserUpdateArgs = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOnUserUpdateByIdArgs = {
  id: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum TabKey {
  Keywords = 'keywords',
  Questions = 'questions',
}

export enum TopRankingStatus {
  Created = 'CREATED',
  ExtractionComplete = 'EXTRACTION_COMPLETE',
  ExtractionFailed = 'EXTRACTION_FAILED',
  ExtractionQueued = 'EXTRACTION_QUEUED',
  SummaryComplete = 'SUMMARY_COMPLETE',
  SummaryQueued = 'SUMMARY_QUEUED',
}

export enum Trend {
  Down = 'DOWN',
  Neutral = 'NEUTRAL',
  None = 'NONE',
  Up = 'UP',
}

export type UiView = {
  __typename?: 'UiView';
  calendarVisibility?: Maybe<Scalars['AWSJSON']['output']>;
  companyPriority?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  expanded?: Maybe<Scalars['AWSJSON']['output']>;
  filtering?: Maybe<Scalars['AWSJSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  showWinnersLosers?: Maybe<Scalars['Boolean']['output']>;
  sorting?: Maybe<Scalars['AWSJSON']['output']>;
  tab?: Maybe<Scalars['String']['output']>;
  tableId?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['AWSJSON']['output']>;
};

export type UiViewDelete = {
  __typename?: 'UiViewDelete';
  id: Scalars['ID']['output'];
};

export type UiViewSubscription = {
  __typename?: 'UiViewSubscription';
  calendarVisibility?: Maybe<Scalars['AWSJSON']['output']>;
  companyPriority?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  expanded?: Maybe<Scalars['AWSJSON']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  filtering?: Maybe<Scalars['AWSJSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  showWinnersLosers?: Maybe<Scalars['Boolean']['output']>;
  sorting?: Maybe<Scalars['AWSJSON']['output']>;
  tab?: Maybe<Scalars['String']['output']>;
  tableId?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['AWSJSON']['output']>;
};

export type UiViewSubscriptionDelete = {
  __typename?: 'UiViewSubscriptionDelete';
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pk1?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UiViewSubscriptionInput = {
  calendarVisibility?: InputMaybe<Scalars['AWSJSON']['input']>;
  companyPriority?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  expanded?: InputMaybe<Scalars['AWSJSON']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  filtering?: InputMaybe<Scalars['AWSJSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  showWinnersLosers?: InputMaybe<Scalars['Boolean']['input']>;
  sorting?: InputMaybe<Scalars['AWSJSON']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
  tableId?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type UpdateApiConnectionInput = {
  connectionData?: InputMaybe<Scalars['AWSJSON']['input']>;
  connectionId?: InputMaybe<Scalars['String']['input']>;
  credentials: Scalars['AWSJSON']['input'];
  organizationId: Scalars['ID']['input'];
  serviceName: ApiConnectionService;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleBriefInput = {
  answered?: InputMaybe<Scalars['Boolean']['input']>;
  articleId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  keywordVariations?: InputMaybe<ArticleKeywordVariationsInput>;
  keywords?: InputMaybe<Array<ArticleKeywordInput>>;
  level?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<Array<ArticleQuestionInput>>;
  relevance?: InputMaybe<Scalars['Float']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ArticleBriefType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  volumes?: InputMaybe<Array<VolumeInput>>;
};

export type UpdateArticleCommentInput = {
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  id: Scalars['String']['input'];
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleDocumentInput = {
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  id: Scalars['ID']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleGuidelinesInput = {
  guidelines?: InputMaybe<Scalars['AWSJSON']['input']>;
  id: Scalars['ID']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleHeadlineInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleHeadlinesInput = {
  articleId: Scalars['String']['input'];
  headlines: Array<UpdateArticleHeadlineInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleInput = {
  advices?: InputMaybe<Scalars['AWSJSON']['input']>;
  articleId?: InputMaybe<Scalars['String']['input']>;
  articlePostType?: InputMaybe<ArticlePostType>;
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  audienceExpertiseId?: InputMaybe<Scalars['ID']['input']>;
  audiencePersonaId?: InputMaybe<Scalars['ID']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  commentId?: InputMaybe<Scalars['String']['input']>;
  companyKnowledgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  completedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  doneAdvices?: InputMaybe<Array<Scalars['String']['input']>>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exploreInputs?: InputMaybe<ExploreInput>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  focusKeyword?: InputMaybe<Scalars['String']['input']>;
  guidelines?: InputMaybe<Scalars['AWSJSON']['input']>;
  hasTopRankings?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  imageGuidelines?: InputMaybe<ArticleImageGuidelinesInput>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseTopicData?: InputMaybe<ArticleNeuraverseTopicDataInput>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<Scalars['AWSJSON']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  readingResources?: InputMaybe<Array<Scalars['String']['input']>>;
  requestOrigin?: InputMaybe<Scalars['String']['input']>;
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
  rscore?: InputMaybe<Scalars['AWSJSON']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  sk2?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ArticleState>;
  title?: InputMaybe<Scalars['String']['input']>;
  toneAndVoiceId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ArticleType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  urlIndexedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  urlStatus?: InputMaybe<ArticleUrlStatus>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
  wordCountPerArticle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleKeywordInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateArticleKeywordsInput = {
  articleId: Scalars['String']['input'];
  keywords: Array<UpdateArticleKeywordInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleMetadataInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  audienceExpertiseId?: InputMaybe<Scalars['ID']['input']>;
  audiencePersonaId?: InputMaybe<Scalars['ID']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  companyKnowledgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  completedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exploreInputs?: InputMaybe<ExploreInput>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  focusKeyword?: InputMaybe<Scalars['String']['input']>;
  hasTopRankings?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  imageGuidelines?: InputMaybe<ArticleImageGuidelinesInput>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseTopicData?: InputMaybe<ArticleNeuraverseTopicDataInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  publicationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  readingResources?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<ArticleState>;
  title?: InputMaybe<Scalars['String']['input']>;
  toneAndVoiceId?: InputMaybe<Scalars['ID']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  urlPath?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
  wordCountPerArticle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticlePostInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  content?: InputMaybe<Scalars['AWSJSON']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleQuestionInput = {
  answered?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateArticleQuestionsInput = {
  articleId: Scalars['String']['input'];
  questions: Array<UpdateArticleQuestionInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleRScoreInput = {
  doneAdvices?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  rscore?: InputMaybe<Scalars['AWSJSON']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  versionCreatorId?: InputMaybe<Scalars['ID']['input']>;
  versionDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
  versionName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateArticleRootDataInput = {
  id: Scalars['ID']['input'];
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  urlIndexedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdateAuth0UserRoleInput = {
  role: Auth0Role;
  userId: Scalars['String']['input'];
};

export type UpdateNVePriorityInput = {
  id: Scalars['String']['input'];
  priority: Scalars['Boolean']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNVeTopicArticlePriorityInput = {
  id: Scalars['String']['input'];
  priority: Scalars['Boolean']['input'];
  type: NVeTopicArticleType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNVeTopicSavedInput = {
  id: Scalars['String']['input'];
  isSaved: Scalars['Boolean']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNotificationInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  data?: InputMaybe<Scalars['AWSJSON']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  initiatedBy?: InputMaybe<Scalars['String']['input']>;
  notificationAction?: InputMaybe<NotificationAction>;
  notificationId?: InputMaybe<Scalars['String']['input']>;
  notificationStatus?: InputMaybe<NotificationStatus>;
  notificationType?: InputMaybe<NotificationType>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdateOrganizationInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectBriefDocumentInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  type: ProjectType;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectBriefDocumentsInput = {
  documents: Array<UpdateProjectBriefDocumentInput>;
  projectId: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectBriefMetadataInput = {
  avoid?: InputMaybe<Scalars['String']['input']>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  id: Scalars['ID']['input'];
  includedInArticleBrief?: InputMaybe<Array<Scalars['String']['input']>>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  planedArticles?: InputMaybe<Scalars['Int']['input']>;
  planedArticlesPerMonth?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<ProjectType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectImageGuidelinesInput = {
  allowGenAI?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  id: Scalars['ID']['input'];
  imageType?: InputMaybe<ImageType>;
  moodAndTone?: InputMaybe<ImageMoodAndTone>;
  orientation?: InputMaybe<ImageOrientation>;
  style?: InputMaybe<ImageStyle>;
  type?: InputMaybe<ProjectType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInput = {
  allowGenAI?: InputMaybe<Scalars['Boolean']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  avoid?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  competitorsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  custom?: InputMaybe<Scalars['Boolean']['input']>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  directReaderAddressing?: InputMaybe<DirectReaderAddressing>;
  domain?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  firstPersonPerspective?: InputMaybe<FirstPersonPerspective>;
  gscConnected?: InputMaybe<Scalars['Boolean']['input']>;
  gscConnectionId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  imageType?: InputMaybe<ImageType>;
  includedInArticleBrief?: InputMaybe<Array<Scalars['String']['input']>>;
  inputTopics?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedinConnected?: InputMaybe<Scalars['Boolean']['input']>;
  linkedinConnectionId?: InputMaybe<Scalars['ID']['input']>;
  linkedinOrgId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
  moodAndTone?: InputMaybe<ImageMoodAndTone>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  orientation?: InputMaybe<ImageOrientation>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  planedArticles?: InputMaybe<Scalars['Int']['input']>;
  planedArticlesPerMonth?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  sk1?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  style?: InputMaybe<ImageStyle>;
  topicInspirations?: InputMaybe<Array<Scalars['String']['input']>>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  type?: InputMaybe<ProjectType>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  wpConnected?: InputMaybe<Scalars['Boolean']['input']>;
  wpConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateProjectLabelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectMetadataInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  gscConnected?: InputMaybe<Scalars['Boolean']['input']>;
  gscConnectionId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  linkedinConnected?: InputMaybe<Scalars['Boolean']['input']>;
  linkedinConnectionId?: InputMaybe<Scalars['ID']['input']>;
  linkedinOrgId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  neuraverseId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  topicInspirations?: InputMaybe<Array<Scalars['String']['input']>>;
  ttl?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  wpConnected?: InputMaybe<Scalars['Boolean']['input']>;
  wpConnectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUiViewInput = {
  calendarVisibility?: InputMaybe<Scalars['AWSJSON']['input']>;
  companyPriority?: InputMaybe<Scalars['Boolean']['input']>;
  expanded?: InputMaybe<Scalars['AWSJSON']['input']>;
  filtering?: InputMaybe<Scalars['AWSJSON']['input']>;
  id: Scalars['ID']['input'];
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  showWinnersLosers?: InputMaybe<Scalars['Boolean']['input']>;
  sorting?: InputMaybe<Scalars['AWSJSON']['input']>;
  tab?: InputMaybe<Scalars['String']['input']>;
  tableId?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type UpdateUserArticleDataInput = {
  articleId: Scalars['ID']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
  userArticlePreference: UserArticlePreference;
  value: Scalars['Boolean']['input'];
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastReadNewsFeedPubDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  locale?: InputMaybe<Locale>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  notificationSettings?: InputMaybe<NotificationSettingsInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  userOrgData?: InputMaybe<UpdateUserOrgDataInput>;
};

export type UpdateUserOrgDataInput = {
  daysBeforeDueDateWarning?: InputMaybe<Scalars['Int']['input']>;
  lastAccessedProjectId?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProjectDataInput = {
  hidden: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
  tileName: HomeDashboardTileName;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UploadUrlOutput = {
  __typename?: 'UploadUrlOutput';
  key: Scalars['String']['output'];
  uploadURL: Scalars['String']['output'];
};

export type UpsertArticleHeadlineInput = {
  articleId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  level: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertArticleHeadlinesInput = {
  articleId: Scalars['String']['input'];
  headlines: Array<UpsertArticleHeadlineInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertArticleKeywordsInput = {
  articleId: Scalars['ID']['input'];
  keywords: Array<ArticleKeywordInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertArticleQuestionsInput = {
  articleId: Scalars['ID']['input'];
  questions: Array<ArticleQuestionInput>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWordpressConnectionInput = {
  applicationPassword: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UpsertWordpressConnectionResponse = {
  __typename?: 'UpsertWordpressConnectionResponse';
  errors?: Maybe<Array<Maybe<WordpressConnectionError>>>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  color?: Maybe<keyof IBaseColors>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastReadNewsFeedPubDate?: Maybe<Scalars['AWSDateTime']['output']>;
  locale?: Maybe<Locale>;
  nickname?: Maybe<Scalars['String']['output']>;
  notificationSettings?: Maybe<NotificationSettings>;
  pk1?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userOrgData?: Maybe<UserOrgData>;
};

export type UserArticleData = {
  __typename?: 'UserArticleData';
  id: Scalars['ID']['output'];
  preferences?: Maybe<Scalars['AWSJSON']['output']>;
};

export enum UserArticlePreference {
  DisableAutoChangeStatusToBrief = 'DisableAutoChangeStatusToBrief',
  DisableAutoChangeStatusToWrite = 'DisableAutoChangeStatusToWrite',
  DisableBriefTips = 'DisableBriefTips',
}

export enum UserArticlePreferences {
  DisableAutoChangeStatusToBrief = 'disableAutoChangeStatusToBrief',
  DisableAutoChangeStatusToWrite = 'disableAutoChangeStatusToWrite',
}

export type UserDelete = {
  __typename?: 'UserDelete';
  id: Scalars['ID']['output'];
};

export type UserItems = {
  __typename?: 'UserItems';
  items: Array<User>;
};

export type UserOrgData = {
  __typename?: 'UserOrgData';
  auth0: Auth0;
  daysBeforeDueDateWarning?: Maybe<Scalars['Int']['output']>;
  lastAccessedProjectId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  updateToken?: Maybe<Scalars['String']['output']>;
};

export type UserOrgDataInput = {
  auth0: Auth0Input;
  daysBeforeDueDateWarning?: InputMaybe<Scalars['Int']['input']>;
  lastAccessedProjectId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  updateToken?: InputMaybe<Scalars['String']['input']>;
};

export type UserProjectData = {
  __typename?: 'UserProjectData';
  hiddenTiles?: Maybe<Scalars['AWSJSON']['output']>;
  id: Scalars['ID']['output'];
};

export type UserProjectWithRole = {
  __typename?: 'UserProjectWithRole';
  project: ProjectMetadata;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type UserProjectsWithRoles = {
  __typename?: 'UserProjectsWithRoles';
  projects: Array<UserProjectWithRole>;
  user: User;
};

export type UserRoleAssignmentInput = {
  entityId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type UserRoleInput = {
  assignments: Array<UserRoleAssignmentInput>;
  userId: Scalars['ID']['input'];
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  avatar?: Maybe<Scalars['String']['output']>;
  color?: Maybe<keyof IBaseColors>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Scalars['AWSEmail']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastReadNewsFeedPubDate?: Maybe<Scalars['AWSDateTime']['output']>;
  locale?: Maybe<Locale>;
  nickname?: Maybe<Scalars['String']['output']>;
  notificationSettings?: Maybe<NotificationSettings>;
  organizationId?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  updateToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userOrgData?: Maybe<UserOrgData>;
};

export type UserSubscriptionDelete = {
  __typename?: 'UserSubscriptionDelete';
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  pk1?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSubscriptionInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastReadNewsFeedPubDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  locale?: InputMaybe<Locale>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  notificationSettings?: InputMaybe<NotificationSettingsInput>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pk1?: InputMaybe<Scalars['String']['input']>;
  updateToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userOrgData?: InputMaybe<UserOrgDataInput>;
};

export type UserWithProjects = {
  __typename?: 'UserWithProjects';
  auth0: Auth0;
  id: Scalars['ID']['output'];
  projectsCount: Scalars['Int']['output'];
  user: User;
};

export type Volume = {
  __typename?: 'Volume';
  amount: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type VolumeElement = {
  __typename?: 'VolumeElement';
  amount: Scalars['Int']['output'];
  date?: Maybe<Scalars['AWSDate']['output']>;
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type VolumeInput = {
  amount: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type WordPressConnectionCredentials = {
  __typename?: 'WordPressConnectionCredentials';
  applicationPassword: Scalars['String']['output'];
  endpoint: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type WordPressExportInput = {
  articleId: Scalars['ID']['input'];
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  exportId?: InputMaybe<Scalars['String']['input']>;
  saveAs: WordPressType;
};

export enum WordPressType {
  Page = 'Page',
  Post = 'Post',
}

export type WordpressCategory = {
  __typename?: 'WordpressCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WordpressConnectionData = {
  __typename?: 'WordpressConnectionData';
  endpoint: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type WordpressConnectionError = {
  __typename?: 'WordpressConnectionError';
  code: Scalars['String']['output'];
  data?: Maybe<WordpressConnectionErrorData>;
  message?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type WordpressConnectionErrorData = {
  __typename?: 'WordpressConnectionErrorData';
  status: Scalars['Int']['output'];
};

export type ListNVeTopicSearchTermsInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LogAnalyticsVariables = Exact<{
  input: CreateAnalyticsLogInput;
}>;

export type LogAnalytics = {
  __typename?: 'Mutation';
  createAnalyticsLog: boolean;
};

export type DeleteApiConnectionVariables = Exact<{
  input: DeleteApiConnectionInput;
}>;

export type DeleteApiConnection = {
  __typename?: 'Mutation';
  deleteApiConnection: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    description?: string | null;
    country?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    neuraverseId?: string | null;
    neuraverseVersion?: NeuraverseVersion | null;
    articleStatistics?: {
      __typename?: 'ArticleStatistics';
      articleCountInProgress: number;
      completedArticlesThisMonth: number;
      articleCountByState: {
        __typename?: 'ArticleCountByState';
        BRIEF: number;
        DONE: number;
        WRITE: number;
        ONHOLD: number;
        REVIEW: number;
        TODO: number;
      };
    } | null;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        startDate?: string | null;
        dueDate?: string | null;
        avoid?: string | null;
        firstPersonPerspective: FirstPersonPerspective;
        directReaderAddressing: DirectReaderAddressing;
        planedArticles?: number | null;
        planedArticlesPerMonth?: number | null;
        competitorsUrls?: Array<string> | null;
        includedInArticleBrief?: Array<string> | null;
        inputTopics?: Array<string> | null;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      imageGuidelines: {
        __typename?: 'ProjectImageGuidelines';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        allowGenAI: boolean;
        moodAndTone: ImageMoodAndTone;
        orientation: ImageOrientation;
        style: ImageStyle;
        imageType: ImageType;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      audiencePersonas: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      toneAndVoices: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      companyKnowledges: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      audienceExpertises: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
    } | null;
    labels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type GetApiConnectionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetApiConnection = {
  __typename?: 'Query';
  getApiConnection?: {
    __typename?: 'ApiConnection';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    connectionData?: string | null;
    serviceName: ApiConnectionService;
    organizationId?: string | null;
  } | null;
};

export type ArticleDocumentFull = {
  __typename?: 'ArticleDocument';
  content?: string | null;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  pk1: string;
  requestOrigin?: string | null;
  sk1: string;
  sk2?: string | null;
  type: ArticleType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
};

export type ArticleGuidelinesFull = {
  __typename?: 'ArticleGuidelines';
  createdAt: string;
  createdBy?: string | null;
  guidelines?: string | null;
  id: string;
  pk1: string;
  requestOrigin?: string | null;
  sk1: string;
  sk2?: string | null;
  type: ArticleType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
};

export type ArticleImageGuidelinesFull = {
  __typename?: 'ArticleImageGuidelines';
  allowGenAI: boolean;
  moodAndTone: ImageMoodAndTone;
  orientation: ImageOrientation;
  style: ImageStyle;
  imageType: ImageType;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
};

export type ArticleMetadataAssignee = {
  __typename?: 'ArticleMetadata';
  assigneeId: string;
  assignee?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
  } | null;
};

export type ArticleMetadataBase = {
  __typename?: 'ArticleMetadata';
  pk1: string;
  sk1: string;
  sk2?: string | null;
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  name?: string | null;
  versionId: number;
  projectId: string;
};

export type ArticleMetadataBrief = {
  __typename?: 'ArticleMetadata';
  wordCountPerArticle?: string | null;
  readingResources?: Array<string> | null;
  hasTopRankings?: boolean | null;
  competitorsUrls?: Array<string> | null;
  avoid?: string | null;
  firstPersonPerspective?: FirstPersonPerspective | null;
  directReaderAddressing: DirectReaderAddressing;
  audienceExpertiseId?: string | null;
  audiencePersonaId?: string | null;
  toneAndVoiceId?: string | null;
  companyKnowledgeIds?: Array<string> | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
  imageGuidelines: {
    __typename?: 'ArticleImageGuidelines';
    allowGenAI: boolean;
    moodAndTone: ImageMoodAndTone;
    orientation: ImageOrientation;
    style: ImageStyle;
    imageType: ImageType;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  };
  selectedProjectBriefDocuments?: {
    __typename?: 'SelectedProjectBriefDocuments';
    audienceExpertise?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
    audiencePersona?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
    companyKnowledges?: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }> | null;
    toneAndVoice?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
  } | null;
};

export type ArticleMetadataDetails = {
  __typename?: 'ArticleMetadata';
  state: ArticleState;
  completedDate?: string | null;
  publicationDate?: string | null;
  dueDate?: string | null;
  focusKeyword?: string | null;
  urlPath?: string | null;
};

export type ArticleMetadataEditor = {
  __typename?: 'ArticleMetadata';
  title?: string | null;
  description?: string | null;
  articleRScore?: {
    __typename?: 'ArticleRScore';
    createdAt: string;
    createdBy?: string | null;
    doneAdvices?: Array<string> | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    rscore?: string | null;
    sk1: string;
    sk2?: string | null;
    updateToken?: string | null;
    type: ArticleType;
    updatedAt: string;
    updatedBy?: string | null;
    versionId: number;
  } | null;
  articleDocument?: {
    __typename?: 'ArticleDocument';
    content?: string | null;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  } | null;
};

export type ArticleMetadataExplore = {
  __typename?: 'ArticleMetadata';
  exploreInputs?: {
    __typename?: 'Explore';
    searchTerms?: Array<string> | null;
    contentCategory?: string | null;
    subcategory?: string | null;
    timeRange?: number | null;
    currentTab?: TabKey | null;
    variety?: number | null;
    searchedOnce?: boolean | null;
    engineLanguage: EngineLanguage;
  } | null;
  neuraverseTopicData?: {
    __typename?: 'ArticleNeuraverseTopicData';
    topicId: string;
    topicNames: Array<string>;
    articleDirection?: string | null;
    articleLengthSuggestion?: number | null;
  } | null;
};

export type ArticleMetadataFull = {
  __typename?: 'ArticleMetadata';
  pk1: string;
  sk1: string;
  sk2?: string | null;
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  name?: string | null;
  versionId: number;
  projectId: string;
  assigneeId: string;
  wordCountPerArticle?: string | null;
  readingResources?: Array<string> | null;
  hasTopRankings?: boolean | null;
  competitorsUrls?: Array<string> | null;
  avoid?: string | null;
  firstPersonPerspective?: FirstPersonPerspective | null;
  directReaderAddressing: DirectReaderAddressing;
  audienceExpertiseId?: string | null;
  audiencePersonaId?: string | null;
  toneAndVoiceId?: string | null;
  companyKnowledgeIds?: Array<string> | null;
  state: ArticleState;
  completedDate?: string | null;
  publicationDate?: string | null;
  dueDate?: string | null;
  focusKeyword?: string | null;
  urlPath?: string | null;
  title?: string | null;
  description?: string | null;
  versionName?: string | null;
  versionDate?: string | null;
  versionCreatorId?: string | null;
  assignee?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
  } | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
  imageGuidelines: {
    __typename?: 'ArticleImageGuidelines';
    allowGenAI: boolean;
    moodAndTone: ImageMoodAndTone;
    orientation: ImageOrientation;
    style: ImageStyle;
    imageType: ImageType;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  };
  selectedProjectBriefDocuments?: {
    __typename?: 'SelectedProjectBriefDocuments';
    audienceExpertise?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
    audiencePersona?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
    companyKnowledges?: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }> | null;
    toneAndVoice?: {
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    } | null;
  } | null;
  articleRScore?: {
    __typename?: 'ArticleRScore';
    createdAt: string;
    createdBy?: string | null;
    doneAdvices?: Array<string> | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    rscore?: string | null;
    sk1: string;
    sk2?: string | null;
    updateToken?: string | null;
    type: ArticleType;
    updatedAt: string;
    updatedBy?: string | null;
    versionId: number;
  } | null;
  articleDocument?: {
    __typename?: 'ArticleDocument';
    content?: string | null;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  } | null;
  exploreInputs?: {
    __typename?: 'Explore';
    searchTerms?: Array<string> | null;
    contentCategory?: string | null;
    subcategory?: string | null;
    timeRange?: number | null;
    currentTab?: TabKey | null;
    variety?: number | null;
    searchedOnce?: boolean | null;
    engineLanguage: EngineLanguage;
  } | null;
  neuraverseTopicData?: {
    __typename?: 'ArticleNeuraverseTopicData';
    topicId: string;
    topicNames: Array<string>;
    articleDirection?: string | null;
    articleLengthSuggestion?: number | null;
  } | null;
  articleGuidelines?: {
    __typename?: 'ArticleGuidelines';
    createdAt: string;
    createdBy?: string | null;
    guidelines?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  } | null;
  project?: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
  } | null;
  versionCreator?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
  } | null;
  articleRootData?: {
    __typename?: 'ArticleRootData';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    priority?: boolean | null;
    labelIds?: Array<string> | null;
    urlStatus?: ArticleUrlStatus | null;
  } | null;
  selectedProjectLabels?: Array<{
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  }> | null;
};

export type ArticleMetadataGuidelines = {
  __typename?: 'ArticleMetadata';
  articleGuidelines?: {
    __typename?: 'ArticleGuidelines';
    createdAt: string;
    createdBy?: string | null;
    guidelines?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  } | null;
};

export type ArticleMetadataProject = {
  __typename?: 'ArticleMetadata';
  project?: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
  } | null;
};

export type ArticleMetadataProjectLabels = {
  __typename?: 'ArticleMetadata';
  selectedProjectLabels?: Array<{
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  }> | null;
};

export type ArticleMetadataRootData = {
  __typename?: 'ArticleMetadata';
  articleRootData?: {
    __typename?: 'ArticleRootData';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    priority?: boolean | null;
    labelIds?: Array<string> | null;
    urlStatus?: ArticleUrlStatus | null;
  } | null;
};

export type ArticleMetadataVersion = {
  __typename?: 'ArticleMetadata';
  versionId: number;
  versionName?: string | null;
  versionDate?: string | null;
  versionCreatorId?: string | null;
  versionCreator?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
  } | null;
};

export type ArticleRScoreFull = {
  __typename?: 'ArticleRScore';
  createdAt: string;
  createdBy?: string | null;
  doneAdvices?: Array<string> | null;
  id: string;
  pk1: string;
  requestOrigin?: string | null;
  rscore?: string | null;
  sk1: string;
  sk2?: string | null;
  updateToken?: string | null;
  type: ArticleType;
  updatedAt: string;
  updatedBy?: string | null;
  versionId: number;
};

export type ArticleRootDataBase = {
  __typename?: 'ArticleRootData';
  pk1: string;
  sk1: string;
  sk2?: string | null;
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
};

export type ArticleRootDataDetails = {
  __typename?: 'ArticleRootData';
  priority?: boolean | null;
  labelIds?: Array<string> | null;
};

export type ArticleRootDataFull = {
  __typename?: 'ArticleRootData';
  pk1: string;
  sk1: string;
  sk2?: string | null;
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  priority?: boolean | null;
  labelIds?: Array<string> | null;
  urlStatus?: ArticleUrlStatus | null;
};

export type ArticleRootDataUrlStatus = {
  __typename?: 'ArticleRootData';
  urlStatus?: ArticleUrlStatus | null;
  pk1: string;
  sk1: string;
  sk2?: string | null;
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
};

export type ArticleSubscriptionFull = {
  __typename?: 'ArticleSubscription';
  createdBy?: string | null;
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  sk1?: string | null;
  id: string;
  requestOrigin?: string | null;
  type?: ArticleType | null;
  content?: string | null;
  name?: string | null;
  articlePostType?: ArticlePostType | null;
  attachments?: Array<string> | null;
  advices?: string | null;
  resolved?: boolean | null;
  commentId?: string | null;
  articleId?: string | null;
  urlStatus?: ArticleUrlStatus | null;
  urlIndexedAt?: string | null;
  priority?: boolean | null;
  sk2?: string | null;
  state?: ArticleState | null;
  completedDate?: string | null;
  publicationDate?: string | null;
  dueDate?: string | null;
  projectId?: string | null;
  assigneeId?: string | null;
  focusKeyword?: string | null;
  urlPath?: string | null;
  title?: string | null;
  description?: string | null;
  wordCountPerArticle?: string | null;
  readingResources?: Array<string> | null;
  directReaderAddressing?: DirectReaderAddressing | null;
  hasTopRankings?: boolean | null;
  competitorsUrls?: Array<string> | null;
  avoid?: string | null;
  inputTopics?: Array<string> | null;
  versionId?: number | null;
  versionName?: string | null;
  versionDate?: string | null;
  versionCreatorId?: string | null;
  rscore?: string | null;
  doneAdvices?: Array<string> | null;
  guidelines?: string | null;
  preferences?: string | null;
  userId?: string | null;
  audienceExpertiseId?: string | null;
  audiencePersonaId?: string | null;
  toneAndVoiceId?: string | null;
  companyKnowledgeIds?: Array<string> | null;
  labelIds?: Array<string> | null;
  neuraverseTopicData?: {
    __typename?: 'ArticleNeuraverseTopicData';
    topicId: string;
    articleDirection?: string | null;
    articleLengthSuggestion?: number | null;
    topicNames: Array<string>;
  } | null;
  imageGuidelines?: {
    __typename?: 'ArticleImageGuidelines';
    allowGenAI: boolean;
    moodAndTone: ImageMoodAndTone;
    orientation: ImageOrientation;
    style: ImageStyle;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  } | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
  exploreInputs?: {
    __typename?: 'Explore';
    searchTerms?: Array<string> | null;
    contentCategory?: string | null;
    subcategory?: string | null;
    timeRange?: number | null;
    currentTab?: TabKey | null;
    variety?: number | null;
    searchedOnce?: boolean | null;
    engineLanguage: EngineLanguage;
  } | null;
};

export type CreateArticleVersionVariables = Exact<{
  input: CreateVersionInput;
}>;

export type CreateArticleVersion = {
  __typename?: 'Mutation';
  createArticleVersion: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type DeleteArticleVersionVariables = Exact<{
  input: DeleteVersionInput;
}>;

export type DeleteArticleVersion = {
  __typename?: 'Mutation';
  deleteArticleVersion: { __typename?: 'ArticleMetadata'; id: string };
};

export type RestoreArticleVersionVariables = Exact<{
  input: RestoreVersionInput;
}>;

export type RestoreArticleVersion = {
  __typename?: 'Mutation';
  restoreArticleVersion: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type CreateArticleVariables = Exact<{
  input: CreateArticleInput;
}>;

export type CreateArticle = {
  __typename?: 'Mutation';
  createArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type CreateArticleByNeuraverseVariables = Exact<{
  input: CreateArticleByNeuraverseInput;
}>;

export type CreateArticleByNeuraverse = {
  __typename?: 'Mutation';
  createArticleByNeuraverse: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type UpdateArticleDocumentVariables = Exact<{
  input: UpdateArticleDocumentInput;
}>;

export type UpdateArticleDocument = {
  __typename?: 'Mutation';
  updateArticleDocument: {
    __typename?: 'ArticleDocument';
    content?: string | null;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  };
};

export type UpdateArticleGuidelinesVariables = Exact<{
  input: UpdateArticleGuidelinesInput;
}>;

export type UpdateArticleGuidelines = {
  __typename?: 'Mutation';
  updateArticleGuidelines: {
    __typename?: 'ArticleGuidelines';
    createdAt: string;
    createdBy?: string | null;
    guidelines?: string | null;
    id: string;
    pk1: string;
    requestOrigin?: string | null;
    sk1: string;
    sk2?: string | null;
    type: ArticleType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
  };
};

export type UpdateArticleMetadataVariables = Exact<{
  input: UpdateArticleMetadataInput;
}>;

export type UpdateArticleMetadata = {
  __typename?: 'Mutation';
  updateArticleMetadata: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type UpdateArticleRootDataVariables = Exact<{
  input: UpdateArticleRootDataInput;
}>;

export type UpdateArticleRootData = {
  __typename?: 'Mutation';
  updateArticleRootData: {
    __typename?: 'ArticleRootData';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    priority?: boolean | null;
    labelIds?: Array<string> | null;
    urlStatus?: ArticleUrlStatus | null;
  };
};

export type DeleteArticleVariables = Exact<{
  input: DeleteArticleInput;
}>;

export type DeleteArticle = {
  __typename?: 'Mutation';
  deleteArticle: { __typename?: 'ArticleDelete'; id: string };
};

export type GenerateArticleByGptVariables = Exact<{
  input: ArticleByGptInput;
}>;

export type GenerateArticleByGpt = {
  __typename?: 'Mutation';
  generateArticleByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GenerateContentByGptVariables = Exact<{
  input: ContentByGptInput;
}>;

export type GenerateContentByGpt = {
  __typename?: 'Mutation';
  generateContentByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type ResetChannelVariables = Exact<{
  input: ResetChannelHandlerInput;
}>;

export type ResetChannel = {
  __typename?: 'Mutation';
  resetChannel?: boolean | null;
};

export type ListArticlesForCountVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticlesForCount = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      id: string;
      versionId: number;
    }>;
  };
};

export type GetArticleForArticleMonitoringVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleForArticleMonitoring = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    publicationDate?: string | null;
    urlPath?: string | null;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      urlStatus?: ArticleUrlStatus | null;
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
    } | null;
  };
};

export type ListArticlesForOverviewVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticlesForOverview = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      completedDate?: string | null;
      state: ArticleState;
      urlPath?: string | null;
      publicationDate?: string | null;
      versionId: number;
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      projectId: string;
      articleRootData?: {
        __typename?: 'ArticleRootData';
        pk1: string;
        sk1: string;
        sk2?: string | null;
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        requestOrigin?: string | null;
        type: ArticleType;
        priority?: boolean | null;
        labelIds?: Array<string> | null;
        urlStatus?: ArticleUrlStatus | null;
      } | null;
    }>;
  };
};

export type ListArticlesForPlanPageVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticlesForPlanPage = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      publicationDate?: string | null;
      dueDate?: string | null;
      state: ArticleState;
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
      articleRootData?: {
        __typename?: 'ArticleRootData';
        pk1: string;
        sk1: string;
        sk2?: string | null;
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        requestOrigin?: string | null;
        type: ArticleType;
        priority?: boolean | null;
        labelIds?: Array<string> | null;
        urlStatus?: ArticleUrlStatus | null;
      } | null;
      selectedProjectLabels?: Array<{
        __typename?: 'ProjectLabel';
        color: string;
        id: string;
        name: string;
        custom?: boolean | null;
        projectId: string;
      }> | null;
    }>;
  };
};

export type ListArticleVersionsVariables = Exact<{
  input: ListVersionsInput;
}>;

export type ListArticleVersions = {
  __typename?: 'Query';
  listVersions: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      urlPath?: string | null;
      publicationDate?: string | null;
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
      versionName?: string | null;
      versionDate?: string | null;
      versionCreatorId?: string | null;
      versionCreator?: {
        __typename?: 'User';
        id: string;
        updatedBy?: string | null;
        updatedAt?: string | null;
        updateToken?: string | null;
        pk1?: string | null;
        nickname?: string | null;
        locale?: Locale | null;
        lastName?: string | null;
        firstName?: string | null;
        createdAt?: string | null;
        color?: Maybe<keyof IBaseColors>;
        avatar?: string | null;
      } | null;
    }>;
  };
};

export type ListArticlesForArticlesPageVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticlesForArticlesPage = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
      assigneeId: string;
      state: ArticleState;
      completedDate?: string | null;
      publicationDate?: string | null;
      dueDate?: string | null;
      focusKeyword?: string | null;
      urlPath?: string | null;
      neuraverseTopicData?: {
        __typename?: 'ArticleNeuraverseTopicData';
        topicId: string;
        topicNames: Array<string>;
      } | null;
      articleRootData?: {
        __typename?: 'ArticleRootData';
        pk1: string;
        sk1: string;
        sk2?: string | null;
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        requestOrigin?: string | null;
        type: ArticleType;
        priority?: boolean | null;
        labelIds?: Array<string> | null;
        urlStatus?: ArticleUrlStatus | null;
      } | null;
      articleRScore?: {
        __typename?: 'ArticleRScore';
        createdAt: string;
        createdBy?: string | null;
        doneAdvices?: Array<string> | null;
        id: string;
        pk1: string;
        requestOrigin?: string | null;
        rscore?: string | null;
        sk1: string;
        sk2?: string | null;
        updateToken?: string | null;
        type: ArticleType;
        updatedAt: string;
        updatedBy?: string | null;
        versionId: number;
      } | null;
      assignee?: {
        __typename?: 'User';
        id: string;
        updatedBy?: string | null;
        updatedAt?: string | null;
        updateToken?: string | null;
        pk1?: string | null;
        nickname?: string | null;
        locale?: Locale | null;
        lastName?: string | null;
        firstName?: string | null;
        createdAt?: string | null;
        color?: Maybe<keyof IBaseColors>;
        avatar?: string | null;
      } | null;
      project?: {
        __typename?: 'ProjectMetadata';
        createdBy?: string | null;
        updatedBy?: string | null;
        updateToken?: string | null;
        createdAt: string;
        updatedAt: string;
        pk1: string;
        id: string;
        name?: string | null;
        archived?: boolean | null;
        organizationId: string;
        avatar?: string | null;
        color?: Maybe<keyof IBaseColors>;
        domain?: string | null;
        locale: Locale;
      } | null;
      selectedProjectLabels?: Array<{
        __typename?: 'ProjectLabel';
        color: string;
        id: string;
        name: string;
        custom?: boolean | null;
        projectId: string;
      }> | null;
    }>;
  };
};

export type ListArticleBaseVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticleBase = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
    }>;
  };
};

export type ListArticlesWithSameFocusKeywordForBriefVariables = Exact<{
  input: ListArticlesWithSameFocusKeywordInput;
}>;

export type ListArticlesWithSameFocusKeywordForBrief = {
  __typename?: 'Query';
  listArticlesWithSameFocusKeyword: {
    __typename?: 'ListArticlesWithSameFocusKeyword';
    items: Array<{
      __typename?: 'ArticlesWithSameFocusKeyword';
      id: string;
      name?: string | null;
    }>;
  };
};

export type GetArticleFullVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleFull = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type GetArticleBaseVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleBase = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
  };
};

export type GetArticleDetailsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleDetails = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
  };
};

export type GetArticleVersionNameVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleVersionName = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    versionName?: string | null;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
  };
};

export type GetArticleForDetailsTabVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleForDetailsTab = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    dueDate?: string | null;
    publicationDate?: string | null;
    state: ArticleState;
    urlPath?: string | null;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type GetArticleExploreVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleExplore = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
  };
};

export type GetArticleNameVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleName = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
  };
};

export type GetArticleFocusKeywordDetailsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleFocusKeywordDetails = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
  };
};

export type GetArticleRscoreVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleRscore = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
  };
};

export type GetArticleRootDataVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleRootData = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
  };
};

export type GetArticleGuidelinesVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleGuidelines = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
  };
};

export type GetArticleUrlVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleUrl = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    urlPath?: string | null;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
  };
};

export type CompareArticleVersionsVariables = Exact<{
  input: CompareVersionsInput;
}>;

export type CompareArticleVersions = {
  __typename?: 'Query';
  compareArticleVersions?: {
    __typename?: 'CompareVersionsOutput';
    comparedHtml: string;
  } | null;
};

export type ListArticleVersionNamesVariables = Exact<{
  input: ListVersionsInput;
}>;

export type ListArticleVersionNames = {
  __typename?: 'Query';
  listVersions: {
    __typename?: 'ListArticles';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ArticleMetadata';
      versionName?: string | null;
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
    }>;
  };
};

export type GetArticleForGenerationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleForGeneration = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    focusKeyword?: string | null;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
  };
};

export type ListArticleHeadlinesForGenerateContentVariables = Exact<{
  articleId: Scalars['String']['input'];
}>;

export type ListArticleHeadlinesForGenerateContent = {
  __typename?: 'Query';
  listArticleHeadlines: {
    __typename?: 'ListArticleHeadlinesType';
    items: Array<{
      __typename?: 'ArticleHeadline';
      articleId: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    }>;
  };
};

export type ListLatestIndexedArticlesVariables = Exact<{
  input: ListLatestIndexedArticlesInput;
}>;

export type ListLatestIndexedArticles = {
  __typename?: 'Query';
  listLatestIndexedArticles: {
    __typename?: 'LatestIndexedArticles';
    articles: Array<{
      __typename?: 'IndexedArticle';
      id: string;
      name?: string | null;
      urlIndexedAt: string;
    }>;
  };
};

export type GetTurnServerCredentialsVariables = Exact<{
  input: GetTurnServerCredentialsInput;
}>;

export type GetTurnServerCredentials = {
  __typename?: 'Query';
  getTURNServerCredentials: {
    __typename?: 'GetTURNServerCredentialsResponse';
    iceServers: Array<{
      __typename?: 'ICEServer';
      credential?: string | null;
      url?: string | null;
      urls?: string | null;
      username?: string | null;
    }>;
  };
};

export type GetUserArticleDataVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserArticleData = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    state: ArticleState;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    userArticle?: {
      __typename?: 'UserArticleData';
      preferences?: string | null;
      id: string;
    } | null;
  };
};

export type GetArticleStateVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetArticleState = {
  __typename?: 'Query';
  getArticle: {
    __typename?: 'ArticleMetadata';
    state: ArticleState;
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
  };
};

export type GenerateFocusKeywordsByGptVariables = Exact<{
  input: FocusKeywordsByGptInput;
}>;

export type GenerateFocusKeywordsByGpt = {
  __typename?: 'Query';
  generateFocusKeywordsByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type OnArticleCreateVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleCreate = {
  __typename?: 'Subscription';
  onArticleCreate?: {
    __typename?: 'ArticleSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    requestOrigin?: string | null;
    type?: ArticleType | null;
    content?: string | null;
    name?: string | null;
    articlePostType?: ArticlePostType | null;
    attachments?: Array<string> | null;
    advices?: string | null;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId?: string | null;
    urlStatus?: ArticleUrlStatus | null;
    urlIndexedAt?: string | null;
    priority?: boolean | null;
    sk2?: string | null;
    state?: ArticleState | null;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    projectId?: string | null;
    assigneeId?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    inputTopics?: Array<string> | null;
    versionId?: number | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    rscore?: string | null;
    doneAdvices?: Array<string> | null;
    guidelines?: string | null;
    preferences?: string | null;
    userId?: string | null;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    labelIds?: Array<string> | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
      topicNames: Array<string>;
    } | null;
    imageGuidelines?: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
  } | null;
};

export type OnArticleUpdateVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleUpdate = {
  __typename?: 'Subscription';
  onArticleUpdate?: {
    __typename?: 'ArticleSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    requestOrigin?: string | null;
    type?: ArticleType | null;
    content?: string | null;
    name?: string | null;
    articlePostType?: ArticlePostType | null;
    attachments?: Array<string> | null;
    advices?: string | null;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId?: string | null;
    urlStatus?: ArticleUrlStatus | null;
    urlIndexedAt?: string | null;
    priority?: boolean | null;
    sk2?: string | null;
    state?: ArticleState | null;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    projectId?: string | null;
    assigneeId?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    inputTopics?: Array<string> | null;
    versionId?: number | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    rscore?: string | null;
    doneAdvices?: Array<string> | null;
    guidelines?: string | null;
    preferences?: string | null;
    userId?: string | null;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    labelIds?: Array<string> | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
      topicNames: Array<string>;
    } | null;
    imageGuidelines?: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
  } | null;
};

export type OnArticleDeleteVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleDelete = {
  __typename?: 'Subscription';
  onArticleDelete?: {
    __typename?: 'ArticleSubscriptionDelete';
    type?: string | null;
    pk1?: string | null;
    id: string;
    userId?: string | null;
  } | null;
};

export type OnArticleUpdateByIdVariables = Exact<{
  pk1: Scalars['String']['input'];
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type OnArticleUpdateById = {
  __typename?: 'Subscription';
  onArticleUpdateById?: {
    __typename?: 'ArticleSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    requestOrigin?: string | null;
    type?: ArticleType | null;
    content?: string | null;
    name?: string | null;
    articlePostType?: ArticlePostType | null;
    attachments?: Array<string> | null;
    advices?: string | null;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId?: string | null;
    urlStatus?: ArticleUrlStatus | null;
    urlIndexedAt?: string | null;
    priority?: boolean | null;
    sk2?: string | null;
    state?: ArticleState | null;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    projectId?: string | null;
    assigneeId?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    inputTopics?: Array<string> | null;
    versionId?: number | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    rscore?: string | null;
    doneAdvices?: Array<string> | null;
    guidelines?: string | null;
    preferences?: string | null;
    userId?: string | null;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    labelIds?: Array<string> | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
      topicNames: Array<string>;
    } | null;
    imageGuidelines?: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
  } | null;
};

export type OnArticleDeleteByIdVariables = Exact<{
  pk1: Scalars['String']['input'];
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type OnArticleDeleteById = {
  __typename?: 'Subscription';
  onArticleDeleteById?: {
    __typename?: 'ArticleSubscriptionDelete';
    type?: string | null;
    pk1?: string | null;
    id: string;
    userId?: string | null;
  } | null;
};

export type ArticleBriefSubscriptionFull = {
  __typename?: 'ArticleBriefSubscription';
  answered?: boolean | null;
  articleId?: string | null;
  content?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hidden?: boolean | null;
  id: string;
  index?: number | null;
  level?: number | null;
  organizationId?: string | null;
  pk1?: string | null;
  relevance?: number | null;
  required?: boolean | null;
  sk1?: string | null;
  title?: string | null;
  type?: ArticleBriefType | null;
  updateToken?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  userId?: string | null;
  volumes?: Array<{
    __typename?: 'Volume';
    amount: number;
    month: number;
    year: number;
  }> | null;
  keywords?: Array<{
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    relevance?: number | null;
    hidden?: boolean | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  }> | null;
  questions?: Array<{
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  }> | null;
};

export type ArticleHeadlineBase = {
  __typename?: 'ArticleHeadline';
  articleId: string;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
};

export type ArticleHeadlineFull = {
  __typename?: 'ArticleHeadline';
  description: string;
  index: number;
  level: number;
  title: string;
  type: ArticleBriefType;
  articleId: string;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
  questions: Array<{
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  }>;
  keywords: Array<{
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    relevance?: number | null;
    hidden?: boolean | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  }>;
};

export type ArticleKeywordBase = {
  __typename?: 'ArticleKeyword';
  articleId: string;
  content: string;
  required: boolean;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  type: ArticleBriefType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
};

export type ArticleKeywordBrief = {
  __typename?: 'ArticleKeyword';
  hidden?: boolean | null;
};

export type ArticleKeywordExplore = {
  __typename?: 'ArticleKeyword';
  relevance?: number | null;
  volumes?: Array<{
    __typename?: 'Volume';
    amount: number;
    month: number;
    year: number;
  }> | null;
};

export type ArticleKeywordFull = {
  __typename?: 'ArticleKeyword';
  articleId: string;
  content: string;
  required: boolean;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  type: ArticleBriefType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
  relevance?: number | null;
  hidden?: boolean | null;
  volumes?: Array<{
    __typename?: 'Volume';
    amount: number;
    month: number;
    year: number;
  }> | null;
  keywordVariations?: {
    __typename?: 'ArticleKeywordVariations';
    singularAndPlural: Array<string>;
    verbsAndNouns: Array<string>;
    verbsAndNounsSingularAndPlural: Array<string>;
    closelyRelatedCompoundNouns: Array<string>;
    separationsByHyphens: Array<string>;
    abbreviationsOrAcronyms: Array<string>;
    synonyms: Array<string>;
    synonymsSingularAndPlural: Array<string>;
    superlatives: Array<string>;
    rootForms: Array<string>;
    variationsUsingConnectingPrepositionOptions: Array<string>;
  } | null;
};

export type ArticleKeywordKeywordVariations = {
  __typename?: 'ArticleKeyword';
  keywordVariations?: {
    __typename?: 'ArticleKeywordVariations';
    singularAndPlural: Array<string>;
    verbsAndNouns: Array<string>;
    verbsAndNounsSingularAndPlural: Array<string>;
    closelyRelatedCompoundNouns: Array<string>;
    separationsByHyphens: Array<string>;
    abbreviationsOrAcronyms: Array<string>;
    synonyms: Array<string>;
    synonymsSingularAndPlural: Array<string>;
    superlatives: Array<string>;
    rootForms: Array<string>;
    variationsUsingConnectingPrepositionOptions: Array<string>;
  } | null;
};

export type ArticleQuestionBase = {
  __typename?: 'ArticleQuestion';
  articleId: string;
  content: string;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  type: ArticleBriefType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
};

export type ArticleQuestionBrief = {
  __typename?: 'ArticleQuestion';
  answered?: boolean | null;
  hidden?: boolean | null;
};

export type ArticleQuestionFull = {
  __typename?: 'ArticleQuestion';
  articleId: string;
  content: string;
  createdAt: string;
  createdBy?: string | null;
  id: string;
  type: ArticleBriefType;
  updateToken?: string | null;
  updatedAt: string;
  updatedBy?: string | null;
  answered?: boolean | null;
  hidden?: boolean | null;
};

export type GoogleTopRankingFull = {
  __typename?: 'GoogleTopRanking';
  locale?: Locale | null;
  averageWordCount?: number | null;
  ttl?: number | null;
  searchTerm: string;
  searchResults?: Array<{
    __typename?: 'GoogleSearchResult';
    summary?: string | null;
    position: number;
    title: string;
    url: string;
  }> | null;
};

export type UpsertArticleQuestionsVariables = Exact<{
  input: UpsertArticleQuestionsInput;
}>;

export type UpsertArticleQuestions = {
  __typename?: 'Mutation';
  upsertArticleQuestions: Array<{
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  }>;
};

export type UpsertArticleQuestionVariables = Exact<{
  input: ArticleQuestionInput;
}>;

export type UpsertArticleQuestion = {
  __typename?: 'Mutation';
  upsertArticleQuestion: {
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  };
};

export type UpdateArticleQuestionsVariables = Exact<{
  input: UpdateArticleQuestionsInput;
}>;

export type UpdateArticleQuestions = {
  __typename?: 'Mutation';
  updateArticleQuestions: Array<{
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  }>;
};

export type DeleteArticleQuestionVariables = Exact<{
  input: DeleteArticleQuestionInput;
}>;

export type DeleteArticleQuestion = {
  __typename?: 'Mutation';
  deleteArticleQuestion?: {
    __typename?: 'ArticleQuestionDelete';
    id: string;
  } | null;
};

export type UpsertArticleKeywordsVariables = Exact<{
  input: UpsertArticleKeywordsInput;
}>;

export type UpsertArticleKeywords = {
  __typename?: 'Mutation';
  upsertArticleKeywords: Array<{
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    relevance?: number | null;
    hidden?: boolean | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  }>;
};

export type UpsertArticleKeywordVariables = Exact<{
  input: ArticleKeywordInput;
}>;

export type UpsertArticleKeyword = {
  __typename?: 'Mutation';
  upsertArticleKeyword: {
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    relevance?: number | null;
    hidden?: boolean | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  };
};

export type UpdateArticleKeywordsVariables = Exact<{
  input: UpdateArticleKeywordsInput;
}>;

export type UpdateArticleKeywords = {
  __typename?: 'Mutation';
  updateArticleKeywords: Array<{
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    relevance?: number | null;
    hidden?: boolean | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  }>;
};

export type DeleteArticleKeywordVariables = Exact<{
  input: DeleteArticleKeywordInput;
}>;

export type DeleteArticleKeyword = {
  __typename?: 'Mutation';
  deleteArticleKeyword?: {
    __typename?: 'ArticleKeywordDelete';
    id: string;
  } | null;
};

export type DeleteArticleHeadlineVariables = Exact<{
  input: DeleteArticleHeadlineInput;
}>;

export type DeleteArticleHeadline = {
  __typename?: 'Mutation';
  deleteArticleHeadline?: {
    __typename?: 'ArticleHeadlineDelete';
    id: string;
  } | null;
};

export type DeleteArticleHeadlinesVariables = Exact<{
  input: DeleteArticleHeadlinesInput;
}>;

export type DeleteArticleHeadlines = {
  __typename?: 'Mutation';
  deleteArticleHeadlines: Array<{
    __typename?: 'ArticleHeadlineDelete';
    id: string;
  }>;
};

export type UpsertArticleHeadlineVariables = Exact<{
  input: UpsertArticleHeadlineInput;
}>;

export type UpsertArticleHeadline = {
  __typename?: 'Mutation';
  upsertArticleHeadline: {
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  };
};

export type UpsertArticleHeadlinesVariables = Exact<{
  input: UpsertArticleHeadlinesInput;
}>;

export type UpsertArticleHeadlines = {
  __typename?: 'Mutation';
  upsertArticleHeadlines: Array<{
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  }>;
};

export type CreateArticleHeadlineRefsVariables = Exact<{
  input: CreateArticleHeadlineRefsInput;
}>;

export type CreateArticleHeadlineRefs = {
  __typename?: 'Mutation';
  createArticleHeadlineRefs: {
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  };
};

export type DeleteArticleHeadlineRefVariables = Exact<{
  input: DeleteArticleHeadlineRefInput;
}>;

export type DeleteArticleHeadlineRef = {
  __typename?: 'Mutation';
  deleteArticleHeadlineRef: {
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  };
};

export type UpsertArticleHeadlinesMutationVariables = Exact<{
  input: UpsertArticleHeadlinesInput;
}>;

export type UpsertArticleHeadlinesMutation = {
  __typename?: 'Mutation';
  upsertArticleHeadlines: Array<{
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  }>;
};

export type UpdateArticleHeadlinesMutationVariables = Exact<{
  input: UpdateArticleHeadlinesInput;
}>;

export type UpdateArticleHeadlinesMutation = {
  __typename?: 'Mutation';
  updateArticleHeadlines: Array<{
    __typename?: 'ArticleHeadline';
    description: string;
    index: number;
    level: number;
    title: string;
    type: ArticleBriefType;
    articleId: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    questions: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }>;
    keywords: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }>;
  }>;
};

export type GenerateOutlineByGptVariables = Exact<{
  input: OutlineByGptInput;
}>;

export type GenerateOutlineByGpt = {
  __typename?: 'Mutation';
  generateOutlineByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type MatchKeywordsAndQuestionsByGptVariables = Exact<{
  input: MatchKeywordsAndQuestionsByGptInput;
}>;

export type MatchKeywordsAndQuestionsByGpt = {
  __typename?: 'Mutation';
  matchKeywordsAndQuestionsByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GetGoogleTopRankingsVariables = Exact<{
  input: GetGoogleTopRankingsInput;
}>;

export type GetGoogleTopRankings = {
  __typename?: 'Query';
  getGoogleTopRankings?: {
    __typename?: 'GoogleTopRanking';
    locale?: Locale | null;
    averageWordCount?: number | null;
    ttl?: number | null;
    searchTerm: string;
    searchResults?: Array<{
      __typename?: 'GoogleSearchResult';
      summary?: string | null;
      position: number;
      title: string;
      url: string;
      outline?: Array<{
        __typename: 'GoogleSearchResultHeadline';
        level: number;
        title: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type ListArticleHeadlinesVariables = Exact<{
  articleId: Scalars['String']['input'];
}>;

export type ListArticleHeadlines = {
  __typename?: 'Query';
  listArticleHeadlines: {
    __typename?: 'ListArticleHeadlinesType';
    items: Array<{
      __typename?: 'ArticleHeadline';
      description: string;
      index: number;
      level: number;
      title: string;
      type: ArticleBriefType;
      articleId: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      questions: Array<{
        __typename?: 'ArticleQuestion';
        articleId: string;
        content: string;
        createdAt: string;
        createdBy?: string | null;
        id: string;
        type: ArticleBriefType;
        updateToken?: string | null;
        updatedAt: string;
        updatedBy?: string | null;
        answered?: boolean | null;
        hidden?: boolean | null;
      }>;
      keywords: Array<{
        __typename?: 'ArticleKeyword';
        articleId: string;
        content: string;
        required: boolean;
        createdAt: string;
        createdBy?: string | null;
        id: string;
        type: ArticleBriefType;
        updateToken?: string | null;
        updatedAt: string;
        updatedBy?: string | null;
        relevance?: number | null;
        hidden?: boolean | null;
        volumes?: Array<{
          __typename?: 'Volume';
          amount: number;
          month: number;
          year: number;
        }> | null;
        keywordVariations?: {
          __typename?: 'ArticleKeywordVariations';
          singularAndPlural: Array<string>;
          verbsAndNouns: Array<string>;
          verbsAndNounsSingularAndPlural: Array<string>;
          closelyRelatedCompoundNouns: Array<string>;
          separationsByHyphens: Array<string>;
          abbreviationsOrAcronyms: Array<string>;
          synonyms: Array<string>;
          synonymsSingularAndPlural: Array<string>;
          superlatives: Array<string>;
          rootForms: Array<string>;
          variationsUsingConnectingPrepositionOptions: Array<string>;
        } | null;
      }>;
    }>;
  };
};

export type GenerateHeadlineInOutlineByGptVariables = Exact<{
  input: GenerateHeadlineByGptInput;
}>;

export type GenerateHeadlineInOutlineByGpt = {
  __typename?: 'Query';
  generateHeadlineInOutlineByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GenerateHeadlineSuggestionsInOutlineByGptVariables = Exact<{
  input: GenerateHeadlineSuggestionsByGptInput;
}>;

export type GenerateHeadlineSuggestionsInOutlineByGpt = {
  __typename?: 'Query';
  generateHeadlineSuggestionsInOutlineByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type ListArticleKeywordsVariables = Exact<{
  articleId: Scalars['String']['input'];
  onlyMatched?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListArticleKeywords = {
  __typename?: 'Query';
  listArticleKeywords: Array<{
    __typename?: 'ArticleKeyword';
    articleId: string;
    content: string;
    required: boolean;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    hidden?: boolean | null;
    keywordVariations?: {
      __typename?: 'ArticleKeywordVariations';
      singularAndPlural: Array<string>;
      verbsAndNouns: Array<string>;
      verbsAndNounsSingularAndPlural: Array<string>;
      closelyRelatedCompoundNouns: Array<string>;
      separationsByHyphens: Array<string>;
      abbreviationsOrAcronyms: Array<string>;
      synonyms: Array<string>;
      synonymsSingularAndPlural: Array<string>;
      superlatives: Array<string>;
      rootForms: Array<string>;
      variationsUsingConnectingPrepositionOptions: Array<string>;
    } | null;
  }>;
};

export type ListArticleQuestionsVariables = Exact<{
  articleId: Scalars['String']['input'];
}>;

export type ListArticleQuestions = {
  __typename?: 'Query';
  listArticleQuestions: Array<{
    __typename?: 'ArticleQuestion';
    articleId: string;
    content: string;
    createdAt: string;
    createdBy?: string | null;
    id: string;
    type: ArticleBriefType;
    updateToken?: string | null;
    updatedAt: string;
    updatedBy?: string | null;
    answered?: boolean | null;
    hidden?: boolean | null;
  }>;
};

export type GetProjectBriefDocumentUsageCountVariables = Exact<{
  input: GetProjectBriefDocumentUsageCountInput;
}>;

export type GetProjectBriefDocumentUsageCount = {
  __typename?: 'Query';
  getProjectBriefDocumentUsageCount: number;
};

export type OnArticleBriefCreateVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleBriefCreate = {
  __typename?: 'Subscription';
  onArticleBriefCreate?: {
    __typename?: 'ArticleBriefSubscription';
    answered?: boolean | null;
    articleId?: string | null;
    content?: string | null;
    createdAt?: string | null;
    createdBy?: string | null;
    description?: string | null;
    hidden?: boolean | null;
    id: string;
    index?: number | null;
    level?: number | null;
    organizationId?: string | null;
    pk1?: string | null;
    relevance?: number | null;
    required?: boolean | null;
    sk1?: string | null;
    title?: string | null;
    type?: ArticleBriefType | null;
    updateToken?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    userId?: string | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywords?: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }> | null;
    questions?: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }> | null;
  } | null;
};

export type OnArticleBriefUpdateVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleBriefUpdate = {
  __typename?: 'Subscription';
  onArticleBriefUpdate?: {
    __typename?: 'ArticleBriefSubscription';
    answered?: boolean | null;
    articleId?: string | null;
    content?: string | null;
    createdAt?: string | null;
    createdBy?: string | null;
    description?: string | null;
    hidden?: boolean | null;
    id: string;
    index?: number | null;
    level?: number | null;
    organizationId?: string | null;
    pk1?: string | null;
    relevance?: number | null;
    required?: boolean | null;
    sk1?: string | null;
    title?: string | null;
    type?: ArticleBriefType | null;
    updateToken?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    userId?: string | null;
    volumes?: Array<{
      __typename?: 'Volume';
      amount: number;
      month: number;
      year: number;
    }> | null;
    keywords?: Array<{
      __typename?: 'ArticleKeyword';
      articleId: string;
      content: string;
      required: boolean;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      relevance?: number | null;
      hidden?: boolean | null;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      }> | null;
      keywordVariations?: {
        __typename?: 'ArticleKeywordVariations';
        singularAndPlural: Array<string>;
        verbsAndNouns: Array<string>;
        verbsAndNounsSingularAndPlural: Array<string>;
        closelyRelatedCompoundNouns: Array<string>;
        separationsByHyphens: Array<string>;
        abbreviationsOrAcronyms: Array<string>;
        synonyms: Array<string>;
        synonymsSingularAndPlural: Array<string>;
        superlatives: Array<string>;
        rootForms: Array<string>;
        variationsUsingConnectingPrepositionOptions: Array<string>;
      } | null;
    }> | null;
    questions?: Array<{
      __typename?: 'ArticleQuestion';
      articleId: string;
      content: string;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      type: ArticleBriefType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
      answered?: boolean | null;
      hidden?: boolean | null;
    }> | null;
  } | null;
};

export type OnArticleBriefDeleteVariables = Exact<{
  userId: Scalars['String']['input'];
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnArticleBriefDelete = {
  __typename?: 'Subscription';
  onArticleBriefDelete?: {
    __typename?: 'ArticleBriefSubscriptionDelete';
    type?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    userId?: string | null;
  } | null;
};

export type ListBrokenLinksInArticleVariables = Exact<{
  input: ListBrokenLinksInArticleInput;
}>;

export type ListBrokenLinksInArticle = {
  __typename?: 'Query';
  listBrokenLinksInArticle?: {
    __typename?: 'BrokenLinksInArticle';
    items?: Array<string> | null;
  } | null;
};

export type ListBrokenLinksInProjectVariables = Exact<{
  input: ListBrokenLinksInProjectInput;
}>;

export type ListBrokenLinksInProject = {
  __typename?: 'Query';
  listBrokenLinksInProject: {
    __typename?: 'BrokenLinksInProjectOutput';
    items: Array<{
      __typename: 'BrokenLink';
      id: string;
      articleTitle: string;
      articleId: string;
      url: string;
      updatedAt?: string | null;
    }>;
  };
};

export type ListBrokenArticleUrlsVariables = Exact<{
  input: ListBrokenArticleUrlsInput;
}>;

export type ListBrokenArticleUrls = {
  __typename?: 'Query';
  listBrokenArticleUrls: {
    __typename?: 'BrokenArticleUrlsOutput';
    items: Array<{
      __typename: 'BrokenArticleUrl';
      id: string;
      articleTitle: string;
      articleId: string;
      url: string;
    }>;
  };
};

export type ArticleCommentFull = {
  __typename?: 'ArticleComment';
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  content: string;
  resolved?: boolean | null;
  commentId?: string | null;
  articleId: string;
  commentCreator?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
  } | null;
};

export type CreateArticleCommentVariables = Exact<{
  input: CreateArticleCommentInput;
}>;

export type CreateArticleComment = {
  __typename?: 'Mutation';
  createArticleComment: {
    __typename?: 'ArticleComment';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    content: string;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId: string;
    commentCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
  };
};

export type UpdateArticleCommentVariables = Exact<{
  input: UpdateArticleCommentInput;
}>;

export type UpdateArticleComment = {
  __typename?: 'Mutation';
  updateArticleComment: {
    __typename?: 'ArticleComment';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    content: string;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId: string;
    commentCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
  };
};

export type DeleteArticleCommentVariables = Exact<{
  input: DeleteArticleCommentInput;
}>;

export type DeleteArticleComment = {
  __typename?: 'Mutation';
  deleteArticleComment: { __typename?: 'ArticleCommentDelete'; id: string };
};

export type GetArticleCommentVariables = Exact<{
  input: GetArticleCommentInput;
}>;

export type GetArticleComment = {
  __typename?: 'Query';
  getArticleComment: {
    __typename?: 'ArticleComment';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    content: string;
    resolved?: boolean | null;
    commentId?: string | null;
    articleId: string;
    commentCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
  };
};

export type ListArticleCommentsVariables = Exact<{
  input: ListArticleCommentInput;
}>;

export type ListArticleComments = {
  __typename?: 'Query';
  listArticleComments: {
    __typename?: 'ListCommentsOutput';
    items: Array<{
      __typename?: 'ArticleComment';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      content: string;
      resolved?: boolean | null;
      commentId?: string | null;
      articleId: string;
      commentCreator?: {
        __typename?: 'User';
        id: string;
        updatedBy?: string | null;
        updatedAt?: string | null;
        updateToken?: string | null;
        pk1?: string | null;
        nickname?: string | null;
        locale?: Locale | null;
        lastName?: string | null;
        firstName?: string | null;
        createdAt?: string | null;
        color?: Maybe<keyof IBaseColors>;
        avatar?: string | null;
      } | null;
    }>;
  };
};

export type TriggerRScoreVariables = Exact<{
  articleId: Scalars['ID']['input'];
}>;

export type TriggerRScore = {
  __typename?: 'Mutation';
  triggerRScore?: boolean | null;
};

export type GenerateMetadataByGptVariables = Exact<{
  input: GenerateMetadataByGptInput;
}>;

export type GenerateMetadataByGpt = {
  __typename?: 'Mutation';
  generateMetadataByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GenerateArticleConclusionByGptVariables = Exact<{
  input: ConclusionByGptInput;
}>;

export type GenerateArticleConclusionByGpt = {
  __typename?: 'Query';
  generateConclusionByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type ContinueTextByGptVariables = Exact<{
  input: ContinueTextByGptInput;
}>;

export type ContinueTextByGpt = {
  __typename?: 'Query';
  continueTextByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type AnswerQuestionByGptVariables = Exact<{
  input: AnswerQuestionByGptInput;
}>;

export type AnswerQuestionByGpt = {
  __typename?: 'Query';
  answerQuestionByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GenerateHeadlineInWriteByGptVariables = Exact<{
  input: GenerateHeadlineByGptInput;
}>;

export type GenerateHeadlineInWriteByGpt = {
  __typename?: 'Query';
  generateHeadlineInWriteByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GenerateImageByDallEVariables = Exact<{
  input: GenerateImageByDallEInput;
}>;

export type GenerateImageByDallE = {
  __typename?: 'Query';
  generateImageByDallE: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GetSimilarQuestionsVariables = Exact<{
  input: SimilarQuestionsInput;
}>;

export type GetSimilarQuestions = {
  __typename?: 'Query';
  getSimilarQuestions?: {
    __typename?: 'SimilarQuestions';
    items?: Array<{
      __typename?: 'SimilarQuestionElement';
      question: string;
    } | null> | null;
  } | null;
};

export type GetSimilarPhrasesByGptWithVolumesVariables = Exact<{
  input: SimilarPhrasesByGptInput;
}>;

export type GetSimilarPhrasesByGptWithVolumes = {
  __typename?: 'Query';
  getSimilarPhrasesByGPT?: {
    __typename?: 'SimilarPhrases';
    items?: Array<{
      __typename?: 'SimilarPhraseElement';
      phrase: string;
      required?: boolean | null;
      score: number;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetSimilarPhrasesByNeuraverseForExplorePageVariables = Exact<{
  input: SimilarPhrasesByNeuraverseInput;
}>;

export type GetSimilarPhrasesByNeuraverseForExplorePage = {
  __typename?: 'Query';
  getSimilarPhrasesByNeuraverse?: {
    __typename?: 'SimilarPhrases';
    items?: Array<{
      __typename?: 'SimilarPhraseElement';
      phrase: string;
      score: number;
      volumes?: Array<{
        __typename?: 'Volume';
        amount: number;
        month: number;
        year: number;
      } | null> | null;
    } | null> | null;
    varieties?: Array<{
      __typename?: 'SimilarPhraseVariety';
      variety: number;
      phrases: Array<number | null>;
    }> | null;
  } | null;
};

export type WpExportVariables = Exact<{
  input: WordPressExportInput;
}>;

export type WpExport = { __typename?: 'Mutation'; wpExport: string };

export type BulkExportVariables = Exact<{
  input: BulkExportInput;
}>;

export type BulkExport = { __typename?: 'Mutation'; bulkExport: string };

export type BulkWordPressExportVariables = Exact<{
  input: BulkWordPressExportInput;
}>;

export type BulkWordPressExport = {
  __typename?: 'Mutation';
  bulkWordPressExport: string;
};

export type UpsertWordpressConnectionVariables = Exact<{
  input?: InputMaybe<UpsertWordpressConnectionInput>;
}>;

export type UpsertWordpressConnection = {
  __typename?: 'Mutation';
  upsertWordPressConnection?: {
    __typename?: 'UpsertWordpressConnectionResponse';
    errors?: Array<{
      __typename?: 'WordpressConnectionError';
      code: string;
      message?: string | null;
      tag?: string | null;
      data?: {
        __typename?: 'WordpressConnectionErrorData';
        status: number;
      } | null;
    } | null> | null;
  } | null;
};

export type GetBriefExportAsDocxVariables = Exact<{
  input: BriefExportInput;
}>;

export type GetBriefExportAsDocx = {
  __typename?: 'Query';
  getBriefExportAsDocx: string;
};

export type GetBriefExportAsPdfVariables = Exact<{
  input: BriefExportInput;
}>;

export type GetBriefExportAsPdf = {
  __typename?: 'Query';
  getBriefExportAsPdf: string;
};

export type ListWordPressCategoriesVariables = Exact<{
  input: ListWordpressCategoriesInput;
}>;

export type ListWordPressCategories = {
  __typename?: 'Query';
  listWordPressCategories?: {
    __typename?: 'ListWordpressCategoriesResponse';
    items?: Array<{
      __typename?: 'WordpressCategory';
      id: string;
      name: string;
    }> | null;
  } | null;
};

export type GetWordPressConnectionVariables = Exact<{
  input?: InputMaybe<GetWordpressConnectionInput>;
}>;

export type GetWordPressConnection = {
  __typename?: 'Query';
  getWordPressConnection?: {
    __typename?: 'GetWordpressConnectionResponse';
    username: string;
    endpoint: string;
    errors?: Array<{
      __typename?: 'WordpressConnectionError';
      code: string;
      message?: string | null;
      tag?: string | null;
      data?: {
        __typename?: 'WordpressConnectionErrorData';
        status: number;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUploadUrlVariables = Exact<{
  entity: Scalars['String']['input'];
  id: Scalars['String']['input'];
  file: Scalars['String']['input'];
}>;

export type GetUploadUrl = {
  __typename?: 'Query';
  getUploadUrl?: {
    __typename?: 'UploadUrlOutput';
    uploadURL: string;
    key: string;
  } | null;
};

export type GetHtmlExportVariables = Exact<{
  input: HtmlExportInput;
}>;

export type GetHtmlExport = {
  __typename?: 'Query';
  getHtmlExport?: { __typename?: 'HtmlExport'; key?: string | null } | null;
};

export type GetOutlineHtmlExportVariables = Exact<{
  input: OutlineHtmlExportInput;
}>;

export type GetOutlineHtmlExport = {
  __typename?: 'Query';
  getOutlineHtmlExport?: {
    __typename?: 'OutlineHtmlExport';
    key?: string | null;
    name?: string | null;
  } | null;
};

export type GetVersionVariables = Exact<{ [key: string]: never }>;

export type GetVersion = {
  __typename?: 'Query';
  getVersion?: {
    __typename?: 'PlatformVersion';
    BE: string;
    FE: string;
    ML: string;
  } | null;
};

export type GetLastProductUpdateVariables = Exact<{ [key: string]: never }>;

export type GetLastProductUpdate = {
  __typename?: 'Query';
  getLastProductUpdate?: {
    __typename?: 'LastProductUpdate';
    pubDate: string;
    excerpt: string;
    url: string;
  } | null;
};

export type CreateGscTokenVariables = Exact<{
  input: GscCreateTokenInput;
}>;

export type CreateGscToken = {
  __typename?: 'Mutation';
  createGSCToken: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
  };
};

export type ListGoogleRankedKeywordsByArticleVariables = Exact<{
  input: GscKeywordsByArticleInput;
}>;

export type ListGoogleRankedKeywordsByArticle = {
  __typename?: 'Query';
  listGSCKeywordsByArticle?: {
    __typename?: 'GSCKeywordsByArticle';
    items?: Array<{
      __typename?: 'GSCArticleKeyword';
      keyword: string;
      averageGoogleRanking?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      clicks?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      ctr?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      impressions?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
    } | null> | null;
  } | null;
};

export type ListGoogleRankedKeywordsByProjectVariables = Exact<{
  input: GscKeywordsByProjectInput;
}>;

export type ListGoogleRankedKeywordsByProject = {
  __typename?: 'Query';
  listGSCKeywordsByProject?: {
    __typename?: 'GSCKeywordsByProject';
    items?: Array<{
      __typename?: 'GSCProjectKeyword';
      articleIds?: Array<string | null> | null;
      keyword: string;
      averageGoogleRanking?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      clicks?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      ctr?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      impressions?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      searchVolume?: {
        __typename?: 'GSCKeywordSearchVolume';
        total: number;
        trend: Trend;
        growth?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListGscArticlesByProjectQueryVariables = Exact<{
  input: GscArticlesByProjectInput;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListGscArticlesByProjectQuery = {
  __typename?: 'Query';
  listGSCArticlesByProject?: {
    __typename?: 'GSCArticlesByProject';
    nextToken?: string | null;
    items?: Array<{
      __typename?: 'GSCArticleByProject';
      title?: string | null;
      rscore?: number | null;
      publicationDate?: string | null;
      averageGoogleRankingChange?: number | null;
      keywords?: number | null;
      articleId?: string | null;
      id: string;
      clicks?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      ctr?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      averageGoogleRanking?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      previousAverageGoogleRanking?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      impressions?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
    } | null> | null;
  } | null;
};

export type GetGscConsentScreenUriVariables = Exact<{
  input: GscConsentScreenUriInput;
}>;

export type GetGscConsentScreenUri = {
  __typename?: 'Query';
  getGSCConsentScreenUri?: {
    __typename?: 'GSCConsentScreenUri';
    signInUri: string;
  } | null;
};

export type ListGscDomainsVariables = Exact<{
  input: GscDomainsInput;
}>;

export type ListGscDomains = {
  __typename?: 'Query';
  listGSCDomains?: {
    __typename?: 'GSCDomains';
    items?: Array<{
      __typename?: 'GSCDomain';
      siteUrl: string;
      permissionLevel: string;
    }> | null;
  } | null;
};

export type GetGscArticleAnalyticsQueryVariables = Exact<{
  input: GscArticleAnalyticsInput;
}>;

export type GetGscArticleAnalyticsQuery = {
  __typename?: 'Query';
  getGSCArticleAnalytics?: {
    __typename?: 'GSCAnalytics';
    impressions?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    clicks?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    ctr?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    averageGoogleRanking?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    searchVolume?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    updatesGoogle?: {
      __typename?: 'GSCGoogleUpdates';
      data?: Array<{
        __typename?: 'GSCGoogleUpdateDataPoint';
        id: string;
        date: string;
        title: string;
        url: string;
        description: string;
      }> | null;
    } | null;
    publishedArticles?: {
      __typename?: 'GSCPublishedArticles';
      data?: Array<{
        __typename?: 'GSCPublishedArticleDataPoint';
        date: string;
        id: string;
        title?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type GetGscProjectAnalyticsQueryVariables = Exact<{
  input: GscProjectAnalyticsInput;
}>;

export type GetGscProjectAnalyticsQuery = {
  __typename?: 'Query';
  getGSCProjectAnalytics?: {
    __typename?: 'GSCAnalytics';
    impressions?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    clicks?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    ctr?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    averageGoogleRanking?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    searchVolume?: {
      __typename?: 'GSCData';
      total: number;
      trend: Trend;
      resolution?: GscResolution | null;
      data: Array<{
        __typename?: 'GSCDataPoint';
        date: string;
        title?: string | null;
        url?: string | null;
        value?: number | null;
      }>;
    } | null;
    updatesGoogle?: {
      __typename?: 'GSCGoogleUpdates';
      data?: Array<{
        __typename?: 'GSCGoogleUpdateDataPoint';
        id: string;
        date: string;
        title: string;
        url: string;
        description: string;
      }> | null;
    } | null;
    publishedArticles?: {
      __typename?: 'GSCPublishedArticles';
      data?: Array<{
        __typename?: 'GSCPublishedArticleDataPoint';
        date: string;
        id: string;
        title?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type ListGscArticlesByKeywordQueryVariables = Exact<{
  input: GscArticlesByKeywordInput;
}>;

export type ListGscArticlesByKeywordQuery = {
  __typename?: 'Query';
  listGSCArticlesByKeyword?: {
    __typename?: 'GSCArticlesByKeyword';
    items?: Array<{
      __typename?: 'GSCArticleByKeyword';
      title?: string | null;
      articleId?: string | null;
      searchVolume?: {
        __typename?: 'GSCKeywordSearchVolume';
        total: number;
        trend: Trend;
        growth?: number | null;
      } | null;
      impressions?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
      ctr?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      clicks?: { __typename?: 'GSCData'; total: number; trend: Trend } | null;
      averageGoogleRanking?: {
        __typename?: 'GSCData';
        total: number;
        trend: Trend;
      } | null;
    } | null> | null;
  } | null;
};

export type InvitationFull = {
  __typename?: 'Invitation';
  id: string;
  organization_id: string;
  invitation_url?: string | null;
  created_at?: string | null;
  expires_at?: string | null;
  client_id: string;
  connection_id: string;
  app_metadata?: string | null;
  user_metadata?: string | null;
  roles?: Array<string | null> | null;
  ticket_id: string;
  invitee?: { __typename?: 'InvitationInvitee'; email: string } | null;
};

export type CreateInvitationsVariables = Exact<{
  input: CreateInvitationsInput;
}>;

export type CreateInvitations = {
  __typename?: 'Mutation';
  createInvitations?: {
    __typename?: 'Invitations';
    items?: Array<{
      __typename?: 'Invitation';
      id: string;
      organization_id: string;
      invitation_url?: string | null;
      created_at?: string | null;
      expires_at?: string | null;
      client_id: string;
      connection_id: string;
      app_metadata?: string | null;
      user_metadata?: string | null;
      roles?: Array<string | null> | null;
      ticket_id: string;
      invitee?: { __typename?: 'InvitationInvitee'; email: string } | null;
    }> | null;
  } | null;
};

export type DeleteInvitationVariables = Exact<{
  input: DeleteInvitationInput;
}>;

export type DeleteInvitation = {
  __typename?: 'Mutation';
  deleteInvitation?: { __typename?: 'Invitation'; id: string } | null;
};

export type ListInvitationsForMembersPageVariables = Exact<{
  [key: string]: never;
}>;

export type ListInvitationsForMembersPage = {
  __typename?: 'Query';
  listInvitations?: {
    __typename?: 'Invitations';
    items?: Array<{
      __typename?: 'Invitation';
      id: string;
      user_metadata?: string | null;
      expires_at?: string | null;
      created_at?: string | null;
      invitee?: { __typename?: 'InvitationInvitee'; email: string } | null;
    }> | null;
  } | null;
};

export type NotificationFull = {
  __typename?: 'Notification';
  createdAt?: string | null;
  data?: string | null;
  entityId?: string | null;
  id: string;
  initiatedBy?: string | null;
  notificationId?: string | null;
  notificationStatus?: NotificationStatus | null;
  notificationType?: NotificationType | null;
  notificationAction?: NotificationAction | null;
  pk1?: string | null;
  read?: boolean | null;
  sk1?: string | null;
  ttl?: number | null;
  updatedAt?: string | null;
};

export type NotificationSubscriptionFull = {
  __typename?: 'NotificationSubscription';
  createdAt?: string | null;
  entityId?: string | null;
  data?: string | null;
  id: string;
  initiatedBy?: string | null;
  notificationAction?: NotificationAction | null;
  notificationId?: string | null;
  notificationStatus?: NotificationStatus | null;
  notificationType?: NotificationType | null;
  pk1?: string | null;
  read?: boolean | null;
  sk1?: string | null;
  ttl?: number | null;
  updatedAt?: string | null;
  userId?: string | null;
};

export type ReadNotificationsVariables = Exact<{
  input: ReadNotificationsInput;
}>;

export type ReadNotifications = {
  __typename?: 'Mutation';
  readNotifications: Array<{
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  }>;
};

export type DeleteNotificationVariables = Exact<{
  input: DeleteNotificationInput;
}>;

export type DeleteNotification = {
  __typename?: 'Mutation';
  deleteNotification: { __typename?: 'NotificationDelete'; id: string };
};

export type ListAllNotificationsVariables = Exact<{
  filter?: InputMaybe<ModelNotificationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sk1?: InputMaybe<ModelStringKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
}>;

export type ListAllNotifications = {
  __typename?: 'Query';
  listNotifications?: {
    __typename?: 'ListNotifications';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'Notification';
      createdAt?: string | null;
      data?: string | null;
      entityId?: string | null;
      id: string;
      initiatedBy?: string | null;
      notificationId?: string | null;
      notificationStatus?: NotificationStatus | null;
      notificationType?: NotificationType | null;
      notificationAction?: NotificationAction | null;
      pk1?: string | null;
      read?: boolean | null;
      sk1?: string | null;
      ttl?: number | null;
      updatedAt?: string | null;
    }>;
  } | null;
};

export type GetNotificationVariables = Exact<{
  input: GetNotificationInput;
}>;

export type GetNotification = {
  __typename?: 'Query';
  getNotification?: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  } | null;
};

export type OnNotificationCreateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnNotificationCreate = {
  __typename?: 'Subscription';
  onNotificationCreate?: {
    __typename?: 'NotificationSubscription';
    createdAt?: string | null;
    entityId?: string | null;
    data?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationAction?: NotificationAction | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
    userId?: string | null;
  } | null;
};

export type OnNotificationUpdateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnNotificationUpdate = {
  __typename?: 'Subscription';
  onNotificationUpdate?: {
    __typename?: 'NotificationSubscription';
    createdAt?: string | null;
    entityId?: string | null;
    data?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationAction?: NotificationAction | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
    userId?: string | null;
  } | null;
};

export type OnNotificationDeleteVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnNotificationDelete = {
  __typename?: 'Subscription';
  onNotificationDelete?: {
    __typename?: 'NotificationSubscriptionDelete';
    pk1?: string | null;
    sk1?: string | null;
    userId?: string | null;
  } | null;
};

export type NVeCompanyBase = {
  __typename?: 'NVeCompany';
  id: string;
  name: string;
  priority?: boolean | null;
};

export type NVeCompanyDetails = {
  __typename?: 'NVeCompany';
  articles: number;
  articlesInTop20: number;
  avgRanking: number;
  traffic: number;
  trafficShare: number;
  searchTerms: number;
};

export type NVeCompanyFull = {
  __typename?: 'NVeCompany';
  id: string;
  name: string;
  priority?: boolean | null;
  articles: number;
  articlesInTop20: number;
  avgRanking: number;
  traffic: number;
  trafficShare: number;
  searchTerms: number;
};

export type NVeTopicArticleFull = {
  __typename?: 'NVeTopicArticle';
  id: string;
  priority: boolean;
  rScore?: number | null;
  type: NVeTopicArticleType;
  direction?: string | null;
  fullName: string;
  articleMetadata?: {
    __typename?: 'ArticleMetadata';
    pk1: string;
    sk1: string;
    sk2?: string | null;
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    name?: string | null;
    versionId: number;
    projectId: string;
    assigneeId: string;
    wordCountPerArticle?: string | null;
    readingResources?: Array<string> | null;
    hasTopRankings?: boolean | null;
    competitorsUrls?: Array<string> | null;
    avoid?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing: DirectReaderAddressing;
    audienceExpertiseId?: string | null;
    audiencePersonaId?: string | null;
    toneAndVoiceId?: string | null;
    companyKnowledgeIds?: Array<string> | null;
    state: ArticleState;
    completedDate?: string | null;
    publicationDate?: string | null;
    dueDate?: string | null;
    focusKeyword?: string | null;
    urlPath?: string | null;
    title?: string | null;
    description?: string | null;
    versionName?: string | null;
    versionDate?: string | null;
    versionCreatorId?: string | null;
    assignee?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
    imageGuidelines: {
      __typename?: 'ArticleImageGuidelines';
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    selectedProjectBriefDocuments?: {
      __typename?: 'SelectedProjectBriefDocuments';
      audienceExpertise?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      audiencePersona?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
      companyKnowledges?: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }> | null;
      toneAndVoice?: {
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      } | null;
    } | null;
    articleRScore?: {
      __typename?: 'ArticleRScore';
      createdAt: string;
      createdBy?: string | null;
      doneAdvices?: Array<string> | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      rscore?: string | null;
      sk1: string;
      sk2?: string | null;
      updateToken?: string | null;
      type: ArticleType;
      updatedAt: string;
      updatedBy?: string | null;
      versionId: number;
    } | null;
    articleDocument?: {
      __typename?: 'ArticleDocument';
      content?: string | null;
      createdAt: string;
      createdBy?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    exploreInputs?: {
      __typename?: 'Explore';
      searchTerms?: Array<string> | null;
      contentCategory?: string | null;
      subcategory?: string | null;
      timeRange?: number | null;
      currentTab?: TabKey | null;
      variety?: number | null;
      searchedOnce?: boolean | null;
      engineLanguage: EngineLanguage;
    } | null;
    neuraverseTopicData?: {
      __typename?: 'ArticleNeuraverseTopicData';
      topicId: string;
      topicNames: Array<string>;
      articleDirection?: string | null;
      articleLengthSuggestion?: number | null;
    } | null;
    articleGuidelines?: {
      __typename?: 'ArticleGuidelines';
      createdAt: string;
      createdBy?: string | null;
      guidelines?: string | null;
      id: string;
      pk1: string;
      requestOrigin?: string | null;
      sk1: string;
      sk2?: string | null;
      type: ArticleType;
      updateToken?: string | null;
      updatedAt: string;
      updatedBy?: string | null;
    } | null;
    project?: {
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
    } | null;
    versionCreator?: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    } | null;
    articleRootData?: {
      __typename?: 'ArticleRootData';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      priority?: boolean | null;
      labelIds?: Array<string> | null;
      urlStatus?: ArticleUrlStatus | null;
    } | null;
    selectedProjectLabels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  } | null;
  webArticle?: {
    __typename?: 'NVeTopicWebArticle';
    ranking?: number | null;
    title: string;
    traffic?: number | null;
    url: string;
    preScore?: number | null;
  } | null;
};

export type NVeTopicBase = {
  __typename?: 'NVeTopic';
  id: string;
  fullName: string;
  updateToken?: string | null;
};

export type NVeTopicDetails = {
  __typename?: 'NVeTopic';
  priority?: boolean | null;
  isSaved?: boolean | null;
  childTopicCount: number;
  relevance: number;
  searchVolume: number;
  difficulty: number;
  searchTermsCount: number;
  companyData?: Array<{
    __typename?: 'NVeTopicCompanyData';
    marketShare: number;
    averageRanking: number;
    articlesCount: number;
    top20ArticlesCount: number;
    traffic: number;
    searchTermsCount: number;
    name: string;
  }> | null;
};

export type NVeTopicFull = {
  __typename?: 'NVeTopic';
  id: string;
  fullName: string;
  updateToken?: string | null;
  priority?: boolean | null;
  isSaved?: boolean | null;
  childTopicCount: number;
  relevance: number;
  searchVolume: number;
  difficulty: number;
  searchTermsCount: number;
  companyData?: Array<{
    __typename?: 'NVeTopicCompanyData';
    marketShare: number;
    averageRanking: number;
    articlesCount: number;
    top20ArticlesCount: number;
    traffic: number;
    searchTermsCount: number;
    name: string;
  }> | null;
};

export type UpdateNVeCompanyPriorityVariables = Exact<{
  input: UpdateNVePriorityInput;
}>;

export type UpdateNVeCompanyPriority = {
  __typename?: 'Mutation';
  updateNVeCompanyPriority: {
    __typename?: 'NVeCompany';
    id: string;
    priority?: boolean | null;
    updateToken?: string | null;
  };
};

export type UpdateNVeTopicArticlePriorityVariables = Exact<{
  input: UpdateNVeTopicArticlePriorityInput;
}>;

export type UpdateNVeTopicArticlePriority = {
  __typename?: 'Mutation';
  updateNVeTopicArticlePriority: {
    __typename?: 'NVeTopicArticle';
    id: string;
    priority: boolean;
    updateToken?: string | null;
  };
};

export type UpdateNVeTopicPriorityVariables = Exact<{
  input: UpdateNVePriorityInput;
}>;

export type UpdateNVeTopicPriority = {
  __typename?: 'Mutation';
  updateNVeTopicPriority: {
    __typename?: 'NVeTopic';
    id: string;
    priority?: boolean | null;
    updateToken?: string | null;
  };
};

export type UpdateNVeTopicSavedVariables = Exact<{
  input: UpdateNVeTopicSavedInput;
}>;

export type UpdateNVeTopicSaved = {
  __typename?: 'Mutation';
  updateNVeTopicSaved: {
    __typename?: 'NVeTopic';
    id: string;
    isSaved?: boolean | null;
    updateToken?: string | null;
  };
};

export type ImportArticleVariables = Exact<{
  input: ImportArticleInput;
}>;

export type ImportArticle = {
  __typename?: 'Mutation';
  importArticle: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type ListNVeTopicArticlesVariables = Exact<{
  input: ListNVeTopicArticlesInput;
}>;

export type ListNVeTopicArticles = {
  __typename?: 'Query';
  listNVeTopicArticles: {
    __typename?: 'NVeTopicArticleList';
    items: Array<{
      __typename?: 'NVeTopicArticle';
      id: string;
      priority: boolean;
      rScore?: number | null;
      type: NVeTopicArticleType;
      direction?: string | null;
      fullName: string;
      articleMetadata?: {
        __typename?: 'ArticleMetadata';
        pk1: string;
        sk1: string;
        sk2?: string | null;
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        requestOrigin?: string | null;
        type: ArticleType;
        name?: string | null;
        versionId: number;
        projectId: string;
        assigneeId: string;
        wordCountPerArticle?: string | null;
        readingResources?: Array<string> | null;
        hasTopRankings?: boolean | null;
        competitorsUrls?: Array<string> | null;
        avoid?: string | null;
        firstPersonPerspective?: FirstPersonPerspective | null;
        directReaderAddressing: DirectReaderAddressing;
        audienceExpertiseId?: string | null;
        audiencePersonaId?: string | null;
        toneAndVoiceId?: string | null;
        companyKnowledgeIds?: Array<string> | null;
        state: ArticleState;
        completedDate?: string | null;
        publicationDate?: string | null;
        dueDate?: string | null;
        focusKeyword?: string | null;
        urlPath?: string | null;
        title?: string | null;
        description?: string | null;
        versionName?: string | null;
        versionDate?: string | null;
        versionCreatorId?: string | null;
        assignee?: {
          __typename?: 'User';
          id: string;
          updatedBy?: string | null;
          updatedAt?: string | null;
          updateToken?: string | null;
          pk1?: string | null;
          nickname?: string | null;
          locale?: Locale | null;
          lastName?: string | null;
          firstName?: string | null;
          createdAt?: string | null;
          color?: Maybe<keyof IBaseColors>;
          avatar?: string | null;
        } | null;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
        imageGuidelines: {
          __typename?: 'ArticleImageGuidelines';
          allowGenAI: boolean;
          moodAndTone: ImageMoodAndTone;
          orientation: ImageOrientation;
          style: ImageStyle;
          imageType: ImageType;
          customFields?: Array<{
            __typename?: 'CustomField';
            key?: string | null;
            value?: string | null;
          }> | null;
        };
        selectedProjectBriefDocuments?: {
          __typename?: 'SelectedProjectBriefDocuments';
          audienceExpertise?: {
            __typename?: 'ProjectBriefDocument';
            id: string;
            updateToken?: string | null;
            updatedBy?: string | null;
            createdBy?: string | null;
            createdAt: string;
            updatedAt: string;
            type: ProjectType;
            name: string;
            content: string;
            projectId: string;
            selected?: boolean | null;
          } | null;
          audiencePersona?: {
            __typename?: 'ProjectBriefDocument';
            id: string;
            updateToken?: string | null;
            updatedBy?: string | null;
            createdBy?: string | null;
            createdAt: string;
            updatedAt: string;
            type: ProjectType;
            name: string;
            content: string;
            projectId: string;
            selected?: boolean | null;
          } | null;
          companyKnowledges?: Array<{
            __typename?: 'ProjectBriefDocument';
            id: string;
            updateToken?: string | null;
            updatedBy?: string | null;
            createdBy?: string | null;
            createdAt: string;
            updatedAt: string;
            type: ProjectType;
            name: string;
            content: string;
            projectId: string;
            selected?: boolean | null;
          }> | null;
          toneAndVoice?: {
            __typename?: 'ProjectBriefDocument';
            id: string;
            updateToken?: string | null;
            updatedBy?: string | null;
            createdBy?: string | null;
            createdAt: string;
            updatedAt: string;
            type: ProjectType;
            name: string;
            content: string;
            projectId: string;
            selected?: boolean | null;
          } | null;
        } | null;
        articleRScore?: {
          __typename?: 'ArticleRScore';
          createdAt: string;
          createdBy?: string | null;
          doneAdvices?: Array<string> | null;
          id: string;
          pk1: string;
          requestOrigin?: string | null;
          rscore?: string | null;
          sk1: string;
          sk2?: string | null;
          updateToken?: string | null;
          type: ArticleType;
          updatedAt: string;
          updatedBy?: string | null;
          versionId: number;
        } | null;
        articleDocument?: {
          __typename?: 'ArticleDocument';
          content?: string | null;
          createdAt: string;
          createdBy?: string | null;
          id: string;
          pk1: string;
          requestOrigin?: string | null;
          sk1: string;
          sk2?: string | null;
          type: ArticleType;
          updateToken?: string | null;
          updatedAt: string;
          updatedBy?: string | null;
        } | null;
        exploreInputs?: {
          __typename?: 'Explore';
          searchTerms?: Array<string> | null;
          contentCategory?: string | null;
          subcategory?: string | null;
          timeRange?: number | null;
          currentTab?: TabKey | null;
          variety?: number | null;
          searchedOnce?: boolean | null;
          engineLanguage: EngineLanguage;
        } | null;
        neuraverseTopicData?: {
          __typename?: 'ArticleNeuraverseTopicData';
          topicId: string;
          topicNames: Array<string>;
          articleDirection?: string | null;
          articleLengthSuggestion?: number | null;
        } | null;
        articleGuidelines?: {
          __typename?: 'ArticleGuidelines';
          createdAt: string;
          createdBy?: string | null;
          guidelines?: string | null;
          id: string;
          pk1: string;
          requestOrigin?: string | null;
          sk1: string;
          sk2?: string | null;
          type: ArticleType;
          updateToken?: string | null;
          updatedAt: string;
          updatedBy?: string | null;
        } | null;
        project?: {
          __typename?: 'ProjectMetadata';
          createdBy?: string | null;
          updatedBy?: string | null;
          updateToken?: string | null;
          createdAt: string;
          updatedAt: string;
          pk1: string;
          id: string;
          name?: string | null;
          archived?: boolean | null;
          organizationId: string;
          avatar?: string | null;
          color?: Maybe<keyof IBaseColors>;
          domain?: string | null;
          locale: Locale;
        } | null;
        versionCreator?: {
          __typename?: 'User';
          id: string;
          updatedBy?: string | null;
          updatedAt?: string | null;
          updateToken?: string | null;
          pk1?: string | null;
          nickname?: string | null;
          locale?: Locale | null;
          lastName?: string | null;
          firstName?: string | null;
          createdAt?: string | null;
          color?: Maybe<keyof IBaseColors>;
          avatar?: string | null;
        } | null;
        articleRootData?: {
          __typename?: 'ArticleRootData';
          pk1: string;
          sk1: string;
          sk2?: string | null;
          id: string;
          updateToken?: string | null;
          updatedBy?: string | null;
          createdBy?: string | null;
          createdAt: string;
          updatedAt: string;
          requestOrigin?: string | null;
          type: ArticleType;
          priority?: boolean | null;
          labelIds?: Array<string> | null;
          urlStatus?: ArticleUrlStatus | null;
        } | null;
        selectedProjectLabels?: Array<{
          __typename?: 'ProjectLabel';
          color: string;
          id: string;
          name: string;
          custom?: boolean | null;
          projectId: string;
        }> | null;
      } | null;
      webArticle?: {
        __typename?: 'NVeTopicWebArticle';
        ranking?: number | null;
        title: string;
        traffic?: number | null;
        url: string;
        preScore?: number | null;
      } | null;
    }>;
  };
};

export type ListNVeTopicsVariables = Exact<{
  input: ListNVeTopicsInput;
}>;

export type ListNVeTopics = {
  __typename?: 'Query';
  listNVeTopics: Array<{
    __typename?: 'NVeTopic';
    isSaved?: boolean | null;
    id: string;
    fullName: string;
    updateToken?: string | null;
  }>;
};

export type ListNVeTopicsForTopicAnalysisPageVariables = Exact<{
  input: ListNVeTopicsInput;
}>;

export type ListNVeTopicsForTopicAnalysisPage = {
  __typename?: 'Query';
  listNVeTopics: Array<{
    __typename?: 'NVeTopic';
    id: string;
    fullName: string;
    updateToken?: string | null;
    priority?: boolean | null;
    isSaved?: boolean | null;
    childTopicCount: number;
    relevance: number;
    searchVolume: number;
    difficulty: number;
    searchTermsCount: number;
    companyData?: Array<{
      __typename?: 'NVeTopicCompanyData';
      marketShare: number;
      averageRanking: number;
      articlesCount: number;
      top20ArticlesCount: number;
      traffic: number;
      searchTermsCount: number;
      name: string;
    }> | null;
  }>;
};

export type ListNVeCompaniesVariables = Exact<{
  input: ListNVeCompaniesInput;
}>;

export type ListNVeCompanies = {
  __typename?: 'Query';
  listNVeCompanies: Array<{
    __typename?: 'NVeCompany';
    id: string;
    name: string;
    priority?: boolean | null;
  }>;
};

export type ListNVeCompaniesForCompetitorsPageVariables = Exact<{
  input: ListNVeCompaniesInput;
}>;

export type ListNVeCompaniesForCompetitorsPage = {
  __typename?: 'Query';
  listNVeCompanies: Array<{
    __typename?: 'NVeCompany';
    id: string;
    name: string;
    priority?: boolean | null;
    articles: number;
    articlesInTop20: number;
    avgRanking: number;
    traffic: number;
    trafficShare: number;
    searchTerms: number;
  }>;
};

export type ListNVeTopicSearchTermsVariables = Exact<{
  input: ListNVeTopicSearchTermsInput;
}>;

export type ListNVeTopicSearchTerms = {
  __typename?: 'Query';
  listNVeTopicSearchTerms: Array<{
    __typename?: 'NVeTopicSearchTerm';
    content: string;
    ranking: number;
    searchVolume: number;
  }>;
};

export type ListNVeTopicTop20ArticlesVariables = Exact<{
  input: ListNVeTopicCompanyDataInput;
}>;

export type ListNVeTopicTop20Articles = {
  __typename?: 'Query';
  listNVeTopicTop20Articles: Array<{
    __typename?: 'NVeTopicWebArticle';
    ranking?: number | null;
    title: string;
    traffic?: number | null;
    url: string;
  }>;
};

export type ListNVeWebArticlesVariables = Exact<{
  input: ListNVeTopicCompanyDataInput;
}>;

export type ListNVeWebArticles = {
  __typename?: 'Query';
  listNVeWebArticles: Array<{
    __typename?: 'NVeTopicWebArticle';
    ranking?: number | null;
    title: string;
    traffic?: number | null;
    url: string;
    preScore?: number | null;
  }>;
};

export type GetNVeMetadataVariables = Exact<{
  input: GetNVeMetadataInput;
}>;

export type GetNVeMetadata = {
  __typename?: 'Query';
  getNVeMetadata: {
    __typename?: 'NVeMetadata';
    id: string;
    updatedAt: string;
    createdAt: string;
    customer: string;
  };
};

export type GetNVeTopicVariables = Exact<{
  input: GetNVeTopicInput;
}>;

export type GetNVeTopic = {
  __typename?: 'Query';
  getNVeTopic: {
    __typename?: 'NVeTopic';
    topKeywords: Array<string>;
    id: string;
    fullName: string;
    updateToken?: string | null;
    priority?: boolean | null;
    isSaved?: boolean | null;
    childTopicCount: number;
    relevance: number;
    searchVolume: number;
    difficulty: number;
    searchTermsCount: number;
    companyData?: Array<{
      __typename?: 'NVeTopicCompanyData';
      marketShare: number;
      averageRanking: number;
      articlesCount: number;
      top20ArticlesCount: number;
      traffic: number;
      searchTermsCount: number;
      name: string;
    }> | null;
  };
};

export type GetNVeFilterDataVariables = Exact<{
  input: GetNVeFilterDataInput;
}>;

export type GetNVeFilterData = {
  __typename?: 'Query';
  getNVeFilterData: {
    __typename?: 'NVeFilterData';
    id: string;
    maxArticlesCount: number;
    maxAverageRanking: number;
    maxChildTopicCount: number;
    maxSearchTermsCount: number;
    maxSearchVolume: number;
    maxTop20ArticlesCount: number;
    maxTraffic: number;
    minArticlesCount: number;
    minAverageRanking: number;
    minChildTopicCount: number;
    minSearchTermsCount: number;
    minSearchVolume: number;
    minTop20ArticlesCount: number;
    minTraffic: number;
  };
};

export type OnNVePriorityUpdateVariables = Exact<{
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type OnNVePriorityUpdate = {
  __typename?: 'Subscription';
  onNVePriorityUpdate?: {
    __typename?: 'NVePriorityUpdateSubscription';
    id: string;
    priority?: boolean | null;
    type?: NVeTopicRelationType | null;
    updateToken?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnNVeTopicSavedUpdateVariables = Exact<{
  pk1: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type OnNVeTopicSavedUpdate = {
  __typename?: 'Subscription';
  onNVeTopicSavedUpdate?: {
    __typename?: 'NVeTopicSavedUpdateSubscription';
    id: string;
    isSaved?: boolean | null;
    updateToken?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OrganizationBase = {
  __typename?: 'Organization';
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  id: string;
  name?: string | null;
  auth0Id?: string | null;
  description?: string | null;
  avatar?: string | null;
};

export type OrganizationFull = {
  __typename?: 'Organization';
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  id: string;
  name?: string | null;
  auth0Id?: string | null;
  description?: string | null;
  avatar?: string | null;
};

export type OrganizationSubscriptionFull = {
  __typename?: 'OrganizationSubscription';
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  id: string;
  name?: string | null;
  auth0Id?: string | null;
  description?: string | null;
  avatar?: string | null;
  filter?: string | null;
  userId?: string | null;
};

export type UpdateOrganizationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganization = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    name?: string | null;
    auth0Id?: string | null;
    description?: string | null;
    avatar?: string | null;
  } | null;
};

export type GetOrganizationVariables = Exact<{ [key: string]: never }>;

export type GetOrganization = {
  __typename?: 'Query';
  getOrganization?: {
    __typename?: 'Organization';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    name?: string | null;
    auth0Id?: string | null;
    description?: string | null;
    avatar?: string | null;
  } | null;
};

export type OnOrganizationUpdateByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnOrganizationUpdateById = {
  __typename?: 'Subscription';
  onOrganizationUpdateById?: {
    __typename?: 'OrganizationSubscription';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    name?: string | null;
    auth0Id?: string | null;
    description?: string | null;
    avatar?: string | null;
    filter?: string | null;
    userId?: string | null;
  } | null;
};

export type OnOrganizationDeleteByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnOrganizationDeleteById = {
  __typename?: 'Subscription';
  onOrganizationDeleteById?: {
    __typename?: 'OrganizationSubscriptionDelete';
    id: string;
  } | null;
};

export type GrantUserRolesVariables = Exact<{
  input: UserRoleInput;
}>;

export type GrantUserRoles = {
  __typename?: 'Mutation';
  grantUserRoles?: {
    __typename?: 'ACLRoleAssignment';
    id: string;
    data: Array<{
      __typename?: 'ACLRoleAssignmentData';
      roleId?: string | null;
      entityId?: string | null;
    }>;
  } | null;
};

export type RevokeUserRolesVariables = Exact<{
  input: UserRoleInput;
}>;

export type RevokeUserRoles = {
  __typename?: 'Mutation';
  revokeUserRoles?: {
    __typename?: 'ACLRoleAssignment';
    id: string;
    data: Array<{
      __typename?: 'ACLRoleAssignmentData';
      roleId?: string | null;
      entityId?: string | null;
    }>;
  } | null;
};

export type UpdateAuth0RoleVariables = Exact<{
  input: UpdateAuth0UserRoleInput;
}>;

export type UpdateAuth0Role = {
  __typename?: 'Mutation';
  updateAuth0Role: Auth0Role;
};

export type GetAuth0RoleVariables = Exact<{ [key: string]: never }>;

export type GetAuth0Role = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id: string;
    userOrgData?: {
      __typename?: 'UserOrgData';
      auth0: { __typename: 'Auth0'; role: Auth0Role; id: string };
    } | null;
  } | null;
};

export type GetUserRoleAssignmentsVariables = Exact<{ [key: string]: never }>;

export type GetUserRoleAssignments = {
  __typename?: 'Query';
  getUserRoleAssignments?: {
    __typename?: 'ACLRoleAssignment';
    id: string;
    data: Array<{
      __typename?: 'ACLRoleAssignmentData';
      roleId?: string | null;
      entityId?: string | null;
    }>;
  } | null;
};

export type GetGlobalPermissionTreeVariables = Exact<{ [key: string]: never }>;

export type GetGlobalPermissionTree = {
  __typename?: 'Query';
  getGlobalPermissionsTree?: {
    __typename?: 'ACLGlobalPermissionTree';
    id: string;
    data?: Array<{
      __typename?: 'ACLGlobalPermissionTreeNode';
      actions: Array<AclAction>;
      level: AclLevel;
      children?: Array<{
        __typename?: 'ACLGlobalPermissionTreeNode';
        actions: Array<AclAction>;
        level: AclLevel;
        children?: Array<{
          __typename?: 'ACLGlobalPermissionTreeNode';
          actions: Array<AclAction>;
          level: AclLevel;
          children?: Array<{
            __typename?: 'ACLGlobalPermissionTreeNode';
            actions: Array<AclAction>;
            level: AclLevel;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetGlobalRoleDefinitionsVariables = Exact<{ [key: string]: never }>;

export type GetGlobalRoleDefinitions = {
  __typename?: 'Query';
  getGlobalRoleDefinitions?: {
    __typename?: 'ACLGlobalRoleDefinitions';
    id: string;
    data: Array<{
      __typename?: 'ACLGlobalRoleDefinition';
      name?: string | null;
      roleId?: string | null;
      statements: Array<{
        __typename?: 'ACLStatement';
        actions: Array<AclAction>;
        effect: AclStatementEffect;
        inheritance?: boolean | null;
        level: AclLevel;
      }>;
    }>;
  } | null;
};

export type OnUserRoleAssignmentsVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OnUserRoleAssignments = {
  __typename?: 'Subscription';
  onUserRoleAssignments?: {
    __typename?: 'ACLRoleAssignment';
    id: string;
    data: Array<{
      __typename?: 'ACLRoleAssignmentData';
      roleId?: string | null;
      entityId?: string | null;
    }>;
  } | null;
};

export type ArticlePostAdvicesFull = {
  __typename?: 'ArticlePostAdvices';
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  advices?: string | null;
};

export type ArticlePostFull = {
  __typename?: 'ArticlePost';
  id: string;
  name?: string | null;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  requestOrigin?: string | null;
  type: ArticleType;
  content?: string | null;
  articlePostType: ArticlePostType;
  attachments?: Array<string> | null;
  advices?: {
    __typename?: 'ArticlePostAdvices';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    advices?: string | null;
  } | null;
};

export type DeleteArticlePostVariables = Exact<{
  input: DeleteArticlePostInput;
}>;

export type DeleteArticlePost = {
  __typename?: 'Mutation';
  deleteArticlePost: { __typename?: 'ArticlePostDelete'; id: string };
};

export type UpdateArticlePostVariables = Exact<{
  input: UpdateArticlePostInput;
}>;

export type UpdateArticlePost = {
  __typename?: 'Mutation';
  updateArticlePost: {
    __typename?: 'ArticlePost';
    id: string;
    name?: string | null;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    content?: string | null;
    articlePostType: ArticlePostType;
    attachments?: Array<string> | null;
    advices?: {
      __typename?: 'ArticlePostAdvices';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      advices?: string | null;
    } | null;
  };
};

export type CreateLinkedinTokenVariables = Exact<{
  input: CreateLinkedinTokenInput;
}>;

export type CreateLinkedinToken = {
  __typename?: 'Mutation';
  createLinkedinToken: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
  };
};

export type PublishArticlePostVariables = Exact<{
  input: PublishArticlePostInput;
}>;

export type PublishArticlePost = {
  __typename?: 'Mutation';
  publishArticlePost: {
    __typename?: 'PublishArticlePostOutput';
    id: string;
    statusCode: number;
    code?: string | null;
    url?: string | null;
  };
};

export type ListArticlePostsVariables = Exact<{
  input: ListArticlePostsInput;
}>;

export type ListArticlePosts = {
  __typename?: 'Query';
  listArticlePosts: {
    __typename?: 'ListArticlePostsOutput';
    items: Array<{
      __typename?: 'ArticlePost';
      id: string;
      name?: string | null;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      content?: string | null;
      articlePostType: ArticlePostType;
      attachments?: Array<string> | null;
      advices?: {
        __typename?: 'ArticlePostAdvices';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        requestOrigin?: string | null;
        type: ArticleType;
        advices?: string | null;
      } | null;
    }>;
  };
};

export type GetArticlePostVariables = Exact<{
  input: GetArticlePostInput;
}>;

export type GetArticlePost = {
  __typename?: 'Query';
  getArticlePost: {
    __typename?: 'ArticlePost';
    id: string;
    name?: string | null;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    requestOrigin?: string | null;
    type: ArticleType;
    content?: string | null;
    articlePostType: ArticlePostType;
    attachments?: Array<string> | null;
    advices?: {
      __typename?: 'ArticlePostAdvices';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      advices?: string | null;
    } | null;
  };
};

export type GenerateLinkedInPostByGptVariables = Exact<{
  input: LinkedInPostByGptInput;
}>;

export type GenerateLinkedInPostByGpt = {
  __typename?: 'Query';
  generateLinkedInPostByGPT: {
    __typename?: 'Notification';
    createdAt?: string | null;
    data?: string | null;
    entityId?: string | null;
    id: string;
    initiatedBy?: string | null;
    notificationId?: string | null;
    notificationStatus?: NotificationStatus | null;
    notificationType?: NotificationType | null;
    notificationAction?: NotificationAction | null;
    pk1?: string | null;
    read?: boolean | null;
    sk1?: string | null;
    ttl?: number | null;
    updatedAt?: string | null;
  };
};

export type GetLinkedInConsentScreenUriVariables = Exact<{
  input: LinkedinConsentScreenUriInput;
}>;

export type GetLinkedInConsentScreenUri = {
  __typename?: 'Query';
  getLinkedinConsentScreenUri?: {
    __typename?: 'LinkedinConsentScreenUri';
    signInUri: string;
  } | null;
};

export type ListLinkedinOrganizationsVariables = Exact<{
  input: ListLinkedinOrganizationsInput;
}>;

export type ListLinkedinOrganizations = {
  __typename?: 'Query';
  listLinkedinOrganizations: Array<{
    __typename?: 'LinkedinOrganization';
    id: string;
    name: string;
  }>;
};

export type ProjectBriefDocumentFull = {
  __typename?: 'ProjectBriefDocument';
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  type: ProjectType;
  name: string;
  content: string;
  projectId: string;
  selected?: boolean | null;
};

export type ProjectBriefMetadataFull = {
  __typename?: 'ProjectBriefMetadata';
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  type: ProjectType;
  startDate?: string | null;
  dueDate?: string | null;
  avoid?: string | null;
  firstPersonPerspective: FirstPersonPerspective;
  directReaderAddressing: DirectReaderAddressing;
  planedArticles?: number | null;
  planedArticlesPerMonth?: number | null;
  competitorsUrls?: Array<string> | null;
  includedInArticleBrief?: Array<string> | null;
  inputTopics?: Array<string> | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
};

export type ProjectImageGuidelinesFull = {
  __typename?: 'ProjectImageGuidelines';
  id: string;
  updateToken?: string | null;
  updatedBy?: string | null;
  createdBy?: string | null;
  createdAt: string;
  updatedAt: string;
  type: ProjectType;
  allowGenAI: boolean;
  moodAndTone: ImageMoodAndTone;
  orientation: ImageOrientation;
  style: ImageStyle;
  imageType: ImageType;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
};

export type ProjectLabelFull = {
  __typename?: 'ProjectLabel';
  color: string;
  id: string;
  name: string;
  custom?: boolean | null;
  projectId: string;
};

export type ProjectMetadataBase = {
  __typename?: 'ProjectMetadata';
  createdBy?: string | null;
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt: string;
  updatedAt: string;
  pk1: string;
  id: string;
  name?: string | null;
  archived?: boolean | null;
  organizationId: string;
  avatar?: string | null;
  color?: Maybe<keyof IBaseColors>;
  domain?: string | null;
  locale: Locale;
};

export type ProjectMetadataBrief = {
  __typename?: 'ProjectMetadata';
  brief?: {
    __typename?: 'ProjectBrief';
    projectBriefMetadata: {
      __typename?: 'ProjectBriefMetadata';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      startDate?: string | null;
      dueDate?: string | null;
      avoid?: string | null;
      firstPersonPerspective: FirstPersonPerspective;
      directReaderAddressing: DirectReaderAddressing;
      planedArticles?: number | null;
      planedArticlesPerMonth?: number | null;
      competitorsUrls?: Array<string> | null;
      includedInArticleBrief?: Array<string> | null;
      inputTopics?: Array<string> | null;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    imageGuidelines: {
      __typename?: 'ProjectImageGuidelines';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    audiencePersonas: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    toneAndVoices: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    companyKnowledges: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    audienceExpertises: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
  } | null;
};

export type ProjectMetadataConnection = {
  __typename?: 'ProjectMetadata';
  gscConnectionId?: string | null;
  gscConnected?: boolean | null;
  wpConnected?: boolean | null;
  wpConnectionId?: string | null;
  linkedinConnected?: boolean | null;
  linkedinConnectionId?: string | null;
  linkedinOrgId?: string | null;
};

export type ProjectMetadataDetails = {
  __typename?: 'ProjectMetadata';
  description?: string | null;
  country?: string | null;
  topicInspirations?: Array<string> | null;
};

export type ProjectMetadataFull = {
  __typename?: 'ProjectMetadata';
  createdBy?: string | null;
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt: string;
  updatedAt: string;
  pk1: string;
  id: string;
  name?: string | null;
  archived?: boolean | null;
  organizationId: string;
  avatar?: string | null;
  color?: Maybe<keyof IBaseColors>;
  domain?: string | null;
  locale: Locale;
  description?: string | null;
  country?: string | null;
  topicInspirations?: Array<string> | null;
  gscConnectionId?: string | null;
  gscConnected?: boolean | null;
  wpConnected?: boolean | null;
  wpConnectionId?: string | null;
  linkedinConnected?: boolean | null;
  linkedinConnectionId?: string | null;
  linkedinOrgId?: string | null;
  neuraverseId?: string | null;
  neuraverseVersion?: NeuraverseVersion | null;
  articleStatistics?: {
    __typename?: 'ArticleStatistics';
    articleCountInProgress: number;
    completedArticlesThisMonth: number;
    articleCountByState: {
      __typename?: 'ArticleCountByState';
      BRIEF: number;
      DONE: number;
      WRITE: number;
      ONHOLD: number;
      REVIEW: number;
      TODO: number;
    };
  } | null;
  brief?: {
    __typename?: 'ProjectBrief';
    projectBriefMetadata: {
      __typename?: 'ProjectBriefMetadata';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      startDate?: string | null;
      dueDate?: string | null;
      avoid?: string | null;
      firstPersonPerspective: FirstPersonPerspective;
      directReaderAddressing: DirectReaderAddressing;
      planedArticles?: number | null;
      planedArticlesPerMonth?: number | null;
      competitorsUrls?: Array<string> | null;
      includedInArticleBrief?: Array<string> | null;
      inputTopics?: Array<string> | null;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    imageGuidelines: {
      __typename?: 'ProjectImageGuidelines';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      allowGenAI: boolean;
      moodAndTone: ImageMoodAndTone;
      orientation: ImageOrientation;
      style: ImageStyle;
      imageType: ImageType;
      customFields?: Array<{
        __typename?: 'CustomField';
        key?: string | null;
        value?: string | null;
      }> | null;
    };
    audiencePersonas: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    toneAndVoices: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    companyKnowledges: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
    audienceExpertises: Array<{
      __typename?: 'ProjectBriefDocument';
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      type: ProjectType;
      name: string;
      content: string;
      projectId: string;
      selected?: boolean | null;
    }>;
  } | null;
  labels?: Array<{
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  }> | null;
};

export type ProjectMetadataLabels = {
  __typename?: 'ProjectMetadata';
  labels?: Array<{
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  }> | null;
};

export type ProjectMetadataNeuraverse = {
  __typename?: 'ProjectMetadata';
  neuraverseId?: string | null;
  neuraverseVersion?: NeuraverseVersion | null;
};

export type ProjectMetadataStatistics = {
  __typename?: 'ProjectMetadata';
  articleStatistics?: {
    __typename?: 'ArticleStatistics';
    articleCountInProgress: number;
    completedArticlesThisMonth: number;
    articleCountByState: {
      __typename?: 'ArticleCountByState';
      BRIEF: number;
      DONE: number;
      WRITE: number;
      ONHOLD: number;
      REVIEW: number;
      TODO: number;
    };
  } | null;
};

export type ProjectSubscriptionFull = {
  __typename?: 'ProjectSubscription';
  createdBy?: string | null;
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  sk1?: string | null;
  id: string;
  type?: ProjectType | null;
  name?: string | null;
  description?: string | null;
  domain?: string | null;
  avatar?: string | null;
  locale?: Locale | null;
  country?: string | null;
  color?: Maybe<keyof IBaseColors>;
  gscConnected?: boolean | null;
  wpConnected?: boolean | null;
  linkedinConnected?: boolean | null;
  archived?: boolean | null;
  organizationId?: string | null;
  ttl?: number | null;
  neuraverseId?: string | null;
  topicInspirations?: Array<string> | null;
  gscConnectionId?: string | null;
  wpConnectionId?: string | null;
  linkedinConnectionId?: string | null;
  linkedinOrgId?: string | null;
  content?: string | null;
  projectId?: string | null;
  selected?: boolean | null;
  startDate?: string | null;
  dueDate?: string | null;
  avoid?: string | null;
  planedArticles?: number | null;
  planedArticlesPerMonth?: number | null;
  competitorsUrls?: Array<string> | null;
  includedInArticleBrief?: Array<string> | null;
  inputTopics?: Array<string> | null;
  allowGenAI?: boolean | null;
  moodAndTone?: ImageMoodAndTone | null;
  orientation?: ImageOrientation | null;
  style?: ImageStyle | null;
  imageType?: ImageType | null;
  filter?: string | null;
  firstPersonPerspective?: FirstPersonPerspective | null;
  directReaderAddressing?: DirectReaderAddressing | null;
  userId?: string | null;
  custom?: boolean | null;
  customFields?: Array<{
    __typename?: 'CustomField';
    key?: string | null;
    value?: string | null;
  }> | null;
};

export type CreateProjectVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProject = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    description?: string | null;
    country?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    neuraverseId?: string | null;
    neuraverseVersion?: NeuraverseVersion | null;
    articleStatistics?: {
      __typename?: 'ArticleStatistics';
      articleCountInProgress: number;
      completedArticlesThisMonth: number;
      articleCountByState: {
        __typename?: 'ArticleCountByState';
        BRIEF: number;
        DONE: number;
        WRITE: number;
        ONHOLD: number;
        REVIEW: number;
        TODO: number;
      };
    } | null;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        startDate?: string | null;
        dueDate?: string | null;
        avoid?: string | null;
        firstPersonPerspective: FirstPersonPerspective;
        directReaderAddressing: DirectReaderAddressing;
        planedArticles?: number | null;
        planedArticlesPerMonth?: number | null;
        competitorsUrls?: Array<string> | null;
        includedInArticleBrief?: Array<string> | null;
        inputTopics?: Array<string> | null;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      imageGuidelines: {
        __typename?: 'ProjectImageGuidelines';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        allowGenAI: boolean;
        moodAndTone: ImageMoodAndTone;
        orientation: ImageOrientation;
        style: ImageStyle;
        imageType: ImageType;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      audiencePersonas: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      toneAndVoices: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      companyKnowledges: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      audienceExpertises: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
    } | null;
    labels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type UpdateProjectMetadataVariables = Exact<{
  input: UpdateProjectMetadataInput;
}>;

export type UpdateProjectMetadata = {
  __typename?: 'Mutation';
  updateProjectMetadata: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    description?: string | null;
    country?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    neuraverseId?: string | null;
    neuraverseVersion?: NeuraverseVersion | null;
    articleStatistics?: {
      __typename?: 'ArticleStatistics';
      articleCountInProgress: number;
      completedArticlesThisMonth: number;
      articleCountByState: {
        __typename?: 'ArticleCountByState';
        BRIEF: number;
        DONE: number;
        WRITE: number;
        ONHOLD: number;
        REVIEW: number;
        TODO: number;
      };
    } | null;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        startDate?: string | null;
        dueDate?: string | null;
        avoid?: string | null;
        firstPersonPerspective: FirstPersonPerspective;
        directReaderAddressing: DirectReaderAddressing;
        planedArticles?: number | null;
        planedArticlesPerMonth?: number | null;
        competitorsUrls?: Array<string> | null;
        includedInArticleBrief?: Array<string> | null;
        inputTopics?: Array<string> | null;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      imageGuidelines: {
        __typename?: 'ProjectImageGuidelines';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        allowGenAI: boolean;
        moodAndTone: ImageMoodAndTone;
        orientation: ImageOrientation;
        style: ImageStyle;
        imageType: ImageType;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      audiencePersonas: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      toneAndVoices: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      companyKnowledges: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      audienceExpertises: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
    } | null;
    labels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type DeleteProjectVariables = Exact<{
  input: DeleteProjectInput;
}>;

export type DeleteProject = {
  __typename?: 'Mutation';
  deleteProject: { __typename?: 'ProjectDelete'; id: string };
};

export type UpdateProjectBriefMetadataVariables = Exact<{
  input: UpdateProjectBriefMetadataInput;
}>;

export type UpdateProjectBriefMetadata = {
  __typename?: 'Mutation';
  updateProjectBriefMetadata: {
    __typename?: 'ProjectBriefMetadata';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    type: ProjectType;
    startDate?: string | null;
    dueDate?: string | null;
    avoid?: string | null;
    firstPersonPerspective: FirstPersonPerspective;
    directReaderAddressing: DirectReaderAddressing;
    planedArticles?: number | null;
    planedArticlesPerMonth?: number | null;
    competitorsUrls?: Array<string> | null;
    includedInArticleBrief?: Array<string> | null;
    inputTopics?: Array<string> | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  };
};

export type UpdateProjectImageGuidelinesVariables = Exact<{
  input: UpdateProjectImageGuidelinesInput;
}>;

export type UpdateProjectImageGuidelines = {
  __typename?: 'Mutation';
  updateProjectImageGuidelines: {
    __typename?: 'ProjectImageGuidelines';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    type: ProjectType;
    allowGenAI: boolean;
    moodAndTone: ImageMoodAndTone;
    orientation: ImageOrientation;
    style: ImageStyle;
    imageType: ImageType;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  };
};

export type CreateProjectBriefDocumentVariables = Exact<{
  input: CreateProjectBriefDocumentInput;
}>;

export type CreateProjectBriefDocument = {
  __typename?: 'Mutation';
  createProjectBriefDocument: {
    __typename?: 'ProjectBriefDocument';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    type: ProjectType;
    name: string;
    content: string;
    projectId: string;
    selected?: boolean | null;
  };
};

export type UpdateProjectBriefDocumentVariables = Exact<{
  input: UpdateProjectBriefDocumentInput;
}>;

export type UpdateProjectBriefDocument = {
  __typename?: 'Mutation';
  updateProjectBriefDocument: {
    __typename?: 'ProjectBriefDocument';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    type: ProjectType;
    name: string;
    content: string;
    projectId: string;
    selected?: boolean | null;
  };
};

export type UpdateProjectBriefDocumentsVariables = Exact<{
  input: UpdateProjectBriefDocumentsInput;
}>;

export type UpdateProjectBriefDocuments = {
  __typename?: 'Mutation';
  updateProjectBriefDocuments: Array<{
    __typename?: 'ProjectBriefDocument';
    id: string;
    updateToken?: string | null;
    updatedBy?: string | null;
    createdBy?: string | null;
    createdAt: string;
    updatedAt: string;
    type: ProjectType;
    name: string;
    content: string;
    projectId: string;
    selected?: boolean | null;
  }>;
};

export type DeleteProjectBriefDocumentVariables = Exact<{
  input: DeleteProjectBriefDocumentInput;
}>;

export type DeleteProjectBriefDocument = {
  __typename?: 'Mutation';
  deleteProjectBriefDocument: {
    __typename?: 'ProjectBriefDocumentDelete';
    id: string;
    type: ProjectType;
  };
};

export type CreateOneClickAnalysisVariables = Exact<{
  input: CreateOneClickAnalysisInput;
}>;

export type CreateOneClickAnalysis = {
  __typename?: 'Mutation';
  createOneClickAnalysis?: OneClickAnalysisStatus | null;
};

export type UpdateUserProjectDataVariables = Exact<{
  input: UpdateUserProjectDataInput;
}>;

export type UpdateUserProjectData = {
  __typename?: 'Mutation';
  updateUserProjectData?: {
    __typename?: 'UserProjectData';
    hiddenTiles?: string | null;
    id: string;
  } | null;
};

export type CreateTopicInspirationsVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type CreateTopicInspirations = {
  __typename?: 'Mutation';
  createTopicInspirations?: string | null;
};

export type CreateProjectLabelVariables = Exact<{
  input: CreateProjectLabelInput;
}>;

export type CreateProjectLabel = {
  __typename?: 'Mutation';
  createProjectLabel: {
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  };
};

export type UpdateProjectLabelVariables = Exact<{
  input: UpdateProjectLabelInput;
}>;

export type UpdateProjectLabel = {
  __typename?: 'Mutation';
  updateProjectLabel: {
    __typename?: 'ProjectLabel';
    color: string;
    id: string;
    name: string;
    custom?: boolean | null;
    projectId: string;
  };
};

export type DeleteProjectLabelVariables = Exact<{
  input: DeleteProjectLabelInput;
}>;

export type DeleteProjectLabel = {
  __typename?: 'Mutation';
  deleteProjectLabel: {
    __typename?: 'ProjectLabelDelete';
    id: string;
    type: ProjectType;
  };
};

export type GetProjectMetadataBaseVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectMetadataBase = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
  };
};

export type GetProjectDataForLayoutVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectDataForLayout = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    description?: string | null;
    country?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
  };
};

export type GetProjectBriefVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectBrief = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        startDate?: string | null;
        dueDate?: string | null;
        avoid?: string | null;
        firstPersonPerspective: FirstPersonPerspective;
        directReaderAddressing: DirectReaderAddressing;
        planedArticles?: number | null;
        planedArticlesPerMonth?: number | null;
        competitorsUrls?: Array<string> | null;
        includedInArticleBrief?: Array<string> | null;
        inputTopics?: Array<string> | null;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      imageGuidelines: {
        __typename?: 'ProjectImageGuidelines';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        allowGenAI: boolean;
        moodAndTone: ImageMoodAndTone;
        orientation: ImageOrientation;
        style: ImageStyle;
        imageType: ImageType;
        customFields?: Array<{
          __typename?: 'CustomField';
          key?: string | null;
          value?: string | null;
        }> | null;
      };
      audiencePersonas: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      toneAndVoices: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      companyKnowledges: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
      audienceExpertises: Array<{
        __typename?: 'ProjectBriefDocument';
        id: string;
        updateToken?: string | null;
        updatedBy?: string | null;
        createdBy?: string | null;
        createdAt: string;
        updatedAt: string;
        type: ProjectType;
        name: string;
        content: string;
        projectId: string;
        selected?: boolean | null;
      }>;
    } | null;
    articleStatistics?: {
      __typename?: 'ArticleStatistics';
      articleCountInProgress: number;
      completedArticlesThisMonth: number;
      articleCountByState: {
        __typename?: 'ArticleCountByState';
        BRIEF: number;
        DONE: number;
        WRITE: number;
        ONHOLD: number;
        REVIEW: number;
        TODO: number;
      };
    } | null;
  };
};

export type GetProjectAllowGenAiVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectAllowGenAi = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      imageGuidelines: {
        __typename?: 'ProjectImageGuidelines';
        allowGenAI: boolean;
      };
    } | null;
  };
};

export type ListProjectIdsVariables = Exact<{
  filter?: InputMaybe<ListProjectsInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListProjectIds = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ListProjects';
    nextToken?: string | null;
    items: Array<{ __typename?: 'ProjectMetadata'; id: string }>;
  };
};

export type ListProjectsForSidebarVariables = Exact<{
  filter?: InputMaybe<ListProjectsInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListProjectsForSidebar = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ListProjects';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ProjectMetadata';
      id: string;
      name?: string | null;
      color?: Maybe<keyof IBaseColors>;
      archived?: boolean | null;
      locale: Locale;
      neuraverseId?: string | null;
      neuraverseVersion?: NeuraverseVersion | null;
    }>;
  };
};

export type GetIsProjectMonitoredVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type GetIsProjectMonitored = {
  __typename?: 'Query';
  getIsProjectMonitored: boolean;
};

export type GetProjectForMonitoringVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectForMonitoring = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    gscConnected?: boolean | null;
    wpConnectionId?: string | null;
    wpConnected?: boolean | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
  };
};

export type ListProjectsForProjectsOverviewVariables = Exact<{
  filter?: InputMaybe<ListProjectsInputFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListProjectsForProjectsOverview = {
  __typename?: 'Query';
  listProjects: {
    __typename?: 'ListProjects';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'ProjectMetadata';
      createdBy?: string | null;
      updatedBy?: string | null;
      updateToken?: string | null;
      createdAt: string;
      updatedAt: string;
      pk1: string;
      id: string;
      name?: string | null;
      archived?: boolean | null;
      organizationId: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      domain?: string | null;
      locale: Locale;
      brief?: {
        __typename?: 'ProjectBrief';
        projectBriefMetadata: {
          __typename?: 'ProjectBriefMetadata';
          planedArticles?: number | null;
          dueDate?: string | null;
        };
      } | null;
      articleStatistics?: {
        __typename?: 'ArticleStatistics';
        articleCountInProgress: number;
        completedArticlesThisMonth: number;
        articleCountByState: {
          __typename?: 'ArticleCountByState';
          BRIEF: number;
          DONE: number;
          WRITE: number;
          ONHOLD: number;
          REVIEW: number;
          TODO: number;
        };
      } | null;
    }>;
  };
};

export type GetProjectForPlanPageVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectForPlanPage = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        startDate?: string | null;
        dueDate?: string | null;
      };
    } | null;
  };
};

export type GetProjectConnectionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectConnection = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
  };
};

export type GetProjectStatisticsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectStatistics = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        planedArticles?: number | null;
        startDate?: string | null;
        dueDate?: string | null;
      };
    } | null;
    articleStatistics?: {
      __typename?: 'ArticleStatistics';
      articleCountInProgress: number;
      completedArticlesThisMonth: number;
      articleCountByState: {
        __typename?: 'ArticleCountByState';
        BRIEF: number;
        DONE: number;
        WRITE: number;
        ONHOLD: number;
        REVIEW: number;
        TODO: number;
      };
    } | null;
  };
};

export type GetProjectMetadataNeuraverseVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectMetadataNeuraverse = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    neuraverseId?: string | null;
    neuraverseVersion?: NeuraverseVersion | null;
  };
};

export type GetProjectForCalendarVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectForCalendar = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        id: string;
        startDate?: string | null;
        dueDate?: string | null;
      };
    } | null;
  };
};

export type GetProjectOverviewVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectOverview = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    topicInspirations?: Array<string> | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    gscConnectionId?: string | null;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    wpConnectionId?: string | null;
    linkedinConnected?: boolean | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    neuraverseId?: string | null;
    neuraverseVersion?: NeuraverseVersion | null;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        inputTopics?: Array<string> | null;
      };
    } | null;
    userProject?: {
      __typename?: 'UserProjectData';
      hiddenTiles?: string | null;
      id: string;
    } | null;
  };
};

export type GetGscConnectionStatusVariables = Exact<{
  input: GetGscConnectionStatusInput;
}>;

export type GetGscConnectionStatus = {
  __typename?: 'Query';
  getGscConnectionStatus: {
    __typename?: 'GscConnectionStatusResponse';
    status: GscConnectionStatus;
  };
};

export type GetLinkedinConnectionStatusVariables = Exact<{
  input: GetLinkedinConnectionStatusInput;
}>;

export type GetLinkedinConnectionStatus = {
  __typename?: 'Query';
  getLinkedinConnectionStatus: {
    __typename?: 'LinkedinConnectionStatusResponse';
    status: LinkedinConnectionStatus;
  };
};

export type GetOneClickAnalysisVariables = Exact<{
  input: GetOneClickAnalysisInput;
}>;

export type GetOneClickAnalysis = {
  __typename?: 'Query';
  getOneClickAnalysis?: {
    __typename?: 'OneClickAnalysis';
    status: OneClickAnalysisStatus;
    finishedAt?: string | null;
    report?: {
      __typename?: 'OneClickAnalysisReport';
      score?: number | null;
      urls: Array<string>;
      coreWebVitals?: {
        __typename?: 'CoreWebVitals';
        cumulativeLayoutShift: { __typename?: 'CoreWebVital'; value: number };
        firstContentfulPaint: { __typename?: 'CoreWebVital'; value: number };
        interactive: { __typename?: 'CoreWebVital'; value: number };
        largestContentfulPaint: { __typename?: 'CoreWebVital'; value: number };
      } | null;
      failedReports?: Array<{
        __typename?: 'OCAFailedReport';
        error: string;
        url: number;
      }> | null;
      recommendations: {
        __typename?: 'OCARecommendations';
        critical: Array<{
          __typename?: 'OCARecommendation';
          id: string;
          urls: Array<number>;
        }>;
        low: Array<{
          __typename?: 'OCARecommendation';
          id: string;
          urls: Array<number>;
        }>;
        medium: Array<{
          __typename?: 'OCARecommendation';
          id: string;
          urls: Array<number>;
        }>;
      };
    } | null;
  } | null;
};

export type ListArticlesForCurrentUserVariables = Exact<{
  filter?: InputMaybe<ListArticlesInputFilter>;
  id?: InputMaybe<ModelStringKeyConditionInput>;
}>;

export type ListArticlesForCurrentUser = {
  __typename?: 'Query';
  listArticles: {
    __typename?: 'ListArticles';
    items: Array<{
      __typename?: 'ArticleMetadata';
      pk1: string;
      sk1: string;
      sk2?: string | null;
      id: string;
      updateToken?: string | null;
      updatedBy?: string | null;
      createdBy?: string | null;
      createdAt: string;
      updatedAt: string;
      requestOrigin?: string | null;
      type: ArticleType;
      name?: string | null;
      versionId: number;
      projectId: string;
      state: ArticleState;
      completedDate?: string | null;
      publicationDate?: string | null;
      dueDate?: string | null;
      focusKeyword?: string | null;
      urlPath?: string | null;
      articleDocument?: {
        __typename?: 'ArticleDocument';
        updatedAt: string;
      } | null;
      articleGuidelines?: {
        __typename?: 'ArticleGuidelines';
        updatedAt: string;
      } | null;
      project?: {
        __typename?: 'ProjectMetadata';
        createdBy?: string | null;
        updatedBy?: string | null;
        updateToken?: string | null;
        createdAt: string;
        updatedAt: string;
        pk1: string;
        id: string;
        name?: string | null;
        archived?: boolean | null;
        organizationId: string;
        avatar?: string | null;
        color?: Maybe<keyof IBaseColors>;
        domain?: string | null;
        locale: Locale;
      } | null;
    }>;
  };
};

export type ListGscTopChangingArticlesVariables = Exact<{
  input: ListGscTopChangingArticlesInput;
}>;

export type ListGscTopChangingArticles = {
  __typename?: 'Query';
  listGSCTopChangingArticles?: {
    __typename?: 'GSCTopChangingArticles';
    items?: Array<{
      __typename?: 'GSCTopChangingArticle';
      articleId: string;
      currentAverageGoogleRanking: number;
      value: number;
      previousAverageGoogleRanking: number;
      title: string;
    } | null> | null;
  } | null;
};

export type GetProjectTopicInspirationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectTopicInspiration = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    topicInspirations?: Array<string> | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    brief?: {
      __typename?: 'ProjectBrief';
      projectBriefMetadata: {
        __typename?: 'ProjectBriefMetadata';
        inputTopics?: Array<string> | null;
      };
    } | null;
  };
};

export type ListProjectLabelsVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ListProjectLabels = {
  __typename?: 'Query';
  getProject: {
    __typename?: 'ProjectMetadata';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt: string;
    updatedAt: string;
    pk1: string;
    id: string;
    name?: string | null;
    archived?: boolean | null;
    organizationId: string;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    domain?: string | null;
    locale: Locale;
    labels?: Array<{
      __typename?: 'ProjectLabel';
      color: string;
      id: string;
      name: string;
      custom?: boolean | null;
      projectId: string;
    }> | null;
  };
};

export type GetProjectLabelUsageCountVariables = Exact<{
  input: GetProjectLabelUsageCountInput;
}>;

export type GetProjectLabelUsageCount = {
  __typename?: 'Query';
  getProjectLabelUsageCount: number;
};

export type OnProjectCreateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnProjectCreate = {
  __typename?: 'Subscription';
  onProjectCreate?: {
    __typename?: 'ProjectSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    type?: ProjectType | null;
    name?: string | null;
    description?: string | null;
    domain?: string | null;
    avatar?: string | null;
    locale?: Locale | null;
    country?: string | null;
    color?: Maybe<keyof IBaseColors>;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    linkedinConnected?: boolean | null;
    archived?: boolean | null;
    organizationId?: string | null;
    ttl?: number | null;
    neuraverseId?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    wpConnectionId?: string | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    content?: string | null;
    projectId?: string | null;
    selected?: boolean | null;
    startDate?: string | null;
    dueDate?: string | null;
    avoid?: string | null;
    planedArticles?: number | null;
    planedArticlesPerMonth?: number | null;
    competitorsUrls?: Array<string> | null;
    includedInArticleBrief?: Array<string> | null;
    inputTopics?: Array<string> | null;
    allowGenAI?: boolean | null;
    moodAndTone?: ImageMoodAndTone | null;
    orientation?: ImageOrientation | null;
    style?: ImageStyle | null;
    imageType?: ImageType | null;
    filter?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    userId?: string | null;
    custom?: boolean | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  } | null;
};

export type OnProjectUpdateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnProjectUpdate = {
  __typename?: 'Subscription';
  onProjectUpdate?: {
    __typename?: 'ProjectSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    type?: ProjectType | null;
    name?: string | null;
    description?: string | null;
    domain?: string | null;
    avatar?: string | null;
    locale?: Locale | null;
    country?: string | null;
    color?: Maybe<keyof IBaseColors>;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    linkedinConnected?: boolean | null;
    archived?: boolean | null;
    organizationId?: string | null;
    ttl?: number | null;
    neuraverseId?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    wpConnectionId?: string | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    content?: string | null;
    projectId?: string | null;
    selected?: boolean | null;
    startDate?: string | null;
    dueDate?: string | null;
    avoid?: string | null;
    planedArticles?: number | null;
    planedArticlesPerMonth?: number | null;
    competitorsUrls?: Array<string> | null;
    includedInArticleBrief?: Array<string> | null;
    inputTopics?: Array<string> | null;
    allowGenAI?: boolean | null;
    moodAndTone?: ImageMoodAndTone | null;
    orientation?: ImageOrientation | null;
    style?: ImageStyle | null;
    imageType?: ImageType | null;
    filter?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    userId?: string | null;
    custom?: boolean | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  } | null;
};

export type OnProjectDeleteVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnProjectDelete = {
  __typename?: 'Subscription';
  onProjectDelete?: {
    __typename?: 'ProjectSubscriptionDelete';
    id: string;
    type?: string | null;
  } | null;
};

export type OnProjectUpdateByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  id: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnProjectUpdateById = {
  __typename?: 'Subscription';
  onProjectUpdateById?: {
    __typename?: 'ProjectSubscription';
    createdBy?: string | null;
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    sk1?: string | null;
    id: string;
    type?: ProjectType | null;
    name?: string | null;
    description?: string | null;
    domain?: string | null;
    avatar?: string | null;
    locale?: Locale | null;
    country?: string | null;
    color?: Maybe<keyof IBaseColors>;
    gscConnected?: boolean | null;
    wpConnected?: boolean | null;
    linkedinConnected?: boolean | null;
    archived?: boolean | null;
    organizationId?: string | null;
    ttl?: number | null;
    neuraverseId?: string | null;
    topicInspirations?: Array<string> | null;
    gscConnectionId?: string | null;
    wpConnectionId?: string | null;
    linkedinConnectionId?: string | null;
    linkedinOrgId?: string | null;
    content?: string | null;
    projectId?: string | null;
    selected?: boolean | null;
    startDate?: string | null;
    dueDate?: string | null;
    avoid?: string | null;
    planedArticles?: number | null;
    planedArticlesPerMonth?: number | null;
    competitorsUrls?: Array<string> | null;
    includedInArticleBrief?: Array<string> | null;
    inputTopics?: Array<string> | null;
    allowGenAI?: boolean | null;
    moodAndTone?: ImageMoodAndTone | null;
    orientation?: ImageOrientation | null;
    style?: ImageStyle | null;
    imageType?: ImageType | null;
    filter?: string | null;
    firstPersonPerspective?: FirstPersonPerspective | null;
    directReaderAddressing?: DirectReaderAddressing | null;
    userId?: string | null;
    custom?: boolean | null;
    customFields?: Array<{
      __typename?: 'CustomField';
      key?: string | null;
      value?: string | null;
    }> | null;
  } | null;
};

export type OnProjectDeleteByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  pk1: Scalars['String']['input'];
  id: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnProjectDeleteById = {
  __typename?: 'Subscription';
  onProjectDeleteById?: {
    __typename?: 'ProjectSubscriptionDelete';
    id: string;
    type?: string | null;
  } | null;
};

export type ProjectMemberFull = {
  __typename?: 'ProjectMember';
  id: string;
  user: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
    email?: string | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename?: 'NotificationSettings';
      article: {
        __typename?: 'ArticleNotificationSettings';
        email: boolean;
        inapp: boolean;
        notifyAll: boolean;
        notifyIfCreator: boolean;
        notifyIfAssignee: boolean;
      };
      project: {
        __typename?: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename?: 'UserOrgData';
      lastAccessedProjectId?: string | null;
      organizationId: string;
      daysBeforeDueDateWarning?: number | null;
      updateToken?: string | null;
      auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
    } | null;
  };
  aclRoles: Array<{
    __typename?: 'ACLUserRoles';
    name?: string | null;
    roleId?: string | null;
  }>;
};

export type UserArticleDataFragment = {
  __typename: 'UserArticleData';
  id: string;
  preferences?: string | null;
};

export type UserBase = {
  __typename?: 'User';
  id: string;
  updatedBy?: string | null;
  updatedAt?: string | null;
  updateToken?: string | null;
  pk1?: string | null;
  nickname?: string | null;
  locale?: Locale | null;
  lastName?: string | null;
  firstName?: string | null;
  createdAt?: string | null;
  color?: Maybe<keyof IBaseColors>;
  avatar?: string | null;
};

export type UserDetails = {
  __typename?: 'User';
  email?: string | null;
  lastReadNewsFeedPubDate?: string | null;
};

export type UserFull = {
  __typename?: 'User';
  id: string;
  updatedBy?: string | null;
  updatedAt?: string | null;
  updateToken?: string | null;
  pk1?: string | null;
  nickname?: string | null;
  locale?: Locale | null;
  lastName?: string | null;
  firstName?: string | null;
  createdAt?: string | null;
  color?: Maybe<keyof IBaseColors>;
  avatar?: string | null;
  email?: string | null;
  lastReadNewsFeedPubDate?: string | null;
  notificationSettings?: {
    __typename?: 'NotificationSettings';
    article: {
      __typename?: 'ArticleNotificationSettings';
      email: boolean;
      inapp: boolean;
      notifyAll: boolean;
      notifyIfCreator: boolean;
      notifyIfAssignee: boolean;
    };
    project: {
      __typename?: 'ProjectNotificationSettings';
      inapp: boolean;
      email: boolean;
    };
  } | null;
  userOrgData?: {
    __typename?: 'UserOrgData';
    lastAccessedProjectId?: string | null;
    organizationId: string;
    daysBeforeDueDateWarning?: number | null;
    updateToken?: string | null;
    auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
  } | null;
};

export type UserNotificationSettings = {
  __typename?: 'User';
  notificationSettings?: {
    __typename?: 'NotificationSettings';
    article: {
      __typename?: 'ArticleNotificationSettings';
      email: boolean;
      inapp: boolean;
      notifyAll: boolean;
      notifyIfCreator: boolean;
      notifyIfAssignee: boolean;
    };
    project: {
      __typename?: 'ProjectNotificationSettings';
      inapp: boolean;
      email: boolean;
    };
  } | null;
};

export type UserOrganization = {
  __typename?: 'User';
  userOrgData?: {
    __typename?: 'UserOrgData';
    lastAccessedProjectId?: string | null;
    organizationId: string;
    daysBeforeDueDateWarning?: number | null;
    updateToken?: string | null;
    auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
  } | null;
};

export type UserProjectDataFragment = {
  __typename: 'UserProjectData';
  id: string;
  hiddenTiles?: string | null;
};

export type UserSubscriptionFull = {
  __typename: 'UserSubscription';
  updatedBy?: string | null;
  updateToken?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  pk1?: string | null;
  id: string;
  nickname?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: string | null;
  color?: Maybe<keyof IBaseColors>;
  email?: string | null;
  organizationId?: string | null;
  userId?: string | null;
  locale?: Locale | null;
  lastReadNewsFeedPubDate?: string | null;
  notificationSettings?: {
    __typename: 'NotificationSettings';
    article: {
      __typename: 'ArticleNotificationSettings';
      inapp: boolean;
      email: boolean;
      notifyIfAssignee: boolean;
      notifyIfCreator: boolean;
      notifyAll: boolean;
    };
    project: {
      __typename: 'ProjectNotificationSettings';
      inapp: boolean;
      email: boolean;
    };
  } | null;
  userOrgData?: {
    __typename: 'UserOrgData';
    organizationId: string;
    updateToken?: string | null;
    lastAccessedProjectId?: string | null;
    daysBeforeDueDateWarning?: number | null;
    auth0: { __typename: 'Auth0'; role: Auth0Role; id: string };
  } | null;
};

export type UpdateUserVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUser = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
    email?: string | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename?: 'NotificationSettings';
      article: {
        __typename?: 'ArticleNotificationSettings';
        email: boolean;
        inapp: boolean;
        notifyAll: boolean;
        notifyIfCreator: boolean;
        notifyIfAssignee: boolean;
      };
      project: {
        __typename?: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename?: 'UserOrgData';
      lastAccessedProjectId?: string | null;
      organizationId: string;
      daysBeforeDueDateWarning?: number | null;
      updateToken?: string | null;
      auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
    } | null;
  } | null;
};

export type DeleteUserVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUser = {
  __typename?: 'Mutation';
  deleteUser: { __typename?: 'User'; id: string };
};

export type UpdateUserArticleDataVariables = Exact<{
  input: UpdateUserArticleDataInput;
}>;

export type UpdateUserArticleData = {
  __typename?: 'Mutation';
  updateUserArticleData?: {
    __typename?: 'UserArticleData';
    preferences?: string | null;
    id: string;
  } | null;
};

export type GetUserVariables = Exact<{ [key: string]: never }>;

export type GetUser = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id: string;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    pk1?: string | null;
    nickname?: string | null;
    locale?: Locale | null;
    lastName?: string | null;
    firstName?: string | null;
    createdAt?: string | null;
    color?: Maybe<keyof IBaseColors>;
    avatar?: string | null;
    email?: string | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename?: 'NotificationSettings';
      article: {
        __typename?: 'ArticleNotificationSettings';
        email: boolean;
        inapp: boolean;
        notifyAll: boolean;
        notifyIfCreator: boolean;
        notifyIfAssignee: boolean;
      };
      project: {
        __typename?: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename?: 'UserOrgData';
      lastAccessedProjectId?: string | null;
      organizationId: string;
      daysBeforeDueDateWarning?: number | null;
      updateToken?: string | null;
      auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
    } | null;
  } | null;
};

export type ListUsersForProjectDialogVariables = Exact<{
  [key: string]: never;
}>;

export type ListUsersForProjectDialog = {
  __typename?: 'Query';
  listUsers?: {
    __typename?: 'UserItems';
    items: Array<{
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
      userOrgData?: {
        __typename?: 'UserOrgData';
        lastAccessedProjectId?: string | null;
        organizationId: string;
        daysBeforeDueDateWarning?: number | null;
        updateToken?: string | null;
        auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
      } | null;
    }>;
  } | null;
};

export type ListUsersForMembersPageVariables = Exact<{ [key: string]: never }>;

export type ListUsersForMembersPage = {
  __typename?: 'Query';
  listUsersWithProjects?: Array<{
    __typename?: 'UserWithProjects';
    id: string;
    projectsCount: number;
    auth0: { __typename?: 'Auth0'; role: Auth0Role };
    user: {
      __typename?: 'User';
      email?: string | null;
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    };
  }> | null;
};

export type ListProjectMembersVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type ListProjectMembers = {
  __typename?: 'Query';
  listProjectMembers: Array<{
    __typename?: 'ProjectMember';
    id: string;
    user: {
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
      email?: string | null;
      lastReadNewsFeedPubDate?: string | null;
      notificationSettings?: {
        __typename?: 'NotificationSettings';
        article: {
          __typename?: 'ArticleNotificationSettings';
          email: boolean;
          inapp: boolean;
          notifyAll: boolean;
          notifyIfCreator: boolean;
          notifyIfAssignee: boolean;
        };
        project: {
          __typename?: 'ProjectNotificationSettings';
          inapp: boolean;
          email: boolean;
        };
      } | null;
      userOrgData?: {
        __typename?: 'UserOrgData';
        lastAccessedProjectId?: string | null;
        organizationId: string;
        daysBeforeDueDateWarning?: number | null;
        updateToken?: string | null;
        auth0: { __typename?: 'Auth0'; id: string; role: Auth0Role };
      } | null;
    };
    aclRoles: Array<{
      __typename?: 'ACLUserRoles';
      name?: string | null;
      roleId?: string | null;
    }>;
  }>;
};

export type ListAllOrgUsersVariables = Exact<{ [key: string]: never }>;

export type ListAllOrgUsers = {
  __typename?: 'Query';
  listUsers?: {
    __typename?: 'UserItems';
    items: Array<{
      __typename?: 'User';
      id: string;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      pk1?: string | null;
      nickname?: string | null;
      locale?: Locale | null;
      lastName?: string | null;
      firstName?: string | null;
      createdAt?: string | null;
      color?: Maybe<keyof IBaseColors>;
      avatar?: string | null;
    }>;
  } | null;
};

export type ListUserProjectsVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type ListUserProjects = {
  __typename?: 'Query';
  listUserProjects?: {
    __typename?: 'UserProjectsWithRoles';
    user: {
      __typename?: 'User';
      id: string;
      avatar?: string | null;
      color?: Maybe<keyof IBaseColors>;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      nickname?: string | null;
    };
    projects: Array<{
      __typename?: 'UserProjectWithRole';
      roleId?: string | null;
      project: {
        __typename?: 'ProjectMetadata';
        id: string;
        name?: string | null;
        avatar?: string | null;
        color?: Maybe<keyof IBaseColors>;
      };
    }>;
  } | null;
};

export type OnUserCreateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUserCreate = {
  __typename?: 'Subscription';
  onUserCreate?: {
    __typename: 'UserSubscription';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    nickname?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    email?: string | null;
    organizationId?: string | null;
    userId?: string | null;
    locale?: Locale | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename: 'NotificationSettings';
      article: {
        __typename: 'ArticleNotificationSettings';
        inapp: boolean;
        email: boolean;
        notifyIfAssignee: boolean;
        notifyIfCreator: boolean;
        notifyAll: boolean;
      };
      project: {
        __typename: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename: 'UserOrgData';
      organizationId: string;
      updateToken?: string | null;
      lastAccessedProjectId?: string | null;
      daysBeforeDueDateWarning?: number | null;
      auth0: { __typename: 'Auth0'; role: Auth0Role; id: string };
    } | null;
  } | null;
};

export type OnUserUpdateVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUserUpdate = {
  __typename?: 'Subscription';
  onUserUpdate?: {
    __typename: 'UserSubscription';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    nickname?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    email?: string | null;
    organizationId?: string | null;
    userId?: string | null;
    locale?: Locale | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename: 'NotificationSettings';
      article: {
        __typename: 'ArticleNotificationSettings';
        inapp: boolean;
        email: boolean;
        notifyIfAssignee: boolean;
        notifyIfCreator: boolean;
        notifyAll: boolean;
      };
      project: {
        __typename: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename: 'UserOrgData';
      organizationId: string;
      updateToken?: string | null;
      lastAccessedProjectId?: string | null;
      daysBeforeDueDateWarning?: number | null;
      auth0: { __typename: 'Auth0'; role: Auth0Role; id: string };
    } | null;
  } | null;
};

export type OnUserDeleteVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUserDelete = {
  __typename?: 'Subscription';
  onUserDelete?: { __typename?: 'UserSubscriptionDelete'; id: string } | null;
};

export type OnUserUpdateByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUserUpdateById = {
  __typename?: 'Subscription';
  onUserUpdateById?: {
    __typename: 'UserSubscription';
    updatedBy?: string | null;
    updateToken?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    pk1?: string | null;
    id: string;
    nickname?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: string | null;
    color?: Maybe<keyof IBaseColors>;
    email?: string | null;
    organizationId?: string | null;
    userId?: string | null;
    locale?: Locale | null;
    lastReadNewsFeedPubDate?: string | null;
    notificationSettings?: {
      __typename: 'NotificationSettings';
      article: {
        __typename: 'ArticleNotificationSettings';
        inapp: boolean;
        email: boolean;
        notifyIfAssignee: boolean;
        notifyIfCreator: boolean;
        notifyAll: boolean;
      };
      project: {
        __typename: 'ProjectNotificationSettings';
        inapp: boolean;
        email: boolean;
      };
    } | null;
    userOrgData?: {
      __typename: 'UserOrgData';
      organizationId: string;
      updateToken?: string | null;
      lastAccessedProjectId?: string | null;
      daysBeforeDueDateWarning?: number | null;
      auth0: { __typename: 'Auth0'; role: Auth0Role; id: string };
    } | null;
  } | null;
};

export type OnUserDeleteByIdVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUserDeleteById = {
  __typename?: 'Subscription';
  onUserDeleteById?: {
    __typename?: 'UserSubscriptionDelete';
    id: string;
  } | null;
};

export type UiViewFull = {
  __typename?: 'UiView';
  createdAt?: string | null;
  createdBy?: string | null;
  entityId?: string | null;
  filtering?: string | null;
  id: string;
  index?: number | null;
  name?: string | null;
  pk1?: string | null;
  searchTerm?: string | null;
  sorting?: string | null;
  tableId?: string | null;
  visibility?: string | null;
  expanded?: string | null;
  priority?: boolean | null;
  companyPriority?: boolean | null;
  tab?: string | null;
  updatedBy?: string | null;
  updatedAt?: string | null;
  updateToken?: string | null;
  showWinnersLosers?: boolean | null;
  calendarVisibility?: string | null;
};

export type UiViewSubscriptionFull = {
  __typename?: 'UiViewSubscription';
  createdAt?: string | null;
  createdBy?: string | null;
  entityId?: string | null;
  filtering?: string | null;
  id: string;
  index?: number | null;
  name?: string | null;
  pk1?: string | null;
  searchTerm?: string | null;
  sorting?: string | null;
  tableId?: string | null;
  updateToken?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visibility?: string | null;
  expanded?: string | null;
  priority?: boolean | null;
  companyPriority?: boolean | null;
  tab?: string | null;
  showWinnersLosers?: boolean | null;
  calendarVisibility?: string | null;
};

export type CreateUiViewVariables = Exact<{
  input: CreateUiViewInput;
}>;

export type CreateUiView = {
  __typename?: 'Mutation';
  createUiView?: {
    __typename?: 'UiView';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export type DeleteUiViewVariables = Exact<{
  input: DeleteUiViewInput;
}>;

export type DeleteUiView = {
  __typename?: 'Mutation';
  deleteUiView?: { __typename?: 'UiViewDelete'; id: string } | null;
};

export type UpdateUiViewVariables = Exact<{
  input: UpdateUiViewInput;
}>;

export type UpdateUiView = {
  __typename?: 'Mutation';
  updateUiView?: {
    __typename?: 'UiView';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export type ListUiViewsFullVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ListUiViewsFull = {
  __typename?: 'Query';
  listUiViews?: {
    __typename?: 'ListUiViews';
    nextToken?: string | null;
    items: Array<{
      __typename?: 'UiView';
      createdAt?: string | null;
      createdBy?: string | null;
      entityId?: string | null;
      filtering?: string | null;
      id: string;
      index?: number | null;
      name?: string | null;
      pk1?: string | null;
      searchTerm?: string | null;
      sorting?: string | null;
      tableId?: string | null;
      visibility?: string | null;
      expanded?: string | null;
      priority?: boolean | null;
      companyPriority?: boolean | null;
      tab?: string | null;
      updatedBy?: string | null;
      updatedAt?: string | null;
      updateToken?: string | null;
      showWinnersLosers?: boolean | null;
      calendarVisibility?: string | null;
    }>;
  } | null;
};

export type GetUiViewVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUiView = {
  __typename?: 'Query';
  getUiView?: {
    __typename?: 'UiView';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    updatedBy?: string | null;
    updatedAt?: string | null;
    updateToken?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export type OnUiViewCreateVariables = Exact<{
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUiViewCreate = {
  __typename?: 'Subscription';
  onUiViewCreate?: {
    __typename?: 'UiViewSubscription';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    updateToken?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export type OnUiViewUpdateVariables = Exact<{
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUiViewUpdate = {
  __typename?: 'Subscription';
  onUiViewUpdate?: {
    __typename?: 'UiViewSubscription';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    updateToken?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export type OnUiViewDeleteVariables = Exact<{
  pk1: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUiViewDelete = {
  __typename?: 'Subscription';
  onUiViewDelete?: {
    __typename?: 'UiViewSubscriptionDelete';
    id: string;
  } | null;
};

export type OnUiViewDeleteByIdVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUiViewDeleteById = {
  __typename?: 'Subscription';
  onUiViewDeleteById?: {
    __typename?: 'UiViewSubscriptionDelete';
    id: string;
  } | null;
};

export type OnUiViewUpdateByIdVariables = Exact<{
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pk1: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnUiViewUpdateById = {
  __typename?: 'Subscription';
  onUiViewUpdateById?: {
    __typename?: 'UiViewSubscription';
    createdAt?: string | null;
    createdBy?: string | null;
    entityId?: string | null;
    filtering?: string | null;
    id: string;
    index?: number | null;
    name?: string | null;
    pk1?: string | null;
    searchTerm?: string | null;
    sorting?: string | null;
    tableId?: string | null;
    updateToken?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    visibility?: string | null;
    expanded?: string | null;
    priority?: boolean | null;
    companyPriority?: boolean | null;
    tab?: string | null;
    showWinnersLosers?: boolean | null;
    calendarVisibility?: string | null;
  } | null;
};

export const ArticleSubscriptionFull = gql`
  fragment ArticleSubscriptionFull on ArticleSubscription {
    createdBy
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    sk1
    id
    requestOrigin
    type
    content
    name
    articlePostType
    attachments
    advices
    resolved
    commentId
    articleId
    urlStatus
    urlIndexedAt
    priority
    sk2
    state
    completedDate
    publicationDate
    dueDate
    projectId
    assigneeId
    focusKeyword
    urlPath
    title
    description
    wordCountPerArticle
    readingResources
    directReaderAddressing
    hasTopRankings
    competitorsUrls
    avoid
    inputTopics
    neuraverseTopicData {
      topicId
      articleDirection
      articleLengthSuggestion
      topicNames
    }
    imageGuidelines {
      allowGenAI
      moodAndTone
      orientation
      style
      customFields {
        key
        value
      }
    }
    versionId
    versionName
    versionDate
    versionCreatorId
    customFields {
      key
      value
    }
    exploreInputs {
      searchTerms
      contentCategory
      subcategory
      timeRange
      currentTab
      variety
      searchedOnce
      engineLanguage
    }
    rscore
    doneAdvices
    guidelines
    preferences
    userId
    advices
    articlePostType
    attachments
    imageGuidelines {
      allowGenAI
      moodAndTone
      orientation
      style
      customFields {
        key
        value
      }
    }
    audienceExpertiseId
    audiencePersonaId
    toneAndVoiceId
    companyKnowledgeIds
    labelIds
  }
`;
export const ArticleKeywordBase = gql`
  fragment ArticleKeywordBase on ArticleKeyword {
    articleId
    content
    required
    createdAt
    createdBy
    id
    type
    updateToken
    updatedAt
    updatedBy
  }
`;
export const ArticleKeywordExplore = gql`
  fragment ArticleKeywordExplore on ArticleKeyword {
    relevance
    volumes {
      amount
      month
      year
    }
  }
`;
export const ArticleKeywordBrief = gql`
  fragment ArticleKeywordBrief on ArticleKeyword {
    hidden
  }
`;
export const ArticleKeywordKeywordVariations = gql`
  fragment ArticleKeywordKeywordVariations on ArticleKeyword {
    keywordVariations {
      singularAndPlural
      verbsAndNouns
      verbsAndNounsSingularAndPlural
      closelyRelatedCompoundNouns
      separationsByHyphens
      abbreviationsOrAcronyms
      synonyms
      synonymsSingularAndPlural
      superlatives
      rootForms
      variationsUsingConnectingPrepositionOptions
    }
  }
`;
export const ArticleKeywordFull = gql`
  fragment ArticleKeywordFull on ArticleKeyword {
    ...ArticleKeywordBase
    ...ArticleKeywordExplore
    ...ArticleKeywordBrief
    ...ArticleKeywordKeywordVariations
  }
  ${ArticleKeywordBase}
  ${ArticleKeywordExplore}
  ${ArticleKeywordBrief}
  ${ArticleKeywordKeywordVariations}
`;
export const ArticleQuestionBase = gql`
  fragment ArticleQuestionBase on ArticleQuestion {
    articleId
    content
    createdAt
    createdBy
    id
    type
    updateToken
    updatedAt
    updatedBy
  }
`;
export const ArticleQuestionBrief = gql`
  fragment ArticleQuestionBrief on ArticleQuestion {
    answered
    hidden
  }
`;
export const ArticleQuestionFull = gql`
  fragment ArticleQuestionFull on ArticleQuestion {
    ...ArticleQuestionBase
    ...ArticleQuestionBrief
  }
  ${ArticleQuestionBase}
  ${ArticleQuestionBrief}
`;
export const ArticleBriefSubscriptionFull = gql`
  fragment ArticleBriefSubscriptionFull on ArticleBriefSubscription {
    answered
    articleId
    content
    createdAt
    createdBy
    description
    hidden
    id
    index
    level
    organizationId
    pk1
    relevance
    required
    sk1
    title
    type
    updateToken
    updatedAt
    updatedBy
    userId
    volumes {
      amount
      month
      year
    }
    keywords {
      ...ArticleKeywordFull
    }
    questions {
      ...ArticleQuestionFull
    }
  }
  ${ArticleKeywordFull}
  ${ArticleQuestionFull}
`;
export const ArticleHeadlineBase = gql`
  fragment ArticleHeadlineBase on ArticleHeadline {
    articleId
    createdAt
    createdBy
    id
    updateToken
    updatedAt
    updatedBy
  }
`;
export const ArticleHeadlineFull = gql`
  fragment ArticleHeadlineFull on ArticleHeadline {
    ...ArticleHeadlineBase
    description
    index
    level
    title
    type
    questions {
      ...ArticleQuestionFull
    }
    keywords {
      ...ArticleKeywordFull
    }
  }
  ${ArticleHeadlineBase}
  ${ArticleQuestionFull}
  ${ArticleKeywordFull}
`;
export const GoogleTopRankingFull = gql`
  fragment GoogleTopRankingFull on GoogleTopRanking {
    locale
    averageWordCount
    ttl
    searchResults {
      summary
      position
      title
      url
    }
    searchTerm
  }
`;
export const UserBase = gql`
  fragment UserBase on User {
    id
    updatedBy
    updatedAt
    updateToken
    pk1
    nickname
    locale
    lastName
    firstName
    createdAt
    color
    avatar
  }
`;
export const ArticleCommentFull = gql`
  fragment ArticleCommentFull on ArticleComment {
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    requestOrigin
    type
    content
    resolved
    commentId
    articleId
    commentCreator {
      ...UserBase
    }
  }
  ${UserBase}
`;
export const InvitationFull = gql`
  fragment InvitationFull on Invitation {
    id
    organization_id
    invitation_url
    created_at
    expires_at
    client_id
    connection_id
    app_metadata
    user_metadata
    roles
    ticket_id
    invitee {
      email
    }
  }
`;
export const NotificationFull = gql`
  fragment NotificationFull on Notification {
    createdAt
    data
    entityId
    id
    initiatedBy
    notificationId
    notificationStatus
    notificationType
    notificationAction
    pk1
    read
    sk1
    ttl
    updatedAt
  }
`;
export const NotificationSubscriptionFull = gql`
  fragment NotificationSubscriptionFull on NotificationSubscription {
    createdAt
    entityId
    data
    id
    initiatedBy
    notificationAction
    notificationId
    notificationStatus
    notificationType
    pk1
    read
    sk1
    ttl
    updatedAt
    userId
  }
`;
export const NVeCompanyBase = gql`
  fragment NVeCompanyBase on NVeCompany {
    id
    name
    priority
  }
`;
export const NVeCompanyDetails = gql`
  fragment NVeCompanyDetails on NVeCompany {
    articles
    articlesInTop20
    avgRanking
    traffic
    trafficShare
    searchTerms
  }
`;
export const NVeCompanyFull = gql`
  fragment NVeCompanyFull on NVeCompany {
    ...NVeCompanyBase
    ...NVeCompanyDetails
  }
  ${NVeCompanyBase}
  ${NVeCompanyDetails}
`;
export const ArticleMetadataBase = gql`
  fragment ArticleMetadataBase on ArticleMetadata {
    pk1
    sk1
    sk2
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    requestOrigin
    type
    name
    versionId
    projectId
  }
`;
export const ArticleMetadataAssignee = gql`
  fragment ArticleMetadataAssignee on ArticleMetadata {
    assignee {
      ...UserBase
    }
    assigneeId
  }
  ${UserBase}
`;
export const ArticleImageGuidelinesFull = gql`
  fragment ArticleImageGuidelinesFull on ArticleImageGuidelines {
    allowGenAI
    moodAndTone
    orientation
    style
    imageType
    customFields {
      key
      value
    }
  }
`;
export const ProjectBriefDocumentFull = gql`
  fragment ProjectBriefDocumentFull on ProjectBriefDocument {
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    type
    name
    content
    projectId
    selected
  }
`;
export const ArticleMetadataBrief = gql`
  fragment ArticleMetadataBrief on ArticleMetadata {
    wordCountPerArticle
    readingResources
    hasTopRankings
    customFields {
      key
      value
    }
    competitorsUrls
    avoid
    firstPersonPerspective
    directReaderAddressing
    imageGuidelines {
      ...ArticleImageGuidelinesFull
    }
    audienceExpertiseId
    audiencePersonaId
    toneAndVoiceId
    companyKnowledgeIds
    selectedProjectBriefDocuments {
      audienceExpertise {
        ...ProjectBriefDocumentFull
      }
      audiencePersona {
        ...ProjectBriefDocumentFull
      }
      companyKnowledges {
        ...ProjectBriefDocumentFull
      }
      toneAndVoice {
        ...ProjectBriefDocumentFull
      }
    }
  }
  ${ArticleImageGuidelinesFull}
  ${ProjectBriefDocumentFull}
`;
export const ArticleMetadataDetails = gql`
  fragment ArticleMetadataDetails on ArticleMetadata {
    state
    completedDate
    publicationDate
    dueDate
    focusKeyword
    urlPath
  }
`;
export const ArticleRScoreFull = gql`
  fragment ArticleRScoreFull on ArticleRScore {
    createdAt
    createdBy
    doneAdvices
    id
    pk1
    requestOrigin
    rscore
    sk1
    sk2
    updateToken
    type
    updatedAt
    updatedBy
    versionId
  }
`;
export const ArticleDocumentFull = gql`
  fragment ArticleDocumentFull on ArticleDocument {
    content
    createdAt
    createdBy
    id
    pk1
    requestOrigin
    sk1
    sk2
    type
    updateToken
    updatedAt
    updatedBy
  }
`;
export const ArticleMetadataEditor = gql`
  fragment ArticleMetadataEditor on ArticleMetadata {
    title
    description
    articleRScore {
      ...ArticleRScoreFull
    }
    articleDocument {
      ...ArticleDocumentFull
    }
  }
  ${ArticleRScoreFull}
  ${ArticleDocumentFull}
`;
export const ArticleMetadataExplore = gql`
  fragment ArticleMetadataExplore on ArticleMetadata {
    exploreInputs {
      searchTerms
      contentCategory
      subcategory
      timeRange
      currentTab
      variety
      searchedOnce
      engineLanguage
    }
    neuraverseTopicData {
      topicId
      topicNames
      articleDirection
      articleLengthSuggestion
    }
  }
`;
export const ArticleGuidelinesFull = gql`
  fragment ArticleGuidelinesFull on ArticleGuidelines {
    createdAt
    createdBy
    guidelines
    id
    pk1
    requestOrigin
    sk1
    sk2
    type
    updateToken
    updatedAt
    updatedBy
  }
`;
export const ArticleMetadataGuidelines = gql`
  fragment ArticleMetadataGuidelines on ArticleMetadata {
    articleGuidelines {
      ...ArticleGuidelinesFull
    }
  }
  ${ArticleGuidelinesFull}
`;
export const ProjectMetadataBase = gql`
  fragment ProjectMetadataBase on ProjectMetadata {
    createdBy
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    id
    name
    archived
    organizationId
    avatar
    color
    domain
    locale
  }
`;
export const ArticleMetadataProject = gql`
  fragment ArticleMetadataProject on ArticleMetadata {
    project {
      ...ProjectMetadataBase
    }
  }
  ${ProjectMetadataBase}
`;
export const ArticleMetadataVersion = gql`
  fragment ArticleMetadataVersion on ArticleMetadata {
    versionId
    versionName
    versionDate
    versionCreatorId
    versionCreator {
      ...UserBase
    }
  }
  ${UserBase}
`;
export const ArticleRootDataBase = gql`
  fragment ArticleRootDataBase on ArticleRootData {
    pk1
    sk1
    sk2
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    requestOrigin
    type
  }
`;
export const ArticleRootDataDetails = gql`
  fragment ArticleRootDataDetails on ArticleRootData {
    priority
    labelIds
  }
`;
export const ArticleRootDataUrlStatus = gql`
  fragment ArticleRootDataUrlStatus on ArticleRootData {
    ...ArticleRootDataBase
    urlStatus
  }
  ${ArticleRootDataBase}
`;
export const ArticleRootDataFull = gql`
  fragment ArticleRootDataFull on ArticleRootData {
    ...ArticleRootDataBase
    ...ArticleRootDataDetails
    ...ArticleRootDataUrlStatus
  }
  ${ArticleRootDataBase}
  ${ArticleRootDataDetails}
  ${ArticleRootDataUrlStatus}
`;
export const ArticleMetadataRootData = gql`
  fragment ArticleMetadataRootData on ArticleMetadata {
    articleRootData {
      ...ArticleRootDataFull
    }
  }
  ${ArticleRootDataFull}
`;
export const ProjectLabelFull = gql`
  fragment ProjectLabelFull on ProjectLabel {
    color
    id
    name
    custom
    projectId
  }
`;
export const ArticleMetadataProjectLabels = gql`
  fragment ArticleMetadataProjectLabels on ArticleMetadata {
    selectedProjectLabels {
      ...ProjectLabelFull
    }
  }
  ${ProjectLabelFull}
`;
export const ArticleMetadataFull = gql`
  fragment ArticleMetadataFull on ArticleMetadata {
    ...ArticleMetadataBase
    ...ArticleMetadataAssignee
    ...ArticleMetadataBrief
    ...ArticleMetadataDetails
    ...ArticleMetadataEditor
    ...ArticleMetadataExplore
    ...ArticleMetadataGuidelines
    ...ArticleMetadataProject
    ...ArticleMetadataVersion
    ...ArticleMetadataRootData
    ...ArticleMetadataProjectLabels
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataAssignee}
  ${ArticleMetadataBrief}
  ${ArticleMetadataDetails}
  ${ArticleMetadataEditor}
  ${ArticleMetadataExplore}
  ${ArticleMetadataGuidelines}
  ${ArticleMetadataProject}
  ${ArticleMetadataVersion}
  ${ArticleMetadataRootData}
  ${ArticleMetadataProjectLabels}
`;
export const NVeTopicArticleFull = gql`
  fragment NVeTopicArticleFull on NVeTopicArticle {
    id
    priority
    rScore
    type
    direction
    fullName
    articleMetadata {
      ...ArticleMetadataFull
    }
    webArticle {
      ranking
      title
      traffic
      url
      preScore
    }
  }
  ${ArticleMetadataFull}
`;
export const NVeTopicBase = gql`
  fragment NVeTopicBase on NVeTopic {
    id
    fullName
    updateToken
  }
`;
export const NVeTopicDetails = gql`
  fragment NVeTopicDetails on NVeTopic {
    priority
    isSaved
    childTopicCount
    relevance
    searchVolume
    difficulty
    searchTermsCount
    companyData {
      marketShare
      averageRanking
      articlesCount
      top20ArticlesCount
      traffic
      searchTermsCount
      name
    }
  }
`;
export const NVeTopicFull = gql`
  fragment NVeTopicFull on NVeTopic {
    ...NVeTopicBase
    ...NVeTopicDetails
  }
  ${NVeTopicBase}
  ${NVeTopicDetails}
`;
export const OrganizationBase = gql`
  fragment OrganizationBase on Organization {
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    id
    name
    auth0Id
    description
    avatar
  }
`;
export const OrganizationFull = gql`
  fragment OrganizationFull on Organization {
    ...OrganizationBase
  }
  ${OrganizationBase}
`;
export const OrganizationSubscriptionFull = gql`
  fragment OrganizationSubscriptionFull on OrganizationSubscription {
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    id
    name
    auth0Id
    description
    avatar
    filter
    userId
  }
`;
export const ArticlePostAdvicesFull = gql`
  fragment ArticlePostAdvicesFull on ArticlePostAdvices {
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    requestOrigin
    type
    advices
  }
`;
export const ArticlePostFull = gql`
  fragment ArticlePostFull on ArticlePost {
    id
    name
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    requestOrigin
    type
    content
    articlePostType
    attachments
    advices {
      ...ArticlePostAdvicesFull
    }
  }
  ${ArticlePostAdvicesFull}
`;
export const ProjectMetadataDetails = gql`
  fragment ProjectMetadataDetails on ProjectMetadata {
    description
    country
    topicInspirations
  }
`;
export const ProjectMetadataConnection = gql`
  fragment ProjectMetadataConnection on ProjectMetadata {
    gscConnectionId
    gscConnected
    wpConnected
    wpConnectionId
    linkedinConnected
    linkedinConnectionId
    linkedinOrgId
  }
`;
export const ProjectMetadataNeuraverse = gql`
  fragment ProjectMetadataNeuraverse on ProjectMetadata {
    neuraverseId
    neuraverseVersion
  }
`;
export const ProjectMetadataStatistics = gql`
  fragment ProjectMetadataStatistics on ProjectMetadata {
    articleStatistics {
      articleCountInProgress
      completedArticlesThisMonth
      articleCountByState {
        BRIEF
        DONE
        WRITE
        ONHOLD
        REVIEW
        TODO
      }
    }
  }
`;
export const ProjectBriefMetadataFull = gql`
  fragment ProjectBriefMetadataFull on ProjectBriefMetadata {
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    type
    startDate
    dueDate
    avoid
    firstPersonPerspective
    directReaderAddressing
    planedArticles
    planedArticlesPerMonth
    competitorsUrls
    customFields {
      key
      value
    }
    includedInArticleBrief
    inputTopics
  }
`;
export const ProjectImageGuidelinesFull = gql`
  fragment ProjectImageGuidelinesFull on ProjectImageGuidelines {
    id
    updateToken
    updatedBy
    createdBy
    createdAt
    updatedAt
    type
    allowGenAI
    moodAndTone
    orientation
    style
    imageType
    customFields {
      key
      value
    }
  }
`;
export const ProjectMetadataBrief = gql`
  fragment ProjectMetadataBrief on ProjectMetadata {
    brief {
      projectBriefMetadata {
        ...ProjectBriefMetadataFull
      }
      imageGuidelines {
        ...ProjectImageGuidelinesFull
      }
      audiencePersonas {
        ...ProjectBriefDocumentFull
      }
      toneAndVoices {
        ...ProjectBriefDocumentFull
      }
      companyKnowledges {
        ...ProjectBriefDocumentFull
      }
      audienceExpertises {
        ...ProjectBriefDocumentFull
      }
    }
  }
  ${ProjectBriefMetadataFull}
  ${ProjectImageGuidelinesFull}
  ${ProjectBriefDocumentFull}
`;
export const ProjectMetadataLabels = gql`
  fragment ProjectMetadataLabels on ProjectMetadata {
    labels {
      ...ProjectLabelFull
    }
  }
  ${ProjectLabelFull}
`;
export const ProjectMetadataFull = gql`
  fragment ProjectMetadataFull on ProjectMetadata {
    ...ProjectMetadataBase
    ...ProjectMetadataDetails
    ...ProjectMetadataConnection
    ...ProjectMetadataNeuraverse
    ...ProjectMetadataStatistics
    ...ProjectMetadataBrief
    ...ProjectMetadataLabels
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataDetails}
  ${ProjectMetadataConnection}
  ${ProjectMetadataNeuraverse}
  ${ProjectMetadataStatistics}
  ${ProjectMetadataBrief}
  ${ProjectMetadataLabels}
`;
export const ProjectSubscriptionFull = gql`
  fragment ProjectSubscriptionFull on ProjectSubscription {
    createdBy
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    sk1
    id
    type
    name
    description
    domain
    avatar
    locale
    country
    color
    gscConnected
    wpConnected
    linkedinConnected
    archived
    organizationId
    ttl
    neuraverseId
    topicInspirations
    gscConnectionId
    wpConnectionId
    linkedinConnectionId
    linkedinOrgId
    content
    projectId
    selected
    startDate
    dueDate
    avoid
    planedArticles
    planedArticlesPerMonth
    competitorsUrls
    customFields {
      key
      value
    }
    includedInArticleBrief
    inputTopics
    allowGenAI
    moodAndTone
    orientation
    style
    imageType
    filter
    firstPersonPerspective
    directReaderAddressing
    userId
    custom
  }
`;
export const UserNotificationSettings = gql`
  fragment UserNotificationSettings on User {
    notificationSettings {
      article {
        email
        inapp
        notifyAll
        notifyIfCreator
        notifyIfAssignee
      }
      project {
        inapp
        email
      }
    }
  }
`;
export const UserDetails = gql`
  fragment UserDetails on User {
    email
    lastReadNewsFeedPubDate
  }
`;
export const UserOrganization = gql`
  fragment UserOrganization on User {
    userOrgData {
      auth0 {
        id
        role
      }
      lastAccessedProjectId
      organizationId
      daysBeforeDueDateWarning
      updateToken
    }
  }
`;
export const UserFull = gql`
  fragment UserFull on User {
    ...UserBase
    ...UserNotificationSettings
    ...UserDetails
    ...UserOrganization
  }
  ${UserBase}
  ${UserNotificationSettings}
  ${UserDetails}
  ${UserOrganization}
`;
export const ProjectMemberFull = gql`
  fragment ProjectMemberFull on ProjectMember {
    id
    user {
      ...UserFull
    }
    aclRoles {
      name
      roleId
    }
  }
  ${UserFull}
`;
export const UserArticleDataFragment = gql`
  fragment UserArticleDataFragment on UserArticleData {
    id
    preferences
    __typename
  }
`;
export const UserProjectDataFragment = gql`
  fragment UserProjectDataFragment on UserProjectData {
    id
    hiddenTiles
    __typename
  }
`;
export const UserSubscriptionFull = gql`
  fragment UserSubscriptionFull on UserSubscription {
    updatedBy
    updateToken
    createdAt
    updatedAt
    pk1
    id
    nickname
    firstName
    lastName
    avatar
    color
    email
    organizationId
    userId
    locale
    lastReadNewsFeedPubDate
    notificationSettings {
      article {
        inapp
        email
        notifyIfAssignee
        notifyIfCreator
        notifyAll
        __typename
      }
      project {
        inapp
        email
        __typename
      }
      __typename
    }
    userOrgData {
      auth0 {
        role
        id
        __typename
      }
      organizationId
      updateToken
      lastAccessedProjectId
      daysBeforeDueDateWarning
      __typename
    }
    userId
    organizationId
    __typename
  }
`;
export const UiViewFull = gql`
  fragment UiViewFull on UiView {
    createdAt
    createdBy
    entityId
    filtering
    id
    index
    name
    pk1
    searchTerm
    sorting
    tableId
    visibility
    expanded
    priority
    companyPriority
    tab
    updatedBy
    updatedAt
    updateToken
    showWinnersLosers
    calendarVisibility
  }
`;
export const UiViewSubscriptionFull = gql`
  fragment UiViewSubscriptionFull on UiViewSubscription {
    createdAt
    createdBy
    entityId
    filtering
    id
    index
    name
    pk1
    searchTerm
    sorting
    tableId
    updateToken
    updatedAt
    updatedBy
    visibility
    expanded
    priority
    companyPriority
    tab
    showWinnersLosers
    calendarVisibility
  }
`;
export const LogAnalyticsDocument = gql`
  mutation LogAnalytics($input: CreateAnalyticsLogInput!) {
    createAnalyticsLog(input: $input)
  }
`;
export type LogAnalyticsMutationFn = Apollo.MutationFunction<
  LogAnalytics,
  LogAnalyticsVariables
>;

/**
 * __useLogAnalytics__
 *
 * To run a mutation, you first call `useLogAnalytics` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogAnalytics` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logAnalytics, { data, loading, error }] = useLogAnalytics({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogAnalytics(
  baseOptions?: Apollo.MutationHookOptions<LogAnalytics, LogAnalyticsVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogAnalytics, LogAnalyticsVariables>(
    LogAnalyticsDocument,
    options
  );
}
export type LogAnalyticsHookResult = ReturnType<typeof useLogAnalytics>;
export type LogAnalyticsMutationResult = Apollo.MutationResult<LogAnalytics>;
export type LogAnalyticsMutationOptions = Apollo.BaseMutationOptions<
  LogAnalytics,
  LogAnalyticsVariables
>;
export const DeleteApiConnectionDocument = gql`
  mutation DeleteApiConnection($input: DeleteApiConnectionInput!) {
    deleteApiConnection(input: $input) {
      ...ProjectMetadataFull
    }
  }
  ${ProjectMetadataFull}
`;
export type DeleteApiConnectionMutationFn = Apollo.MutationFunction<
  DeleteApiConnection,
  DeleteApiConnectionVariables
>;

/**
 * __useDeleteApiConnection__
 *
 * To run a mutation, you first call `useDeleteApiConnection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiConnection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiConnection, { data, loading, error }] = useDeleteApiConnection({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApiConnection(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiConnection,
    DeleteApiConnectionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteApiConnection, DeleteApiConnectionVariables>(
    DeleteApiConnectionDocument,
    options
  );
}
export type DeleteApiConnectionHookResult = ReturnType<
  typeof useDeleteApiConnection
>;
export type DeleteApiConnectionMutationResult =
  Apollo.MutationResult<DeleteApiConnection>;
export type DeleteApiConnectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiConnection,
  DeleteApiConnectionVariables
>;
export const GetApiConnectionDocument = gql`
  query GetApiConnection($id: ID!) {
    getApiConnection(id: $id) {
      createdBy
      updatedBy
      updateToken
      createdAt
      updatedAt
      pk1
      id
      connectionData
      serviceName
      organizationId
    }
  }
`;

/**
 * __useGetApiConnection__
 *
 * To run a query within a React component, call `useGetApiConnection` and pass it any options that fit your needs.
 * When your component renders, `useGetApiConnection` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiConnection({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApiConnection(
  baseOptions: Apollo.QueryHookOptions<
    GetApiConnection,
    GetApiConnectionVariables
  > &
    (
      | { variables: GetApiConnectionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetApiConnection, GetApiConnectionVariables>(
    GetApiConnectionDocument,
    options
  );
}
export function useGetApiConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiConnection,
    GetApiConnectionVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetApiConnection, GetApiConnectionVariables>(
    GetApiConnectionDocument,
    options
  );
}
export function useGetApiConnectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApiConnection,
    GetApiConnectionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetApiConnection, GetApiConnectionVariables>(
    GetApiConnectionDocument,
    options
  );
}
export type GetApiConnectionHookResult = ReturnType<typeof useGetApiConnection>;
export type GetApiConnectionLazyQueryHookResult = ReturnType<
  typeof useGetApiConnectionLazyQuery
>;
export type GetApiConnectionSuspenseQueryHookResult = ReturnType<
  typeof useGetApiConnectionSuspenseQuery
>;
export type GetApiConnectionQueryResult = Apollo.QueryResult<
  GetApiConnection,
  GetApiConnectionVariables
>;
export const CreateArticleVersionDocument = gql`
  mutation CreateArticleVersion($input: CreateVersionInput!) {
    createArticleVersion(input: $input) {
      ...ArticleMetadataFull
    }
  }
  ${ArticleMetadataFull}
`;
export type CreateArticleVersionMutationFn = Apollo.MutationFunction<
  CreateArticleVersion,
  CreateArticleVersionVariables
>;

/**
 * __useCreateArticleVersion__
 *
 * To run a mutation, you first call `useCreateArticleVersion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleVersion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleVersion, { data, loading, error }] = useCreateArticleVersion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleVersion(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleVersion,
    CreateArticleVersionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleVersion,
    CreateArticleVersionVariables
  >(CreateArticleVersionDocument, options);
}
export type CreateArticleVersionHookResult = ReturnType<
  typeof useCreateArticleVersion
>;
export type CreateArticleVersionMutationResult =
  Apollo.MutationResult<CreateArticleVersion>;
export type CreateArticleVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleVersion,
  CreateArticleVersionVariables
>;
export const DeleteArticleVersionDocument = gql`
  mutation DeleteArticleVersion($input: DeleteVersionInput!) {
    deleteArticleVersion(input: $input) {
      id
    }
  }
`;
export type DeleteArticleVersionMutationFn = Apollo.MutationFunction<
  DeleteArticleVersion,
  DeleteArticleVersionVariables
>;

/**
 * __useDeleteArticleVersion__
 *
 * To run a mutation, you first call `useDeleteArticleVersion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleVersion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleVersion, { data, loading, error }] = useDeleteArticleVersion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleVersion(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleVersion,
    DeleteArticleVersionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleVersion,
    DeleteArticleVersionVariables
  >(DeleteArticleVersionDocument, options);
}
export type DeleteArticleVersionHookResult = ReturnType<
  typeof useDeleteArticleVersion
>;
export type DeleteArticleVersionMutationResult =
  Apollo.MutationResult<DeleteArticleVersion>;
export type DeleteArticleVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleVersion,
  DeleteArticleVersionVariables
>;
export const RestoreArticleVersionDocument = gql`
  mutation RestoreArticleVersion($input: RestoreVersionInput!) {
    restoreArticleVersion(input: $input) {
      ...ArticleMetadataFull
    }
  }
  ${ArticleMetadataFull}
`;
export type RestoreArticleVersionMutationFn = Apollo.MutationFunction<
  RestoreArticleVersion,
  RestoreArticleVersionVariables
>;

/**
 * __useRestoreArticleVersion__
 *
 * To run a mutation, you first call `useRestoreArticleVersion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreArticleVersion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreArticleVersion, { data, loading, error }] = useRestoreArticleVersion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreArticleVersion(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreArticleVersion,
    RestoreArticleVersionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreArticleVersion,
    RestoreArticleVersionVariables
  >(RestoreArticleVersionDocument, options);
}
export type RestoreArticleVersionHookResult = ReturnType<
  typeof useRestoreArticleVersion
>;
export type RestoreArticleVersionMutationResult =
  Apollo.MutationResult<RestoreArticleVersion>;
export type RestoreArticleVersionMutationOptions = Apollo.BaseMutationOptions<
  RestoreArticleVersion,
  RestoreArticleVersionVariables
>;
export const CreateArticleDocument = gql`
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      ...ArticleMetadataFull
    }
  }
  ${ArticleMetadataFull}
`;
export type CreateArticleMutationFn = Apollo.MutationFunction<
  CreateArticle,
  CreateArticleVariables
>;

/**
 * __useCreateArticle__
 *
 * To run a mutation, you first call `useCreateArticle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticle, { data, loading, error }] = useCreateArticle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticle(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticle,
    CreateArticleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateArticle, CreateArticleVariables>(
    CreateArticleDocument,
    options
  );
}
export type CreateArticleHookResult = ReturnType<typeof useCreateArticle>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticle>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticle,
  CreateArticleVariables
>;
export const CreateArticleByNeuraverseDocument = gql`
  mutation CreateArticleByNeuraverse($input: CreateArticleByNeuraverseInput!) {
    createArticleByNeuraverse(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type CreateArticleByNeuraverseMutationFn = Apollo.MutationFunction<
  CreateArticleByNeuraverse,
  CreateArticleByNeuraverseVariables
>;

/**
 * __useCreateArticleByNeuraverse__
 *
 * To run a mutation, you first call `useCreateArticleByNeuraverse` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleByNeuraverse` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleByNeuraverse, { data, loading, error }] = useCreateArticleByNeuraverse({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleByNeuraverse(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleByNeuraverse,
    CreateArticleByNeuraverseVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleByNeuraverse,
    CreateArticleByNeuraverseVariables
  >(CreateArticleByNeuraverseDocument, options);
}
export type CreateArticleByNeuraverseHookResult = ReturnType<
  typeof useCreateArticleByNeuraverse
>;
export type CreateArticleByNeuraverseMutationResult =
  Apollo.MutationResult<CreateArticleByNeuraverse>;
export type CreateArticleByNeuraverseMutationOptions =
  Apollo.BaseMutationOptions<
    CreateArticleByNeuraverse,
    CreateArticleByNeuraverseVariables
  >;
export const UpdateArticleDocumentDocument = gql`
  mutation UpdateArticleDocument($input: UpdateArticleDocumentInput!) {
    updateArticleDocument(input: $input) {
      ...ArticleDocumentFull
    }
  }
  ${ArticleDocumentFull}
`;
export type UpdateArticleDocumentMutationFn = Apollo.MutationFunction<
  UpdateArticleDocument,
  UpdateArticleDocumentVariables
>;

/**
 * __useUpdateArticleDocument__
 *
 * To run a mutation, you first call `useUpdateArticleDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleDocument, { data, loading, error }] = useUpdateArticleDocument({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleDocument(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleDocument,
    UpdateArticleDocumentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleDocument,
    UpdateArticleDocumentVariables
  >(UpdateArticleDocumentDocument, options);
}
export type UpdateArticleDocumentHookResult = ReturnType<
  typeof useUpdateArticleDocument
>;
export type UpdateArticleDocumentMutationResult =
  Apollo.MutationResult<UpdateArticleDocument>;
export type UpdateArticleDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleDocument,
  UpdateArticleDocumentVariables
>;
export const UpdateArticleGuidelinesDocument = gql`
  mutation UpdateArticleGuidelines($input: UpdateArticleGuidelinesInput!) {
    updateArticleGuidelines(input: $input) {
      ...ArticleGuidelinesFull
    }
  }
  ${ArticleGuidelinesFull}
`;
export type UpdateArticleGuidelinesMutationFn = Apollo.MutationFunction<
  UpdateArticleGuidelines,
  UpdateArticleGuidelinesVariables
>;

/**
 * __useUpdateArticleGuidelines__
 *
 * To run a mutation, you first call `useUpdateArticleGuidelines` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleGuidelines` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleGuidelines, { data, loading, error }] = useUpdateArticleGuidelines({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleGuidelines(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleGuidelines,
    UpdateArticleGuidelinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleGuidelines,
    UpdateArticleGuidelinesVariables
  >(UpdateArticleGuidelinesDocument, options);
}
export type UpdateArticleGuidelinesHookResult = ReturnType<
  typeof useUpdateArticleGuidelines
>;
export type UpdateArticleGuidelinesMutationResult =
  Apollo.MutationResult<UpdateArticleGuidelines>;
export type UpdateArticleGuidelinesMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleGuidelines,
  UpdateArticleGuidelinesVariables
>;
export const UpdateArticleMetadataDocument = gql`
  mutation UpdateArticleMetadata($input: UpdateArticleMetadataInput!) {
    updateArticleMetadata(input: $input) {
      ...ArticleMetadataFull
    }
  }
  ${ArticleMetadataFull}
`;
export type UpdateArticleMetadataMutationFn = Apollo.MutationFunction<
  UpdateArticleMetadata,
  UpdateArticleMetadataVariables
>;

/**
 * __useUpdateArticleMetadata__
 *
 * To run a mutation, you first call `useUpdateArticleMetadata` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMetadata` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMetadata, { data, loading, error }] = useUpdateArticleMetadata({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleMetadata(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleMetadata,
    UpdateArticleMetadataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleMetadata,
    UpdateArticleMetadataVariables
  >(UpdateArticleMetadataDocument, options);
}
export type UpdateArticleMetadataHookResult = ReturnType<
  typeof useUpdateArticleMetadata
>;
export type UpdateArticleMetadataMutationResult =
  Apollo.MutationResult<UpdateArticleMetadata>;
export type UpdateArticleMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleMetadata,
  UpdateArticleMetadataVariables
>;
export const UpdateArticleRootDataDocument = gql`
  mutation UpdateArticleRootData($input: UpdateArticleRootDataInput!) {
    updateArticleRootData(input: $input) {
      ...ArticleRootDataFull
    }
  }
  ${ArticleRootDataFull}
`;
export type UpdateArticleRootDataMutationFn = Apollo.MutationFunction<
  UpdateArticleRootData,
  UpdateArticleRootDataVariables
>;

/**
 * __useUpdateArticleRootData__
 *
 * To run a mutation, you first call `useUpdateArticleRootData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleRootData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleRootData, { data, loading, error }] = useUpdateArticleRootData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleRootData(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleRootData,
    UpdateArticleRootDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleRootData,
    UpdateArticleRootDataVariables
  >(UpdateArticleRootDataDocument, options);
}
export type UpdateArticleRootDataHookResult = ReturnType<
  typeof useUpdateArticleRootData
>;
export type UpdateArticleRootDataMutationResult =
  Apollo.MutationResult<UpdateArticleRootData>;
export type UpdateArticleRootDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleRootData,
  UpdateArticleRootDataVariables
>;
export const DeleteArticleDocument = gql`
  mutation DeleteArticle($input: DeleteArticleInput!) {
    deleteArticle(input: $input) {
      id
    }
  }
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<
  DeleteArticle,
  DeleteArticleVariables
>;

/**
 * __useDeleteArticle__
 *
 * To run a mutation, you first call `useDeleteArticle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticle, { data, loading, error }] = useDeleteArticle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticle(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticle,
    DeleteArticleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteArticle, DeleteArticleVariables>(
    DeleteArticleDocument,
    options
  );
}
export type DeleteArticleHookResult = ReturnType<typeof useDeleteArticle>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticle>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticle,
  DeleteArticleVariables
>;
export const GenerateArticleByGptDocument = gql`
  mutation GenerateArticleByGPT($input: ArticleByGPTInput!) {
    generateArticleByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type GenerateArticleByGptMutationFn = Apollo.MutationFunction<
  GenerateArticleByGpt,
  GenerateArticleByGptVariables
>;

/**
 * __useGenerateArticleByGpt__
 *
 * To run a mutation, you first call `useGenerateArticleByGpt` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateArticleByGpt` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateArticleByGpt, { data, loading, error }] = useGenerateArticleByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateArticleByGpt(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateArticleByGpt,
    GenerateArticleByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateArticleByGpt,
    GenerateArticleByGptVariables
  >(GenerateArticleByGptDocument, options);
}
export type GenerateArticleByGptHookResult = ReturnType<
  typeof useGenerateArticleByGpt
>;
export type GenerateArticleByGptMutationResult =
  Apollo.MutationResult<GenerateArticleByGpt>;
export type GenerateArticleByGptMutationOptions = Apollo.BaseMutationOptions<
  GenerateArticleByGpt,
  GenerateArticleByGptVariables
>;
export const GenerateContentByGptDocument = gql`
  mutation GenerateContentByGPT($input: ContentByGPTInput!) {
    generateContentByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type GenerateContentByGptMutationFn = Apollo.MutationFunction<
  GenerateContentByGpt,
  GenerateContentByGptVariables
>;

/**
 * __useGenerateContentByGpt__
 *
 * To run a mutation, you first call `useGenerateContentByGpt` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContentByGpt` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContentByGpt, { data, loading, error }] = useGenerateContentByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateContentByGpt(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateContentByGpt,
    GenerateContentByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateContentByGpt,
    GenerateContentByGptVariables
  >(GenerateContentByGptDocument, options);
}
export type GenerateContentByGptHookResult = ReturnType<
  typeof useGenerateContentByGpt
>;
export type GenerateContentByGptMutationResult =
  Apollo.MutationResult<GenerateContentByGpt>;
export type GenerateContentByGptMutationOptions = Apollo.BaseMutationOptions<
  GenerateContentByGpt,
  GenerateContentByGptVariables
>;
export const ResetChannelDocument = gql`
  mutation ResetChannel($input: ResetChannelHandlerInput!) {
    resetChannel(input: $input)
  }
`;
export type ResetChannelMutationFn = Apollo.MutationFunction<
  ResetChannel,
  ResetChannelVariables
>;

/**
 * __useResetChannel__
 *
 * To run a mutation, you first call `useResetChannel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChannel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChannel, { data, loading, error }] = useResetChannel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetChannel(
  baseOptions?: Apollo.MutationHookOptions<ResetChannel, ResetChannelVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetChannel, ResetChannelVariables>(
    ResetChannelDocument,
    options
  );
}
export type ResetChannelHookResult = ReturnType<typeof useResetChannel>;
export type ResetChannelMutationResult = Apollo.MutationResult<ResetChannel>;
export type ResetChannelMutationOptions = Apollo.BaseMutationOptions<
  ResetChannel,
  ResetChannelVariables
>;
export const ListArticlesForCountDocument = gql`
  query ListArticlesForCount(
    $filter: ListArticlesInputFilter
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $id: ModelStringKeyConditionInput
  ) {
    listArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      id: $id
    ) {
      items {
        id
        versionId
      }
      nextToken
    }
  }
`;

/**
 * __useListArticlesForCount__
 *
 * To run a query within a React component, call `useListArticlesForCount` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForCount({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticlesForCount(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticlesForCount,
    ListArticlesForCountVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticlesForCount, ListArticlesForCountVariables>(
    ListArticlesForCountDocument,
    options
  );
}
export function useListArticlesForCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesForCount,
    ListArticlesForCountVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesForCount,
    ListArticlesForCountVariables
  >(ListArticlesForCountDocument, options);
}
export function useListArticlesForCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesForCount,
    ListArticlesForCountVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesForCount,
    ListArticlesForCountVariables
  >(ListArticlesForCountDocument, options);
}
export type ListArticlesForCountHookResult = ReturnType<
  typeof useListArticlesForCount
>;
export type ListArticlesForCountLazyQueryHookResult = ReturnType<
  typeof useListArticlesForCountLazyQuery
>;
export type ListArticlesForCountSuspenseQueryHookResult = ReturnType<
  typeof useListArticlesForCountSuspenseQuery
>;
export type ListArticlesForCountQueryResult = Apollo.QueryResult<
  ListArticlesForCount,
  ListArticlesForCountVariables
>;
export const GetArticleForArticleMonitoringDocument = gql`
  query GetArticleForArticleMonitoring($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      publicationDate
      urlPath
      articleRootData {
        ...ArticleRootDataUrlStatus
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleRootDataUrlStatus}
`;

/**
 * __useGetArticleForArticleMonitoring__
 *
 * To run a query within a React component, call `useGetArticleForArticleMonitoring` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleForArticleMonitoring` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleForArticleMonitoring({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleForArticleMonitoring(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  > &
    (
      | { variables: GetArticleForArticleMonitoringVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  >(GetArticleForArticleMonitoringDocument, options);
}
export function useGetArticleForArticleMonitoringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  >(GetArticleForArticleMonitoringDocument, options);
}
export function useGetArticleForArticleMonitoringSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleForArticleMonitoring,
    GetArticleForArticleMonitoringVariables
  >(GetArticleForArticleMonitoringDocument, options);
}
export type GetArticleForArticleMonitoringHookResult = ReturnType<
  typeof useGetArticleForArticleMonitoring
>;
export type GetArticleForArticleMonitoringLazyQueryHookResult = ReturnType<
  typeof useGetArticleForArticleMonitoringLazyQuery
>;
export type GetArticleForArticleMonitoringSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleForArticleMonitoringSuspenseQuery
>;
export type GetArticleForArticleMonitoringQueryResult = Apollo.QueryResult<
  GetArticleForArticleMonitoring,
  GetArticleForArticleMonitoringVariables
>;
export const ListArticlesForOverviewDocument = gql`
  query ListArticlesForOverview(
    $filter: ListArticlesInputFilter
    $limit: Int
    $nextToken: String
    $id: ModelStringKeyConditionInput = {}
  ) {
    listArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      id: $id
    ) {
      items {
        ...ArticleMetadataBase
        completedDate
        state
        urlPath
        publicationDate
        versionId
        articleRootData {
          ...ArticleRootDataFull
        }
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
  ${ArticleRootDataFull}
`;

/**
 * __useListArticlesForOverview__
 *
 * To run a query within a React component, call `useListArticlesForOverview` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForOverview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForOverview({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticlesForOverview(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >(ListArticlesForOverviewDocument, options);
}
export function useListArticlesForOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >(ListArticlesForOverviewDocument, options);
}
export function useListArticlesForOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesForOverview,
    ListArticlesForOverviewVariables
  >(ListArticlesForOverviewDocument, options);
}
export type ListArticlesForOverviewHookResult = ReturnType<
  typeof useListArticlesForOverview
>;
export type ListArticlesForOverviewLazyQueryHookResult = ReturnType<
  typeof useListArticlesForOverviewLazyQuery
>;
export type ListArticlesForOverviewSuspenseQueryHookResult = ReturnType<
  typeof useListArticlesForOverviewSuspenseQuery
>;
export type ListArticlesForOverviewQueryResult = Apollo.QueryResult<
  ListArticlesForOverview,
  ListArticlesForOverviewVariables
>;
export const ListArticlesForPlanPageDocument = gql`
  query ListArticlesForPlanPage(
    $filter: ListArticlesInputFilter
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $id: ModelStringKeyConditionInput
  ) {
    listArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      id: $id
    ) {
      items {
        ...ArticleMetadataBase
        publicationDate
        dueDate
        state
        articleRootData {
          ...ArticleRootDataFull
        }
        ...ArticleMetadataProjectLabels
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
  ${ArticleRootDataFull}
  ${ArticleMetadataProjectLabels}
`;

/**
 * __useListArticlesForPlanPage__
 *
 * To run a query within a React component, call `useListArticlesForPlanPage` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForPlanPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForPlanPage({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sortDirection: // value for 'sortDirection'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticlesForPlanPage(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >(ListArticlesForPlanPageDocument, options);
}
export function useListArticlesForPlanPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >(ListArticlesForPlanPageDocument, options);
}
export function useListArticlesForPlanPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesForPlanPage,
    ListArticlesForPlanPageVariables
  >(ListArticlesForPlanPageDocument, options);
}
export type ListArticlesForPlanPageHookResult = ReturnType<
  typeof useListArticlesForPlanPage
>;
export type ListArticlesForPlanPageLazyQueryHookResult = ReturnType<
  typeof useListArticlesForPlanPageLazyQuery
>;
export type ListArticlesForPlanPageSuspenseQueryHookResult = ReturnType<
  typeof useListArticlesForPlanPageSuspenseQuery
>;
export type ListArticlesForPlanPageQueryResult = Apollo.QueryResult<
  ListArticlesForPlanPage,
  ListArticlesForPlanPageVariables
>;
export const ListArticleVersionsDocument = gql`
  query ListArticleVersions($input: ListVersionsInput!) {
    listVersions(input: $input) {
      items {
        ...ArticleMetadataBase
        ...ArticleMetadataVersion
        urlPath
        publicationDate
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataVersion}
`;

/**
 * __useListArticleVersions__
 *
 * To run a query within a React component, call `useListArticleVersions` and pass it any options that fit your needs.
 * When your component renders, `useListArticleVersions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleVersions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListArticleVersions(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleVersions,
    ListArticleVersionsVariables
  > &
    (
      | { variables: ListArticleVersionsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleVersions, ListArticleVersionsVariables>(
    ListArticleVersionsDocument,
    options
  );
}
export function useListArticleVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleVersions,
    ListArticleVersionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListArticleVersions, ListArticleVersionsVariables>(
    ListArticleVersionsDocument,
    options
  );
}
export function useListArticleVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleVersions,
    ListArticleVersionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleVersions,
    ListArticleVersionsVariables
  >(ListArticleVersionsDocument, options);
}
export type ListArticleVersionsHookResult = ReturnType<
  typeof useListArticleVersions
>;
export type ListArticleVersionsLazyQueryHookResult = ReturnType<
  typeof useListArticleVersionsLazyQuery
>;
export type ListArticleVersionsSuspenseQueryHookResult = ReturnType<
  typeof useListArticleVersionsSuspenseQuery
>;
export type ListArticleVersionsQueryResult = Apollo.QueryResult<
  ListArticleVersions,
  ListArticleVersionsVariables
>;
export const ListArticlesForArticlesPageDocument = gql`
  query ListArticlesForArticlesPage(
    $filter: ListArticlesInputFilter
    $limit: Int
    $nextToken: String
    $id: ModelStringKeyConditionInput
  ) {
    listArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      id: $id
    ) {
      items {
        ...ArticleMetadataBase
        ...ArticleMetadataAssignee
        ...ArticleMetadataDetails
        ...ArticleMetadataProject
        ...ArticleMetadataProjectLabels
        neuraverseTopicData {
          topicId
          topicNames
        }
        articleRootData {
          ...ArticleRootDataFull
        }
        articleRScore {
          ...ArticleRScoreFull
        }
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataAssignee}
  ${ArticleMetadataDetails}
  ${ArticleMetadataProject}
  ${ArticleMetadataProjectLabels}
  ${ArticleRootDataFull}
  ${ArticleRScoreFull}
`;

/**
 * __useListArticlesForArticlesPage__
 *
 * To run a query within a React component, call `useListArticlesForArticlesPage` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForArticlesPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForArticlesPage({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticlesForArticlesPage(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >(ListArticlesForArticlesPageDocument, options);
}
export function useListArticlesForArticlesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >(ListArticlesForArticlesPageDocument, options);
}
export function useListArticlesForArticlesPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesForArticlesPage,
    ListArticlesForArticlesPageVariables
  >(ListArticlesForArticlesPageDocument, options);
}
export type ListArticlesForArticlesPageHookResult = ReturnType<
  typeof useListArticlesForArticlesPage
>;
export type ListArticlesForArticlesPageLazyQueryHookResult = ReturnType<
  typeof useListArticlesForArticlesPageLazyQuery
>;
export type ListArticlesForArticlesPageSuspenseQueryHookResult = ReturnType<
  typeof useListArticlesForArticlesPageSuspenseQuery
>;
export type ListArticlesForArticlesPageQueryResult = Apollo.QueryResult<
  ListArticlesForArticlesPage,
  ListArticlesForArticlesPageVariables
>;
export const ListArticleBaseDocument = gql`
  query ListArticleBase(
    $filter: ListArticlesInputFilter
    $limit: Int
    $nextToken: String
    $id: ModelStringKeyConditionInput
  ) {
    listArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      id: $id
    ) {
      items {
        ...ArticleMetadataBase
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useListArticleBase__
 *
 * To run a query within a React component, call `useListArticleBase` and pass it any options that fit your needs.
 * When your component renders, `useListArticleBase` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleBase({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticleBase(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticleBase,
    ListArticleBaseVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleBase, ListArticleBaseVariables>(
    ListArticleBaseDocument,
    options
  );
}
export function useListArticleBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleBase,
    ListArticleBaseVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListArticleBase, ListArticleBaseVariables>(
    ListArticleBaseDocument,
    options
  );
}
export function useListArticleBaseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleBase,
    ListArticleBaseVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListArticleBase, ListArticleBaseVariables>(
    ListArticleBaseDocument,
    options
  );
}
export type ListArticleBaseHookResult = ReturnType<typeof useListArticleBase>;
export type ListArticleBaseLazyQueryHookResult = ReturnType<
  typeof useListArticleBaseLazyQuery
>;
export type ListArticleBaseSuspenseQueryHookResult = ReturnType<
  typeof useListArticleBaseSuspenseQuery
>;
export type ListArticleBaseQueryResult = Apollo.QueryResult<
  ListArticleBase,
  ListArticleBaseVariables
>;
export const ListArticlesWithSameFocusKeywordForBriefDocument = gql`
  query ListArticlesWithSameFocusKeywordForBrief(
    $input: ListArticlesWithSameFocusKeywordInput!
  ) {
    listArticlesWithSameFocusKeyword(input: $input) {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useListArticlesWithSameFocusKeywordForBrief__
 *
 * To run a query within a React component, call `useListArticlesWithSameFocusKeywordForBrief` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesWithSameFocusKeywordForBrief` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesWithSameFocusKeywordForBrief({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListArticlesWithSameFocusKeywordForBrief(
  baseOptions: Apollo.QueryHookOptions<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  > &
    (
      | {
          variables: ListArticlesWithSameFocusKeywordForBriefVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >(ListArticlesWithSameFocusKeywordForBriefDocument, options);
}
export function useListArticlesWithSameFocusKeywordForBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >(ListArticlesWithSameFocusKeywordForBriefDocument, options);
}
export function useListArticlesWithSameFocusKeywordForBriefSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >(ListArticlesWithSameFocusKeywordForBriefDocument, options);
}
export type ListArticlesWithSameFocusKeywordForBriefHookResult = ReturnType<
  typeof useListArticlesWithSameFocusKeywordForBrief
>;
export type ListArticlesWithSameFocusKeywordForBriefLazyQueryHookResult =
  ReturnType<typeof useListArticlesWithSameFocusKeywordForBriefLazyQuery>;
export type ListArticlesWithSameFocusKeywordForBriefSuspenseQueryHookResult =
  ReturnType<typeof useListArticlesWithSameFocusKeywordForBriefSuspenseQuery>;
export type ListArticlesWithSameFocusKeywordForBriefQueryResult =
  Apollo.QueryResult<
    ListArticlesWithSameFocusKeywordForBrief,
    ListArticlesWithSameFocusKeywordForBriefVariables
  >;
export const GetArticleFullDocument = gql`
  query GetArticleFull($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataFull
    }
  }
  ${ArticleMetadataFull}
`;

/**
 * __useGetArticleFull__
 *
 * To run a query within a React component, call `useGetArticleFull` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleFull` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleFull({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleFull(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleFull,
    GetArticleFullVariables
  > &
    ({ variables: GetArticleFullVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleFull, GetArticleFullVariables>(
    GetArticleFullDocument,
    options
  );
}
export function useGetArticleFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleFull,
    GetArticleFullVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleFull, GetArticleFullVariables>(
    GetArticleFullDocument,
    options
  );
}
export function useGetArticleFullSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleFull,
    GetArticleFullVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleFull, GetArticleFullVariables>(
    GetArticleFullDocument,
    options
  );
}
export type GetArticleFullHookResult = ReturnType<typeof useGetArticleFull>;
export type GetArticleFullLazyQueryHookResult = ReturnType<
  typeof useGetArticleFullLazyQuery
>;
export type GetArticleFullSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleFullSuspenseQuery
>;
export type GetArticleFullQueryResult = Apollo.QueryResult<
  GetArticleFull,
  GetArticleFullVariables
>;
export const GetArticleBaseDocument = gql`
  query GetArticleBase($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetArticleBase__
 *
 * To run a query within a React component, call `useGetArticleBase` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleBase` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleBase({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleBase(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleBase,
    GetArticleBaseVariables
  > &
    ({ variables: GetArticleBaseVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleBase, GetArticleBaseVariables>(
    GetArticleBaseDocument,
    options
  );
}
export function useGetArticleBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleBase,
    GetArticleBaseVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleBase, GetArticleBaseVariables>(
    GetArticleBaseDocument,
    options
  );
}
export function useGetArticleBaseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleBase,
    GetArticleBaseVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleBase, GetArticleBaseVariables>(
    GetArticleBaseDocument,
    options
  );
}
export type GetArticleBaseHookResult = ReturnType<typeof useGetArticleBase>;
export type GetArticleBaseLazyQueryHookResult = ReturnType<
  typeof useGetArticleBaseLazyQuery
>;
export type GetArticleBaseSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleBaseSuspenseQuery
>;
export type GetArticleBaseQueryResult = Apollo.QueryResult<
  GetArticleBase,
  GetArticleBaseVariables
>;
export const GetArticleDetailsDocument = gql`
  query GetArticleDetails($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataDetails
    }
  }
  ${ArticleMetadataDetails}
`;

/**
 * __useGetArticleDetails__
 *
 * To run a query within a React component, call `useGetArticleDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleDetails(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleDetails,
    GetArticleDetailsVariables
  > &
    (
      | { variables: GetArticleDetailsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleDetails, GetArticleDetailsVariables>(
    GetArticleDetailsDocument,
    options
  );
}
export function useGetArticleDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleDetails,
    GetArticleDetailsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleDetails, GetArticleDetailsVariables>(
    GetArticleDetailsDocument,
    options
  );
}
export function useGetArticleDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleDetails,
    GetArticleDetailsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleDetails, GetArticleDetailsVariables>(
    GetArticleDetailsDocument,
    options
  );
}
export type GetArticleDetailsHookResult = ReturnType<
  typeof useGetArticleDetails
>;
export type GetArticleDetailsLazyQueryHookResult = ReturnType<
  typeof useGetArticleDetailsLazyQuery
>;
export type GetArticleDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleDetailsSuspenseQuery
>;
export type GetArticleDetailsQueryResult = Apollo.QueryResult<
  GetArticleDetails,
  GetArticleDetailsVariables
>;
export const GetArticleVersionNameDocument = gql`
  query GetArticleVersionName($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      versionName
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetArticleVersionName__
 *
 * To run a query within a React component, call `useGetArticleVersionName` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleVersionName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleVersionName({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleVersionName(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleVersionName,
    GetArticleVersionNameVariables
  > &
    (
      | { variables: GetArticleVersionNameVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleVersionName, GetArticleVersionNameVariables>(
    GetArticleVersionNameDocument,
    options
  );
}
export function useGetArticleVersionNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleVersionName,
    GetArticleVersionNameVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleVersionName,
    GetArticleVersionNameVariables
  >(GetArticleVersionNameDocument, options);
}
export function useGetArticleVersionNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleVersionName,
    GetArticleVersionNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleVersionName,
    GetArticleVersionNameVariables
  >(GetArticleVersionNameDocument, options);
}
export type GetArticleVersionNameHookResult = ReturnType<
  typeof useGetArticleVersionName
>;
export type GetArticleVersionNameLazyQueryHookResult = ReturnType<
  typeof useGetArticleVersionNameLazyQuery
>;
export type GetArticleVersionNameSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleVersionNameSuspenseQuery
>;
export type GetArticleVersionNameQueryResult = Apollo.QueryResult<
  GetArticleVersionName,
  GetArticleVersionNameVariables
>;
export const GetArticleForDetailsTabDocument = gql`
  query GetArticleForDetailsTab($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      ...ArticleMetadataAssignee
      ...ArticleMetadataRootData
      ...ArticleMetadataProjectLabels
      dueDate
      publicationDate
      state
      urlPath
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataAssignee}
  ${ArticleMetadataRootData}
  ${ArticleMetadataProjectLabels}
`;

/**
 * __useGetArticleForDetailsTab__
 *
 * To run a query within a React component, call `useGetArticleForDetailsTab` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleForDetailsTab` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleForDetailsTab({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleForDetailsTab(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  > &
    (
      | { variables: GetArticleForDetailsTabVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  >(GetArticleForDetailsTabDocument, options);
}
export function useGetArticleForDetailsTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  >(GetArticleForDetailsTabDocument, options);
}
export function useGetArticleForDetailsTabSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleForDetailsTab,
    GetArticleForDetailsTabVariables
  >(GetArticleForDetailsTabDocument, options);
}
export type GetArticleForDetailsTabHookResult = ReturnType<
  typeof useGetArticleForDetailsTab
>;
export type GetArticleForDetailsTabLazyQueryHookResult = ReturnType<
  typeof useGetArticleForDetailsTabLazyQuery
>;
export type GetArticleForDetailsTabSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleForDetailsTabSuspenseQuery
>;
export type GetArticleForDetailsTabQueryResult = Apollo.QueryResult<
  GetArticleForDetailsTab,
  GetArticleForDetailsTabVariables
>;
export const GetArticleExploreDocument = gql`
  query GetArticleExplore($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      ...ArticleMetadataExplore
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataExplore}
`;

/**
 * __useGetArticleExplore__
 *
 * To run a query within a React component, call `useGetArticleExplore` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleExplore` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleExplore({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleExplore(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleExplore,
    GetArticleExploreVariables
  > &
    (
      | { variables: GetArticleExploreVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleExplore, GetArticleExploreVariables>(
    GetArticleExploreDocument,
    options
  );
}
export function useGetArticleExploreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleExplore,
    GetArticleExploreVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleExplore, GetArticleExploreVariables>(
    GetArticleExploreDocument,
    options
  );
}
export function useGetArticleExploreSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleExplore,
    GetArticleExploreVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleExplore, GetArticleExploreVariables>(
    GetArticleExploreDocument,
    options
  );
}
export type GetArticleExploreHookResult = ReturnType<
  typeof useGetArticleExplore
>;
export type GetArticleExploreLazyQueryHookResult = ReturnType<
  typeof useGetArticleExploreLazyQuery
>;
export type GetArticleExploreSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleExploreSuspenseQuery
>;
export type GetArticleExploreQueryResult = Apollo.QueryResult<
  GetArticleExplore,
  GetArticleExploreVariables
>;
export const GetArticleNameDocument = gql`
  query GetArticleName($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      articleDocument {
        ...ArticleDocumentFull
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleDocumentFull}
`;

/**
 * __useGetArticleName__
 *
 * To run a query within a React component, call `useGetArticleName` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleName({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleName(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleName,
    GetArticleNameVariables
  > &
    ({ variables: GetArticleNameVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleName, GetArticleNameVariables>(
    GetArticleNameDocument,
    options
  );
}
export function useGetArticleNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleName,
    GetArticleNameVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleName, GetArticleNameVariables>(
    GetArticleNameDocument,
    options
  );
}
export function useGetArticleNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleName,
    GetArticleNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleName, GetArticleNameVariables>(
    GetArticleNameDocument,
    options
  );
}
export type GetArticleNameHookResult = ReturnType<typeof useGetArticleName>;
export type GetArticleNameLazyQueryHookResult = ReturnType<
  typeof useGetArticleNameLazyQuery
>;
export type GetArticleNameSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleNameSuspenseQuery
>;
export type GetArticleNameQueryResult = Apollo.QueryResult<
  GetArticleName,
  GetArticleNameVariables
>;
export const GetArticleFocusKeywordDetailsDocument = gql`
  query GetArticleFocusKeywordDetails($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      articleDocument {
        ...ArticleDocumentFull
      }
      neuraverseTopicData {
        topicId
        topicNames
        articleDirection
        articleLengthSuggestion
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleDocumentFull}
`;

/**
 * __useGetArticleFocusKeywordDetails__
 *
 * To run a query within a React component, call `useGetArticleFocusKeywordDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleFocusKeywordDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleFocusKeywordDetails({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleFocusKeywordDetails(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  > &
    (
      | { variables: GetArticleFocusKeywordDetailsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  >(GetArticleFocusKeywordDetailsDocument, options);
}
export function useGetArticleFocusKeywordDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  >(GetArticleFocusKeywordDetailsDocument, options);
}
export function useGetArticleFocusKeywordDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleFocusKeywordDetails,
    GetArticleFocusKeywordDetailsVariables
  >(GetArticleFocusKeywordDetailsDocument, options);
}
export type GetArticleFocusKeywordDetailsHookResult = ReturnType<
  typeof useGetArticleFocusKeywordDetails
>;
export type GetArticleFocusKeywordDetailsLazyQueryHookResult = ReturnType<
  typeof useGetArticleFocusKeywordDetailsLazyQuery
>;
export type GetArticleFocusKeywordDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleFocusKeywordDetailsSuspenseQuery
>;
export type GetArticleFocusKeywordDetailsQueryResult = Apollo.QueryResult<
  GetArticleFocusKeywordDetails,
  GetArticleFocusKeywordDetailsVariables
>;
export const GetArticleRscoreDocument = gql`
  query GetArticleRscore($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      articleRScore {
        ...ArticleRScoreFull
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleRScoreFull}
`;

/**
 * __useGetArticleRscore__
 *
 * To run a query within a React component, call `useGetArticleRscore` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleRscore` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleRscore({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleRscore(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleRscore,
    GetArticleRscoreVariables
  > &
    (
      | { variables: GetArticleRscoreVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleRscore, GetArticleRscoreVariables>(
    GetArticleRscoreDocument,
    options
  );
}
export function useGetArticleRscoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleRscore,
    GetArticleRscoreVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleRscore, GetArticleRscoreVariables>(
    GetArticleRscoreDocument,
    options
  );
}
export function useGetArticleRscoreSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleRscore,
    GetArticleRscoreVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleRscore, GetArticleRscoreVariables>(
    GetArticleRscoreDocument,
    options
  );
}
export type GetArticleRscoreHookResult = ReturnType<typeof useGetArticleRscore>;
export type GetArticleRscoreLazyQueryHookResult = ReturnType<
  typeof useGetArticleRscoreLazyQuery
>;
export type GetArticleRscoreSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleRscoreSuspenseQuery
>;
export type GetArticleRscoreQueryResult = Apollo.QueryResult<
  GetArticleRscore,
  GetArticleRscoreVariables
>;
export const GetArticleRootDataDocument = gql`
  query GetArticleRootData($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      articleRootData {
        ...ArticleRootDataFull
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleRootDataFull}
`;

/**
 * __useGetArticleRootData__
 *
 * To run a query within a React component, call `useGetArticleRootData` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleRootData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleRootData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleRootData(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleRootData,
    GetArticleRootDataVariables
  > &
    (
      | { variables: GetArticleRootDataVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleRootData, GetArticleRootDataVariables>(
    GetArticleRootDataDocument,
    options
  );
}
export function useGetArticleRootDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleRootData,
    GetArticleRootDataVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleRootData, GetArticleRootDataVariables>(
    GetArticleRootDataDocument,
    options
  );
}
export function useGetArticleRootDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleRootData,
    GetArticleRootDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleRootData,
    GetArticleRootDataVariables
  >(GetArticleRootDataDocument, options);
}
export type GetArticleRootDataHookResult = ReturnType<
  typeof useGetArticleRootData
>;
export type GetArticleRootDataLazyQueryHookResult = ReturnType<
  typeof useGetArticleRootDataLazyQuery
>;
export type GetArticleRootDataSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleRootDataSuspenseQuery
>;
export type GetArticleRootDataQueryResult = Apollo.QueryResult<
  GetArticleRootData,
  GetArticleRootDataVariables
>;
export const GetArticleGuidelinesDocument = gql`
  query GetArticleGuidelines($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      ...ArticleMetadataGuidelines
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataGuidelines}
`;

/**
 * __useGetArticleGuidelines__
 *
 * To run a query within a React component, call `useGetArticleGuidelines` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleGuidelines` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleGuidelines({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleGuidelines(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleGuidelines,
    GetArticleGuidelinesVariables
  > &
    (
      | { variables: GetArticleGuidelinesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleGuidelines, GetArticleGuidelinesVariables>(
    GetArticleGuidelinesDocument,
    options
  );
}
export function useGetArticleGuidelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleGuidelines,
    GetArticleGuidelinesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleGuidelines,
    GetArticleGuidelinesVariables
  >(GetArticleGuidelinesDocument, options);
}
export function useGetArticleGuidelinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleGuidelines,
    GetArticleGuidelinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleGuidelines,
    GetArticleGuidelinesVariables
  >(GetArticleGuidelinesDocument, options);
}
export type GetArticleGuidelinesHookResult = ReturnType<
  typeof useGetArticleGuidelines
>;
export type GetArticleGuidelinesLazyQueryHookResult = ReturnType<
  typeof useGetArticleGuidelinesLazyQuery
>;
export type GetArticleGuidelinesSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleGuidelinesSuspenseQuery
>;
export type GetArticleGuidelinesQueryResult = Apollo.QueryResult<
  GetArticleGuidelines,
  GetArticleGuidelinesVariables
>;
export const GetArticleUrlDocument = gql`
  query GetArticleUrl($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      urlPath
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetArticleUrl__
 *
 * To run a query within a React component, call `useGetArticleUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleUrl({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleUrl(
  baseOptions: Apollo.QueryHookOptions<GetArticleUrl, GetArticleUrlVariables> &
    ({ variables: GetArticleUrlVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleUrl, GetArticleUrlVariables>(
    GetArticleUrlDocument,
    options
  );
}
export function useGetArticleUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleUrl,
    GetArticleUrlVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleUrl, GetArticleUrlVariables>(
    GetArticleUrlDocument,
    options
  );
}
export function useGetArticleUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleUrl,
    GetArticleUrlVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleUrl, GetArticleUrlVariables>(
    GetArticleUrlDocument,
    options
  );
}
export type GetArticleUrlHookResult = ReturnType<typeof useGetArticleUrl>;
export type GetArticleUrlLazyQueryHookResult = ReturnType<
  typeof useGetArticleUrlLazyQuery
>;
export type GetArticleUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleUrlSuspenseQuery
>;
export type GetArticleUrlQueryResult = Apollo.QueryResult<
  GetArticleUrl,
  GetArticleUrlVariables
>;
export const CompareArticleVersionsDocument = gql`
  query CompareArticleVersions($input: CompareVersionsInput!) {
    compareArticleVersions(input: $input) {
      comparedHtml
    }
  }
`;

/**
 * __useCompareArticleVersions__
 *
 * To run a query within a React component, call `useCompareArticleVersions` and pass it any options that fit your needs.
 * When your component renders, `useCompareArticleVersions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareArticleVersions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompareArticleVersions(
  baseOptions: Apollo.QueryHookOptions<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  > &
    (
      | { variables: CompareArticleVersionsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  >(CompareArticleVersionsDocument, options);
}
export function useCompareArticleVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  >(CompareArticleVersionsDocument, options);
}
export function useCompareArticleVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompareArticleVersions,
    CompareArticleVersionsVariables
  >(CompareArticleVersionsDocument, options);
}
export type CompareArticleVersionsHookResult = ReturnType<
  typeof useCompareArticleVersions
>;
export type CompareArticleVersionsLazyQueryHookResult = ReturnType<
  typeof useCompareArticleVersionsLazyQuery
>;
export type CompareArticleVersionsSuspenseQueryHookResult = ReturnType<
  typeof useCompareArticleVersionsSuspenseQuery
>;
export type CompareArticleVersionsQueryResult = Apollo.QueryResult<
  CompareArticleVersions,
  CompareArticleVersionsVariables
>;
export const ListArticleVersionNamesDocument = gql`
  query ListArticleVersionNames($input: ListVersionsInput!) {
    listVersions(input: $input) {
      items {
        ...ArticleMetadataBase
        versionName
      }
      nextToken
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useListArticleVersionNames__
 *
 * To run a query within a React component, call `useListArticleVersionNames` and pass it any options that fit your needs.
 * When your component renders, `useListArticleVersionNames` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleVersionNames({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListArticleVersionNames(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  > &
    (
      | { variables: ListArticleVersionNamesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  >(ListArticleVersionNamesDocument, options);
}
export function useListArticleVersionNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  >(ListArticleVersionNamesDocument, options);
}
export function useListArticleVersionNamesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleVersionNames,
    ListArticleVersionNamesVariables
  >(ListArticleVersionNamesDocument, options);
}
export type ListArticleVersionNamesHookResult = ReturnType<
  typeof useListArticleVersionNames
>;
export type ListArticleVersionNamesLazyQueryHookResult = ReturnType<
  typeof useListArticleVersionNamesLazyQuery
>;
export type ListArticleVersionNamesSuspenseQueryHookResult = ReturnType<
  typeof useListArticleVersionNamesSuspenseQuery
>;
export type ListArticleVersionNamesQueryResult = Apollo.QueryResult<
  ListArticleVersionNames,
  ListArticleVersionNamesVariables
>;
export const GetArticleForGenerationDocument = gql`
  query GetArticleForGeneration($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      focusKeyword
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetArticleForGeneration__
 *
 * To run a query within a React component, call `useGetArticleForGeneration` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleForGeneration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleForGeneration({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleForGeneration(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  > &
    (
      | { variables: GetArticleForGenerationVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  >(GetArticleForGenerationDocument, options);
}
export function useGetArticleForGenerationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  >(GetArticleForGenerationDocument, options);
}
export function useGetArticleForGenerationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetArticleForGeneration,
    GetArticleForGenerationVariables
  >(GetArticleForGenerationDocument, options);
}
export type GetArticleForGenerationHookResult = ReturnType<
  typeof useGetArticleForGeneration
>;
export type GetArticleForGenerationLazyQueryHookResult = ReturnType<
  typeof useGetArticleForGenerationLazyQuery
>;
export type GetArticleForGenerationSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleForGenerationSuspenseQuery
>;
export type GetArticleForGenerationQueryResult = Apollo.QueryResult<
  GetArticleForGeneration,
  GetArticleForGenerationVariables
>;
export const ListArticleHeadlinesForGenerateContentDocument = gql`
  query ListArticleHeadlinesForGenerateContent($articleId: String!) {
    listArticleHeadlines(articleId: $articleId) {
      items {
        ...ArticleHeadlineBase
      }
    }
  }
  ${ArticleHeadlineBase}
`;

/**
 * __useListArticleHeadlinesForGenerateContent__
 *
 * To run a query within a React component, call `useListArticleHeadlinesForGenerateContent` and pass it any options that fit your needs.
 * When your component renders, `useListArticleHeadlinesForGenerateContent` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleHeadlinesForGenerateContent({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useListArticleHeadlinesForGenerateContent(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  > &
    (
      | {
          variables: ListArticleHeadlinesForGenerateContentVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >(ListArticleHeadlinesForGenerateContentDocument, options);
}
export function useListArticleHeadlinesForGenerateContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >(ListArticleHeadlinesForGenerateContentDocument, options);
}
export function useListArticleHeadlinesForGenerateContentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >(ListArticleHeadlinesForGenerateContentDocument, options);
}
export type ListArticleHeadlinesForGenerateContentHookResult = ReturnType<
  typeof useListArticleHeadlinesForGenerateContent
>;
export type ListArticleHeadlinesForGenerateContentLazyQueryHookResult =
  ReturnType<typeof useListArticleHeadlinesForGenerateContentLazyQuery>;
export type ListArticleHeadlinesForGenerateContentSuspenseQueryHookResult =
  ReturnType<typeof useListArticleHeadlinesForGenerateContentSuspenseQuery>;
export type ListArticleHeadlinesForGenerateContentQueryResult =
  Apollo.QueryResult<
    ListArticleHeadlinesForGenerateContent,
    ListArticleHeadlinesForGenerateContentVariables
  >;
export const ListLatestIndexedArticlesDocument = gql`
  query ListLatestIndexedArticles($input: ListLatestIndexedArticlesInput!) {
    listLatestIndexedArticles(input: $input) {
      articles {
        id
        name
        urlIndexedAt
      }
    }
  }
`;

/**
 * __useListLatestIndexedArticles__
 *
 * To run a query within a React component, call `useListLatestIndexedArticles` and pass it any options that fit your needs.
 * When your component renders, `useListLatestIndexedArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLatestIndexedArticles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListLatestIndexedArticles(
  baseOptions: Apollo.QueryHookOptions<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  > &
    (
      | { variables: ListLatestIndexedArticlesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  >(ListLatestIndexedArticlesDocument, options);
}
export function useListLatestIndexedArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  >(ListLatestIndexedArticlesDocument, options);
}
export function useListLatestIndexedArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListLatestIndexedArticles,
    ListLatestIndexedArticlesVariables
  >(ListLatestIndexedArticlesDocument, options);
}
export type ListLatestIndexedArticlesHookResult = ReturnType<
  typeof useListLatestIndexedArticles
>;
export type ListLatestIndexedArticlesLazyQueryHookResult = ReturnType<
  typeof useListLatestIndexedArticlesLazyQuery
>;
export type ListLatestIndexedArticlesSuspenseQueryHookResult = ReturnType<
  typeof useListLatestIndexedArticlesSuspenseQuery
>;
export type ListLatestIndexedArticlesQueryResult = Apollo.QueryResult<
  ListLatestIndexedArticles,
  ListLatestIndexedArticlesVariables
>;
export const GetTurnServerCredentialsDocument = gql`
  query GetTURNServerCredentials($input: GetTURNServerCredentialsInput!) {
    getTURNServerCredentials(input: $input) {
      iceServers {
        credential
        url
        urls
        username
      }
    }
  }
`;

/**
 * __useGetTurnServerCredentials__
 *
 * To run a query within a React component, call `useGetTurnServerCredentials` and pass it any options that fit your needs.
 * When your component renders, `useGetTurnServerCredentials` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTurnServerCredentials({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTurnServerCredentials(
  baseOptions: Apollo.QueryHookOptions<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  > &
    (
      | { variables: GetTurnServerCredentialsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  >(GetTurnServerCredentialsDocument, options);
}
export function useGetTurnServerCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  >(GetTurnServerCredentialsDocument, options);
}
export function useGetTurnServerCredentialsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTurnServerCredentials,
    GetTurnServerCredentialsVariables
  >(GetTurnServerCredentialsDocument, options);
}
export type GetTurnServerCredentialsHookResult = ReturnType<
  typeof useGetTurnServerCredentials
>;
export type GetTurnServerCredentialsLazyQueryHookResult = ReturnType<
  typeof useGetTurnServerCredentialsLazyQuery
>;
export type GetTurnServerCredentialsSuspenseQueryHookResult = ReturnType<
  typeof useGetTurnServerCredentialsSuspenseQuery
>;
export type GetTurnServerCredentialsQueryResult = Apollo.QueryResult<
  GetTurnServerCredentials,
  GetTurnServerCredentialsVariables
>;
export const GetUserArticleDataDocument = gql`
  query GetUserArticleData($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      userArticle {
        preferences
        id
      }
      state
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetUserArticleData__
 *
 * To run a query within a React component, call `useGetUserArticleData` and pass it any options that fit your needs.
 * When your component renders, `useGetUserArticleData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserArticleData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserArticleData(
  baseOptions: Apollo.QueryHookOptions<
    GetUserArticleData,
    GetUserArticleDataVariables
  > &
    (
      | { variables: GetUserArticleDataVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetUserArticleData, GetUserArticleDataVariables>(
    GetUserArticleDataDocument,
    options
  );
}
export function useGetUserArticleDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserArticleData,
    GetUserArticleDataVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetUserArticleData, GetUserArticleDataVariables>(
    GetUserArticleDataDocument,
    options
  );
}
export function useGetUserArticleDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserArticleData,
    GetUserArticleDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserArticleData,
    GetUserArticleDataVariables
  >(GetUserArticleDataDocument, options);
}
export type GetUserArticleDataHookResult = ReturnType<
  typeof useGetUserArticleData
>;
export type GetUserArticleDataLazyQueryHookResult = ReturnType<
  typeof useGetUserArticleDataLazyQuery
>;
export type GetUserArticleDataSuspenseQueryHookResult = ReturnType<
  typeof useGetUserArticleDataSuspenseQuery
>;
export type GetUserArticleDataQueryResult = Apollo.QueryResult<
  GetUserArticleData,
  GetUserArticleDataVariables
>;
export const GetArticleStateDocument = gql`
  query GetArticleState($id: ID!) {
    getArticle(id: $id) {
      ...ArticleMetadataBase
      state
    }
  }
  ${ArticleMetadataBase}
`;

/**
 * __useGetArticleState__
 *
 * To run a query within a React component, call `useGetArticleState` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleState` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleState({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleState(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleState,
    GetArticleStateVariables
  > &
    (
      | { variables: GetArticleStateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleState, GetArticleStateVariables>(
    GetArticleStateDocument,
    options
  );
}
export function useGetArticleStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleState,
    GetArticleStateVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleState, GetArticleStateVariables>(
    GetArticleStateDocument,
    options
  );
}
export function useGetArticleStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleState,
    GetArticleStateVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleState, GetArticleStateVariables>(
    GetArticleStateDocument,
    options
  );
}
export type GetArticleStateHookResult = ReturnType<typeof useGetArticleState>;
export type GetArticleStateLazyQueryHookResult = ReturnType<
  typeof useGetArticleStateLazyQuery
>;
export type GetArticleStateSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleStateSuspenseQuery
>;
export type GetArticleStateQueryResult = Apollo.QueryResult<
  GetArticleState,
  GetArticleStateVariables
>;
export const GenerateFocusKeywordsByGptDocument = gql`
  query GenerateFocusKeywordsByGPT($input: FocusKeywordsByGPTInput!) {
    generateFocusKeywordsByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateFocusKeywordsByGpt__
 *
 * To run a query within a React component, call `useGenerateFocusKeywordsByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateFocusKeywordsByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateFocusKeywordsByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFocusKeywordsByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  > &
    (
      | { variables: GenerateFocusKeywordsByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  >(GenerateFocusKeywordsByGptDocument, options);
}
export function useGenerateFocusKeywordsByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  >(GenerateFocusKeywordsByGptDocument, options);
}
export function useGenerateFocusKeywordsByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateFocusKeywordsByGpt,
    GenerateFocusKeywordsByGptVariables
  >(GenerateFocusKeywordsByGptDocument, options);
}
export type GenerateFocusKeywordsByGptHookResult = ReturnType<
  typeof useGenerateFocusKeywordsByGpt
>;
export type GenerateFocusKeywordsByGptLazyQueryHookResult = ReturnType<
  typeof useGenerateFocusKeywordsByGptLazyQuery
>;
export type GenerateFocusKeywordsByGptSuspenseQueryHookResult = ReturnType<
  typeof useGenerateFocusKeywordsByGptSuspenseQuery
>;
export type GenerateFocusKeywordsByGptQueryResult = Apollo.QueryResult<
  GenerateFocusKeywordsByGpt,
  GenerateFocusKeywordsByGptVariables
>;
export const OnArticleCreateDocument = gql`
  subscription OnArticleCreate(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleCreate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ArticleSubscriptionFull
    }
  }
  ${ArticleSubscriptionFull}
`;

/**
 * __useOnArticleCreate__
 *
 * To run a query within a React component, call `useOnArticleCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleCreate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleCreate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleCreate,
    OnArticleCreateVariables
  > &
    (
      | { variables: OnArticleCreateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnArticleCreate, OnArticleCreateVariables>(
    OnArticleCreateDocument,
    options
  );
}
export type OnArticleCreateHookResult = ReturnType<typeof useOnArticleCreate>;
export type OnArticleCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleCreate>;
export const OnArticleUpdateDocument = gql`
  subscription OnArticleUpdate(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleUpdate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ArticleSubscriptionFull
    }
  }
  ${ArticleSubscriptionFull}
`;

/**
 * __useOnArticleUpdate__
 *
 * To run a query within a React component, call `useOnArticleUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleUpdate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleUpdate,
    OnArticleUpdateVariables
  > &
    (
      | { variables: OnArticleUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnArticleUpdate, OnArticleUpdateVariables>(
    OnArticleUpdateDocument,
    options
  );
}
export type OnArticleUpdateHookResult = ReturnType<typeof useOnArticleUpdate>;
export type OnArticleUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleUpdate>;
export const OnArticleDeleteDocument = gql`
  subscription OnArticleDelete(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleDelete(userId: $userId, pk1: $pk1, filter: $filter) {
      type
      pk1
      id
      userId
    }
  }
`;

/**
 * __useOnArticleDelete__
 *
 * To run a query within a React component, call `useOnArticleDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleDelete({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleDelete(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleDelete,
    OnArticleDeleteVariables
  > &
    (
      | { variables: OnArticleDeleteVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnArticleDelete, OnArticleDeleteVariables>(
    OnArticleDeleteDocument,
    options
  );
}
export type OnArticleDeleteHookResult = ReturnType<typeof useOnArticleDelete>;
export type OnArticleDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleDelete>;
export const OnArticleUpdateByIdDocument = gql`
  subscription OnArticleUpdateById(
    $pk1: String!
    $id: String!
    $userId: String!
  ) {
    onArticleUpdateById(pk1: $pk1, id: $id, userId: $userId) {
      ...ArticleSubscriptionFull
    }
  }
  ${ArticleSubscriptionFull}
`;

/**
 * __useOnArticleUpdateById__
 *
 * To run a query within a React component, call `useOnArticleUpdateById` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleUpdateById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleUpdateById({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnArticleUpdateById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleUpdateById,
    OnArticleUpdateByIdVariables
  > &
    (
      | { variables: OnArticleUpdateByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnArticleUpdateById,
    OnArticleUpdateByIdVariables
  >(OnArticleUpdateByIdDocument, options);
}
export type OnArticleUpdateByIdHookResult = ReturnType<
  typeof useOnArticleUpdateById
>;
export type OnArticleUpdateByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleUpdateById>;
export const OnArticleDeleteByIdDocument = gql`
  subscription OnArticleDeleteById(
    $pk1: String!
    $id: String!
    $userId: String!
  ) {
    onArticleDeleteById(pk1: $pk1, id: $id, userId: $userId) {
      type
      pk1
      id
      userId
    }
  }
`;

/**
 * __useOnArticleDeleteById__
 *
 * To run a query within a React component, call `useOnArticleDeleteById` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleDeleteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleDeleteById({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnArticleDeleteById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleDeleteById,
    OnArticleDeleteByIdVariables
  > &
    (
      | { variables: OnArticleDeleteByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnArticleDeleteById,
    OnArticleDeleteByIdVariables
  >(OnArticleDeleteByIdDocument, options);
}
export type OnArticleDeleteByIdHookResult = ReturnType<
  typeof useOnArticleDeleteById
>;
export type OnArticleDeleteByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleDeleteById>;
export const UpsertArticleQuestionsDocument = gql`
  mutation UpsertArticleQuestions($input: UpsertArticleQuestionsInput!) {
    upsertArticleQuestions(input: $input) {
      ...ArticleQuestionFull
    }
  }
  ${ArticleQuestionFull}
`;
export type UpsertArticleQuestionsMutationFn = Apollo.MutationFunction<
  UpsertArticleQuestions,
  UpsertArticleQuestionsVariables
>;

/**
 * __useUpsertArticleQuestions__
 *
 * To run a mutation, you first call `useUpsertArticleQuestions` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleQuestions` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleQuestions, { data, loading, error }] = useUpsertArticleQuestions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleQuestions(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleQuestions,
    UpsertArticleQuestionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleQuestions,
    UpsertArticleQuestionsVariables
  >(UpsertArticleQuestionsDocument, options);
}
export type UpsertArticleQuestionsHookResult = ReturnType<
  typeof useUpsertArticleQuestions
>;
export type UpsertArticleQuestionsMutationResult =
  Apollo.MutationResult<UpsertArticleQuestions>;
export type UpsertArticleQuestionsMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleQuestions,
  UpsertArticleQuestionsVariables
>;
export const UpsertArticleQuestionDocument = gql`
  mutation UpsertArticleQuestion($input: ArticleQuestionInput!) {
    upsertArticleQuestion(input: $input) {
      ...ArticleQuestionFull
    }
  }
  ${ArticleQuestionFull}
`;
export type UpsertArticleQuestionMutationFn = Apollo.MutationFunction<
  UpsertArticleQuestion,
  UpsertArticleQuestionVariables
>;

/**
 * __useUpsertArticleQuestion__
 *
 * To run a mutation, you first call `useUpsertArticleQuestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleQuestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleQuestion, { data, loading, error }] = useUpsertArticleQuestion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleQuestion(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleQuestion,
    UpsertArticleQuestionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleQuestion,
    UpsertArticleQuestionVariables
  >(UpsertArticleQuestionDocument, options);
}
export type UpsertArticleQuestionHookResult = ReturnType<
  typeof useUpsertArticleQuestion
>;
export type UpsertArticleQuestionMutationResult =
  Apollo.MutationResult<UpsertArticleQuestion>;
export type UpsertArticleQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleQuestion,
  UpsertArticleQuestionVariables
>;
export const UpdateArticleQuestionsDocument = gql`
  mutation UpdateArticleQuestions($input: UpdateArticleQuestionsInput!) {
    updateArticleQuestions(input: $input) {
      ...ArticleQuestionFull
    }
  }
  ${ArticleQuestionFull}
`;
export type UpdateArticleQuestionsMutationFn = Apollo.MutationFunction<
  UpdateArticleQuestions,
  UpdateArticleQuestionsVariables
>;

/**
 * __useUpdateArticleQuestions__
 *
 * To run a mutation, you first call `useUpdateArticleQuestions` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleQuestions` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleQuestions, { data, loading, error }] = useUpdateArticleQuestions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleQuestions(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleQuestions,
    UpdateArticleQuestionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleQuestions,
    UpdateArticleQuestionsVariables
  >(UpdateArticleQuestionsDocument, options);
}
export type UpdateArticleQuestionsHookResult = ReturnType<
  typeof useUpdateArticleQuestions
>;
export type UpdateArticleQuestionsMutationResult =
  Apollo.MutationResult<UpdateArticleQuestions>;
export type UpdateArticleQuestionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleQuestions,
  UpdateArticleQuestionsVariables
>;
export const DeleteArticleQuestionDocument = gql`
  mutation DeleteArticleQuestion($input: DeleteArticleQuestionInput!) {
    deleteArticleQuestion(input: $input) {
      id
    }
  }
`;
export type DeleteArticleQuestionMutationFn = Apollo.MutationFunction<
  DeleteArticleQuestion,
  DeleteArticleQuestionVariables
>;

/**
 * __useDeleteArticleQuestion__
 *
 * To run a mutation, you first call `useDeleteArticleQuestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleQuestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleQuestion, { data, loading, error }] = useDeleteArticleQuestion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleQuestion(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleQuestion,
    DeleteArticleQuestionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleQuestion,
    DeleteArticleQuestionVariables
  >(DeleteArticleQuestionDocument, options);
}
export type DeleteArticleQuestionHookResult = ReturnType<
  typeof useDeleteArticleQuestion
>;
export type DeleteArticleQuestionMutationResult =
  Apollo.MutationResult<DeleteArticleQuestion>;
export type DeleteArticleQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleQuestion,
  DeleteArticleQuestionVariables
>;
export const UpsertArticleKeywordsDocument = gql`
  mutation UpsertArticleKeywords($input: UpsertArticleKeywordsInput!) {
    upsertArticleKeywords(input: $input) {
      ...ArticleKeywordFull
    }
  }
  ${ArticleKeywordFull}
`;
export type UpsertArticleKeywordsMutationFn = Apollo.MutationFunction<
  UpsertArticleKeywords,
  UpsertArticleKeywordsVariables
>;

/**
 * __useUpsertArticleKeywords__
 *
 * To run a mutation, you first call `useUpsertArticleKeywords` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleKeywords` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleKeywords, { data, loading, error }] = useUpsertArticleKeywords({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleKeywords(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleKeywords,
    UpsertArticleKeywordsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleKeywords,
    UpsertArticleKeywordsVariables
  >(UpsertArticleKeywordsDocument, options);
}
export type UpsertArticleKeywordsHookResult = ReturnType<
  typeof useUpsertArticleKeywords
>;
export type UpsertArticleKeywordsMutationResult =
  Apollo.MutationResult<UpsertArticleKeywords>;
export type UpsertArticleKeywordsMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleKeywords,
  UpsertArticleKeywordsVariables
>;
export const UpsertArticleKeywordDocument = gql`
  mutation UpsertArticleKeyword($input: ArticleKeywordInput!) {
    upsertArticleKeyword(input: $input) {
      ...ArticleKeywordFull
    }
  }
  ${ArticleKeywordFull}
`;
export type UpsertArticleKeywordMutationFn = Apollo.MutationFunction<
  UpsertArticleKeyword,
  UpsertArticleKeywordVariables
>;

/**
 * __useUpsertArticleKeyword__
 *
 * To run a mutation, you first call `useUpsertArticleKeyword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleKeyword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleKeyword, { data, loading, error }] = useUpsertArticleKeyword({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleKeyword(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleKeyword,
    UpsertArticleKeywordVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleKeyword,
    UpsertArticleKeywordVariables
  >(UpsertArticleKeywordDocument, options);
}
export type UpsertArticleKeywordHookResult = ReturnType<
  typeof useUpsertArticleKeyword
>;
export type UpsertArticleKeywordMutationResult =
  Apollo.MutationResult<UpsertArticleKeyword>;
export type UpsertArticleKeywordMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleKeyword,
  UpsertArticleKeywordVariables
>;
export const UpdateArticleKeywordsDocument = gql`
  mutation UpdateArticleKeywords($input: UpdateArticleKeywordsInput!) {
    updateArticleKeywords(input: $input) {
      ...ArticleKeywordFull
    }
  }
  ${ArticleKeywordFull}
`;
export type UpdateArticleKeywordsMutationFn = Apollo.MutationFunction<
  UpdateArticleKeywords,
  UpdateArticleKeywordsVariables
>;

/**
 * __useUpdateArticleKeywords__
 *
 * To run a mutation, you first call `useUpdateArticleKeywords` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleKeywords` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleKeywords, { data, loading, error }] = useUpdateArticleKeywords({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleKeywords(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleKeywords,
    UpdateArticleKeywordsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleKeywords,
    UpdateArticleKeywordsVariables
  >(UpdateArticleKeywordsDocument, options);
}
export type UpdateArticleKeywordsHookResult = ReturnType<
  typeof useUpdateArticleKeywords
>;
export type UpdateArticleKeywordsMutationResult =
  Apollo.MutationResult<UpdateArticleKeywords>;
export type UpdateArticleKeywordsMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleKeywords,
  UpdateArticleKeywordsVariables
>;
export const DeleteArticleKeywordDocument = gql`
  mutation DeleteArticleKeyword($input: DeleteArticleKeywordInput!) {
    deleteArticleKeyword(input: $input) {
      id
    }
  }
`;
export type DeleteArticleKeywordMutationFn = Apollo.MutationFunction<
  DeleteArticleKeyword,
  DeleteArticleKeywordVariables
>;

/**
 * __useDeleteArticleKeyword__
 *
 * To run a mutation, you first call `useDeleteArticleKeyword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleKeyword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleKeyword, { data, loading, error }] = useDeleteArticleKeyword({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleKeyword(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleKeyword,
    DeleteArticleKeywordVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleKeyword,
    DeleteArticleKeywordVariables
  >(DeleteArticleKeywordDocument, options);
}
export type DeleteArticleKeywordHookResult = ReturnType<
  typeof useDeleteArticleKeyword
>;
export type DeleteArticleKeywordMutationResult =
  Apollo.MutationResult<DeleteArticleKeyword>;
export type DeleteArticleKeywordMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleKeyword,
  DeleteArticleKeywordVariables
>;
export const DeleteArticleHeadlineDocument = gql`
  mutation DeleteArticleHeadline($input: DeleteArticleHeadlineInput!) {
    deleteArticleHeadline(input: $input) {
      id
    }
  }
`;
export type DeleteArticleHeadlineMutationFn = Apollo.MutationFunction<
  DeleteArticleHeadline,
  DeleteArticleHeadlineVariables
>;

/**
 * __useDeleteArticleHeadline__
 *
 * To run a mutation, you first call `useDeleteArticleHeadline` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleHeadline` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleHeadline, { data, loading, error }] = useDeleteArticleHeadline({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleHeadline(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleHeadline,
    DeleteArticleHeadlineVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleHeadline,
    DeleteArticleHeadlineVariables
  >(DeleteArticleHeadlineDocument, options);
}
export type DeleteArticleHeadlineHookResult = ReturnType<
  typeof useDeleteArticleHeadline
>;
export type DeleteArticleHeadlineMutationResult =
  Apollo.MutationResult<DeleteArticleHeadline>;
export type DeleteArticleHeadlineMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleHeadline,
  DeleteArticleHeadlineVariables
>;
export const DeleteArticleHeadlinesDocument = gql`
  mutation DeleteArticleHeadlines($input: DeleteArticleHeadlinesInput!) {
    deleteArticleHeadlines(input: $input) {
      id
    }
  }
`;
export type DeleteArticleHeadlinesMutationFn = Apollo.MutationFunction<
  DeleteArticleHeadlines,
  DeleteArticleHeadlinesVariables
>;

/**
 * __useDeleteArticleHeadlines__
 *
 * To run a mutation, you first call `useDeleteArticleHeadlines` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleHeadlines` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleHeadlines, { data, loading, error }] = useDeleteArticleHeadlines({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleHeadlines(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleHeadlines,
    DeleteArticleHeadlinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleHeadlines,
    DeleteArticleHeadlinesVariables
  >(DeleteArticleHeadlinesDocument, options);
}
export type DeleteArticleHeadlinesHookResult = ReturnType<
  typeof useDeleteArticleHeadlines
>;
export type DeleteArticleHeadlinesMutationResult =
  Apollo.MutationResult<DeleteArticleHeadlines>;
export type DeleteArticleHeadlinesMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleHeadlines,
  DeleteArticleHeadlinesVariables
>;
export const UpsertArticleHeadlineDocument = gql`
  mutation UpsertArticleHeadline($input: UpsertArticleHeadlineInput!) {
    upsertArticleHeadline(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type UpsertArticleHeadlineMutationFn = Apollo.MutationFunction<
  UpsertArticleHeadline,
  UpsertArticleHeadlineVariables
>;

/**
 * __useUpsertArticleHeadline__
 *
 * To run a mutation, you first call `useUpsertArticleHeadline` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleHeadline` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleHeadline, { data, loading, error }] = useUpsertArticleHeadline({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleHeadline(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleHeadline,
    UpsertArticleHeadlineVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleHeadline,
    UpsertArticleHeadlineVariables
  >(UpsertArticleHeadlineDocument, options);
}
export type UpsertArticleHeadlineHookResult = ReturnType<
  typeof useUpsertArticleHeadline
>;
export type UpsertArticleHeadlineMutationResult =
  Apollo.MutationResult<UpsertArticleHeadline>;
export type UpsertArticleHeadlineMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleHeadline,
  UpsertArticleHeadlineVariables
>;
export const UpsertArticleHeadlinesDocument = gql`
  mutation UpsertArticleHeadlines($input: UpsertArticleHeadlinesInput!) {
    upsertArticleHeadlines(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type UpsertArticleHeadlinesMutationFn = Apollo.MutationFunction<
  UpsertArticleHeadlines,
  UpsertArticleHeadlinesVariables
>;

/**
 * __useUpsertArticleHeadlines__
 *
 * To run a mutation, you first call `useUpsertArticleHeadlines` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleHeadlines` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleHeadlines, { data, loading, error }] = useUpsertArticleHeadlines({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleHeadlines(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleHeadlines,
    UpsertArticleHeadlinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleHeadlines,
    UpsertArticleHeadlinesVariables
  >(UpsertArticleHeadlinesDocument, options);
}
export type UpsertArticleHeadlinesHookResult = ReturnType<
  typeof useUpsertArticleHeadlines
>;
export type UpsertArticleHeadlinesMutationResult =
  Apollo.MutationResult<UpsertArticleHeadlines>;
export type UpsertArticleHeadlinesMutationOptions = Apollo.BaseMutationOptions<
  UpsertArticleHeadlines,
  UpsertArticleHeadlinesVariables
>;
export const CreateArticleHeadlineRefsDocument = gql`
  mutation CreateArticleHeadlineRefs($input: CreateArticleHeadlineRefsInput!) {
    createArticleHeadlineRefs(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type CreateArticleHeadlineRefsMutationFn = Apollo.MutationFunction<
  CreateArticleHeadlineRefs,
  CreateArticleHeadlineRefsVariables
>;

/**
 * __useCreateArticleHeadlineRefs__
 *
 * To run a mutation, you first call `useCreateArticleHeadlineRefs` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleHeadlineRefs` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleHeadlineRefs, { data, loading, error }] = useCreateArticleHeadlineRefs({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleHeadlineRefs(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleHeadlineRefs,
    CreateArticleHeadlineRefsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleHeadlineRefs,
    CreateArticleHeadlineRefsVariables
  >(CreateArticleHeadlineRefsDocument, options);
}
export type CreateArticleHeadlineRefsHookResult = ReturnType<
  typeof useCreateArticleHeadlineRefs
>;
export type CreateArticleHeadlineRefsMutationResult =
  Apollo.MutationResult<CreateArticleHeadlineRefs>;
export type CreateArticleHeadlineRefsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateArticleHeadlineRefs,
    CreateArticleHeadlineRefsVariables
  >;
export const DeleteArticleHeadlineRefDocument = gql`
  mutation DeleteArticleHeadlineRef($input: DeleteArticleHeadlineRefInput!) {
    deleteArticleHeadlineRef(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type DeleteArticleHeadlineRefMutationFn = Apollo.MutationFunction<
  DeleteArticleHeadlineRef,
  DeleteArticleHeadlineRefVariables
>;

/**
 * __useDeleteArticleHeadlineRef__
 *
 * To run a mutation, you first call `useDeleteArticleHeadlineRef` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleHeadlineRef` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleHeadlineRef, { data, loading, error }] = useDeleteArticleHeadlineRef({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleHeadlineRef(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleHeadlineRef,
    DeleteArticleHeadlineRefVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleHeadlineRef,
    DeleteArticleHeadlineRefVariables
  >(DeleteArticleHeadlineRefDocument, options);
}
export type DeleteArticleHeadlineRefHookResult = ReturnType<
  typeof useDeleteArticleHeadlineRef
>;
export type DeleteArticleHeadlineRefMutationResult =
  Apollo.MutationResult<DeleteArticleHeadlineRef>;
export type DeleteArticleHeadlineRefMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteArticleHeadlineRef,
    DeleteArticleHeadlineRefVariables
  >;
export const UpsertArticleHeadlinesMutationDocument = gql`
  mutation UpsertArticleHeadlinesMutation(
    $input: UpsertArticleHeadlinesInput!
  ) {
    upsertArticleHeadlines(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type UpsertArticleHeadlinesMutationMutationFn = Apollo.MutationFunction<
  UpsertArticleHeadlinesMutation,
  UpsertArticleHeadlinesMutationVariables
>;

/**
 * __useUpsertArticleHeadlinesMutation__
 *
 * To run a mutation, you first call `useUpsertArticleHeadlinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArticleHeadlinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArticleHeadlinesMutation, { data, loading, error }] = useUpsertArticleHeadlinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArticleHeadlinesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertArticleHeadlinesMutation,
    UpsertArticleHeadlinesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertArticleHeadlinesMutation,
    UpsertArticleHeadlinesMutationVariables
  >(UpsertArticleHeadlinesMutationDocument, options);
}
export type UpsertArticleHeadlinesMutationHookResult = ReturnType<
  typeof useUpsertArticleHeadlinesMutation
>;
export type UpsertArticleHeadlinesMutationMutationResult =
  Apollo.MutationResult<UpsertArticleHeadlinesMutation>;
export type UpsertArticleHeadlinesMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertArticleHeadlinesMutation,
    UpsertArticleHeadlinesMutationVariables
  >;
export const UpdateArticleHeadlinesMutationDocument = gql`
  mutation UpdateArticleHeadlinesMutation(
    $input: UpdateArticleHeadlinesInput!
  ) {
    updateArticleHeadlines(input: $input) {
      ...ArticleHeadlineFull
    }
  }
  ${ArticleHeadlineFull}
`;
export type UpdateArticleHeadlinesMutationMutationFn = Apollo.MutationFunction<
  UpdateArticleHeadlinesMutation,
  UpdateArticleHeadlinesMutationVariables
>;

/**
 * __useUpdateArticleHeadlinesMutation__
 *
 * To run a mutation, you first call `useUpdateArticleHeadlinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleHeadlinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleHeadlinesMutation, { data, loading, error }] = useUpdateArticleHeadlinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleHeadlinesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleHeadlinesMutation,
    UpdateArticleHeadlinesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleHeadlinesMutation,
    UpdateArticleHeadlinesMutationVariables
  >(UpdateArticleHeadlinesMutationDocument, options);
}
export type UpdateArticleHeadlinesMutationHookResult = ReturnType<
  typeof useUpdateArticleHeadlinesMutation
>;
export type UpdateArticleHeadlinesMutationMutationResult =
  Apollo.MutationResult<UpdateArticleHeadlinesMutation>;
export type UpdateArticleHeadlinesMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateArticleHeadlinesMutation,
    UpdateArticleHeadlinesMutationVariables
  >;
export const GenerateOutlineByGptDocument = gql`
  mutation GenerateOutlineByGPT($input: OutlineByGPTInput!) {
    generateOutlineByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type GenerateOutlineByGptMutationFn = Apollo.MutationFunction<
  GenerateOutlineByGpt,
  GenerateOutlineByGptVariables
>;

/**
 * __useGenerateOutlineByGpt__
 *
 * To run a mutation, you first call `useGenerateOutlineByGpt` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOutlineByGpt` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOutlineByGpt, { data, loading, error }] = useGenerateOutlineByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateOutlineByGpt(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOutlineByGpt,
    GenerateOutlineByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateOutlineByGpt,
    GenerateOutlineByGptVariables
  >(GenerateOutlineByGptDocument, options);
}
export type GenerateOutlineByGptHookResult = ReturnType<
  typeof useGenerateOutlineByGpt
>;
export type GenerateOutlineByGptMutationResult =
  Apollo.MutationResult<GenerateOutlineByGpt>;
export type GenerateOutlineByGptMutationOptions = Apollo.BaseMutationOptions<
  GenerateOutlineByGpt,
  GenerateOutlineByGptVariables
>;
export const MatchKeywordsAndQuestionsByGptDocument = gql`
  mutation MatchKeywordsAndQuestionsByGPT(
    $input: MatchKeywordsAndQuestionsByGPTInput!
  ) {
    matchKeywordsAndQuestionsByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type MatchKeywordsAndQuestionsByGptMutationFn = Apollo.MutationFunction<
  MatchKeywordsAndQuestionsByGpt,
  MatchKeywordsAndQuestionsByGptVariables
>;

/**
 * __useMatchKeywordsAndQuestionsByGpt__
 *
 * To run a mutation, you first call `useMatchKeywordsAndQuestionsByGpt` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchKeywordsAndQuestionsByGpt` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchKeywordsAndQuestionsByGpt, { data, loading, error }] = useMatchKeywordsAndQuestionsByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMatchKeywordsAndQuestionsByGpt(
  baseOptions?: Apollo.MutationHookOptions<
    MatchKeywordsAndQuestionsByGpt,
    MatchKeywordsAndQuestionsByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MatchKeywordsAndQuestionsByGpt,
    MatchKeywordsAndQuestionsByGptVariables
  >(MatchKeywordsAndQuestionsByGptDocument, options);
}
export type MatchKeywordsAndQuestionsByGptHookResult = ReturnType<
  typeof useMatchKeywordsAndQuestionsByGpt
>;
export type MatchKeywordsAndQuestionsByGptMutationResult =
  Apollo.MutationResult<MatchKeywordsAndQuestionsByGpt>;
export type MatchKeywordsAndQuestionsByGptMutationOptions =
  Apollo.BaseMutationOptions<
    MatchKeywordsAndQuestionsByGpt,
    MatchKeywordsAndQuestionsByGptVariables
  >;
export const GetGoogleTopRankingsDocument = gql`
  query GetGoogleTopRankings($input: GetGoogleTopRankingsInput!) {
    getGoogleTopRankings(input: $input) {
      locale
      averageWordCount
      ttl
      searchResults {
        summary
        position
        title
        url
        outline {
          level
          title
          __typename
        }
      }
      searchTerm
    }
  }
`;

/**
 * __useGetGoogleTopRankings__
 *
 * To run a query within a React component, call `useGetGoogleTopRankings` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleTopRankings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleTopRankings({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGoogleTopRankings(
  baseOptions: Apollo.QueryHookOptions<
    GetGoogleTopRankings,
    GetGoogleTopRankingsVariables
  > &
    (
      | { variables: GetGoogleTopRankingsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetGoogleTopRankings, GetGoogleTopRankingsVariables>(
    GetGoogleTopRankingsDocument,
    options
  );
}
export function useGetGoogleTopRankingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGoogleTopRankings,
    GetGoogleTopRankingsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGoogleTopRankings,
    GetGoogleTopRankingsVariables
  >(GetGoogleTopRankingsDocument, options);
}
export function useGetGoogleTopRankingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGoogleTopRankings,
    GetGoogleTopRankingsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGoogleTopRankings,
    GetGoogleTopRankingsVariables
  >(GetGoogleTopRankingsDocument, options);
}
export type GetGoogleTopRankingsHookResult = ReturnType<
  typeof useGetGoogleTopRankings
>;
export type GetGoogleTopRankingsLazyQueryHookResult = ReturnType<
  typeof useGetGoogleTopRankingsLazyQuery
>;
export type GetGoogleTopRankingsSuspenseQueryHookResult = ReturnType<
  typeof useGetGoogleTopRankingsSuspenseQuery
>;
export type GetGoogleTopRankingsQueryResult = Apollo.QueryResult<
  GetGoogleTopRankings,
  GetGoogleTopRankingsVariables
>;
export const ListArticleHeadlinesDocument = gql`
  query ListArticleHeadlines($articleId: String!) {
    listArticleHeadlines(articleId: $articleId) {
      items {
        ...ArticleHeadlineFull
      }
    }
  }
  ${ArticleHeadlineFull}
`;

/**
 * __useListArticleHeadlines__
 *
 * To run a query within a React component, call `useListArticleHeadlines` and pass it any options that fit your needs.
 * When your component renders, `useListArticleHeadlines` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleHeadlines({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useListArticleHeadlines(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleHeadlines,
    ListArticleHeadlinesVariables
  > &
    (
      | { variables: ListArticleHeadlinesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleHeadlines, ListArticleHeadlinesVariables>(
    ListArticleHeadlinesDocument,
    options
  );
}
export function useListArticleHeadlinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleHeadlines,
    ListArticleHeadlinesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticleHeadlines,
    ListArticleHeadlinesVariables
  >(ListArticleHeadlinesDocument, options);
}
export function useListArticleHeadlinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleHeadlines,
    ListArticleHeadlinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleHeadlines,
    ListArticleHeadlinesVariables
  >(ListArticleHeadlinesDocument, options);
}
export type ListArticleHeadlinesHookResult = ReturnType<
  typeof useListArticleHeadlines
>;
export type ListArticleHeadlinesLazyQueryHookResult = ReturnType<
  typeof useListArticleHeadlinesLazyQuery
>;
export type ListArticleHeadlinesSuspenseQueryHookResult = ReturnType<
  typeof useListArticleHeadlinesSuspenseQuery
>;
export type ListArticleHeadlinesQueryResult = Apollo.QueryResult<
  ListArticleHeadlines,
  ListArticleHeadlinesVariables
>;
export const GenerateHeadlineInOutlineByGptDocument = gql`
  query GenerateHeadlineInOutlineByGPT($input: GenerateHeadlineByGPTInput!) {
    generateHeadlineInOutlineByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateHeadlineInOutlineByGpt__
 *
 * To run a query within a React component, call `useGenerateHeadlineInOutlineByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateHeadlineInOutlineByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateHeadlineInOutlineByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateHeadlineInOutlineByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  > &
    (
      | { variables: GenerateHeadlineInOutlineByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  >(GenerateHeadlineInOutlineByGptDocument, options);
}
export function useGenerateHeadlineInOutlineByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  >(GenerateHeadlineInOutlineByGptDocument, options);
}
export function useGenerateHeadlineInOutlineByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateHeadlineInOutlineByGpt,
    GenerateHeadlineInOutlineByGptVariables
  >(GenerateHeadlineInOutlineByGptDocument, options);
}
export type GenerateHeadlineInOutlineByGptHookResult = ReturnType<
  typeof useGenerateHeadlineInOutlineByGpt
>;
export type GenerateHeadlineInOutlineByGptLazyQueryHookResult = ReturnType<
  typeof useGenerateHeadlineInOutlineByGptLazyQuery
>;
export type GenerateHeadlineInOutlineByGptSuspenseQueryHookResult = ReturnType<
  typeof useGenerateHeadlineInOutlineByGptSuspenseQuery
>;
export type GenerateHeadlineInOutlineByGptQueryResult = Apollo.QueryResult<
  GenerateHeadlineInOutlineByGpt,
  GenerateHeadlineInOutlineByGptVariables
>;
export const GenerateHeadlineSuggestionsInOutlineByGptDocument = gql`
  query GenerateHeadlineSuggestionsInOutlineByGPT(
    $input: GenerateHeadlineSuggestionsByGPTInput!
  ) {
    generateHeadlineSuggestionsInOutlineByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateHeadlineSuggestionsInOutlineByGpt__
 *
 * To run a query within a React component, call `useGenerateHeadlineSuggestionsInOutlineByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateHeadlineSuggestionsInOutlineByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateHeadlineSuggestionsInOutlineByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateHeadlineSuggestionsInOutlineByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  > &
    (
      | {
          variables: GenerateHeadlineSuggestionsInOutlineByGptVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >(GenerateHeadlineSuggestionsInOutlineByGptDocument, options);
}
export function useGenerateHeadlineSuggestionsInOutlineByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >(GenerateHeadlineSuggestionsInOutlineByGptDocument, options);
}
export function useGenerateHeadlineSuggestionsInOutlineByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >(GenerateHeadlineSuggestionsInOutlineByGptDocument, options);
}
export type GenerateHeadlineSuggestionsInOutlineByGptHookResult = ReturnType<
  typeof useGenerateHeadlineSuggestionsInOutlineByGpt
>;
export type GenerateHeadlineSuggestionsInOutlineByGptLazyQueryHookResult =
  ReturnType<typeof useGenerateHeadlineSuggestionsInOutlineByGptLazyQuery>;
export type GenerateHeadlineSuggestionsInOutlineByGptSuspenseQueryHookResult =
  ReturnType<typeof useGenerateHeadlineSuggestionsInOutlineByGptSuspenseQuery>;
export type GenerateHeadlineSuggestionsInOutlineByGptQueryResult =
  Apollo.QueryResult<
    GenerateHeadlineSuggestionsInOutlineByGpt,
    GenerateHeadlineSuggestionsInOutlineByGptVariables
  >;
export const ListArticleKeywordsDocument = gql`
  query ListArticleKeywords($articleId: String!, $onlyMatched: Boolean) {
    listArticleKeywords(articleId: $articleId, onlyMatched: $onlyMatched) {
      ...ArticleKeywordBase
      ...ArticleKeywordBrief
      ...ArticleKeywordKeywordVariations
    }
  }
  ${ArticleKeywordBase}
  ${ArticleKeywordBrief}
  ${ArticleKeywordKeywordVariations}
`;

/**
 * __useListArticleKeywords__
 *
 * To run a query within a React component, call `useListArticleKeywords` and pass it any options that fit your needs.
 * When your component renders, `useListArticleKeywords` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleKeywords({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      onlyMatched: // value for 'onlyMatched'
 *   },
 * });
 */
export function useListArticleKeywords(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleKeywords,
    ListArticleKeywordsVariables
  > &
    (
      | { variables: ListArticleKeywordsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleKeywords, ListArticleKeywordsVariables>(
    ListArticleKeywordsDocument,
    options
  );
}
export function useListArticleKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleKeywords,
    ListArticleKeywordsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListArticleKeywords, ListArticleKeywordsVariables>(
    ListArticleKeywordsDocument,
    options
  );
}
export function useListArticleKeywordsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleKeywords,
    ListArticleKeywordsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleKeywords,
    ListArticleKeywordsVariables
  >(ListArticleKeywordsDocument, options);
}
export type ListArticleKeywordsHookResult = ReturnType<
  typeof useListArticleKeywords
>;
export type ListArticleKeywordsLazyQueryHookResult = ReturnType<
  typeof useListArticleKeywordsLazyQuery
>;
export type ListArticleKeywordsSuspenseQueryHookResult = ReturnType<
  typeof useListArticleKeywordsSuspenseQuery
>;
export type ListArticleKeywordsQueryResult = Apollo.QueryResult<
  ListArticleKeywords,
  ListArticleKeywordsVariables
>;
export const ListArticleQuestionsDocument = gql`
  query ListArticleQuestions($articleId: String!) {
    listArticleQuestions(articleId: $articleId) {
      ...ArticleQuestionBase
      ...ArticleQuestionBrief
    }
  }
  ${ArticleQuestionBase}
  ${ArticleQuestionBrief}
`;

/**
 * __useListArticleQuestions__
 *
 * To run a query within a React component, call `useListArticleQuestions` and pass it any options that fit your needs.
 * When your component renders, `useListArticleQuestions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleQuestions({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useListArticleQuestions(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleQuestions,
    ListArticleQuestionsVariables
  > &
    (
      | { variables: ListArticleQuestionsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleQuestions, ListArticleQuestionsVariables>(
    ListArticleQuestionsDocument,
    options
  );
}
export function useListArticleQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleQuestions,
    ListArticleQuestionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticleQuestions,
    ListArticleQuestionsVariables
  >(ListArticleQuestionsDocument, options);
}
export function useListArticleQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleQuestions,
    ListArticleQuestionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleQuestions,
    ListArticleQuestionsVariables
  >(ListArticleQuestionsDocument, options);
}
export type ListArticleQuestionsHookResult = ReturnType<
  typeof useListArticleQuestions
>;
export type ListArticleQuestionsLazyQueryHookResult = ReturnType<
  typeof useListArticleQuestionsLazyQuery
>;
export type ListArticleQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useListArticleQuestionsSuspenseQuery
>;
export type ListArticleQuestionsQueryResult = Apollo.QueryResult<
  ListArticleQuestions,
  ListArticleQuestionsVariables
>;
export const GetProjectBriefDocumentUsageCountDocument = gql`
  query getProjectBriefDocumentUsageCount(
    $input: GetProjectBriefDocumentUsageCountInput!
  ) {
    getProjectBriefDocumentUsageCount(input: $input)
  }
`;

/**
 * __useGetProjectBriefDocumentUsageCount__
 *
 * To run a query within a React component, call `useGetProjectBriefDocumentUsageCount` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBriefDocumentUsageCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBriefDocumentUsageCount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProjectBriefDocumentUsageCount(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  > &
    (
      | {
          variables: GetProjectBriefDocumentUsageCountVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  >(GetProjectBriefDocumentUsageCountDocument, options);
}
export function useGetProjectBriefDocumentUsageCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  >(GetProjectBriefDocumentUsageCountDocument, options);
}
export function useGetProjectBriefDocumentUsageCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectBriefDocumentUsageCount,
    GetProjectBriefDocumentUsageCountVariables
  >(GetProjectBriefDocumentUsageCountDocument, options);
}
export type GetProjectBriefDocumentUsageCountHookResult = ReturnType<
  typeof useGetProjectBriefDocumentUsageCount
>;
export type GetProjectBriefDocumentUsageCountLazyQueryHookResult = ReturnType<
  typeof useGetProjectBriefDocumentUsageCountLazyQuery
>;
export type GetProjectBriefDocumentUsageCountSuspenseQueryHookResult =
  ReturnType<typeof useGetProjectBriefDocumentUsageCountSuspenseQuery>;
export type GetProjectBriefDocumentUsageCountQueryResult = Apollo.QueryResult<
  GetProjectBriefDocumentUsageCount,
  GetProjectBriefDocumentUsageCountVariables
>;
export const OnArticleBriefCreateDocument = gql`
  subscription OnArticleBriefCreate(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleBriefCreate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ArticleBriefSubscriptionFull
    }
  }
  ${ArticleBriefSubscriptionFull}
`;

/**
 * __useOnArticleBriefCreate__
 *
 * To run a query within a React component, call `useOnArticleBriefCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleBriefCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleBriefCreate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleBriefCreate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleBriefCreate,
    OnArticleBriefCreateVariables
  > &
    (
      | { variables: OnArticleBriefCreateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnArticleBriefCreate,
    OnArticleBriefCreateVariables
  >(OnArticleBriefCreateDocument, options);
}
export type OnArticleBriefCreateHookResult = ReturnType<
  typeof useOnArticleBriefCreate
>;
export type OnArticleBriefCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleBriefCreate>;
export const OnArticleBriefUpdateDocument = gql`
  subscription OnArticleBriefUpdate(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleBriefUpdate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ArticleBriefSubscriptionFull
    }
  }
  ${ArticleBriefSubscriptionFull}
`;

/**
 * __useOnArticleBriefUpdate__
 *
 * To run a query within a React component, call `useOnArticleBriefUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleBriefUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleBriefUpdate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleBriefUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleBriefUpdate,
    OnArticleBriefUpdateVariables
  > &
    (
      | { variables: OnArticleBriefUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnArticleBriefUpdate,
    OnArticleBriefUpdateVariables
  >(OnArticleBriefUpdateDocument, options);
}
export type OnArticleBriefUpdateHookResult = ReturnType<
  typeof useOnArticleBriefUpdate
>;
export type OnArticleBriefUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleBriefUpdate>;
export const OnArticleBriefDeleteDocument = gql`
  subscription OnArticleBriefDelete(
    $userId: String!
    $pk1: String!
    $filter: String
  ) {
    onArticleBriefDelete(userId: $userId, pk1: $pk1, filter: $filter) {
      type
      pk1
      sk1
      userId
    }
  }
`;

/**
 * __useOnArticleBriefDelete__
 *
 * To run a query within a React component, call `useOnArticleBriefDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnArticleBriefDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnArticleBriefDelete({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnArticleBriefDelete(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnArticleBriefDelete,
    OnArticleBriefDeleteVariables
  > &
    (
      | { variables: OnArticleBriefDeleteVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnArticleBriefDelete,
    OnArticleBriefDeleteVariables
  >(OnArticleBriefDeleteDocument, options);
}
export type OnArticleBriefDeleteHookResult = ReturnType<
  typeof useOnArticleBriefDelete
>;
export type OnArticleBriefDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnArticleBriefDelete>;
export const ListBrokenLinksInArticleDocument = gql`
  query ListBrokenLinksInArticle($input: ListBrokenLinksInArticleInput!) {
    listBrokenLinksInArticle(input: $input) {
      items
    }
  }
`;

/**
 * __useListBrokenLinksInArticle__
 *
 * To run a query within a React component, call `useListBrokenLinksInArticle` and pass it any options that fit your needs.
 * When your component renders, `useListBrokenLinksInArticle` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBrokenLinksInArticle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListBrokenLinksInArticle(
  baseOptions: Apollo.QueryHookOptions<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  > &
    (
      | { variables: ListBrokenLinksInArticleVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  >(ListBrokenLinksInArticleDocument, options);
}
export function useListBrokenLinksInArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  >(ListBrokenLinksInArticleDocument, options);
}
export function useListBrokenLinksInArticleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListBrokenLinksInArticle,
    ListBrokenLinksInArticleVariables
  >(ListBrokenLinksInArticleDocument, options);
}
export type ListBrokenLinksInArticleHookResult = ReturnType<
  typeof useListBrokenLinksInArticle
>;
export type ListBrokenLinksInArticleLazyQueryHookResult = ReturnType<
  typeof useListBrokenLinksInArticleLazyQuery
>;
export type ListBrokenLinksInArticleSuspenseQueryHookResult = ReturnType<
  typeof useListBrokenLinksInArticleSuspenseQuery
>;
export type ListBrokenLinksInArticleQueryResult = Apollo.QueryResult<
  ListBrokenLinksInArticle,
  ListBrokenLinksInArticleVariables
>;
export const ListBrokenLinksInProjectDocument = gql`
  query ListBrokenLinksInProject($input: ListBrokenLinksInProjectInput!) {
    listBrokenLinksInProject(input: $input) {
      items {
        id
        articleTitle
        articleId
        url
        updatedAt
        __typename
      }
    }
  }
`;

/**
 * __useListBrokenLinksInProject__
 *
 * To run a query within a React component, call `useListBrokenLinksInProject` and pass it any options that fit your needs.
 * When your component renders, `useListBrokenLinksInProject` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBrokenLinksInProject({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListBrokenLinksInProject(
  baseOptions: Apollo.QueryHookOptions<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  > &
    (
      | { variables: ListBrokenLinksInProjectVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  >(ListBrokenLinksInProjectDocument, options);
}
export function useListBrokenLinksInProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  >(ListBrokenLinksInProjectDocument, options);
}
export function useListBrokenLinksInProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListBrokenLinksInProject,
    ListBrokenLinksInProjectVariables
  >(ListBrokenLinksInProjectDocument, options);
}
export type ListBrokenLinksInProjectHookResult = ReturnType<
  typeof useListBrokenLinksInProject
>;
export type ListBrokenLinksInProjectLazyQueryHookResult = ReturnType<
  typeof useListBrokenLinksInProjectLazyQuery
>;
export type ListBrokenLinksInProjectSuspenseQueryHookResult = ReturnType<
  typeof useListBrokenLinksInProjectSuspenseQuery
>;
export type ListBrokenLinksInProjectQueryResult = Apollo.QueryResult<
  ListBrokenLinksInProject,
  ListBrokenLinksInProjectVariables
>;
export const ListBrokenArticleUrlsDocument = gql`
  query ListBrokenArticleUrls($input: ListBrokenArticleUrlsInput!) {
    listBrokenArticleUrls(input: $input) {
      items {
        id
        articleTitle
        articleId
        url
        __typename
      }
    }
  }
`;

/**
 * __useListBrokenArticleUrls__
 *
 * To run a query within a React component, call `useListBrokenArticleUrls` and pass it any options that fit your needs.
 * When your component renders, `useListBrokenArticleUrls` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBrokenArticleUrls({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListBrokenArticleUrls(
  baseOptions: Apollo.QueryHookOptions<
    ListBrokenArticleUrls,
    ListBrokenArticleUrlsVariables
  > &
    (
      | { variables: ListBrokenArticleUrlsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListBrokenArticleUrls, ListBrokenArticleUrlsVariables>(
    ListBrokenArticleUrlsDocument,
    options
  );
}
export function useListBrokenArticleUrlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBrokenArticleUrls,
    ListBrokenArticleUrlsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListBrokenArticleUrls,
    ListBrokenArticleUrlsVariables
  >(ListBrokenArticleUrlsDocument, options);
}
export function useListBrokenArticleUrlsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListBrokenArticleUrls,
    ListBrokenArticleUrlsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListBrokenArticleUrls,
    ListBrokenArticleUrlsVariables
  >(ListBrokenArticleUrlsDocument, options);
}
export type ListBrokenArticleUrlsHookResult = ReturnType<
  typeof useListBrokenArticleUrls
>;
export type ListBrokenArticleUrlsLazyQueryHookResult = ReturnType<
  typeof useListBrokenArticleUrlsLazyQuery
>;
export type ListBrokenArticleUrlsSuspenseQueryHookResult = ReturnType<
  typeof useListBrokenArticleUrlsSuspenseQuery
>;
export type ListBrokenArticleUrlsQueryResult = Apollo.QueryResult<
  ListBrokenArticleUrls,
  ListBrokenArticleUrlsVariables
>;
export const CreateArticleCommentDocument = gql`
  mutation CreateArticleComment($input: CreateArticleCommentInput!) {
    createArticleComment(input: $input) {
      ...ArticleCommentFull
    }
  }
  ${ArticleCommentFull}
`;
export type CreateArticleCommentMutationFn = Apollo.MutationFunction<
  CreateArticleComment,
  CreateArticleCommentVariables
>;

/**
 * __useCreateArticleComment__
 *
 * To run a mutation, you first call `useCreateArticleComment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleComment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleComment, { data, loading, error }] = useCreateArticleComment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleComment(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleComment,
    CreateArticleCommentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleComment,
    CreateArticleCommentVariables
  >(CreateArticleCommentDocument, options);
}
export type CreateArticleCommentHookResult = ReturnType<
  typeof useCreateArticleComment
>;
export type CreateArticleCommentMutationResult =
  Apollo.MutationResult<CreateArticleComment>;
export type CreateArticleCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleComment,
  CreateArticleCommentVariables
>;
export const UpdateArticleCommentDocument = gql`
  mutation UpdateArticleComment($input: UpdateArticleCommentInput!) {
    updateArticleComment(input: $input) {
      ...ArticleCommentFull
    }
  }
  ${ArticleCommentFull}
`;
export type UpdateArticleCommentMutationFn = Apollo.MutationFunction<
  UpdateArticleComment,
  UpdateArticleCommentVariables
>;

/**
 * __useUpdateArticleComment__
 *
 * To run a mutation, you first call `useUpdateArticleComment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleComment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleComment, { data, loading, error }] = useUpdateArticleComment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleComment(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleComment,
    UpdateArticleCommentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleComment,
    UpdateArticleCommentVariables
  >(UpdateArticleCommentDocument, options);
}
export type UpdateArticleCommentHookResult = ReturnType<
  typeof useUpdateArticleComment
>;
export type UpdateArticleCommentMutationResult =
  Apollo.MutationResult<UpdateArticleComment>;
export type UpdateArticleCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleComment,
  UpdateArticleCommentVariables
>;
export const DeleteArticleCommentDocument = gql`
  mutation DeleteArticleComment($input: DeleteArticleCommentInput!) {
    deleteArticleComment(input: $input) {
      id
    }
  }
`;
export type DeleteArticleCommentMutationFn = Apollo.MutationFunction<
  DeleteArticleComment,
  DeleteArticleCommentVariables
>;

/**
 * __useDeleteArticleComment__
 *
 * To run a mutation, you first call `useDeleteArticleComment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleComment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleComment, { data, loading, error }] = useDeleteArticleComment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticleComment(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleComment,
    DeleteArticleCommentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleComment,
    DeleteArticleCommentVariables
  >(DeleteArticleCommentDocument, options);
}
export type DeleteArticleCommentHookResult = ReturnType<
  typeof useDeleteArticleComment
>;
export type DeleteArticleCommentMutationResult =
  Apollo.MutationResult<DeleteArticleComment>;
export type DeleteArticleCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleComment,
  DeleteArticleCommentVariables
>;
export const GetArticleCommentDocument = gql`
  query GetArticleComment($input: GetArticleCommentInput!) {
    getArticleComment(input: $input) {
      ...ArticleCommentFull
    }
  }
  ${ArticleCommentFull}
`;

/**
 * __useGetArticleComment__
 *
 * To run a query within a React component, call `useGetArticleComment` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleComment` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleComment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetArticleComment(
  baseOptions: Apollo.QueryHookOptions<
    GetArticleComment,
    GetArticleCommentVariables
  > &
    (
      | { variables: GetArticleCommentVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticleComment, GetArticleCommentVariables>(
    GetArticleCommentDocument,
    options
  );
}
export function useGetArticleCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleComment,
    GetArticleCommentVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticleComment, GetArticleCommentVariables>(
    GetArticleCommentDocument,
    options
  );
}
export function useGetArticleCommentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticleComment,
    GetArticleCommentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticleComment, GetArticleCommentVariables>(
    GetArticleCommentDocument,
    options
  );
}
export type GetArticleCommentHookResult = ReturnType<
  typeof useGetArticleComment
>;
export type GetArticleCommentLazyQueryHookResult = ReturnType<
  typeof useGetArticleCommentLazyQuery
>;
export type GetArticleCommentSuspenseQueryHookResult = ReturnType<
  typeof useGetArticleCommentSuspenseQuery
>;
export type GetArticleCommentQueryResult = Apollo.QueryResult<
  GetArticleComment,
  GetArticleCommentVariables
>;
export const ListArticleCommentsDocument = gql`
  query ListArticleComments($input: ListArticleCommentInput!) {
    listArticleComments(input: $input) {
      items {
        ...ArticleCommentFull
      }
    }
  }
  ${ArticleCommentFull}
`;

/**
 * __useListArticleComments__
 *
 * To run a query within a React component, call `useListArticleComments` and pass it any options that fit your needs.
 * When your component renders, `useListArticleComments` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticleComments({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListArticleComments(
  baseOptions: Apollo.QueryHookOptions<
    ListArticleComments,
    ListArticleCommentsVariables
  > &
    (
      | { variables: ListArticleCommentsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticleComments, ListArticleCommentsVariables>(
    ListArticleCommentsDocument,
    options
  );
}
export function useListArticleCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticleComments,
    ListArticleCommentsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListArticleComments, ListArticleCommentsVariables>(
    ListArticleCommentsDocument,
    options
  );
}
export function useListArticleCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticleComments,
    ListArticleCommentsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticleComments,
    ListArticleCommentsVariables
  >(ListArticleCommentsDocument, options);
}
export type ListArticleCommentsHookResult = ReturnType<
  typeof useListArticleComments
>;
export type ListArticleCommentsLazyQueryHookResult = ReturnType<
  typeof useListArticleCommentsLazyQuery
>;
export type ListArticleCommentsSuspenseQueryHookResult = ReturnType<
  typeof useListArticleCommentsSuspenseQuery
>;
export type ListArticleCommentsQueryResult = Apollo.QueryResult<
  ListArticleComments,
  ListArticleCommentsVariables
>;
export const TriggerRScoreDocument = gql`
  mutation TriggerRScore($articleId: ID!) {
    triggerRScore(articleId: $articleId)
  }
`;
export type TriggerRScoreMutationFn = Apollo.MutationFunction<
  TriggerRScore,
  TriggerRScoreVariables
>;

/**
 * __useTriggerRScore__
 *
 * To run a mutation, you first call `useTriggerRScore` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRScore` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRScore, { data, loading, error }] = useTriggerRScore({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useTriggerRScore(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerRScore,
    TriggerRScoreVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerRScore, TriggerRScoreVariables>(
    TriggerRScoreDocument,
    options
  );
}
export type TriggerRScoreHookResult = ReturnType<typeof useTriggerRScore>;
export type TriggerRScoreMutationResult = Apollo.MutationResult<TriggerRScore>;
export type TriggerRScoreMutationOptions = Apollo.BaseMutationOptions<
  TriggerRScore,
  TriggerRScoreVariables
>;
export const GenerateMetadataByGptDocument = gql`
  mutation GenerateMetadataByGPT($input: GenerateMetadataByGPTInput!) {
    generateMetadataByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type GenerateMetadataByGptMutationFn = Apollo.MutationFunction<
  GenerateMetadataByGpt,
  GenerateMetadataByGptVariables
>;

/**
 * __useGenerateMetadataByGpt__
 *
 * To run a mutation, you first call `useGenerateMetadataByGpt` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMetadataByGpt` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMetadataByGpt, { data, loading, error }] = useGenerateMetadataByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMetadataByGpt(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateMetadataByGpt,
    GenerateMetadataByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateMetadataByGpt,
    GenerateMetadataByGptVariables
  >(GenerateMetadataByGptDocument, options);
}
export type GenerateMetadataByGptHookResult = ReturnType<
  typeof useGenerateMetadataByGpt
>;
export type GenerateMetadataByGptMutationResult =
  Apollo.MutationResult<GenerateMetadataByGpt>;
export type GenerateMetadataByGptMutationOptions = Apollo.BaseMutationOptions<
  GenerateMetadataByGpt,
  GenerateMetadataByGptVariables
>;
export const GenerateArticleConclusionByGptDocument = gql`
  query GenerateArticleConclusionByGPT($input: ConclusionByGPTInput!) {
    generateConclusionByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateArticleConclusionByGpt__
 *
 * To run a query within a React component, call `useGenerateArticleConclusionByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateArticleConclusionByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateArticleConclusionByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateArticleConclusionByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  > &
    (
      | { variables: GenerateArticleConclusionByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  >(GenerateArticleConclusionByGptDocument, options);
}
export function useGenerateArticleConclusionByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  >(GenerateArticleConclusionByGptDocument, options);
}
export function useGenerateArticleConclusionByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateArticleConclusionByGpt,
    GenerateArticleConclusionByGptVariables
  >(GenerateArticleConclusionByGptDocument, options);
}
export type GenerateArticleConclusionByGptHookResult = ReturnType<
  typeof useGenerateArticleConclusionByGpt
>;
export type GenerateArticleConclusionByGptLazyQueryHookResult = ReturnType<
  typeof useGenerateArticleConclusionByGptLazyQuery
>;
export type GenerateArticleConclusionByGptSuspenseQueryHookResult = ReturnType<
  typeof useGenerateArticleConclusionByGptSuspenseQuery
>;
export type GenerateArticleConclusionByGptQueryResult = Apollo.QueryResult<
  GenerateArticleConclusionByGpt,
  GenerateArticleConclusionByGptVariables
>;
export const ContinueTextByGptDocument = gql`
  query ContinueTextByGPT($input: ContinueTextByGPTInput!) {
    continueTextByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useContinueTextByGpt__
 *
 * To run a query within a React component, call `useContinueTextByGpt` and pass it any options that fit your needs.
 * When your component renders, `useContinueTextByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContinueTextByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContinueTextByGpt(
  baseOptions: Apollo.QueryHookOptions<
    ContinueTextByGpt,
    ContinueTextByGptVariables
  > &
    (
      | { variables: ContinueTextByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ContinueTextByGpt, ContinueTextByGptVariables>(
    ContinueTextByGptDocument,
    options
  );
}
export function useContinueTextByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContinueTextByGpt,
    ContinueTextByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ContinueTextByGpt, ContinueTextByGptVariables>(
    ContinueTextByGptDocument,
    options
  );
}
export function useContinueTextByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContinueTextByGpt,
    ContinueTextByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContinueTextByGpt, ContinueTextByGptVariables>(
    ContinueTextByGptDocument,
    options
  );
}
export type ContinueTextByGptHookResult = ReturnType<
  typeof useContinueTextByGpt
>;
export type ContinueTextByGptLazyQueryHookResult = ReturnType<
  typeof useContinueTextByGptLazyQuery
>;
export type ContinueTextByGptSuspenseQueryHookResult = ReturnType<
  typeof useContinueTextByGptSuspenseQuery
>;
export type ContinueTextByGptQueryResult = Apollo.QueryResult<
  ContinueTextByGpt,
  ContinueTextByGptVariables
>;
export const AnswerQuestionByGptDocument = gql`
  query AnswerQuestionByGPT($input: AnswerQuestionByGPTInput!) {
    answerQuestionByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useAnswerQuestionByGpt__
 *
 * To run a query within a React component, call `useAnswerQuestionByGpt` and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuestionByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerQuestionByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerQuestionByGpt(
  baseOptions: Apollo.QueryHookOptions<
    AnswerQuestionByGpt,
    AnswerQuestionByGptVariables
  > &
    (
      | { variables: AnswerQuestionByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<AnswerQuestionByGpt, AnswerQuestionByGptVariables>(
    AnswerQuestionByGptDocument,
    options
  );
}
export function useAnswerQuestionByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnswerQuestionByGpt,
    AnswerQuestionByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<AnswerQuestionByGpt, AnswerQuestionByGptVariables>(
    AnswerQuestionByGptDocument,
    options
  );
}
export function useAnswerQuestionByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AnswerQuestionByGpt,
    AnswerQuestionByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AnswerQuestionByGpt,
    AnswerQuestionByGptVariables
  >(AnswerQuestionByGptDocument, options);
}
export type AnswerQuestionByGptHookResult = ReturnType<
  typeof useAnswerQuestionByGpt
>;
export type AnswerQuestionByGptLazyQueryHookResult = ReturnType<
  typeof useAnswerQuestionByGptLazyQuery
>;
export type AnswerQuestionByGptSuspenseQueryHookResult = ReturnType<
  typeof useAnswerQuestionByGptSuspenseQuery
>;
export type AnswerQuestionByGptQueryResult = Apollo.QueryResult<
  AnswerQuestionByGpt,
  AnswerQuestionByGptVariables
>;
export const GenerateHeadlineInWriteByGptDocument = gql`
  query GenerateHeadlineInWriteByGPT($input: GenerateHeadlineByGPTInput!) {
    generateHeadlineInWriteByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateHeadlineInWriteByGpt__
 *
 * To run a query within a React component, call `useGenerateHeadlineInWriteByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateHeadlineInWriteByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateHeadlineInWriteByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateHeadlineInWriteByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  > &
    (
      | { variables: GenerateHeadlineInWriteByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  >(GenerateHeadlineInWriteByGptDocument, options);
}
export function useGenerateHeadlineInWriteByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  >(GenerateHeadlineInWriteByGptDocument, options);
}
export function useGenerateHeadlineInWriteByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateHeadlineInWriteByGpt,
    GenerateHeadlineInWriteByGptVariables
  >(GenerateHeadlineInWriteByGptDocument, options);
}
export type GenerateHeadlineInWriteByGptHookResult = ReturnType<
  typeof useGenerateHeadlineInWriteByGpt
>;
export type GenerateHeadlineInWriteByGptLazyQueryHookResult = ReturnType<
  typeof useGenerateHeadlineInWriteByGptLazyQuery
>;
export type GenerateHeadlineInWriteByGptSuspenseQueryHookResult = ReturnType<
  typeof useGenerateHeadlineInWriteByGptSuspenseQuery
>;
export type GenerateHeadlineInWriteByGptQueryResult = Apollo.QueryResult<
  GenerateHeadlineInWriteByGpt,
  GenerateHeadlineInWriteByGptVariables
>;
export const GenerateImageByDallEDocument = gql`
  query GenerateImageByDallE($input: GenerateImageByDallEInput!) {
    generateImageByDallE(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateImageByDallE__
 *
 * To run a query within a React component, call `useGenerateImageByDallE` and pass it any options that fit your needs.
 * When your component renders, `useGenerateImageByDallE` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateImageByDallE({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateImageByDallE(
  baseOptions: Apollo.QueryHookOptions<
    GenerateImageByDallE,
    GenerateImageByDallEVariables
  > &
    (
      | { variables: GenerateImageByDallEVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GenerateImageByDallE, GenerateImageByDallEVariables>(
    GenerateImageByDallEDocument,
    options
  );
}
export function useGenerateImageByDallELazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateImageByDallE,
    GenerateImageByDallEVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateImageByDallE,
    GenerateImageByDallEVariables
  >(GenerateImageByDallEDocument, options);
}
export function useGenerateImageByDallESuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateImageByDallE,
    GenerateImageByDallEVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateImageByDallE,
    GenerateImageByDallEVariables
  >(GenerateImageByDallEDocument, options);
}
export type GenerateImageByDallEHookResult = ReturnType<
  typeof useGenerateImageByDallE
>;
export type GenerateImageByDallELazyQueryHookResult = ReturnType<
  typeof useGenerateImageByDallELazyQuery
>;
export type GenerateImageByDallESuspenseQueryHookResult = ReturnType<
  typeof useGenerateImageByDallESuspenseQuery
>;
export type GenerateImageByDallEQueryResult = Apollo.QueryResult<
  GenerateImageByDallE,
  GenerateImageByDallEVariables
>;
export const GetSimilarQuestionsDocument = gql`
  query GetSimilarQuestions($input: SimilarQuestionsInput!) {
    getSimilarQuestions(input: $input) {
      items {
        question
      }
    }
  }
`;

/**
 * __useGetSimilarQuestions__
 *
 * To run a query within a React component, call `useGetSimilarQuestions` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarQuestions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarQuestions({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSimilarQuestions(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarQuestions,
    GetSimilarQuestionsVariables
  > &
    (
      | { variables: GetSimilarQuestionsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetSimilarQuestions, GetSimilarQuestionsVariables>(
    GetSimilarQuestionsDocument,
    options
  );
}
export function useGetSimilarQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarQuestions,
    GetSimilarQuestionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetSimilarQuestions, GetSimilarQuestionsVariables>(
    GetSimilarQuestionsDocument,
    options
  );
}
export function useGetSimilarQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSimilarQuestions,
    GetSimilarQuestionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSimilarQuestions,
    GetSimilarQuestionsVariables
  >(GetSimilarQuestionsDocument, options);
}
export type GetSimilarQuestionsHookResult = ReturnType<
  typeof useGetSimilarQuestions
>;
export type GetSimilarQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetSimilarQuestionsLazyQuery
>;
export type GetSimilarQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useGetSimilarQuestionsSuspenseQuery
>;
export type GetSimilarQuestionsQueryResult = Apollo.QueryResult<
  GetSimilarQuestions,
  GetSimilarQuestionsVariables
>;
export const GetSimilarPhrasesByGptWithVolumesDocument = gql`
  query GetSimilarPhrasesByGPTWithVolumes($input: SimilarPhrasesByGPTInput!) {
    getSimilarPhrasesByGPT(input: $input) {
      items {
        phrase
        required
        score
        volumes {
          amount
          month
          year
        }
      }
    }
  }
`;

/**
 * __useGetSimilarPhrasesByGptWithVolumes__
 *
 * To run a query within a React component, call `useGetSimilarPhrasesByGptWithVolumes` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarPhrasesByGptWithVolumes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarPhrasesByGptWithVolumes({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSimilarPhrasesByGptWithVolumes(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  > &
    (
      | {
          variables: GetSimilarPhrasesByGptWithVolumesVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  >(GetSimilarPhrasesByGptWithVolumesDocument, options);
}
export function useGetSimilarPhrasesByGptWithVolumesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  >(GetSimilarPhrasesByGptWithVolumesDocument, options);
}
export function useGetSimilarPhrasesByGptWithVolumesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSimilarPhrasesByGptWithVolumes,
    GetSimilarPhrasesByGptWithVolumesVariables
  >(GetSimilarPhrasesByGptWithVolumesDocument, options);
}
export type GetSimilarPhrasesByGptWithVolumesHookResult = ReturnType<
  typeof useGetSimilarPhrasesByGptWithVolumes
>;
export type GetSimilarPhrasesByGptWithVolumesLazyQueryHookResult = ReturnType<
  typeof useGetSimilarPhrasesByGptWithVolumesLazyQuery
>;
export type GetSimilarPhrasesByGptWithVolumesSuspenseQueryHookResult =
  ReturnType<typeof useGetSimilarPhrasesByGptWithVolumesSuspenseQuery>;
export type GetSimilarPhrasesByGptWithVolumesQueryResult = Apollo.QueryResult<
  GetSimilarPhrasesByGptWithVolumes,
  GetSimilarPhrasesByGptWithVolumesVariables
>;
export const GetSimilarPhrasesByNeuraverseForExplorePageDocument = gql`
  query GetSimilarPhrasesByNeuraverseForExplorePage(
    $input: SimilarPhrasesByNeuraverseInput!
  ) {
    getSimilarPhrasesByNeuraverse(input: $input) {
      items {
        phrase
        score
        volumes {
          amount
          month
          year
        }
      }
      varieties {
        variety
        phrases
      }
    }
  }
`;

/**
 * __useGetSimilarPhrasesByNeuraverseForExplorePage__
 *
 * To run a query within a React component, call `useGetSimilarPhrasesByNeuraverseForExplorePage` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarPhrasesByNeuraverseForExplorePage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarPhrasesByNeuraverseForExplorePage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSimilarPhrasesByNeuraverseForExplorePage(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  > &
    (
      | {
          variables: GetSimilarPhrasesByNeuraverseForExplorePageVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >(GetSimilarPhrasesByNeuraverseForExplorePageDocument, options);
}
export function useGetSimilarPhrasesByNeuraverseForExplorePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >(GetSimilarPhrasesByNeuraverseForExplorePageDocument, options);
}
export function useGetSimilarPhrasesByNeuraverseForExplorePageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >(GetSimilarPhrasesByNeuraverseForExplorePageDocument, options);
}
export type GetSimilarPhrasesByNeuraverseForExplorePageHookResult = ReturnType<
  typeof useGetSimilarPhrasesByNeuraverseForExplorePage
>;
export type GetSimilarPhrasesByNeuraverseForExplorePageLazyQueryHookResult =
  ReturnType<typeof useGetSimilarPhrasesByNeuraverseForExplorePageLazyQuery>;
export type GetSimilarPhrasesByNeuraverseForExplorePageSuspenseQueryHookResult =
  ReturnType<
    typeof useGetSimilarPhrasesByNeuraverseForExplorePageSuspenseQuery
  >;
export type GetSimilarPhrasesByNeuraverseForExplorePageQueryResult =
  Apollo.QueryResult<
    GetSimilarPhrasesByNeuraverseForExplorePage,
    GetSimilarPhrasesByNeuraverseForExplorePageVariables
  >;
export const WpExportDocument = gql`
  mutation WpExport($input: WordPressExportInput!) {
    wpExport(input: $input)
  }
`;
export type WpExportMutationFn = Apollo.MutationFunction<
  WpExport,
  WpExportVariables
>;

/**
 * __useWpExport__
 *
 * To run a mutation, you first call `useWpExport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWpExport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wpExport, { data, loading, error }] = useWpExport({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWpExport(
  baseOptions?: Apollo.MutationHookOptions<WpExport, WpExportVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WpExport, WpExportVariables>(
    WpExportDocument,
    options
  );
}
export type WpExportHookResult = ReturnType<typeof useWpExport>;
export type WpExportMutationResult = Apollo.MutationResult<WpExport>;
export type WpExportMutationOptions = Apollo.BaseMutationOptions<
  WpExport,
  WpExportVariables
>;
export const BulkExportDocument = gql`
  mutation BulkExport($input: BulkExportInput!) {
    bulkExport(input: $input)
  }
`;
export type BulkExportMutationFn = Apollo.MutationFunction<
  BulkExport,
  BulkExportVariables
>;

/**
 * __useBulkExport__
 *
 * To run a mutation, you first call `useBulkExport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkExport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkExport, { data, loading, error }] = useBulkExport({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkExport(
  baseOptions?: Apollo.MutationHookOptions<BulkExport, BulkExportVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkExport, BulkExportVariables>(
    BulkExportDocument,
    options
  );
}
export type BulkExportHookResult = ReturnType<typeof useBulkExport>;
export type BulkExportMutationResult = Apollo.MutationResult<BulkExport>;
export type BulkExportMutationOptions = Apollo.BaseMutationOptions<
  BulkExport,
  BulkExportVariables
>;
export const BulkWordPressExportDocument = gql`
  mutation BulkWordPressExport($input: BulkWordPressExportInput!) {
    bulkWordPressExport(input: $input)
  }
`;
export type BulkWordPressExportMutationFn = Apollo.MutationFunction<
  BulkWordPressExport,
  BulkWordPressExportVariables
>;

/**
 * __useBulkWordPressExport__
 *
 * To run a mutation, you first call `useBulkWordPressExport` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkWordPressExport` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkWordPressExport, { data, loading, error }] = useBulkWordPressExport({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkWordPressExport(
  baseOptions?: Apollo.MutationHookOptions<
    BulkWordPressExport,
    BulkWordPressExportVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkWordPressExport, BulkWordPressExportVariables>(
    BulkWordPressExportDocument,
    options
  );
}
export type BulkWordPressExportHookResult = ReturnType<
  typeof useBulkWordPressExport
>;
export type BulkWordPressExportMutationResult =
  Apollo.MutationResult<BulkWordPressExport>;
export type BulkWordPressExportMutationOptions = Apollo.BaseMutationOptions<
  BulkWordPressExport,
  BulkWordPressExportVariables
>;
export const UpsertWordpressConnectionDocument = gql`
  mutation UpsertWordpressConnection($input: UpsertWordpressConnectionInput) {
    upsertWordPressConnection(input: $input) {
      errors {
        code
        message
        tag
        data {
          status
        }
      }
    }
  }
`;
export type UpsertWordpressConnectionMutationFn = Apollo.MutationFunction<
  UpsertWordpressConnection,
  UpsertWordpressConnectionVariables
>;

/**
 * __useUpsertWordpressConnection__
 *
 * To run a mutation, you first call `useUpsertWordpressConnection` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWordpressConnection` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWordpressConnection, { data, loading, error }] = useUpsertWordpressConnection({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertWordpressConnection(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertWordpressConnection,
    UpsertWordpressConnectionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertWordpressConnection,
    UpsertWordpressConnectionVariables
  >(UpsertWordpressConnectionDocument, options);
}
export type UpsertWordpressConnectionHookResult = ReturnType<
  typeof useUpsertWordpressConnection
>;
export type UpsertWordpressConnectionMutationResult =
  Apollo.MutationResult<UpsertWordpressConnection>;
export type UpsertWordpressConnectionMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertWordpressConnection,
    UpsertWordpressConnectionVariables
  >;
export const GetBriefExportAsDocxDocument = gql`
  query GetBriefExportAsDocx($input: BriefExportInput!) {
    getBriefExportAsDocx(input: $input)
  }
`;

/**
 * __useGetBriefExportAsDocx__
 *
 * To run a query within a React component, call `useGetBriefExportAsDocx` and pass it any options that fit your needs.
 * When your component renders, `useGetBriefExportAsDocx` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBriefExportAsDocx({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBriefExportAsDocx(
  baseOptions: Apollo.QueryHookOptions<
    GetBriefExportAsDocx,
    GetBriefExportAsDocxVariables
  > &
    (
      | { variables: GetBriefExportAsDocxVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetBriefExportAsDocx, GetBriefExportAsDocxVariables>(
    GetBriefExportAsDocxDocument,
    options
  );
}
export function useGetBriefExportAsDocxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBriefExportAsDocx,
    GetBriefExportAsDocxVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetBriefExportAsDocx,
    GetBriefExportAsDocxVariables
  >(GetBriefExportAsDocxDocument, options);
}
export function useGetBriefExportAsDocxSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBriefExportAsDocx,
    GetBriefExportAsDocxVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetBriefExportAsDocx,
    GetBriefExportAsDocxVariables
  >(GetBriefExportAsDocxDocument, options);
}
export type GetBriefExportAsDocxHookResult = ReturnType<
  typeof useGetBriefExportAsDocx
>;
export type GetBriefExportAsDocxLazyQueryHookResult = ReturnType<
  typeof useGetBriefExportAsDocxLazyQuery
>;
export type GetBriefExportAsDocxSuspenseQueryHookResult = ReturnType<
  typeof useGetBriefExportAsDocxSuspenseQuery
>;
export type GetBriefExportAsDocxQueryResult = Apollo.QueryResult<
  GetBriefExportAsDocx,
  GetBriefExportAsDocxVariables
>;
export const GetBriefExportAsPdfDocument = gql`
  query GetBriefExportAsPdf($input: BriefExportInput!) {
    getBriefExportAsPdf(input: $input)
  }
`;

/**
 * __useGetBriefExportAsPdf__
 *
 * To run a query within a React component, call `useGetBriefExportAsPdf` and pass it any options that fit your needs.
 * When your component renders, `useGetBriefExportAsPdf` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBriefExportAsPdf({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBriefExportAsPdf(
  baseOptions: Apollo.QueryHookOptions<
    GetBriefExportAsPdf,
    GetBriefExportAsPdfVariables
  > &
    (
      | { variables: GetBriefExportAsPdfVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetBriefExportAsPdf, GetBriefExportAsPdfVariables>(
    GetBriefExportAsPdfDocument,
    options
  );
}
export function useGetBriefExportAsPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBriefExportAsPdf,
    GetBriefExportAsPdfVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetBriefExportAsPdf, GetBriefExportAsPdfVariables>(
    GetBriefExportAsPdfDocument,
    options
  );
}
export function useGetBriefExportAsPdfSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBriefExportAsPdf,
    GetBriefExportAsPdfVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetBriefExportAsPdf,
    GetBriefExportAsPdfVariables
  >(GetBriefExportAsPdfDocument, options);
}
export type GetBriefExportAsPdfHookResult = ReturnType<
  typeof useGetBriefExportAsPdf
>;
export type GetBriefExportAsPdfLazyQueryHookResult = ReturnType<
  typeof useGetBriefExportAsPdfLazyQuery
>;
export type GetBriefExportAsPdfSuspenseQueryHookResult = ReturnType<
  typeof useGetBriefExportAsPdfSuspenseQuery
>;
export type GetBriefExportAsPdfQueryResult = Apollo.QueryResult<
  GetBriefExportAsPdf,
  GetBriefExportAsPdfVariables
>;
export const ListWordPressCategoriesDocument = gql`
  query ListWordPressCategories($input: ListWordpressCategoriesInput!) {
    listWordPressCategories(input: $input) {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useListWordPressCategories__
 *
 * To run a query within a React component, call `useListWordPressCategories` and pass it any options that fit your needs.
 * When your component renders, `useListWordPressCategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWordPressCategories({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListWordPressCategories(
  baseOptions: Apollo.QueryHookOptions<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  > &
    (
      | { variables: ListWordPressCategoriesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  >(ListWordPressCategoriesDocument, options);
}
export function useListWordPressCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  >(ListWordPressCategoriesDocument, options);
}
export function useListWordPressCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListWordPressCategories,
    ListWordPressCategoriesVariables
  >(ListWordPressCategoriesDocument, options);
}
export type ListWordPressCategoriesHookResult = ReturnType<
  typeof useListWordPressCategories
>;
export type ListWordPressCategoriesLazyQueryHookResult = ReturnType<
  typeof useListWordPressCategoriesLazyQuery
>;
export type ListWordPressCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useListWordPressCategoriesSuspenseQuery
>;
export type ListWordPressCategoriesQueryResult = Apollo.QueryResult<
  ListWordPressCategories,
  ListWordPressCategoriesVariables
>;
export const GetWordPressConnectionDocument = gql`
  query GetWordPressConnection($input: GetWordpressConnectionInput) {
    getWordPressConnection(input: $input) {
      errors {
        code
        message
        tag
        data {
          status
        }
      }
      username
      endpoint
    }
  }
`;

/**
 * __useGetWordPressConnection__
 *
 * To run a query within a React component, call `useGetWordPressConnection` and pass it any options that fit your needs.
 * When your component renders, `useGetWordPressConnection` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWordPressConnection({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWordPressConnection(
  baseOptions?: Apollo.QueryHookOptions<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >(GetWordPressConnectionDocument, options);
}
export function useGetWordPressConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >(GetWordPressConnectionDocument, options);
}
export function useGetWordPressConnectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWordPressConnection,
    GetWordPressConnectionVariables
  >(GetWordPressConnectionDocument, options);
}
export type GetWordPressConnectionHookResult = ReturnType<
  typeof useGetWordPressConnection
>;
export type GetWordPressConnectionLazyQueryHookResult = ReturnType<
  typeof useGetWordPressConnectionLazyQuery
>;
export type GetWordPressConnectionSuspenseQueryHookResult = ReturnType<
  typeof useGetWordPressConnectionSuspenseQuery
>;
export type GetWordPressConnectionQueryResult = Apollo.QueryResult<
  GetWordPressConnection,
  GetWordPressConnectionVariables
>;
export const GetUploadUrlDocument = gql`
  query GetUploadUrl($entity: String!, $id: String!, $file: String!) {
    getUploadUrl(entity: $entity, id: $id, file: $file) {
      uploadURL
      key
    }
  }
`;

/**
 * __useGetUploadUrl__
 *
 * To run a query within a React component, call `useGetUploadUrl` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrl` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrl({
 *   variables: {
 *      entity: // value for 'entity'
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useGetUploadUrl(
  baseOptions: Apollo.QueryHookOptions<GetUploadUrl, GetUploadUrlVariables> &
    ({ variables: GetUploadUrlVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetUploadUrl, GetUploadUrlVariables>(
    GetUploadUrlDocument,
    options
  );
}
export function useGetUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUploadUrl, GetUploadUrlVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetUploadUrl, GetUploadUrlVariables>(
    GetUploadUrlDocument,
    options
  );
}
export function useGetUploadUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUploadUrl,
    GetUploadUrlVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUploadUrl, GetUploadUrlVariables>(
    GetUploadUrlDocument,
    options
  );
}
export type GetUploadUrlHookResult = ReturnType<typeof useGetUploadUrl>;
export type GetUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadUrlLazyQuery
>;
export type GetUploadUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetUploadUrlSuspenseQuery
>;
export type GetUploadUrlQueryResult = Apollo.QueryResult<
  GetUploadUrl,
  GetUploadUrlVariables
>;
export const GetHtmlExportDocument = gql`
  query GetHtmlExport($input: HtmlExportInput!) {
    getHtmlExport(input: $input) {
      key
    }
  }
`;

/**
 * __useGetHtmlExport__
 *
 * To run a query within a React component, call `useGetHtmlExport` and pass it any options that fit your needs.
 * When your component renders, `useGetHtmlExport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHtmlExport({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHtmlExport(
  baseOptions: Apollo.QueryHookOptions<GetHtmlExport, GetHtmlExportVariables> &
    ({ variables: GetHtmlExportVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetHtmlExport, GetHtmlExportVariables>(
    GetHtmlExportDocument,
    options
  );
}
export function useGetHtmlExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHtmlExport,
    GetHtmlExportVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetHtmlExport, GetHtmlExportVariables>(
    GetHtmlExportDocument,
    options
  );
}
export function useGetHtmlExportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHtmlExport,
    GetHtmlExportVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetHtmlExport, GetHtmlExportVariables>(
    GetHtmlExportDocument,
    options
  );
}
export type GetHtmlExportHookResult = ReturnType<typeof useGetHtmlExport>;
export type GetHtmlExportLazyQueryHookResult = ReturnType<
  typeof useGetHtmlExportLazyQuery
>;
export type GetHtmlExportSuspenseQueryHookResult = ReturnType<
  typeof useGetHtmlExportSuspenseQuery
>;
export type GetHtmlExportQueryResult = Apollo.QueryResult<
  GetHtmlExport,
  GetHtmlExportVariables
>;
export const GetOutlineHtmlExportDocument = gql`
  query GetOutlineHtmlExport($input: OutlineHtmlExportInput!) {
    getOutlineHtmlExport(input: $input) {
      key
      name
    }
  }
`;

/**
 * __useGetOutlineHtmlExport__
 *
 * To run a query within a React component, call `useGetOutlineHtmlExport` and pass it any options that fit your needs.
 * When your component renders, `useGetOutlineHtmlExport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutlineHtmlExport({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOutlineHtmlExport(
  baseOptions: Apollo.QueryHookOptions<
    GetOutlineHtmlExport,
    GetOutlineHtmlExportVariables
  > &
    (
      | { variables: GetOutlineHtmlExportVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetOutlineHtmlExport, GetOutlineHtmlExportVariables>(
    GetOutlineHtmlExportDocument,
    options
  );
}
export function useGetOutlineHtmlExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutlineHtmlExport,
    GetOutlineHtmlExportVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetOutlineHtmlExport,
    GetOutlineHtmlExportVariables
  >(GetOutlineHtmlExportDocument, options);
}
export function useGetOutlineHtmlExportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOutlineHtmlExport,
    GetOutlineHtmlExportVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOutlineHtmlExport,
    GetOutlineHtmlExportVariables
  >(GetOutlineHtmlExportDocument, options);
}
export type GetOutlineHtmlExportHookResult = ReturnType<
  typeof useGetOutlineHtmlExport
>;
export type GetOutlineHtmlExportLazyQueryHookResult = ReturnType<
  typeof useGetOutlineHtmlExportLazyQuery
>;
export type GetOutlineHtmlExportSuspenseQueryHookResult = ReturnType<
  typeof useGetOutlineHtmlExportSuspenseQuery
>;
export type GetOutlineHtmlExportQueryResult = Apollo.QueryResult<
  GetOutlineHtmlExport,
  GetOutlineHtmlExportVariables
>;
export const GetVersionDocument = gql`
  query GetVersion {
    getVersion {
      BE
      FE
      ML
    }
  }
`;

/**
 * __useGetVersion__
 *
 * To run a query within a React component, call `useGetVersion` and pass it any options that fit your needs.
 * When your component renders, `useGetVersion` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersion({
 *   variables: {
 *   },
 * });
 */
export function useGetVersion(
  baseOptions?: Apollo.QueryHookOptions<GetVersion, GetVersionVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetVersion, GetVersionVariables>(
    GetVersionDocument,
    options
  );
}
export function useGetVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVersion, GetVersionVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetVersion, GetVersionVariables>(
    GetVersionDocument,
    options
  );
}
export function useGetVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetVersion, GetVersionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVersion, GetVersionVariables>(
    GetVersionDocument,
    options
  );
}
export type GetVersionHookResult = ReturnType<typeof useGetVersion>;
export type GetVersionLazyQueryHookResult = ReturnType<
  typeof useGetVersionLazyQuery
>;
export type GetVersionSuspenseQueryHookResult = ReturnType<
  typeof useGetVersionSuspenseQuery
>;
export type GetVersionQueryResult = Apollo.QueryResult<
  GetVersion,
  GetVersionVariables
>;
export const GetLastProductUpdateDocument = gql`
  query GetLastProductUpdate {
    getLastProductUpdate {
      pubDate
      excerpt
      url
    }
  }
`;

/**
 * __useGetLastProductUpdate__
 *
 * To run a query within a React component, call `useGetLastProductUpdate` and pass it any options that fit your needs.
 * When your component renders, `useGetLastProductUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastProductUpdate({
 *   variables: {
 *   },
 * });
 */
export function useGetLastProductUpdate(
  baseOptions?: Apollo.QueryHookOptions<
    GetLastProductUpdate,
    GetLastProductUpdateVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetLastProductUpdate, GetLastProductUpdateVariables>(
    GetLastProductUpdateDocument,
    options
  );
}
export function useGetLastProductUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastProductUpdate,
    GetLastProductUpdateVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetLastProductUpdate,
    GetLastProductUpdateVariables
  >(GetLastProductUpdateDocument, options);
}
export function useGetLastProductUpdateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLastProductUpdate,
    GetLastProductUpdateVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLastProductUpdate,
    GetLastProductUpdateVariables
  >(GetLastProductUpdateDocument, options);
}
export type GetLastProductUpdateHookResult = ReturnType<
  typeof useGetLastProductUpdate
>;
export type GetLastProductUpdateLazyQueryHookResult = ReturnType<
  typeof useGetLastProductUpdateLazyQuery
>;
export type GetLastProductUpdateSuspenseQueryHookResult = ReturnType<
  typeof useGetLastProductUpdateSuspenseQuery
>;
export type GetLastProductUpdateQueryResult = Apollo.QueryResult<
  GetLastProductUpdate,
  GetLastProductUpdateVariables
>;
export const CreateGscTokenDocument = gql`
  mutation CreateGSCToken($input: GSCCreateTokenInput!) {
    createGSCToken(input: $input) {
      ...ProjectMetadataBase
      ...ProjectMetadataConnection
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataConnection}
`;
export type CreateGscTokenMutationFn = Apollo.MutationFunction<
  CreateGscToken,
  CreateGscTokenVariables
>;

/**
 * __useCreateGscToken__
 *
 * To run a mutation, you first call `useCreateGscToken` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGscToken` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGscToken, { data, loading, error }] = useCreateGscToken({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGscToken(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGscToken,
    CreateGscTokenVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGscToken, CreateGscTokenVariables>(
    CreateGscTokenDocument,
    options
  );
}
export type CreateGscTokenHookResult = ReturnType<typeof useCreateGscToken>;
export type CreateGscTokenMutationResult =
  Apollo.MutationResult<CreateGscToken>;
export type CreateGscTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateGscToken,
  CreateGscTokenVariables
>;
export const ListGoogleRankedKeywordsByArticleDocument = gql`
  query ListGoogleRankedKeywordsByArticle($input: GSCKeywordsByArticleInput!) {
    listGSCKeywordsByArticle(input: $input) {
      items {
        averageGoogleRanking {
          total
          trend
        }
        clicks {
          total
          trend
        }
        ctr {
          total
          trend
        }
        impressions {
          total
          trend
        }
        keyword
      }
    }
  }
`;

/**
 * __useListGoogleRankedKeywordsByArticle__
 *
 * To run a query within a React component, call `useListGoogleRankedKeywordsByArticle` and pass it any options that fit your needs.
 * When your component renders, `useListGoogleRankedKeywordsByArticle` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGoogleRankedKeywordsByArticle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGoogleRankedKeywordsByArticle(
  baseOptions: Apollo.QueryHookOptions<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  > &
    (
      | {
          variables: ListGoogleRankedKeywordsByArticleVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  >(ListGoogleRankedKeywordsByArticleDocument, options);
}
export function useListGoogleRankedKeywordsByArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  >(ListGoogleRankedKeywordsByArticleDocument, options);
}
export function useListGoogleRankedKeywordsByArticleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListGoogleRankedKeywordsByArticle,
    ListGoogleRankedKeywordsByArticleVariables
  >(ListGoogleRankedKeywordsByArticleDocument, options);
}
export type ListGoogleRankedKeywordsByArticleHookResult = ReturnType<
  typeof useListGoogleRankedKeywordsByArticle
>;
export type ListGoogleRankedKeywordsByArticleLazyQueryHookResult = ReturnType<
  typeof useListGoogleRankedKeywordsByArticleLazyQuery
>;
export type ListGoogleRankedKeywordsByArticleSuspenseQueryHookResult =
  ReturnType<typeof useListGoogleRankedKeywordsByArticleSuspenseQuery>;
export type ListGoogleRankedKeywordsByArticleQueryResult = Apollo.QueryResult<
  ListGoogleRankedKeywordsByArticle,
  ListGoogleRankedKeywordsByArticleVariables
>;
export const ListGoogleRankedKeywordsByProjectDocument = gql`
  query ListGoogleRankedKeywordsByProject($input: GSCKeywordsByProjectInput!) {
    listGSCKeywordsByProject(input: $input) {
      items {
        averageGoogleRanking {
          total
          trend
        }
        clicks {
          total
          trend
        }
        ctr {
          total
          trend
        }
        impressions {
          total
          trend
        }
        searchVolume {
          total
          trend
          growth
        }
        articleIds
        keyword
      }
    }
  }
`;

/**
 * __useListGoogleRankedKeywordsByProject__
 *
 * To run a query within a React component, call `useListGoogleRankedKeywordsByProject` and pass it any options that fit your needs.
 * When your component renders, `useListGoogleRankedKeywordsByProject` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGoogleRankedKeywordsByProject({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGoogleRankedKeywordsByProject(
  baseOptions: Apollo.QueryHookOptions<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  > &
    (
      | {
          variables: ListGoogleRankedKeywordsByProjectVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  >(ListGoogleRankedKeywordsByProjectDocument, options);
}
export function useListGoogleRankedKeywordsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  >(ListGoogleRankedKeywordsByProjectDocument, options);
}
export function useListGoogleRankedKeywordsByProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListGoogleRankedKeywordsByProject,
    ListGoogleRankedKeywordsByProjectVariables
  >(ListGoogleRankedKeywordsByProjectDocument, options);
}
export type ListGoogleRankedKeywordsByProjectHookResult = ReturnType<
  typeof useListGoogleRankedKeywordsByProject
>;
export type ListGoogleRankedKeywordsByProjectLazyQueryHookResult = ReturnType<
  typeof useListGoogleRankedKeywordsByProjectLazyQuery
>;
export type ListGoogleRankedKeywordsByProjectSuspenseQueryHookResult =
  ReturnType<typeof useListGoogleRankedKeywordsByProjectSuspenseQuery>;
export type ListGoogleRankedKeywordsByProjectQueryResult = Apollo.QueryResult<
  ListGoogleRankedKeywordsByProject,
  ListGoogleRankedKeywordsByProjectVariables
>;
export const ListGscArticlesByProjectQueryDocument = gql`
  query ListGSCArticlesByProjectQuery(
    $input: GSCArticlesByProjectInput!
    $nextToken: String
  ) {
    listGSCArticlesByProject(input: $input, nextToken: $nextToken) {
      items {
        title
        rscore
        publicationDate
        clicks {
          total
          trend
        }
        ctr {
          total
          trend
        }
        averageGoogleRanking {
          total
          trend
        }
        previousAverageGoogleRanking {
          total
          trend
        }
        averageGoogleRankingChange
        keywords
        impressions {
          total
          trend
        }
        articleId
        id
      }
      nextToken
    }
  }
`;

/**
 * __useListGscArticlesByProjectQuery__
 *
 * To run a query within a React component, call `useListGscArticlesByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGscArticlesByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGscArticlesByProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListGscArticlesByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  > &
    (
      | { variables: ListGscArticlesByProjectQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  >(ListGscArticlesByProjectQueryDocument, options);
}
export function useListGscArticlesByProjectQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  >(ListGscArticlesByProjectQueryDocument, options);
}
export function useListGscArticlesByProjectQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListGscArticlesByProjectQuery,
    ListGscArticlesByProjectQueryVariables
  >(ListGscArticlesByProjectQueryDocument, options);
}
export type ListGscArticlesByProjectQueryHookResult = ReturnType<
  typeof useListGscArticlesByProjectQuery
>;
export type ListGscArticlesByProjectQueryLazyQueryHookResult = ReturnType<
  typeof useListGscArticlesByProjectQueryLazyQuery
>;
export type ListGscArticlesByProjectQuerySuspenseQueryHookResult = ReturnType<
  typeof useListGscArticlesByProjectQuerySuspenseQuery
>;
export type ListGscArticlesByProjectQueryQueryResult = Apollo.QueryResult<
  ListGscArticlesByProjectQuery,
  ListGscArticlesByProjectQueryVariables
>;
export const GetGscConsentScreenUriDocument = gql`
  query GetGSCConsentScreenUri($input: GSCConsentScreenUriInput!) {
    getGSCConsentScreenUri(input: $input) {
      signInUri
    }
  }
`;

/**
 * __useGetGscConsentScreenUri__
 *
 * To run a query within a React component, call `useGetGscConsentScreenUri` and pass it any options that fit your needs.
 * When your component renders, `useGetGscConsentScreenUri` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGscConsentScreenUri({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGscConsentScreenUri(
  baseOptions: Apollo.QueryHookOptions<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  > &
    (
      | { variables: GetGscConsentScreenUriVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  >(GetGscConsentScreenUriDocument, options);
}
export function useGetGscConsentScreenUriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  >(GetGscConsentScreenUriDocument, options);
}
export function useGetGscConsentScreenUriSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGscConsentScreenUri,
    GetGscConsentScreenUriVariables
  >(GetGscConsentScreenUriDocument, options);
}
export type GetGscConsentScreenUriHookResult = ReturnType<
  typeof useGetGscConsentScreenUri
>;
export type GetGscConsentScreenUriLazyQueryHookResult = ReturnType<
  typeof useGetGscConsentScreenUriLazyQuery
>;
export type GetGscConsentScreenUriSuspenseQueryHookResult = ReturnType<
  typeof useGetGscConsentScreenUriSuspenseQuery
>;
export type GetGscConsentScreenUriQueryResult = Apollo.QueryResult<
  GetGscConsentScreenUri,
  GetGscConsentScreenUriVariables
>;
export const ListGscDomainsDocument = gql`
  query ListGSCDomains($input: GSCDomainsInput!) {
    listGSCDomains(input: $input) {
      items {
        siteUrl
        permissionLevel
      }
    }
  }
`;

/**
 * __useListGscDomains__
 *
 * To run a query within a React component, call `useListGscDomains` and pass it any options that fit your needs.
 * When your component renders, `useListGscDomains` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGscDomains({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGscDomains(
  baseOptions: Apollo.QueryHookOptions<
    ListGscDomains,
    ListGscDomainsVariables
  > &
    ({ variables: ListGscDomainsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListGscDomains, ListGscDomainsVariables>(
    ListGscDomainsDocument,
    options
  );
}
export function useListGscDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGscDomains,
    ListGscDomainsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListGscDomains, ListGscDomainsVariables>(
    ListGscDomainsDocument,
    options
  );
}
export function useListGscDomainsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGscDomains,
    ListGscDomainsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListGscDomains, ListGscDomainsVariables>(
    ListGscDomainsDocument,
    options
  );
}
export type ListGscDomainsHookResult = ReturnType<typeof useListGscDomains>;
export type ListGscDomainsLazyQueryHookResult = ReturnType<
  typeof useListGscDomainsLazyQuery
>;
export type ListGscDomainsSuspenseQueryHookResult = ReturnType<
  typeof useListGscDomainsSuspenseQuery
>;
export type ListGscDomainsQueryResult = Apollo.QueryResult<
  ListGscDomains,
  ListGscDomainsVariables
>;
export const GetGscArticleAnalyticsQueryDocument = gql`
  query GetGSCArticleAnalyticsQuery($input: GSCArticleAnalyticsInput!) {
    getGSCArticleAnalytics(input: $input) {
      impressions {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      clicks {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      ctr {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      averageGoogleRanking {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      searchVolume {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      updatesGoogle {
        data {
          id
          date
          title
          url
          description
        }
      }
      publishedArticles {
        data {
          date
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGetGscArticleAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetGscArticleAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGscArticleAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGscArticleAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGscArticleAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  > &
    (
      | { variables: GetGscArticleAnalyticsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  >(GetGscArticleAnalyticsQueryDocument, options);
}
export function useGetGscArticleAnalyticsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  >(GetGscArticleAnalyticsQueryDocument, options);
}
export function useGetGscArticleAnalyticsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGscArticleAnalyticsQuery,
    GetGscArticleAnalyticsQueryVariables
  >(GetGscArticleAnalyticsQueryDocument, options);
}
export type GetGscArticleAnalyticsQueryHookResult = ReturnType<
  typeof useGetGscArticleAnalyticsQuery
>;
export type GetGscArticleAnalyticsQueryLazyQueryHookResult = ReturnType<
  typeof useGetGscArticleAnalyticsQueryLazyQuery
>;
export type GetGscArticleAnalyticsQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetGscArticleAnalyticsQuerySuspenseQuery
>;
export type GetGscArticleAnalyticsQueryQueryResult = Apollo.QueryResult<
  GetGscArticleAnalyticsQuery,
  GetGscArticleAnalyticsQueryVariables
>;
export const GetGscProjectAnalyticsQueryDocument = gql`
  query GetGSCProjectAnalyticsQuery($input: GSCProjectAnalyticsInput!) {
    getGSCProjectAnalytics(input: $input) {
      impressions {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      clicks {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      ctr {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      averageGoogleRanking {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      searchVolume {
        total
        trend
        data {
          date
          title
          url
          value
        }
        resolution
      }
      updatesGoogle {
        data {
          id
          date
          title
          url
          description
        }
      }
      publishedArticles {
        data {
          date
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGetGscProjectAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetGscProjectAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGscProjectAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGscProjectAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGscProjectAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  > &
    (
      | { variables: GetGscProjectAnalyticsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  >(GetGscProjectAnalyticsQueryDocument, options);
}
export function useGetGscProjectAnalyticsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  >(GetGscProjectAnalyticsQueryDocument, options);
}
export function useGetGscProjectAnalyticsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGscProjectAnalyticsQuery,
    GetGscProjectAnalyticsQueryVariables
  >(GetGscProjectAnalyticsQueryDocument, options);
}
export type GetGscProjectAnalyticsQueryHookResult = ReturnType<
  typeof useGetGscProjectAnalyticsQuery
>;
export type GetGscProjectAnalyticsQueryLazyQueryHookResult = ReturnType<
  typeof useGetGscProjectAnalyticsQueryLazyQuery
>;
export type GetGscProjectAnalyticsQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetGscProjectAnalyticsQuerySuspenseQuery
>;
export type GetGscProjectAnalyticsQueryQueryResult = Apollo.QueryResult<
  GetGscProjectAnalyticsQuery,
  GetGscProjectAnalyticsQueryVariables
>;
export const ListGscArticlesByKeywordQueryDocument = gql`
  query ListGSCArticlesByKeywordQuery($input: GSCArticlesByKeywordInput!) {
    listGSCArticlesByKeyword(input: $input) {
      items {
        title
        searchVolume {
          total
          trend
          growth
        }
        impressions {
          total
          trend
        }
        articleId
        ctr {
          total
          trend
        }
        clicks {
          total
          trend
        }
        averageGoogleRanking {
          total
          trend
        }
      }
    }
  }
`;

/**
 * __useListGscArticlesByKeywordQuery__
 *
 * To run a query within a React component, call `useListGscArticlesByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGscArticlesByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGscArticlesByKeywordQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGscArticlesByKeywordQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  > &
    (
      | { variables: ListGscArticlesByKeywordQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  >(ListGscArticlesByKeywordQueryDocument, options);
}
export function useListGscArticlesByKeywordQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  >(ListGscArticlesByKeywordQueryDocument, options);
}
export function useListGscArticlesByKeywordQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListGscArticlesByKeywordQuery,
    ListGscArticlesByKeywordQueryVariables
  >(ListGscArticlesByKeywordQueryDocument, options);
}
export type ListGscArticlesByKeywordQueryHookResult = ReturnType<
  typeof useListGscArticlesByKeywordQuery
>;
export type ListGscArticlesByKeywordQueryLazyQueryHookResult = ReturnType<
  typeof useListGscArticlesByKeywordQueryLazyQuery
>;
export type ListGscArticlesByKeywordQuerySuspenseQueryHookResult = ReturnType<
  typeof useListGscArticlesByKeywordQuerySuspenseQuery
>;
export type ListGscArticlesByKeywordQueryQueryResult = Apollo.QueryResult<
  ListGscArticlesByKeywordQuery,
  ListGscArticlesByKeywordQueryVariables
>;
export const CreateInvitationsDocument = gql`
  mutation CreateInvitations($input: CreateInvitationsInput!) {
    createInvitations(input: $input) {
      items {
        ...InvitationFull
      }
    }
  }
  ${InvitationFull}
`;
export type CreateInvitationsMutationFn = Apollo.MutationFunction<
  CreateInvitations,
  CreateInvitationsVariables
>;

/**
 * __useCreateInvitations__
 *
 * To run a mutation, you first call `useCreateInvitations` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitations` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitations, { data, loading, error }] = useCreateInvitations({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvitations(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvitations,
    CreateInvitationsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvitations, CreateInvitationsVariables>(
    CreateInvitationsDocument,
    options
  );
}
export type CreateInvitationsHookResult = ReturnType<
  typeof useCreateInvitations
>;
export type CreateInvitationsMutationResult =
  Apollo.MutationResult<CreateInvitations>;
export type CreateInvitationsMutationOptions = Apollo.BaseMutationOptions<
  CreateInvitations,
  CreateInvitationsVariables
>;
export const DeleteInvitationDocument = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      id
    }
  }
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitation,
  DeleteInvitationVariables
>;

/**
 * __useDeleteInvitation__
 *
 * To run a mutation, you first call `useDeleteInvitation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitation, { data, loading, error }] = useDeleteInvitation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvitation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvitation,
    DeleteInvitationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvitation, DeleteInvitationVariables>(
    DeleteInvitationDocument,
    options
  );
}
export type DeleteInvitationHookResult = ReturnType<typeof useDeleteInvitation>;
export type DeleteInvitationMutationResult =
  Apollo.MutationResult<DeleteInvitation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitation,
  DeleteInvitationVariables
>;
export const ListInvitationsForMembersPageDocument = gql`
  query listInvitationsForMembersPage {
    listInvitations {
      items {
        id
        user_metadata
        invitee {
          email
        }
        expires_at
        created_at
      }
    }
  }
`;

/**
 * __useListInvitationsForMembersPage__
 *
 * To run a query within a React component, call `useListInvitationsForMembersPage` and pass it any options that fit your needs.
 * When your component renders, `useListInvitationsForMembersPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitationsForMembersPage({
 *   variables: {
 *   },
 * });
 */
export function useListInvitationsForMembersPage(
  baseOptions?: Apollo.QueryHookOptions<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >(ListInvitationsForMembersPageDocument, options);
}
export function useListInvitationsForMembersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >(ListInvitationsForMembersPageDocument, options);
}
export function useListInvitationsForMembersPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListInvitationsForMembersPage,
    ListInvitationsForMembersPageVariables
  >(ListInvitationsForMembersPageDocument, options);
}
export type ListInvitationsForMembersPageHookResult = ReturnType<
  typeof useListInvitationsForMembersPage
>;
export type ListInvitationsForMembersPageLazyQueryHookResult = ReturnType<
  typeof useListInvitationsForMembersPageLazyQuery
>;
export type ListInvitationsForMembersPageSuspenseQueryHookResult = ReturnType<
  typeof useListInvitationsForMembersPageSuspenseQuery
>;
export type ListInvitationsForMembersPageQueryResult = Apollo.QueryResult<
  ListInvitationsForMembersPage,
  ListInvitationsForMembersPageVariables
>;
export const ReadNotificationsDocument = gql`
  mutation ReadNotifications($input: ReadNotificationsInput!) {
    readNotifications(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type ReadNotificationsMutationFn = Apollo.MutationFunction<
  ReadNotifications,
  ReadNotificationsVariables
>;

/**
 * __useReadNotifications__
 *
 * To run a mutation, you first call `useReadNotifications` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotifications` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotifications, { data, loading, error }] = useReadNotifications({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadNotifications(
  baseOptions?: Apollo.MutationHookOptions<
    ReadNotifications,
    ReadNotificationsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadNotifications, ReadNotificationsVariables>(
    ReadNotificationsDocument,
    options
  );
}
export type ReadNotificationsHookResult = ReturnType<
  typeof useReadNotifications
>;
export type ReadNotificationsMutationResult =
  Apollo.MutationResult<ReadNotifications>;
export type ReadNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ReadNotifications,
  ReadNotificationsVariables
>;
export const DeleteNotificationDocument = gql`
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
    }
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotification,
  DeleteNotificationVariables
>;

/**
 * __useDeleteNotification__
 *
 * To run a mutation, you first call `useDeleteNotification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotification, { data, loading, error }] = useDeleteNotification({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNotification(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotification,
    DeleteNotificationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNotification, DeleteNotificationVariables>(
    DeleteNotificationDocument,
    options
  );
}
export type DeleteNotificationHookResult = ReturnType<
  typeof useDeleteNotification
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotification>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotification,
  DeleteNotificationVariables
>;
export const ListAllNotificationsDocument = gql`
  query ListAllNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sk1: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sk1: $sk1
      sortDirection: $sortDirection
    ) {
      items {
        ...NotificationFull
      }
      nextToken
    }
  }
  ${NotificationFull}
`;

/**
 * __useListAllNotifications__
 *
 * To run a query within a React component, call `useListAllNotifications` and pass it any options that fit your needs.
 * When your component renders, `useListAllNotifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllNotifications({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *      sk1: // value for 'sk1'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useListAllNotifications(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllNotifications,
    ListAllNotificationsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListAllNotifications, ListAllNotificationsVariables>(
    ListAllNotificationsDocument,
    options
  );
}
export function useListAllNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllNotifications,
    ListAllNotificationsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListAllNotifications,
    ListAllNotificationsVariables
  >(ListAllNotificationsDocument, options);
}
export function useListAllNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListAllNotifications,
    ListAllNotificationsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListAllNotifications,
    ListAllNotificationsVariables
  >(ListAllNotificationsDocument, options);
}
export type ListAllNotificationsHookResult = ReturnType<
  typeof useListAllNotifications
>;
export type ListAllNotificationsLazyQueryHookResult = ReturnType<
  typeof useListAllNotificationsLazyQuery
>;
export type ListAllNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useListAllNotificationsSuspenseQuery
>;
export type ListAllNotificationsQueryResult = Apollo.QueryResult<
  ListAllNotifications,
  ListAllNotificationsVariables
>;
export const GetNotificationDocument = gql`
  query GetNotification($input: GetNotificationInput!) {
    getNotification(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGetNotification__
 *
 * To run a query within a React component, call `useGetNotification` and pass it any options that fit your needs.
 * When your component renders, `useGetNotification` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotification({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotification(
  baseOptions: Apollo.QueryHookOptions<
    GetNotification,
    GetNotificationVariables
  > &
    (
      | { variables: GetNotificationVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetNotification, GetNotificationVariables>(
    GetNotificationDocument,
    options
  );
}
export function useGetNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotification,
    GetNotificationVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetNotification, GetNotificationVariables>(
    GetNotificationDocument,
    options
  );
}
export function useGetNotificationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNotification,
    GetNotificationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNotification, GetNotificationVariables>(
    GetNotificationDocument,
    options
  );
}
export type GetNotificationHookResult = ReturnType<typeof useGetNotification>;
export type GetNotificationLazyQueryHookResult = ReturnType<
  typeof useGetNotificationLazyQuery
>;
export type GetNotificationSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationSuspenseQuery
>;
export type GetNotificationQueryResult = Apollo.QueryResult<
  GetNotification,
  GetNotificationVariables
>;
export const OnNotificationCreateDocument = gql`
  subscription OnNotificationCreate(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onNotificationCreate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...NotificationSubscriptionFull
    }
  }
  ${NotificationSubscriptionFull}
`;

/**
 * __useOnNotificationCreate__
 *
 * To run a query within a React component, call `useOnNotificationCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnNotificationCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNotificationCreate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnNotificationCreate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnNotificationCreate,
    OnNotificationCreateVariables
  > &
    (
      | { variables: OnNotificationCreateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNotificationCreate,
    OnNotificationCreateVariables
  >(OnNotificationCreateDocument, options);
}
export type OnNotificationCreateHookResult = ReturnType<
  typeof useOnNotificationCreate
>;
export type OnNotificationCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnNotificationCreate>;
export const OnNotificationUpdateDocument = gql`
  subscription OnNotificationUpdate(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onNotificationUpdate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...NotificationSubscriptionFull
    }
  }
  ${NotificationSubscriptionFull}
`;

/**
 * __useOnNotificationUpdate__
 *
 * To run a query within a React component, call `useOnNotificationUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnNotificationUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNotificationUpdate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnNotificationUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnNotificationUpdate,
    OnNotificationUpdateVariables
  > &
    (
      | { variables: OnNotificationUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNotificationUpdate,
    OnNotificationUpdateVariables
  >(OnNotificationUpdateDocument, options);
}
export type OnNotificationUpdateHookResult = ReturnType<
  typeof useOnNotificationUpdate
>;
export type OnNotificationUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnNotificationUpdate>;
export const OnNotificationDeleteDocument = gql`
  subscription OnNotificationDelete(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onNotificationDelete(userId: $userId, pk1: $pk1, filter: $filter) {
      pk1
      sk1
      userId
    }
  }
`;

/**
 * __useOnNotificationDelete__
 *
 * To run a query within a React component, call `useOnNotificationDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnNotificationDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNotificationDelete({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnNotificationDelete(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnNotificationDelete,
    OnNotificationDeleteVariables
  > &
    (
      | { variables: OnNotificationDeleteVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNotificationDelete,
    OnNotificationDeleteVariables
  >(OnNotificationDeleteDocument, options);
}
export type OnNotificationDeleteHookResult = ReturnType<
  typeof useOnNotificationDelete
>;
export type OnNotificationDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnNotificationDelete>;
export const UpdateNVeCompanyPriorityDocument = gql`
  mutation UpdateNVeCompanyPriority($input: UpdateNVePriorityInput!) {
    updateNVeCompanyPriority(input: $input) {
      id
      priority
      updateToken
    }
  }
`;
export type UpdateNVeCompanyPriorityMutationFn = Apollo.MutationFunction<
  UpdateNVeCompanyPriority,
  UpdateNVeCompanyPriorityVariables
>;

/**
 * __useUpdateNVeCompanyPriority__
 *
 * To run a mutation, you first call `useUpdateNVeCompanyPriority` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNVeCompanyPriority` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNVeCompanyPriority, { data, loading, error }] = useUpdateNVeCompanyPriority({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNVeCompanyPriority(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNVeCompanyPriority,
    UpdateNVeCompanyPriorityVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNVeCompanyPriority,
    UpdateNVeCompanyPriorityVariables
  >(UpdateNVeCompanyPriorityDocument, options);
}
export type UpdateNVeCompanyPriorityHookResult = ReturnType<
  typeof useUpdateNVeCompanyPriority
>;
export type UpdateNVeCompanyPriorityMutationResult =
  Apollo.MutationResult<UpdateNVeCompanyPriority>;
export type UpdateNVeCompanyPriorityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNVeCompanyPriority,
    UpdateNVeCompanyPriorityVariables
  >;
export const UpdateNVeTopicArticlePriorityDocument = gql`
  mutation UpdateNVeTopicArticlePriority(
    $input: UpdateNVeTopicArticlePriorityInput!
  ) {
    updateNVeTopicArticlePriority(input: $input) {
      id
      priority
      updateToken
    }
  }
`;
export type UpdateNVeTopicArticlePriorityMutationFn = Apollo.MutationFunction<
  UpdateNVeTopicArticlePriority,
  UpdateNVeTopicArticlePriorityVariables
>;

/**
 * __useUpdateNVeTopicArticlePriority__
 *
 * To run a mutation, you first call `useUpdateNVeTopicArticlePriority` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNVeTopicArticlePriority` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNVeTopicArticlePriority, { data, loading, error }] = useUpdateNVeTopicArticlePriority({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNVeTopicArticlePriority(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNVeTopicArticlePriority,
    UpdateNVeTopicArticlePriorityVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNVeTopicArticlePriority,
    UpdateNVeTopicArticlePriorityVariables
  >(UpdateNVeTopicArticlePriorityDocument, options);
}
export type UpdateNVeTopicArticlePriorityHookResult = ReturnType<
  typeof useUpdateNVeTopicArticlePriority
>;
export type UpdateNVeTopicArticlePriorityMutationResult =
  Apollo.MutationResult<UpdateNVeTopicArticlePriority>;
export type UpdateNVeTopicArticlePriorityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNVeTopicArticlePriority,
    UpdateNVeTopicArticlePriorityVariables
  >;
export const UpdateNVeTopicPriorityDocument = gql`
  mutation UpdateNVeTopicPriority($input: UpdateNVePriorityInput!) {
    updateNVeTopicPriority(input: $input) {
      id
      priority
      updateToken
    }
  }
`;
export type UpdateNVeTopicPriorityMutationFn = Apollo.MutationFunction<
  UpdateNVeTopicPriority,
  UpdateNVeTopicPriorityVariables
>;

/**
 * __useUpdateNVeTopicPriority__
 *
 * To run a mutation, you first call `useUpdateNVeTopicPriority` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNVeTopicPriority` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNVeTopicPriority, { data, loading, error }] = useUpdateNVeTopicPriority({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNVeTopicPriority(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNVeTopicPriority,
    UpdateNVeTopicPriorityVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNVeTopicPriority,
    UpdateNVeTopicPriorityVariables
  >(UpdateNVeTopicPriorityDocument, options);
}
export type UpdateNVeTopicPriorityHookResult = ReturnType<
  typeof useUpdateNVeTopicPriority
>;
export type UpdateNVeTopicPriorityMutationResult =
  Apollo.MutationResult<UpdateNVeTopicPriority>;
export type UpdateNVeTopicPriorityMutationOptions = Apollo.BaseMutationOptions<
  UpdateNVeTopicPriority,
  UpdateNVeTopicPriorityVariables
>;
export const UpdateNVeTopicSavedDocument = gql`
  mutation UpdateNVeTopicSaved($input: UpdateNVeTopicSavedInput!) {
    updateNVeTopicSaved(input: $input) {
      id
      isSaved
      updateToken
    }
  }
`;
export type UpdateNVeTopicSavedMutationFn = Apollo.MutationFunction<
  UpdateNVeTopicSaved,
  UpdateNVeTopicSavedVariables
>;

/**
 * __useUpdateNVeTopicSaved__
 *
 * To run a mutation, you first call `useUpdateNVeTopicSaved` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNVeTopicSaved` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNVeTopicSaved, { data, loading, error }] = useUpdateNVeTopicSaved({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNVeTopicSaved(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNVeTopicSaved,
    UpdateNVeTopicSavedVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNVeTopicSaved, UpdateNVeTopicSavedVariables>(
    UpdateNVeTopicSavedDocument,
    options
  );
}
export type UpdateNVeTopicSavedHookResult = ReturnType<
  typeof useUpdateNVeTopicSaved
>;
export type UpdateNVeTopicSavedMutationResult =
  Apollo.MutationResult<UpdateNVeTopicSaved>;
export type UpdateNVeTopicSavedMutationOptions = Apollo.BaseMutationOptions<
  UpdateNVeTopicSaved,
  UpdateNVeTopicSavedVariables
>;
export const ImportArticleDocument = gql`
  mutation ImportArticle($input: ImportArticleInput!) {
    importArticle(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;
export type ImportArticleMutationFn = Apollo.MutationFunction<
  ImportArticle,
  ImportArticleVariables
>;

/**
 * __useImportArticle__
 *
 * To run a mutation, you first call `useImportArticle` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportArticle` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importArticle, { data, loading, error }] = useImportArticle({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportArticle(
  baseOptions?: Apollo.MutationHookOptions<
    ImportArticle,
    ImportArticleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportArticle, ImportArticleVariables>(
    ImportArticleDocument,
    options
  );
}
export type ImportArticleHookResult = ReturnType<typeof useImportArticle>;
export type ImportArticleMutationResult = Apollo.MutationResult<ImportArticle>;
export type ImportArticleMutationOptions = Apollo.BaseMutationOptions<
  ImportArticle,
  ImportArticleVariables
>;
export const ListNVeTopicArticlesDocument = gql`
  query ListNVeTopicArticles($input: ListNVeTopicArticlesInput!) {
    listNVeTopicArticles(input: $input) {
      items {
        ...NVeTopicArticleFull
      }
    }
  }
  ${NVeTopicArticleFull}
`;

/**
 * __useListNVeTopicArticles__
 *
 * To run a query within a React component, call `useListNVeTopicArticles` and pass it any options that fit your needs.
 * When your component renders, `useListNVeTopicArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeTopicArticles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeTopicArticles(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeTopicArticles,
    ListNVeTopicArticlesVariables
  > &
    (
      | { variables: ListNVeTopicArticlesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListNVeTopicArticles, ListNVeTopicArticlesVariables>(
    ListNVeTopicArticlesDocument,
    options
  );
}
export function useListNVeTopicArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeTopicArticles,
    ListNVeTopicArticlesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListNVeTopicArticles,
    ListNVeTopicArticlesVariables
  >(ListNVeTopicArticlesDocument, options);
}
export function useListNVeTopicArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeTopicArticles,
    ListNVeTopicArticlesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeTopicArticles,
    ListNVeTopicArticlesVariables
  >(ListNVeTopicArticlesDocument, options);
}
export type ListNVeTopicArticlesHookResult = ReturnType<
  typeof useListNVeTopicArticles
>;
export type ListNVeTopicArticlesLazyQueryHookResult = ReturnType<
  typeof useListNVeTopicArticlesLazyQuery
>;
export type ListNVeTopicArticlesSuspenseQueryHookResult = ReturnType<
  typeof useListNVeTopicArticlesSuspenseQuery
>;
export type ListNVeTopicArticlesQueryResult = Apollo.QueryResult<
  ListNVeTopicArticles,
  ListNVeTopicArticlesVariables
>;
export const ListNVeTopicsDocument = gql`
  query ListNVeTopics($input: ListNVeTopicsInput!) {
    listNVeTopics(input: $input) {
      ...NVeTopicBase
      isSaved
    }
  }
  ${NVeTopicBase}
`;

/**
 * __useListNVeTopics__
 *
 * To run a query within a React component, call `useListNVeTopics` and pass it any options that fit your needs.
 * When your component renders, `useListNVeTopics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeTopics({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeTopics(
  baseOptions: Apollo.QueryHookOptions<ListNVeTopics, ListNVeTopicsVariables> &
    ({ variables: ListNVeTopicsVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListNVeTopics, ListNVeTopicsVariables>(
    ListNVeTopicsDocument,
    options
  );
}
export function useListNVeTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeTopics,
    ListNVeTopicsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListNVeTopics, ListNVeTopicsVariables>(
    ListNVeTopicsDocument,
    options
  );
}
export function useListNVeTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeTopics,
    ListNVeTopicsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListNVeTopics, ListNVeTopicsVariables>(
    ListNVeTopicsDocument,
    options
  );
}
export type ListNVeTopicsHookResult = ReturnType<typeof useListNVeTopics>;
export type ListNVeTopicsLazyQueryHookResult = ReturnType<
  typeof useListNVeTopicsLazyQuery
>;
export type ListNVeTopicsSuspenseQueryHookResult = ReturnType<
  typeof useListNVeTopicsSuspenseQuery
>;
export type ListNVeTopicsQueryResult = Apollo.QueryResult<
  ListNVeTopics,
  ListNVeTopicsVariables
>;
export const ListNVeTopicsForTopicAnalysisPageDocument = gql`
  query ListNVeTopicsForTopicAnalysisPage($input: ListNVeTopicsInput!) {
    listNVeTopics(input: $input) {
      ...NVeTopicFull
    }
  }
  ${NVeTopicFull}
`;

/**
 * __useListNVeTopicsForTopicAnalysisPage__
 *
 * To run a query within a React component, call `useListNVeTopicsForTopicAnalysisPage` and pass it any options that fit your needs.
 * When your component renders, `useListNVeTopicsForTopicAnalysisPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeTopicsForTopicAnalysisPage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeTopicsForTopicAnalysisPage(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  > &
    (
      | {
          variables: ListNVeTopicsForTopicAnalysisPageVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  >(ListNVeTopicsForTopicAnalysisPageDocument, options);
}
export function useListNVeTopicsForTopicAnalysisPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  >(ListNVeTopicsForTopicAnalysisPageDocument, options);
}
export function useListNVeTopicsForTopicAnalysisPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeTopicsForTopicAnalysisPage,
    ListNVeTopicsForTopicAnalysisPageVariables
  >(ListNVeTopicsForTopicAnalysisPageDocument, options);
}
export type ListNVeTopicsForTopicAnalysisPageHookResult = ReturnType<
  typeof useListNVeTopicsForTopicAnalysisPage
>;
export type ListNVeTopicsForTopicAnalysisPageLazyQueryHookResult = ReturnType<
  typeof useListNVeTopicsForTopicAnalysisPageLazyQuery
>;
export type ListNVeTopicsForTopicAnalysisPageSuspenseQueryHookResult =
  ReturnType<typeof useListNVeTopicsForTopicAnalysisPageSuspenseQuery>;
export type ListNVeTopicsForTopicAnalysisPageQueryResult = Apollo.QueryResult<
  ListNVeTopicsForTopicAnalysisPage,
  ListNVeTopicsForTopicAnalysisPageVariables
>;
export const ListNVeCompaniesDocument = gql`
  query ListNVeCompanies($input: ListNVeCompaniesInput!) {
    listNVeCompanies(input: $input) {
      ...NVeCompanyBase
    }
  }
  ${NVeCompanyBase}
`;

/**
 * __useListNVeCompanies__
 *
 * To run a query within a React component, call `useListNVeCompanies` and pass it any options that fit your needs.
 * When your component renders, `useListNVeCompanies` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeCompanies({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeCompanies(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeCompanies,
    ListNVeCompaniesVariables
  > &
    (
      | { variables: ListNVeCompaniesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListNVeCompanies, ListNVeCompaniesVariables>(
    ListNVeCompaniesDocument,
    options
  );
}
export function useListNVeCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeCompanies,
    ListNVeCompaniesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListNVeCompanies, ListNVeCompaniesVariables>(
    ListNVeCompaniesDocument,
    options
  );
}
export function useListNVeCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeCompanies,
    ListNVeCompaniesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListNVeCompanies, ListNVeCompaniesVariables>(
    ListNVeCompaniesDocument,
    options
  );
}
export type ListNVeCompaniesHookResult = ReturnType<typeof useListNVeCompanies>;
export type ListNVeCompaniesLazyQueryHookResult = ReturnType<
  typeof useListNVeCompaniesLazyQuery
>;
export type ListNVeCompaniesSuspenseQueryHookResult = ReturnType<
  typeof useListNVeCompaniesSuspenseQuery
>;
export type ListNVeCompaniesQueryResult = Apollo.QueryResult<
  ListNVeCompanies,
  ListNVeCompaniesVariables
>;
export const ListNVeCompaniesForCompetitorsPageDocument = gql`
  query ListNVeCompaniesForCompetitorsPage($input: ListNVeCompaniesInput!) {
    listNVeCompanies(input: $input) {
      ...NVeCompanyFull
    }
  }
  ${NVeCompanyFull}
`;

/**
 * __useListNVeCompaniesForCompetitorsPage__
 *
 * To run a query within a React component, call `useListNVeCompaniesForCompetitorsPage` and pass it any options that fit your needs.
 * When your component renders, `useListNVeCompaniesForCompetitorsPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeCompaniesForCompetitorsPage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeCompaniesForCompetitorsPage(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  > &
    (
      | {
          variables: ListNVeCompaniesForCompetitorsPageVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  >(ListNVeCompaniesForCompetitorsPageDocument, options);
}
export function useListNVeCompaniesForCompetitorsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  >(ListNVeCompaniesForCompetitorsPageDocument, options);
}
export function useListNVeCompaniesForCompetitorsPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeCompaniesForCompetitorsPage,
    ListNVeCompaniesForCompetitorsPageVariables
  >(ListNVeCompaniesForCompetitorsPageDocument, options);
}
export type ListNVeCompaniesForCompetitorsPageHookResult = ReturnType<
  typeof useListNVeCompaniesForCompetitorsPage
>;
export type ListNVeCompaniesForCompetitorsPageLazyQueryHookResult = ReturnType<
  typeof useListNVeCompaniesForCompetitorsPageLazyQuery
>;
export type ListNVeCompaniesForCompetitorsPageSuspenseQueryHookResult =
  ReturnType<typeof useListNVeCompaniesForCompetitorsPageSuspenseQuery>;
export type ListNVeCompaniesForCompetitorsPageQueryResult = Apollo.QueryResult<
  ListNVeCompaniesForCompetitorsPage,
  ListNVeCompaniesForCompetitorsPageVariables
>;
export const ListNVeTopicSearchTermsDocument = gql`
  query ListNVeTopicSearchTerms($input: listNVeTopicSearchTermsInput!) {
    listNVeTopicSearchTerms(input: $input) {
      content
      ranking
      searchVolume
    }
  }
`;

/**
 * __useListNVeTopicSearchTerms__
 *
 * To run a query within a React component, call `useListNVeTopicSearchTerms` and pass it any options that fit your needs.
 * When your component renders, `useListNVeTopicSearchTerms` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeTopicSearchTerms({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeTopicSearchTerms(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  > &
    (
      | { variables: ListNVeTopicSearchTermsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  >(ListNVeTopicSearchTermsDocument, options);
}
export function useListNVeTopicSearchTermsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  >(ListNVeTopicSearchTermsDocument, options);
}
export function useListNVeTopicSearchTermsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeTopicSearchTerms,
    ListNVeTopicSearchTermsVariables
  >(ListNVeTopicSearchTermsDocument, options);
}
export type ListNVeTopicSearchTermsHookResult = ReturnType<
  typeof useListNVeTopicSearchTerms
>;
export type ListNVeTopicSearchTermsLazyQueryHookResult = ReturnType<
  typeof useListNVeTopicSearchTermsLazyQuery
>;
export type ListNVeTopicSearchTermsSuspenseQueryHookResult = ReturnType<
  typeof useListNVeTopicSearchTermsSuspenseQuery
>;
export type ListNVeTopicSearchTermsQueryResult = Apollo.QueryResult<
  ListNVeTopicSearchTerms,
  ListNVeTopicSearchTermsVariables
>;
export const ListNVeTopicTop20ArticlesDocument = gql`
  query ListNVeTopicTop20Articles($input: ListNVeTopicCompanyDataInput!) {
    listNVeTopicTop20Articles(input: $input) {
      ranking
      title
      traffic
      url
    }
  }
`;

/**
 * __useListNVeTopicTop20Articles__
 *
 * To run a query within a React component, call `useListNVeTopicTop20Articles` and pass it any options that fit your needs.
 * When your component renders, `useListNVeTopicTop20Articles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeTopicTop20Articles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeTopicTop20Articles(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  > &
    (
      | { variables: ListNVeTopicTop20ArticlesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  >(ListNVeTopicTop20ArticlesDocument, options);
}
export function useListNVeTopicTop20ArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  >(ListNVeTopicTop20ArticlesDocument, options);
}
export function useListNVeTopicTop20ArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeTopicTop20Articles,
    ListNVeTopicTop20ArticlesVariables
  >(ListNVeTopicTop20ArticlesDocument, options);
}
export type ListNVeTopicTop20ArticlesHookResult = ReturnType<
  typeof useListNVeTopicTop20Articles
>;
export type ListNVeTopicTop20ArticlesLazyQueryHookResult = ReturnType<
  typeof useListNVeTopicTop20ArticlesLazyQuery
>;
export type ListNVeTopicTop20ArticlesSuspenseQueryHookResult = ReturnType<
  typeof useListNVeTopicTop20ArticlesSuspenseQuery
>;
export type ListNVeTopicTop20ArticlesQueryResult = Apollo.QueryResult<
  ListNVeTopicTop20Articles,
  ListNVeTopicTop20ArticlesVariables
>;
export const ListNVeWebArticlesDocument = gql`
  query ListNVeWebArticles($input: ListNVeTopicCompanyDataInput!) {
    listNVeWebArticles(input: $input) {
      ranking
      title
      traffic
      url
      preScore
    }
  }
`;

/**
 * __useListNVeWebArticles__
 *
 * To run a query within a React component, call `useListNVeWebArticles` and pass it any options that fit your needs.
 * When your component renders, `useListNVeWebArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNVeWebArticles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListNVeWebArticles(
  baseOptions: Apollo.QueryHookOptions<
    ListNVeWebArticles,
    ListNVeWebArticlesVariables
  > &
    (
      | { variables: ListNVeWebArticlesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListNVeWebArticles, ListNVeWebArticlesVariables>(
    ListNVeWebArticlesDocument,
    options
  );
}
export function useListNVeWebArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListNVeWebArticles,
    ListNVeWebArticlesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListNVeWebArticles, ListNVeWebArticlesVariables>(
    ListNVeWebArticlesDocument,
    options
  );
}
export function useListNVeWebArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListNVeWebArticles,
    ListNVeWebArticlesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListNVeWebArticles,
    ListNVeWebArticlesVariables
  >(ListNVeWebArticlesDocument, options);
}
export type ListNVeWebArticlesHookResult = ReturnType<
  typeof useListNVeWebArticles
>;
export type ListNVeWebArticlesLazyQueryHookResult = ReturnType<
  typeof useListNVeWebArticlesLazyQuery
>;
export type ListNVeWebArticlesSuspenseQueryHookResult = ReturnType<
  typeof useListNVeWebArticlesSuspenseQuery
>;
export type ListNVeWebArticlesQueryResult = Apollo.QueryResult<
  ListNVeWebArticles,
  ListNVeWebArticlesVariables
>;
export const GetNVeMetadataDocument = gql`
  query GetNVeMetadata($input: GetNVeMetadataInput!) {
    getNVeMetadata(input: $input) {
      id
      updatedAt
      createdAt
      customer
    }
  }
`;

/**
 * __useGetNVeMetadata__
 *
 * To run a query within a React component, call `useGetNVeMetadata` and pass it any options that fit your needs.
 * When your component renders, `useGetNVeMetadata` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNVeMetadata({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNVeMetadata(
  baseOptions: Apollo.QueryHookOptions<
    GetNVeMetadata,
    GetNVeMetadataVariables
  > &
    ({ variables: GetNVeMetadataVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetNVeMetadata, GetNVeMetadataVariables>(
    GetNVeMetadataDocument,
    options
  );
}
export function useGetNVeMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNVeMetadata,
    GetNVeMetadataVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetNVeMetadata, GetNVeMetadataVariables>(
    GetNVeMetadataDocument,
    options
  );
}
export function useGetNVeMetadataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNVeMetadata,
    GetNVeMetadataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNVeMetadata, GetNVeMetadataVariables>(
    GetNVeMetadataDocument,
    options
  );
}
export type GetNVeMetadataHookResult = ReturnType<typeof useGetNVeMetadata>;
export type GetNVeMetadataLazyQueryHookResult = ReturnType<
  typeof useGetNVeMetadataLazyQuery
>;
export type GetNVeMetadataSuspenseQueryHookResult = ReturnType<
  typeof useGetNVeMetadataSuspenseQuery
>;
export type GetNVeMetadataQueryResult = Apollo.QueryResult<
  GetNVeMetadata,
  GetNVeMetadataVariables
>;
export const GetNVeTopicDocument = gql`
  query GetNVeTopic($input: GetNVeTopicInput!) {
    getNVeTopic(input: $input) {
      ...NVeTopicFull
      topKeywords
    }
  }
  ${NVeTopicFull}
`;

/**
 * __useGetNVeTopic__
 *
 * To run a query within a React component, call `useGetNVeTopic` and pass it any options that fit your needs.
 * When your component renders, `useGetNVeTopic` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNVeTopic({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNVeTopic(
  baseOptions: Apollo.QueryHookOptions<GetNVeTopic, GetNVeTopicVariables> &
    ({ variables: GetNVeTopicVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetNVeTopic, GetNVeTopicVariables>(
    GetNVeTopicDocument,
    options
  );
}
export function useGetNVeTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNVeTopic, GetNVeTopicVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetNVeTopic, GetNVeTopicVariables>(
    GetNVeTopicDocument,
    options
  );
}
export function useGetNVeTopicSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNVeTopic,
    GetNVeTopicVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNVeTopic, GetNVeTopicVariables>(
    GetNVeTopicDocument,
    options
  );
}
export type GetNVeTopicHookResult = ReturnType<typeof useGetNVeTopic>;
export type GetNVeTopicLazyQueryHookResult = ReturnType<
  typeof useGetNVeTopicLazyQuery
>;
export type GetNVeTopicSuspenseQueryHookResult = ReturnType<
  typeof useGetNVeTopicSuspenseQuery
>;
export type GetNVeTopicQueryResult = Apollo.QueryResult<
  GetNVeTopic,
  GetNVeTopicVariables
>;
export const GetNVeFilterDataDocument = gql`
  query GetNVeFilterData($input: GetNVeFilterDataInput!) {
    getNVeFilterData(input: $input) {
      id
      maxArticlesCount
      maxAverageRanking
      maxChildTopicCount
      maxSearchTermsCount
      maxSearchVolume
      maxTop20ArticlesCount
      maxTraffic
      minArticlesCount
      minAverageRanking
      minChildTopicCount
      minSearchTermsCount
      minSearchVolume
      minTop20ArticlesCount
      minTraffic
    }
  }
`;

/**
 * __useGetNVeFilterData__
 *
 * To run a query within a React component, call `useGetNVeFilterData` and pass it any options that fit your needs.
 * When your component renders, `useGetNVeFilterData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNVeFilterData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNVeFilterData(
  baseOptions: Apollo.QueryHookOptions<
    GetNVeFilterData,
    GetNVeFilterDataVariables
  > &
    (
      | { variables: GetNVeFilterDataVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetNVeFilterData, GetNVeFilterDataVariables>(
    GetNVeFilterDataDocument,
    options
  );
}
export function useGetNVeFilterDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNVeFilterData,
    GetNVeFilterDataVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetNVeFilterData, GetNVeFilterDataVariables>(
    GetNVeFilterDataDocument,
    options
  );
}
export function useGetNVeFilterDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNVeFilterData,
    GetNVeFilterDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNVeFilterData, GetNVeFilterDataVariables>(
    GetNVeFilterDataDocument,
    options
  );
}
export type GetNVeFilterDataHookResult = ReturnType<typeof useGetNVeFilterData>;
export type GetNVeFilterDataLazyQueryHookResult = ReturnType<
  typeof useGetNVeFilterDataLazyQuery
>;
export type GetNVeFilterDataSuspenseQueryHookResult = ReturnType<
  typeof useGetNVeFilterDataSuspenseQuery
>;
export type GetNVeFilterDataQueryResult = Apollo.QueryResult<
  GetNVeFilterData,
  GetNVeFilterDataVariables
>;
export const OnNVePriorityUpdateDocument = gql`
  subscription OnNVePriorityUpdate($pk1: String!, $userId: String!) {
    onNVePriorityUpdate(pk1: $pk1, userId: $userId) {
      id
      priority
      type
      updateToken
      updatedAt
    }
  }
`;

/**
 * __useOnNVePriorityUpdate__
 *
 * To run a query within a React component, call `useOnNVePriorityUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnNVePriorityUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNVePriorityUpdate({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnNVePriorityUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnNVePriorityUpdate,
    OnNVePriorityUpdateVariables
  > &
    (
      | { variables: OnNVePriorityUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNVePriorityUpdate,
    OnNVePriorityUpdateVariables
  >(OnNVePriorityUpdateDocument, options);
}
export type OnNVePriorityUpdateHookResult = ReturnType<
  typeof useOnNVePriorityUpdate
>;
export type OnNVePriorityUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnNVePriorityUpdate>;
export const OnNVeTopicSavedUpdateDocument = gql`
  subscription OnNVeTopicSavedUpdate($pk1: String!, $userId: String!) {
    onNVeTopicSavedUpdate(pk1: $pk1, userId: $userId) {
      id
      isSaved
      updateToken
      updatedAt
    }
  }
`;

/**
 * __useOnNVeTopicSavedUpdate__
 *
 * To run a query within a React component, call `useOnNVeTopicSavedUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnNVeTopicSavedUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNVeTopicSavedUpdate({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnNVeTopicSavedUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnNVeTopicSavedUpdate,
    OnNVeTopicSavedUpdateVariables
  > &
    (
      | { variables: OnNVeTopicSavedUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnNVeTopicSavedUpdate,
    OnNVeTopicSavedUpdateVariables
  >(OnNVeTopicSavedUpdateDocument, options);
}
export type OnNVeTopicSavedUpdateHookResult = ReturnType<
  typeof useOnNVeTopicSavedUpdate
>;
export type OnNVeTopicSavedUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnNVeTopicSavedUpdate>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...OrganizationFull
    }
  }
  ${OrganizationFull}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganization,
  UpdateOrganizationVariables
>;

/**
 * __useUpdateOrganization__
 *
 * To run a mutation, you first call `useUpdateOrganization` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganization` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganization, { data, loading, error }] = useUpdateOrganization({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganization(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganization,
    UpdateOrganizationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganization, UpdateOrganizationVariables>(
    UpdateOrganizationDocument,
    options
  );
}
export type UpdateOrganizationHookResult = ReturnType<
  typeof useUpdateOrganization
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganization>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganization,
  UpdateOrganizationVariables
>;
export const GetOrganizationDocument = gql`
  query GetOrganization {
    getOrganization {
      ...OrganizationFull
    }
  }
  ${OrganizationFull}
`;

/**
 * __useGetOrganization__
 *
 * To run a query within a React component, call `useGetOrganization` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganization` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganization({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganization(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganization,
    GetOrganizationVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetOrganization, GetOrganizationVariables>(
    GetOrganizationDocument,
    options
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganization,
    GetOrganizationVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetOrganization, GetOrganizationVariables>(
    GetOrganizationDocument,
    options
  );
}
export function useGetOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganization,
    GetOrganizationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrganization, GetOrganizationVariables>(
    GetOrganizationDocument,
    options
  );
}
export type GetOrganizationHookResult = ReturnType<typeof useGetOrganization>;
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationSuspenseQuery
>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganization,
  GetOrganizationVariables
>;
export const OnOrganizationUpdateByIdDocument = gql`
  subscription OnOrganizationUpdateById(
    $userId: String
    $pk1: String!
    $id: ID!
    $filter: String
  ) {
    onOrganizationUpdateById(
      userId: $userId
      pk1: $pk1
      id: $id
      filter: $filter
    ) {
      ...OrganizationSubscriptionFull
    }
  }
  ${OrganizationSubscriptionFull}
`;

/**
 * __useOnOrganizationUpdateById__
 *
 * To run a query within a React component, call `useOnOrganizationUpdateById` and pass it any options that fit your needs.
 * When your component renders, `useOnOrganizationUpdateById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrganizationUpdateById({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnOrganizationUpdateById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnOrganizationUpdateById,
    OnOrganizationUpdateByIdVariables
  > &
    (
      | { variables: OnOrganizationUpdateByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnOrganizationUpdateById,
    OnOrganizationUpdateByIdVariables
  >(OnOrganizationUpdateByIdDocument, options);
}
export type OnOrganizationUpdateByIdHookResult = ReturnType<
  typeof useOnOrganizationUpdateById
>;
export type OnOrganizationUpdateByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnOrganizationUpdateById>;
export const OnOrganizationDeleteByIdDocument = gql`
  subscription OnOrganizationDeleteById(
    $userId: String
    $pk1: String!
    $id: ID!
    $filter: String
  ) {
    onOrganizationDeleteById(
      userId: $userId
      pk1: $pk1
      id: $id
      filter: $filter
    ) {
      id
    }
  }
`;

/**
 * __useOnOrganizationDeleteById__
 *
 * To run a query within a React component, call `useOnOrganizationDeleteById` and pass it any options that fit your needs.
 * When your component renders, `useOnOrganizationDeleteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrganizationDeleteById({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnOrganizationDeleteById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnOrganizationDeleteById,
    OnOrganizationDeleteByIdVariables
  > &
    (
      | { variables: OnOrganizationDeleteByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnOrganizationDeleteById,
    OnOrganizationDeleteByIdVariables
  >(OnOrganizationDeleteByIdDocument, options);
}
export type OnOrganizationDeleteByIdHookResult = ReturnType<
  typeof useOnOrganizationDeleteById
>;
export type OnOrganizationDeleteByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnOrganizationDeleteById>;
export const GrantUserRolesDocument = gql`
  mutation GrantUserRoles($input: UserRoleInput!) {
    grantUserRoles(input: $input) {
      id
      data {
        roleId
        entityId
      }
    }
  }
`;
export type GrantUserRolesMutationFn = Apollo.MutationFunction<
  GrantUserRoles,
  GrantUserRolesVariables
>;

/**
 * __useGrantUserRoles__
 *
 * To run a mutation, you first call `useGrantUserRoles` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantUserRoles` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantUserRoles, { data, loading, error }] = useGrantUserRoles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGrantUserRoles(
  baseOptions?: Apollo.MutationHookOptions<
    GrantUserRoles,
    GrantUserRolesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GrantUserRoles, GrantUserRolesVariables>(
    GrantUserRolesDocument,
    options
  );
}
export type GrantUserRolesHookResult = ReturnType<typeof useGrantUserRoles>;
export type GrantUserRolesMutationResult =
  Apollo.MutationResult<GrantUserRoles>;
export type GrantUserRolesMutationOptions = Apollo.BaseMutationOptions<
  GrantUserRoles,
  GrantUserRolesVariables
>;
export const RevokeUserRolesDocument = gql`
  mutation RevokeUserRoles($input: UserRoleInput!) {
    revokeUserRoles(input: $input) {
      id
      data {
        roleId
        entityId
      }
    }
  }
`;
export type RevokeUserRolesMutationFn = Apollo.MutationFunction<
  RevokeUserRoles,
  RevokeUserRolesVariables
>;

/**
 * __useRevokeUserRoles__
 *
 * To run a mutation, you first call `useRevokeUserRoles` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeUserRoles` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeUserRoles, { data, loading, error }] = useRevokeUserRoles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeUserRoles(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeUserRoles,
    RevokeUserRolesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RevokeUserRoles, RevokeUserRolesVariables>(
    RevokeUserRolesDocument,
    options
  );
}
export type RevokeUserRolesHookResult = ReturnType<typeof useRevokeUserRoles>;
export type RevokeUserRolesMutationResult =
  Apollo.MutationResult<RevokeUserRoles>;
export type RevokeUserRolesMutationOptions = Apollo.BaseMutationOptions<
  RevokeUserRoles,
  RevokeUserRolesVariables
>;
export const UpdateAuth0RoleDocument = gql`
  mutation UpdateAuth0Role($input: UpdateAuth0UserRoleInput!) {
    updateAuth0Role(input: $input)
  }
`;
export type UpdateAuth0RoleMutationFn = Apollo.MutationFunction<
  UpdateAuth0Role,
  UpdateAuth0RoleVariables
>;

/**
 * __useUpdateAuth0Role__
 *
 * To run a mutation, you first call `useUpdateAuth0Role` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuth0Role` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuth0Role, { data, loading, error }] = useUpdateAuth0Role({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuth0Role(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAuth0Role,
    UpdateAuth0RoleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAuth0Role, UpdateAuth0RoleVariables>(
    UpdateAuth0RoleDocument,
    options
  );
}
export type UpdateAuth0RoleHookResult = ReturnType<typeof useUpdateAuth0Role>;
export type UpdateAuth0RoleMutationResult =
  Apollo.MutationResult<UpdateAuth0Role>;
export type UpdateAuth0RoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuth0Role,
  UpdateAuth0RoleVariables
>;
export const GetAuth0RoleDocument = gql`
  query getAuth0Role {
    getUser {
      id
      userOrgData {
        auth0 {
          role
          id
          __typename
        }
      }
    }
  }
`;

/**
 * __useGetAuth0Role__
 *
 * To run a query within a React component, call `useGetAuth0Role` and pass it any options that fit your needs.
 * When your component renders, `useGetAuth0Role` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuth0Role({
 *   variables: {
 *   },
 * });
 */
export function useGetAuth0Role(
  baseOptions?: Apollo.QueryHookOptions<GetAuth0Role, GetAuth0RoleVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetAuth0Role, GetAuth0RoleVariables>(
    GetAuth0RoleDocument,
    options
  );
}
export function useGetAuth0RoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuth0Role, GetAuth0RoleVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetAuth0Role, GetAuth0RoleVariables>(
    GetAuth0RoleDocument,
    options
  );
}
export function useGetAuth0RoleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuth0Role,
    GetAuth0RoleVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAuth0Role, GetAuth0RoleVariables>(
    GetAuth0RoleDocument,
    options
  );
}
export type GetAuth0RoleHookResult = ReturnType<typeof useGetAuth0Role>;
export type GetAuth0RoleLazyQueryHookResult = ReturnType<
  typeof useGetAuth0RoleLazyQuery
>;
export type GetAuth0RoleSuspenseQueryHookResult = ReturnType<
  typeof useGetAuth0RoleSuspenseQuery
>;
export type GetAuth0RoleQueryResult = Apollo.QueryResult<
  GetAuth0Role,
  GetAuth0RoleVariables
>;
export const GetUserRoleAssignmentsDocument = gql`
  query GetUserRoleAssignments {
    getUserRoleAssignments {
      id
      data {
        roleId
        entityId
      }
    }
  }
`;

/**
 * __useGetUserRoleAssignments__
 *
 * To run a query within a React component, call `useGetUserRoleAssignments` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleAssignments` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleAssignments({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleAssignments(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >(GetUserRoleAssignmentsDocument, options);
}
export function useGetUserRoleAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >(GetUserRoleAssignmentsDocument, options);
}
export function useGetUserRoleAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUserRoleAssignments,
    GetUserRoleAssignmentsVariables
  >(GetUserRoleAssignmentsDocument, options);
}
export type GetUserRoleAssignmentsHookResult = ReturnType<
  typeof useGetUserRoleAssignments
>;
export type GetUserRoleAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetUserRoleAssignmentsLazyQuery
>;
export type GetUserRoleAssignmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserRoleAssignmentsSuspenseQuery
>;
export type GetUserRoleAssignmentsQueryResult = Apollo.QueryResult<
  GetUserRoleAssignments,
  GetUserRoleAssignmentsVariables
>;
export const GetGlobalPermissionTreeDocument = gql`
  query GetGlobalPermissionTree {
    getGlobalPermissionsTree {
      data {
        actions
        children {
          actions
          level
          children {
            actions
            level
            children {
              actions
              level
            }
          }
        }
        level
      }
      id
    }
  }
`;

/**
 * __useGetGlobalPermissionTree__
 *
 * To run a query within a React component, call `useGetGlobalPermissionTree` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalPermissionTree` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalPermissionTree({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalPermissionTree(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >(GetGlobalPermissionTreeDocument, options);
}
export function useGetGlobalPermissionTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >(GetGlobalPermissionTreeDocument, options);
}
export function useGetGlobalPermissionTreeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGlobalPermissionTree,
    GetGlobalPermissionTreeVariables
  >(GetGlobalPermissionTreeDocument, options);
}
export type GetGlobalPermissionTreeHookResult = ReturnType<
  typeof useGetGlobalPermissionTree
>;
export type GetGlobalPermissionTreeLazyQueryHookResult = ReturnType<
  typeof useGetGlobalPermissionTreeLazyQuery
>;
export type GetGlobalPermissionTreeSuspenseQueryHookResult = ReturnType<
  typeof useGetGlobalPermissionTreeSuspenseQuery
>;
export type GetGlobalPermissionTreeQueryResult = Apollo.QueryResult<
  GetGlobalPermissionTree,
  GetGlobalPermissionTreeVariables
>;
export const GetGlobalRoleDefinitionsDocument = gql`
  query GetGlobalRoleDefinitions {
    getGlobalRoleDefinitions {
      data {
        name
        roleId
        statements {
          actions
          effect
          inheritance
          level
        }
      }
      id
    }
  }
`;

/**
 * __useGetGlobalRoleDefinitions__
 *
 * To run a query within a React component, call `useGetGlobalRoleDefinitions` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalRoleDefinitions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalRoleDefinitions({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalRoleDefinitions(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >(GetGlobalRoleDefinitionsDocument, options);
}
export function useGetGlobalRoleDefinitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >(GetGlobalRoleDefinitionsDocument, options);
}
export function useGetGlobalRoleDefinitionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGlobalRoleDefinitions,
    GetGlobalRoleDefinitionsVariables
  >(GetGlobalRoleDefinitionsDocument, options);
}
export type GetGlobalRoleDefinitionsHookResult = ReturnType<
  typeof useGetGlobalRoleDefinitions
>;
export type GetGlobalRoleDefinitionsLazyQueryHookResult = ReturnType<
  typeof useGetGlobalRoleDefinitionsLazyQuery
>;
export type GetGlobalRoleDefinitionsSuspenseQueryHookResult = ReturnType<
  typeof useGetGlobalRoleDefinitionsSuspenseQuery
>;
export type GetGlobalRoleDefinitionsQueryResult = Apollo.QueryResult<
  GetGlobalRoleDefinitions,
  GetGlobalRoleDefinitionsVariables
>;
export const OnUserRoleAssignmentsDocument = gql`
  subscription OnUserRoleAssignments($id: String!) {
    onUserRoleAssignments(id: $id) {
      id
      data {
        roleId
        entityId
      }
    }
  }
`;

/**
 * __useOnUserRoleAssignments__
 *
 * To run a query within a React component, call `useOnUserRoleAssignments` and pass it any options that fit your needs.
 * When your component renders, `useOnUserRoleAssignments` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserRoleAssignments({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnUserRoleAssignments(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUserRoleAssignments,
    OnUserRoleAssignmentsVariables
  > &
    (
      | { variables: OnUserRoleAssignmentsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnUserRoleAssignments,
    OnUserRoleAssignmentsVariables
  >(OnUserRoleAssignmentsDocument, options);
}
export type OnUserRoleAssignmentsHookResult = ReturnType<
  typeof useOnUserRoleAssignments
>;
export type OnUserRoleAssignmentsSubscriptionResult =
  Apollo.SubscriptionResult<OnUserRoleAssignments>;
export const DeleteArticlePostDocument = gql`
  mutation DeleteArticlePost($input: DeleteArticlePostInput!) {
    deleteArticlePost(input: $input) {
      id
    }
  }
`;
export type DeleteArticlePostMutationFn = Apollo.MutationFunction<
  DeleteArticlePost,
  DeleteArticlePostVariables
>;

/**
 * __useDeleteArticlePost__
 *
 * To run a mutation, you first call `useDeleteArticlePost` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticlePost` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticlePost, { data, loading, error }] = useDeleteArticlePost({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteArticlePost(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticlePost,
    DeleteArticlePostVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteArticlePost, DeleteArticlePostVariables>(
    DeleteArticlePostDocument,
    options
  );
}
export type DeleteArticlePostHookResult = ReturnType<
  typeof useDeleteArticlePost
>;
export type DeleteArticlePostMutationResult =
  Apollo.MutationResult<DeleteArticlePost>;
export type DeleteArticlePostMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticlePost,
  DeleteArticlePostVariables
>;
export const UpdateArticlePostDocument = gql`
  mutation UpdateArticlePost($input: UpdateArticlePostInput!) {
    updateArticlePost(input: $input) {
      ...ArticlePostFull
    }
  }
  ${ArticlePostFull}
`;
export type UpdateArticlePostMutationFn = Apollo.MutationFunction<
  UpdateArticlePost,
  UpdateArticlePostVariables
>;

/**
 * __useUpdateArticlePost__
 *
 * To run a mutation, you first call `useUpdateArticlePost` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticlePost` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticlePost, { data, loading, error }] = useUpdateArticlePost({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticlePost(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticlePost,
    UpdateArticlePostVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateArticlePost, UpdateArticlePostVariables>(
    UpdateArticlePostDocument,
    options
  );
}
export type UpdateArticlePostHookResult = ReturnType<
  typeof useUpdateArticlePost
>;
export type UpdateArticlePostMutationResult =
  Apollo.MutationResult<UpdateArticlePost>;
export type UpdateArticlePostMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticlePost,
  UpdateArticlePostVariables
>;
export const CreateLinkedinTokenDocument = gql`
  mutation CreateLinkedinToken($input: CreateLinkedinTokenInput!) {
    createLinkedinToken(input: $input) {
      ...ProjectMetadataBase
      ...ProjectMetadataConnection
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataConnection}
`;
export type CreateLinkedinTokenMutationFn = Apollo.MutationFunction<
  CreateLinkedinToken,
  CreateLinkedinTokenVariables
>;

/**
 * __useCreateLinkedinToken__
 *
 * To run a mutation, you first call `useCreateLinkedinToken` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedinToken` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedinToken, { data, loading, error }] = useCreateLinkedinToken({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkedinToken(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkedinToken,
    CreateLinkedinTokenVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLinkedinToken, CreateLinkedinTokenVariables>(
    CreateLinkedinTokenDocument,
    options
  );
}
export type CreateLinkedinTokenHookResult = ReturnType<
  typeof useCreateLinkedinToken
>;
export type CreateLinkedinTokenMutationResult =
  Apollo.MutationResult<CreateLinkedinToken>;
export type CreateLinkedinTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkedinToken,
  CreateLinkedinTokenVariables
>;
export const PublishArticlePostDocument = gql`
  mutation PublishArticlePost($input: PublishArticlePostInput!) {
    publishArticlePost(input: $input) {
      id
      statusCode
      code
      url
    }
  }
`;
export type PublishArticlePostMutationFn = Apollo.MutationFunction<
  PublishArticlePost,
  PublishArticlePostVariables
>;

/**
 * __usePublishArticlePost__
 *
 * To run a mutation, you first call `usePublishArticlePost` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishArticlePost` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishArticlePost, { data, loading, error }] = usePublishArticlePost({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishArticlePost(
  baseOptions?: Apollo.MutationHookOptions<
    PublishArticlePost,
    PublishArticlePostVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishArticlePost, PublishArticlePostVariables>(
    PublishArticlePostDocument,
    options
  );
}
export type PublishArticlePostHookResult = ReturnType<
  typeof usePublishArticlePost
>;
export type PublishArticlePostMutationResult =
  Apollo.MutationResult<PublishArticlePost>;
export type PublishArticlePostMutationOptions = Apollo.BaseMutationOptions<
  PublishArticlePost,
  PublishArticlePostVariables
>;
export const ListArticlePostsDocument = gql`
  query ListArticlePosts($input: ListArticlePostsInput!) {
    listArticlePosts(input: $input) {
      items {
        ...ArticlePostFull
      }
    }
  }
  ${ArticlePostFull}
`;

/**
 * __useListArticlePosts__
 *
 * To run a query within a React component, call `useListArticlePosts` and pass it any options that fit your needs.
 * When your component renders, `useListArticlePosts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlePosts({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListArticlePosts(
  baseOptions: Apollo.QueryHookOptions<
    ListArticlePosts,
    ListArticlePostsVariables
  > &
    (
      | { variables: ListArticlePostsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListArticlePosts, ListArticlePostsVariables>(
    ListArticlePostsDocument,
    options
  );
}
export function useListArticlePostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlePosts,
    ListArticlePostsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListArticlePosts, ListArticlePostsVariables>(
    ListArticlePostsDocument,
    options
  );
}
export function useListArticlePostsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlePosts,
    ListArticlePostsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListArticlePosts, ListArticlePostsVariables>(
    ListArticlePostsDocument,
    options
  );
}
export type ListArticlePostsHookResult = ReturnType<typeof useListArticlePosts>;
export type ListArticlePostsLazyQueryHookResult = ReturnType<
  typeof useListArticlePostsLazyQuery
>;
export type ListArticlePostsSuspenseQueryHookResult = ReturnType<
  typeof useListArticlePostsSuspenseQuery
>;
export type ListArticlePostsQueryResult = Apollo.QueryResult<
  ListArticlePosts,
  ListArticlePostsVariables
>;
export const GetArticlePostDocument = gql`
  query GetArticlePost($input: GetArticlePostInput!) {
    getArticlePost(input: $input) {
      ...ArticlePostFull
    }
  }
  ${ArticlePostFull}
`;

/**
 * __useGetArticlePost__
 *
 * To run a query within a React component, call `useGetArticlePost` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlePost` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlePost({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetArticlePost(
  baseOptions: Apollo.QueryHookOptions<
    GetArticlePost,
    GetArticlePostVariables
  > &
    ({ variables: GetArticlePostVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetArticlePost, GetArticlePostVariables>(
    GetArticlePostDocument,
    options
  );
}
export function useGetArticlePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlePost,
    GetArticlePostVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetArticlePost, GetArticlePostVariables>(
    GetArticlePostDocument,
    options
  );
}
export function useGetArticlePostSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetArticlePost,
    GetArticlePostVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetArticlePost, GetArticlePostVariables>(
    GetArticlePostDocument,
    options
  );
}
export type GetArticlePostHookResult = ReturnType<typeof useGetArticlePost>;
export type GetArticlePostLazyQueryHookResult = ReturnType<
  typeof useGetArticlePostLazyQuery
>;
export type GetArticlePostSuspenseQueryHookResult = ReturnType<
  typeof useGetArticlePostSuspenseQuery
>;
export type GetArticlePostQueryResult = Apollo.QueryResult<
  GetArticlePost,
  GetArticlePostVariables
>;
export const GenerateLinkedInPostByGptDocument = gql`
  query GenerateLinkedInPostByGPT($input: LinkedInPostByGPTInput!) {
    generateLinkedInPostByGPT(input: $input) {
      ...NotificationFull
    }
  }
  ${NotificationFull}
`;

/**
 * __useGenerateLinkedInPostByGpt__
 *
 * To run a query within a React component, call `useGenerateLinkedInPostByGpt` and pass it any options that fit your needs.
 * When your component renders, `useGenerateLinkedInPostByGpt` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateLinkedInPostByGpt({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLinkedInPostByGpt(
  baseOptions: Apollo.QueryHookOptions<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  > &
    (
      | { variables: GenerateLinkedInPostByGptVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  >(GenerateLinkedInPostByGptDocument, options);
}
export function useGenerateLinkedInPostByGptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  >(GenerateLinkedInPostByGptDocument, options);
}
export function useGenerateLinkedInPostByGptSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GenerateLinkedInPostByGpt,
    GenerateLinkedInPostByGptVariables
  >(GenerateLinkedInPostByGptDocument, options);
}
export type GenerateLinkedInPostByGptHookResult = ReturnType<
  typeof useGenerateLinkedInPostByGpt
>;
export type GenerateLinkedInPostByGptLazyQueryHookResult = ReturnType<
  typeof useGenerateLinkedInPostByGptLazyQuery
>;
export type GenerateLinkedInPostByGptSuspenseQueryHookResult = ReturnType<
  typeof useGenerateLinkedInPostByGptSuspenseQuery
>;
export type GenerateLinkedInPostByGptQueryResult = Apollo.QueryResult<
  GenerateLinkedInPostByGpt,
  GenerateLinkedInPostByGptVariables
>;
export const GetLinkedInConsentScreenUriDocument = gql`
  query GetLinkedInConsentScreenUri($input: LinkedinConsentScreenUriInput!) {
    getLinkedinConsentScreenUri(input: $input) {
      signInUri
    }
  }
`;

/**
 * __useGetLinkedInConsentScreenUri__
 *
 * To run a query within a React component, call `useGetLinkedInConsentScreenUri` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedInConsentScreenUri` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedInConsentScreenUri({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLinkedInConsentScreenUri(
  baseOptions: Apollo.QueryHookOptions<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  > &
    (
      | { variables: GetLinkedInConsentScreenUriVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  >(GetLinkedInConsentScreenUriDocument, options);
}
export function useGetLinkedInConsentScreenUriLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  >(GetLinkedInConsentScreenUriDocument, options);
}
export function useGetLinkedInConsentScreenUriSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLinkedInConsentScreenUri,
    GetLinkedInConsentScreenUriVariables
  >(GetLinkedInConsentScreenUriDocument, options);
}
export type GetLinkedInConsentScreenUriHookResult = ReturnType<
  typeof useGetLinkedInConsentScreenUri
>;
export type GetLinkedInConsentScreenUriLazyQueryHookResult = ReturnType<
  typeof useGetLinkedInConsentScreenUriLazyQuery
>;
export type GetLinkedInConsentScreenUriSuspenseQueryHookResult = ReturnType<
  typeof useGetLinkedInConsentScreenUriSuspenseQuery
>;
export type GetLinkedInConsentScreenUriQueryResult = Apollo.QueryResult<
  GetLinkedInConsentScreenUri,
  GetLinkedInConsentScreenUriVariables
>;
export const ListLinkedinOrganizationsDocument = gql`
  query ListLinkedinOrganizations($input: ListLinkedinOrganizationsInput!) {
    listLinkedinOrganizations(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useListLinkedinOrganizations__
 *
 * To run a query within a React component, call `useListLinkedinOrganizations` and pass it any options that fit your needs.
 * When your component renders, `useListLinkedinOrganizations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLinkedinOrganizations({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListLinkedinOrganizations(
  baseOptions: Apollo.QueryHookOptions<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  > &
    (
      | { variables: ListLinkedinOrganizationsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  >(ListLinkedinOrganizationsDocument, options);
}
export function useListLinkedinOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  >(ListLinkedinOrganizationsDocument, options);
}
export function useListLinkedinOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListLinkedinOrganizations,
    ListLinkedinOrganizationsVariables
  >(ListLinkedinOrganizationsDocument, options);
}
export type ListLinkedinOrganizationsHookResult = ReturnType<
  typeof useListLinkedinOrganizations
>;
export type ListLinkedinOrganizationsLazyQueryHookResult = ReturnType<
  typeof useListLinkedinOrganizationsLazyQuery
>;
export type ListLinkedinOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useListLinkedinOrganizationsSuspenseQuery
>;
export type ListLinkedinOrganizationsQueryResult = Apollo.QueryResult<
  ListLinkedinOrganizations,
  ListLinkedinOrganizationsVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      ...ProjectMetadataFull
    }
  }
  ${ProjectMetadataFull}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProject,
  CreateProjectVariables
>;

/**
 * __useCreateProject__
 *
 * To run a mutation, you first call `useCreateProject` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProject` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProject, { data, loading, error }] = useCreateProject({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProject(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProject,
    CreateProjectVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProject, CreateProjectVariables>(
    CreateProjectDocument,
    options
  );
}
export type CreateProjectHookResult = ReturnType<typeof useCreateProject>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProject>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProject,
  CreateProjectVariables
>;
export const UpdateProjectMetadataDocument = gql`
  mutation UpdateProjectMetadata($input: UpdateProjectMetadataInput!) {
    updateProjectMetadata(input: $input) {
      ...ProjectMetadataFull
    }
  }
  ${ProjectMetadataFull}
`;
export type UpdateProjectMetadataMutationFn = Apollo.MutationFunction<
  UpdateProjectMetadata,
  UpdateProjectMetadataVariables
>;

/**
 * __useUpdateProjectMetadata__
 *
 * To run a mutation, you first call `useUpdateProjectMetadata` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMetadata` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMetadata, { data, loading, error }] = useUpdateProjectMetadata({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMetadata(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMetadata,
    UpdateProjectMetadataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectMetadata,
    UpdateProjectMetadataVariables
  >(UpdateProjectMetadataDocument, options);
}
export type UpdateProjectMetadataHookResult = ReturnType<
  typeof useUpdateProjectMetadata
>;
export type UpdateProjectMetadataMutationResult =
  Apollo.MutationResult<UpdateProjectMetadata>;
export type UpdateProjectMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMetadata,
  UpdateProjectMetadataVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProject,
  DeleteProjectVariables
>;

/**
 * __useDeleteProject__
 *
 * To run a mutation, you first call `useDeleteProject` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProject` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProject, { data, loading, error }] = useDeleteProject({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProject(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProject,
    DeleteProjectVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProject, DeleteProjectVariables>(
    DeleteProjectDocument,
    options
  );
}
export type DeleteProjectHookResult = ReturnType<typeof useDeleteProject>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProject>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProject,
  DeleteProjectVariables
>;
export const UpdateProjectBriefMetadataDocument = gql`
  mutation UpdateProjectBriefMetadata(
    $input: UpdateProjectBriefMetadataInput!
  ) {
    updateProjectBriefMetadata(input: $input) {
      ...ProjectBriefMetadataFull
    }
  }
  ${ProjectBriefMetadataFull}
`;
export type UpdateProjectBriefMetadataMutationFn = Apollo.MutationFunction<
  UpdateProjectBriefMetadata,
  UpdateProjectBriefMetadataVariables
>;

/**
 * __useUpdateProjectBriefMetadata__
 *
 * To run a mutation, you first call `useUpdateProjectBriefMetadata` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBriefMetadata` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBriefMetadata, { data, loading, error }] = useUpdateProjectBriefMetadata({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectBriefMetadata(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectBriefMetadata,
    UpdateProjectBriefMetadataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectBriefMetadata,
    UpdateProjectBriefMetadataVariables
  >(UpdateProjectBriefMetadataDocument, options);
}
export type UpdateProjectBriefMetadataHookResult = ReturnType<
  typeof useUpdateProjectBriefMetadata
>;
export type UpdateProjectBriefMetadataMutationResult =
  Apollo.MutationResult<UpdateProjectBriefMetadata>;
export type UpdateProjectBriefMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectBriefMetadata,
    UpdateProjectBriefMetadataVariables
  >;
export const UpdateProjectImageGuidelinesDocument = gql`
  mutation UpdateProjectImageGuidelines(
    $input: UpdateProjectImageGuidelinesInput!
  ) {
    updateProjectImageGuidelines(input: $input) {
      ...ProjectImageGuidelinesFull
    }
  }
  ${ProjectImageGuidelinesFull}
`;
export type UpdateProjectImageGuidelinesMutationFn = Apollo.MutationFunction<
  UpdateProjectImageGuidelines,
  UpdateProjectImageGuidelinesVariables
>;

/**
 * __useUpdateProjectImageGuidelines__
 *
 * To run a mutation, you first call `useUpdateProjectImageGuidelines` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectImageGuidelines` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectImageGuidelines, { data, loading, error }] = useUpdateProjectImageGuidelines({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectImageGuidelines(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectImageGuidelines,
    UpdateProjectImageGuidelinesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectImageGuidelines,
    UpdateProjectImageGuidelinesVariables
  >(UpdateProjectImageGuidelinesDocument, options);
}
export type UpdateProjectImageGuidelinesHookResult = ReturnType<
  typeof useUpdateProjectImageGuidelines
>;
export type UpdateProjectImageGuidelinesMutationResult =
  Apollo.MutationResult<UpdateProjectImageGuidelines>;
export type UpdateProjectImageGuidelinesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectImageGuidelines,
    UpdateProjectImageGuidelinesVariables
  >;
export const CreateProjectBriefDocumentDocument = gql`
  mutation CreateProjectBriefDocument(
    $input: CreateProjectBriefDocumentInput!
  ) {
    createProjectBriefDocument(input: $input) {
      ...ProjectBriefDocumentFull
    }
  }
  ${ProjectBriefDocumentFull}
`;
export type CreateProjectBriefDocumentMutationFn = Apollo.MutationFunction<
  CreateProjectBriefDocument,
  CreateProjectBriefDocumentVariables
>;

/**
 * __useCreateProjectBriefDocument__
 *
 * To run a mutation, you first call `useCreateProjectBriefDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectBriefDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectBriefDocument, { data, loading, error }] = useCreateProjectBriefDocument({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectBriefDocument(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectBriefDocument,
    CreateProjectBriefDocumentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProjectBriefDocument,
    CreateProjectBriefDocumentVariables
  >(CreateProjectBriefDocumentDocument, options);
}
export type CreateProjectBriefDocumentHookResult = ReturnType<
  typeof useCreateProjectBriefDocument
>;
export type CreateProjectBriefDocumentMutationResult =
  Apollo.MutationResult<CreateProjectBriefDocument>;
export type CreateProjectBriefDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProjectBriefDocument,
    CreateProjectBriefDocumentVariables
  >;
export const UpdateProjectBriefDocumentDocument = gql`
  mutation UpdateProjectBriefDocument(
    $input: UpdateProjectBriefDocumentInput!
  ) {
    updateProjectBriefDocument(input: $input) {
      ...ProjectBriefDocumentFull
    }
  }
  ${ProjectBriefDocumentFull}
`;
export type UpdateProjectBriefDocumentMutationFn = Apollo.MutationFunction<
  UpdateProjectBriefDocument,
  UpdateProjectBriefDocumentVariables
>;

/**
 * __useUpdateProjectBriefDocument__
 *
 * To run a mutation, you first call `useUpdateProjectBriefDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBriefDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBriefDocument, { data, loading, error }] = useUpdateProjectBriefDocument({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectBriefDocument(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectBriefDocument,
    UpdateProjectBriefDocumentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectBriefDocument,
    UpdateProjectBriefDocumentVariables
  >(UpdateProjectBriefDocumentDocument, options);
}
export type UpdateProjectBriefDocumentHookResult = ReturnType<
  typeof useUpdateProjectBriefDocument
>;
export type UpdateProjectBriefDocumentMutationResult =
  Apollo.MutationResult<UpdateProjectBriefDocument>;
export type UpdateProjectBriefDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectBriefDocument,
    UpdateProjectBriefDocumentVariables
  >;
export const UpdateProjectBriefDocumentsDocument = gql`
  mutation UpdateProjectBriefDocuments(
    $input: UpdateProjectBriefDocumentsInput!
  ) {
    updateProjectBriefDocuments(input: $input) {
      ...ProjectBriefDocumentFull
    }
  }
  ${ProjectBriefDocumentFull}
`;
export type UpdateProjectBriefDocumentsMutationFn = Apollo.MutationFunction<
  UpdateProjectBriefDocuments,
  UpdateProjectBriefDocumentsVariables
>;

/**
 * __useUpdateProjectBriefDocuments__
 *
 * To run a mutation, you first call `useUpdateProjectBriefDocuments` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBriefDocuments` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBriefDocuments, { data, loading, error }] = useUpdateProjectBriefDocuments({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectBriefDocuments(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectBriefDocuments,
    UpdateProjectBriefDocumentsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectBriefDocuments,
    UpdateProjectBriefDocumentsVariables
  >(UpdateProjectBriefDocumentsDocument, options);
}
export type UpdateProjectBriefDocumentsHookResult = ReturnType<
  typeof useUpdateProjectBriefDocuments
>;
export type UpdateProjectBriefDocumentsMutationResult =
  Apollo.MutationResult<UpdateProjectBriefDocuments>;
export type UpdateProjectBriefDocumentsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProjectBriefDocuments,
    UpdateProjectBriefDocumentsVariables
  >;
export const DeleteProjectBriefDocumentDocument = gql`
  mutation DeleteProjectBriefDocument(
    $input: DeleteProjectBriefDocumentInput!
  ) {
    deleteProjectBriefDocument(input: $input) {
      id
      type
    }
  }
`;
export type DeleteProjectBriefDocumentMutationFn = Apollo.MutationFunction<
  DeleteProjectBriefDocument,
  DeleteProjectBriefDocumentVariables
>;

/**
 * __useDeleteProjectBriefDocument__
 *
 * To run a mutation, you first call `useDeleteProjectBriefDocument` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectBriefDocument` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectBriefDocument, { data, loading, error }] = useDeleteProjectBriefDocument({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectBriefDocument(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectBriefDocument,
    DeleteProjectBriefDocumentVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectBriefDocument,
    DeleteProjectBriefDocumentVariables
  >(DeleteProjectBriefDocumentDocument, options);
}
export type DeleteProjectBriefDocumentHookResult = ReturnType<
  typeof useDeleteProjectBriefDocument
>;
export type DeleteProjectBriefDocumentMutationResult =
  Apollo.MutationResult<DeleteProjectBriefDocument>;
export type DeleteProjectBriefDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteProjectBriefDocument,
    DeleteProjectBriefDocumentVariables
  >;
export const CreateOneClickAnalysisDocument = gql`
  mutation CreateOneClickAnalysis($input: CreateOneClickAnalysisInput!) {
    createOneClickAnalysis(input: $input)
  }
`;
export type CreateOneClickAnalysisMutationFn = Apollo.MutationFunction<
  CreateOneClickAnalysis,
  CreateOneClickAnalysisVariables
>;

/**
 * __useCreateOneClickAnalysis__
 *
 * To run a mutation, you first call `useCreateOneClickAnalysis` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClickAnalysis` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClickAnalysis, { data, loading, error }] = useCreateOneClickAnalysis({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClickAnalysis(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneClickAnalysis,
    CreateOneClickAnalysisVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneClickAnalysis,
    CreateOneClickAnalysisVariables
  >(CreateOneClickAnalysisDocument, options);
}
export type CreateOneClickAnalysisHookResult = ReturnType<
  typeof useCreateOneClickAnalysis
>;
export type CreateOneClickAnalysisMutationResult =
  Apollo.MutationResult<CreateOneClickAnalysis>;
export type CreateOneClickAnalysisMutationOptions = Apollo.BaseMutationOptions<
  CreateOneClickAnalysis,
  CreateOneClickAnalysisVariables
>;
export const UpdateUserProjectDataDocument = gql`
  mutation UpdateUserProjectData($input: UpdateUserProjectDataInput!) {
    updateUserProjectData(input: $input) {
      hiddenTiles
      id
    }
  }
`;
export type UpdateUserProjectDataMutationFn = Apollo.MutationFunction<
  UpdateUserProjectData,
  UpdateUserProjectDataVariables
>;

/**
 * __useUpdateUserProjectData__
 *
 * To run a mutation, you first call `useUpdateUserProjectData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProjectData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProjectData, { data, loading, error }] = useUpdateUserProjectData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProjectData(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProjectData,
    UpdateUserProjectDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserProjectData,
    UpdateUserProjectDataVariables
  >(UpdateUserProjectDataDocument, options);
}
export type UpdateUserProjectDataHookResult = ReturnType<
  typeof useUpdateUserProjectData
>;
export type UpdateUserProjectDataMutationResult =
  Apollo.MutationResult<UpdateUserProjectData>;
export type UpdateUserProjectDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProjectData,
  UpdateUserProjectDataVariables
>;
export const CreateTopicInspirationsDocument = gql`
  mutation CreateTopicInspirations($projectId: ID!) {
    createTopicInspirations(projectId: $projectId)
  }
`;
export type CreateTopicInspirationsMutationFn = Apollo.MutationFunction<
  CreateTopicInspirations,
  CreateTopicInspirationsVariables
>;

/**
 * __useCreateTopicInspirations__
 *
 * To run a mutation, you first call `useCreateTopicInspirations` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicInspirations` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicInspirations, { data, loading, error }] = useCreateTopicInspirations({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateTopicInspirations(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTopicInspirations,
    CreateTopicInspirationsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTopicInspirations,
    CreateTopicInspirationsVariables
  >(CreateTopicInspirationsDocument, options);
}
export type CreateTopicInspirationsHookResult = ReturnType<
  typeof useCreateTopicInspirations
>;
export type CreateTopicInspirationsMutationResult =
  Apollo.MutationResult<CreateTopicInspirations>;
export type CreateTopicInspirationsMutationOptions = Apollo.BaseMutationOptions<
  CreateTopicInspirations,
  CreateTopicInspirationsVariables
>;
export const CreateProjectLabelDocument = gql`
  mutation CreateProjectLabel($input: CreateProjectLabelInput!) {
    createProjectLabel(input: $input) {
      ...ProjectLabelFull
    }
  }
  ${ProjectLabelFull}
`;
export type CreateProjectLabelMutationFn = Apollo.MutationFunction<
  CreateProjectLabel,
  CreateProjectLabelVariables
>;

/**
 * __useCreateProjectLabel__
 *
 * To run a mutation, you first call `useCreateProjectLabel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectLabel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectLabel, { data, loading, error }] = useCreateProjectLabel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectLabel(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectLabel,
    CreateProjectLabelVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectLabel, CreateProjectLabelVariables>(
    CreateProjectLabelDocument,
    options
  );
}
export type CreateProjectLabelHookResult = ReturnType<
  typeof useCreateProjectLabel
>;
export type CreateProjectLabelMutationResult =
  Apollo.MutationResult<CreateProjectLabel>;
export type CreateProjectLabelMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectLabel,
  CreateProjectLabelVariables
>;
export const UpdateProjectLabelDocument = gql`
  mutation UpdateProjectLabel($input: UpdateProjectLabelInput!) {
    updateProjectLabel(input: $input) {
      ...ProjectLabelFull
    }
  }
  ${ProjectLabelFull}
`;
export type UpdateProjectLabelMutationFn = Apollo.MutationFunction<
  UpdateProjectLabel,
  UpdateProjectLabelVariables
>;

/**
 * __useUpdateProjectLabel__
 *
 * To run a mutation, you first call `useUpdateProjectLabel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectLabel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectLabel, { data, loading, error }] = useUpdateProjectLabel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectLabel(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectLabel,
    UpdateProjectLabelVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectLabel, UpdateProjectLabelVariables>(
    UpdateProjectLabelDocument,
    options
  );
}
export type UpdateProjectLabelHookResult = ReturnType<
  typeof useUpdateProjectLabel
>;
export type UpdateProjectLabelMutationResult =
  Apollo.MutationResult<UpdateProjectLabel>;
export type UpdateProjectLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectLabel,
  UpdateProjectLabelVariables
>;
export const DeleteProjectLabelDocument = gql`
  mutation DeleteProjectLabel($input: DeleteProjectLabelInput!) {
    deleteProjectLabel(input: $input) {
      id
      type
    }
  }
`;
export type DeleteProjectLabelMutationFn = Apollo.MutationFunction<
  DeleteProjectLabel,
  DeleteProjectLabelVariables
>;

/**
 * __useDeleteProjectLabel__
 *
 * To run a mutation, you first call `useDeleteProjectLabel` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectLabel` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectLabel, { data, loading, error }] = useDeleteProjectLabel({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectLabel(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectLabel,
    DeleteProjectLabelVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectLabel, DeleteProjectLabelVariables>(
    DeleteProjectLabelDocument,
    options
  );
}
export type DeleteProjectLabelHookResult = ReturnType<
  typeof useDeleteProjectLabel
>;
export type DeleteProjectLabelMutationResult =
  Apollo.MutationResult<DeleteProjectLabel>;
export type DeleteProjectLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectLabel,
  DeleteProjectLabelVariables
>;
export const GetProjectMetadataBaseDocument = gql`
  query GetProjectMetadataBase($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectMetadataBase__
 *
 * To run a query within a React component, call `useGetProjectMetadataBase` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMetadataBase` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMetadataBase({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectMetadataBase(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  > &
    (
      | { variables: GetProjectMetadataBaseVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  >(GetProjectMetadataBaseDocument, options);
}
export function useGetProjectMetadataBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  >(GetProjectMetadataBaseDocument, options);
}
export function useGetProjectMetadataBaseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectMetadataBase,
    GetProjectMetadataBaseVariables
  >(GetProjectMetadataBaseDocument, options);
}
export type GetProjectMetadataBaseHookResult = ReturnType<
  typeof useGetProjectMetadataBase
>;
export type GetProjectMetadataBaseLazyQueryHookResult = ReturnType<
  typeof useGetProjectMetadataBaseLazyQuery
>;
export type GetProjectMetadataBaseSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectMetadataBaseSuspenseQuery
>;
export type GetProjectMetadataBaseQueryResult = Apollo.QueryResult<
  GetProjectMetadataBase,
  GetProjectMetadataBaseVariables
>;
export const GetProjectDataForLayoutDocument = gql`
  query GetProjectDataForLayout($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataDetails
      ...ProjectMetadataConnection
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataDetails}
  ${ProjectMetadataConnection}
`;

/**
 * __useGetProjectDataForLayout__
 *
 * To run a query within a React component, call `useGetProjectDataForLayout` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataForLayout` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataForLayout({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectDataForLayout(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  > &
    (
      | { variables: GetProjectDataForLayoutVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  >(GetProjectDataForLayoutDocument, options);
}
export function useGetProjectDataForLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  >(GetProjectDataForLayoutDocument, options);
}
export function useGetProjectDataForLayoutSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectDataForLayout,
    GetProjectDataForLayoutVariables
  >(GetProjectDataForLayoutDocument, options);
}
export type GetProjectDataForLayoutHookResult = ReturnType<
  typeof useGetProjectDataForLayout
>;
export type GetProjectDataForLayoutLazyQueryHookResult = ReturnType<
  typeof useGetProjectDataForLayoutLazyQuery
>;
export type GetProjectDataForLayoutSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectDataForLayoutSuspenseQuery
>;
export type GetProjectDataForLayoutQueryResult = Apollo.QueryResult<
  GetProjectDataForLayout,
  GetProjectDataForLayoutVariables
>;
export const GetProjectBriefDocument = gql`
  query GetProjectBrief($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataBrief
      ...ProjectMetadataStatistics
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataBrief}
  ${ProjectMetadataStatistics}
`;

/**
 * __useGetProjectBrief__
 *
 * To run a query within a React component, call `useGetProjectBrief` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectBrief` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectBrief({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectBrief(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectBrief,
    GetProjectBriefVariables
  > &
    (
      | { variables: GetProjectBriefVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectBrief, GetProjectBriefVariables>(
    GetProjectBriefDocument,
    options
  );
}
export function useGetProjectBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectBrief,
    GetProjectBriefVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetProjectBrief, GetProjectBriefVariables>(
    GetProjectBriefDocument,
    options
  );
}
export function useGetProjectBriefSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectBrief,
    GetProjectBriefVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectBrief, GetProjectBriefVariables>(
    GetProjectBriefDocument,
    options
  );
}
export type GetProjectBriefHookResult = ReturnType<typeof useGetProjectBrief>;
export type GetProjectBriefLazyQueryHookResult = ReturnType<
  typeof useGetProjectBriefLazyQuery
>;
export type GetProjectBriefSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectBriefSuspenseQuery
>;
export type GetProjectBriefQueryResult = Apollo.QueryResult<
  GetProjectBrief,
  GetProjectBriefVariables
>;
export const GetProjectAllowGenAiDocument = gql`
  query GetProjectAllowGenAI($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      brief {
        imageGuidelines {
          allowGenAI
        }
      }
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectAllowGenAi__
 *
 * To run a query within a React component, call `useGetProjectAllowGenAi` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAllowGenAi` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAllowGenAi({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectAllowGenAi(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectAllowGenAi,
    GetProjectAllowGenAiVariables
  > &
    (
      | { variables: GetProjectAllowGenAiVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectAllowGenAi, GetProjectAllowGenAiVariables>(
    GetProjectAllowGenAiDocument,
    options
  );
}
export function useGetProjectAllowGenAiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectAllowGenAi,
    GetProjectAllowGenAiVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectAllowGenAi,
    GetProjectAllowGenAiVariables
  >(GetProjectAllowGenAiDocument, options);
}
export function useGetProjectAllowGenAiSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectAllowGenAi,
    GetProjectAllowGenAiVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectAllowGenAi,
    GetProjectAllowGenAiVariables
  >(GetProjectAllowGenAiDocument, options);
}
export type GetProjectAllowGenAiHookResult = ReturnType<
  typeof useGetProjectAllowGenAi
>;
export type GetProjectAllowGenAiLazyQueryHookResult = ReturnType<
  typeof useGetProjectAllowGenAiLazyQuery
>;
export type GetProjectAllowGenAiSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectAllowGenAiSuspenseQuery
>;
export type GetProjectAllowGenAiQueryResult = Apollo.QueryResult<
  GetProjectAllowGenAi,
  GetProjectAllowGenAiVariables
>;
export const ListProjectIdsDocument = gql`
  query ListProjectIds(
    $filter: ListProjectsInputFilter
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

/**
 * __useListProjectIds__
 *
 * To run a query within a React component, call `useListProjectIds` and pass it any options that fit your needs.
 * When your component renders, `useListProjectIds` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectIds({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListProjectIds(
  baseOptions?: Apollo.QueryHookOptions<ListProjectIds, ListProjectIdsVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListProjectIds, ListProjectIdsVariables>(
    ListProjectIdsDocument,
    options
  );
}
export function useListProjectIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectIds,
    ListProjectIdsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListProjectIds, ListProjectIdsVariables>(
    ListProjectIdsDocument,
    options
  );
}
export function useListProjectIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListProjectIds,
    ListProjectIdsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListProjectIds, ListProjectIdsVariables>(
    ListProjectIdsDocument,
    options
  );
}
export type ListProjectIdsHookResult = ReturnType<typeof useListProjectIds>;
export type ListProjectIdsLazyQueryHookResult = ReturnType<
  typeof useListProjectIdsLazyQuery
>;
export type ListProjectIdsSuspenseQueryHookResult = ReturnType<
  typeof useListProjectIdsSuspenseQuery
>;
export type ListProjectIdsQueryResult = Apollo.QueryResult<
  ListProjectIds,
  ListProjectIdsVariables
>;
export const ListProjectsForSidebarDocument = gql`
  query ListProjectsForSidebar(
    $filter: ListProjectsInputFilter
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        color
        archived
        locale
        ...ProjectMetadataNeuraverse
      }
      nextToken
    }
  }
  ${ProjectMetadataNeuraverse}
`;

/**
 * __useListProjectsForSidebar__
 *
 * To run a query within a React component, call `useListProjectsForSidebar` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsForSidebar` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsForSidebar({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListProjectsForSidebar(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >(ListProjectsForSidebarDocument, options);
}
export function useListProjectsForSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >(ListProjectsForSidebarDocument, options);
}
export function useListProjectsForSidebarSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListProjectsForSidebar,
    ListProjectsForSidebarVariables
  >(ListProjectsForSidebarDocument, options);
}
export type ListProjectsForSidebarHookResult = ReturnType<
  typeof useListProjectsForSidebar
>;
export type ListProjectsForSidebarLazyQueryHookResult = ReturnType<
  typeof useListProjectsForSidebarLazyQuery
>;
export type ListProjectsForSidebarSuspenseQueryHookResult = ReturnType<
  typeof useListProjectsForSidebarSuspenseQuery
>;
export type ListProjectsForSidebarQueryResult = Apollo.QueryResult<
  ListProjectsForSidebar,
  ListProjectsForSidebarVariables
>;
export const GetIsProjectMonitoredDocument = gql`
  query GetIsProjectMonitored($projectId: String!) {
    getIsProjectMonitored(projectId: $projectId)
  }
`;

/**
 * __useGetIsProjectMonitored__
 *
 * To run a query within a React component, call `useGetIsProjectMonitored` and pass it any options that fit your needs.
 * When your component renders, `useGetIsProjectMonitored` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsProjectMonitored({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetIsProjectMonitored(
  baseOptions: Apollo.QueryHookOptions<
    GetIsProjectMonitored,
    GetIsProjectMonitoredVariables
  > &
    (
      | { variables: GetIsProjectMonitoredVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetIsProjectMonitored, GetIsProjectMonitoredVariables>(
    GetIsProjectMonitoredDocument,
    options
  );
}
export function useGetIsProjectMonitoredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIsProjectMonitored,
    GetIsProjectMonitoredVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetIsProjectMonitored,
    GetIsProjectMonitoredVariables
  >(GetIsProjectMonitoredDocument, options);
}
export function useGetIsProjectMonitoredSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIsProjectMonitored,
    GetIsProjectMonitoredVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIsProjectMonitored,
    GetIsProjectMonitoredVariables
  >(GetIsProjectMonitoredDocument, options);
}
export type GetIsProjectMonitoredHookResult = ReturnType<
  typeof useGetIsProjectMonitored
>;
export type GetIsProjectMonitoredLazyQueryHookResult = ReturnType<
  typeof useGetIsProjectMonitoredLazyQuery
>;
export type GetIsProjectMonitoredSuspenseQueryHookResult = ReturnType<
  typeof useGetIsProjectMonitoredSuspenseQuery
>;
export type GetIsProjectMonitoredQueryResult = Apollo.QueryResult<
  GetIsProjectMonitored,
  GetIsProjectMonitoredVariables
>;
export const GetProjectForMonitoringDocument = gql`
  query GetProjectForMonitoring($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      gscConnected
      wpConnectionId
      wpConnected
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectForMonitoring__
 *
 * To run a query within a React component, call `useGetProjectForMonitoring` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectForMonitoring` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectForMonitoring({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectForMonitoring(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  > &
    (
      | { variables: GetProjectForMonitoringVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  >(GetProjectForMonitoringDocument, options);
}
export function useGetProjectForMonitoringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  >(GetProjectForMonitoringDocument, options);
}
export function useGetProjectForMonitoringSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectForMonitoring,
    GetProjectForMonitoringVariables
  >(GetProjectForMonitoringDocument, options);
}
export type GetProjectForMonitoringHookResult = ReturnType<
  typeof useGetProjectForMonitoring
>;
export type GetProjectForMonitoringLazyQueryHookResult = ReturnType<
  typeof useGetProjectForMonitoringLazyQuery
>;
export type GetProjectForMonitoringSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectForMonitoringSuspenseQuery
>;
export type GetProjectForMonitoringQueryResult = Apollo.QueryResult<
  GetProjectForMonitoring,
  GetProjectForMonitoringVariables
>;
export const ListProjectsForProjectsOverviewDocument = gql`
  query ListProjectsForProjectsOverview(
    $filter: ListProjectsInputFilter
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...ProjectMetadataBase
        ...ProjectMetadataStatistics
        brief {
          projectBriefMetadata {
            planedArticles
            dueDate
          }
        }
      }
      nextToken
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataStatistics}
`;

/**
 * __useListProjectsForProjectsOverview__
 *
 * To run a query within a React component, call `useListProjectsForProjectsOverview` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsForProjectsOverview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsForProjectsOverview({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListProjectsForProjectsOverview(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >(ListProjectsForProjectsOverviewDocument, options);
}
export function useListProjectsForProjectsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >(ListProjectsForProjectsOverviewDocument, options);
}
export function useListProjectsForProjectsOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListProjectsForProjectsOverview,
    ListProjectsForProjectsOverviewVariables
  >(ListProjectsForProjectsOverviewDocument, options);
}
export type ListProjectsForProjectsOverviewHookResult = ReturnType<
  typeof useListProjectsForProjectsOverview
>;
export type ListProjectsForProjectsOverviewLazyQueryHookResult = ReturnType<
  typeof useListProjectsForProjectsOverviewLazyQuery
>;
export type ListProjectsForProjectsOverviewSuspenseQueryHookResult = ReturnType<
  typeof useListProjectsForProjectsOverviewSuspenseQuery
>;
export type ListProjectsForProjectsOverviewQueryResult = Apollo.QueryResult<
  ListProjectsForProjectsOverview,
  ListProjectsForProjectsOverviewVariables
>;
export const GetProjectForPlanPageDocument = gql`
  query GetProjectForPlanPage($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      brief {
        projectBriefMetadata {
          startDate
          dueDate
        }
      }
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectForPlanPage__
 *
 * To run a query within a React component, call `useGetProjectForPlanPage` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectForPlanPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectForPlanPage({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectForPlanPage(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectForPlanPage,
    GetProjectForPlanPageVariables
  > &
    (
      | { variables: GetProjectForPlanPageVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectForPlanPage, GetProjectForPlanPageVariables>(
    GetProjectForPlanPageDocument,
    options
  );
}
export function useGetProjectForPlanPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectForPlanPage,
    GetProjectForPlanPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectForPlanPage,
    GetProjectForPlanPageVariables
  >(GetProjectForPlanPageDocument, options);
}
export function useGetProjectForPlanPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectForPlanPage,
    GetProjectForPlanPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectForPlanPage,
    GetProjectForPlanPageVariables
  >(GetProjectForPlanPageDocument, options);
}
export type GetProjectForPlanPageHookResult = ReturnType<
  typeof useGetProjectForPlanPage
>;
export type GetProjectForPlanPageLazyQueryHookResult = ReturnType<
  typeof useGetProjectForPlanPageLazyQuery
>;
export type GetProjectForPlanPageSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectForPlanPageSuspenseQuery
>;
export type GetProjectForPlanPageQueryResult = Apollo.QueryResult<
  GetProjectForPlanPage,
  GetProjectForPlanPageVariables
>;
export const GetProjectConnectionDocument = gql`
  query GetProjectConnection($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataConnection
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataConnection}
`;

/**
 * __useGetProjectConnection__
 *
 * To run a query within a React component, call `useGetProjectConnection` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectConnection` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectConnection({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectConnection(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectConnection,
    GetProjectConnectionVariables
  > &
    (
      | { variables: GetProjectConnectionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectConnection, GetProjectConnectionVariables>(
    GetProjectConnectionDocument,
    options
  );
}
export function useGetProjectConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectConnection,
    GetProjectConnectionVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectConnection,
    GetProjectConnectionVariables
  >(GetProjectConnectionDocument, options);
}
export function useGetProjectConnectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectConnection,
    GetProjectConnectionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectConnection,
    GetProjectConnectionVariables
  >(GetProjectConnectionDocument, options);
}
export type GetProjectConnectionHookResult = ReturnType<
  typeof useGetProjectConnection
>;
export type GetProjectConnectionLazyQueryHookResult = ReturnType<
  typeof useGetProjectConnectionLazyQuery
>;
export type GetProjectConnectionSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectConnectionSuspenseQuery
>;
export type GetProjectConnectionQueryResult = Apollo.QueryResult<
  GetProjectConnection,
  GetProjectConnectionVariables
>;
export const GetProjectStatisticsDocument = gql`
  query GetProjectStatistics($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataStatistics
      brief {
        projectBriefMetadata {
          planedArticles
          startDate
          dueDate
        }
      }
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataStatistics}
`;

/**
 * __useGetProjectStatistics__
 *
 * To run a query within a React component, call `useGetProjectStatistics` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStatistics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStatistics({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectStatistics(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectStatistics,
    GetProjectStatisticsVariables
  > &
    (
      | { variables: GetProjectStatisticsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectStatistics, GetProjectStatisticsVariables>(
    GetProjectStatisticsDocument,
    options
  );
}
export function useGetProjectStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectStatistics,
    GetProjectStatisticsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectStatistics,
    GetProjectStatisticsVariables
  >(GetProjectStatisticsDocument, options);
}
export function useGetProjectStatisticsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectStatistics,
    GetProjectStatisticsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectStatistics,
    GetProjectStatisticsVariables
  >(GetProjectStatisticsDocument, options);
}
export type GetProjectStatisticsHookResult = ReturnType<
  typeof useGetProjectStatistics
>;
export type GetProjectStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetProjectStatisticsLazyQuery
>;
export type GetProjectStatisticsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectStatisticsSuspenseQuery
>;
export type GetProjectStatisticsQueryResult = Apollo.QueryResult<
  GetProjectStatistics,
  GetProjectStatisticsVariables
>;
export const GetProjectMetadataNeuraverseDocument = gql`
  query GetProjectMetadataNeuraverse($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataNeuraverse
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataNeuraverse}
`;

/**
 * __useGetProjectMetadataNeuraverse__
 *
 * To run a query within a React component, call `useGetProjectMetadataNeuraverse` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMetadataNeuraverse` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMetadataNeuraverse({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectMetadataNeuraverse(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  > &
    (
      | { variables: GetProjectMetadataNeuraverseVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  >(GetProjectMetadataNeuraverseDocument, options);
}
export function useGetProjectMetadataNeuraverseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  >(GetProjectMetadataNeuraverseDocument, options);
}
export function useGetProjectMetadataNeuraverseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectMetadataNeuraverse,
    GetProjectMetadataNeuraverseVariables
  >(GetProjectMetadataNeuraverseDocument, options);
}
export type GetProjectMetadataNeuraverseHookResult = ReturnType<
  typeof useGetProjectMetadataNeuraverse
>;
export type GetProjectMetadataNeuraverseLazyQueryHookResult = ReturnType<
  typeof useGetProjectMetadataNeuraverseLazyQuery
>;
export type GetProjectMetadataNeuraverseSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectMetadataNeuraverseSuspenseQuery
>;
export type GetProjectMetadataNeuraverseQueryResult = Apollo.QueryResult<
  GetProjectMetadataNeuraverse,
  GetProjectMetadataNeuraverseVariables
>;
export const GetProjectForCalendarDocument = gql`
  query GetProjectForCalendar($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      brief {
        projectBriefMetadata {
          id
          startDate
          dueDate
        }
      }
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectForCalendar__
 *
 * To run a query within a React component, call `useGetProjectForCalendar` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectForCalendar` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectForCalendar({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectForCalendar(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectForCalendar,
    GetProjectForCalendarVariables
  > &
    (
      | { variables: GetProjectForCalendarVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectForCalendar, GetProjectForCalendarVariables>(
    GetProjectForCalendarDocument,
    options
  );
}
export function useGetProjectForCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectForCalendar,
    GetProjectForCalendarVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectForCalendar,
    GetProjectForCalendarVariables
  >(GetProjectForCalendarDocument, options);
}
export function useGetProjectForCalendarSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectForCalendar,
    GetProjectForCalendarVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectForCalendar,
    GetProjectForCalendarVariables
  >(GetProjectForCalendarDocument, options);
}
export type GetProjectForCalendarHookResult = ReturnType<
  typeof useGetProjectForCalendar
>;
export type GetProjectForCalendarLazyQueryHookResult = ReturnType<
  typeof useGetProjectForCalendarLazyQuery
>;
export type GetProjectForCalendarSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectForCalendarSuspenseQuery
>;
export type GetProjectForCalendarQueryResult = Apollo.QueryResult<
  GetProjectForCalendar,
  GetProjectForCalendarVariables
>;
export const GetProjectOverviewDocument = gql`
  query GetProjectOverview($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataConnection
      ...ProjectMetadataNeuraverse
      brief {
        projectBriefMetadata {
          inputTopics
        }
      }
      topicInspirations
      userProject {
        hiddenTiles
        id
      }
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataConnection}
  ${ProjectMetadataNeuraverse}
`;

/**
 * __useGetProjectOverview__
 *
 * To run a query within a React component, call `useGetProjectOverview` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOverview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOverview({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectOverview(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectOverview,
    GetProjectOverviewVariables
  > &
    (
      | { variables: GetProjectOverviewVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetProjectOverview, GetProjectOverviewVariables>(
    GetProjectOverviewDocument,
    options
  );
}
export function useGetProjectOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectOverview,
    GetProjectOverviewVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetProjectOverview, GetProjectOverviewVariables>(
    GetProjectOverviewDocument,
    options
  );
}
export function useGetProjectOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectOverview,
    GetProjectOverviewVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectOverview,
    GetProjectOverviewVariables
  >(GetProjectOverviewDocument, options);
}
export type GetProjectOverviewHookResult = ReturnType<
  typeof useGetProjectOverview
>;
export type GetProjectOverviewLazyQueryHookResult = ReturnType<
  typeof useGetProjectOverviewLazyQuery
>;
export type GetProjectOverviewSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectOverviewSuspenseQuery
>;
export type GetProjectOverviewQueryResult = Apollo.QueryResult<
  GetProjectOverview,
  GetProjectOverviewVariables
>;
export const GetGscConnectionStatusDocument = gql`
  query GetGscConnectionStatus($input: GetGSCConnectionStatusInput!) {
    getGscConnectionStatus(input: $input) {
      status
    }
  }
`;

/**
 * __useGetGscConnectionStatus__
 *
 * To run a query within a React component, call `useGetGscConnectionStatus` and pass it any options that fit your needs.
 * When your component renders, `useGetGscConnectionStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGscConnectionStatus({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGscConnectionStatus(
  baseOptions: Apollo.QueryHookOptions<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  > &
    (
      | { variables: GetGscConnectionStatusVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  >(GetGscConnectionStatusDocument, options);
}
export function useGetGscConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  >(GetGscConnectionStatusDocument, options);
}
export function useGetGscConnectionStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetGscConnectionStatus,
    GetGscConnectionStatusVariables
  >(GetGscConnectionStatusDocument, options);
}
export type GetGscConnectionStatusHookResult = ReturnType<
  typeof useGetGscConnectionStatus
>;
export type GetGscConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useGetGscConnectionStatusLazyQuery
>;
export type GetGscConnectionStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetGscConnectionStatusSuspenseQuery
>;
export type GetGscConnectionStatusQueryResult = Apollo.QueryResult<
  GetGscConnectionStatus,
  GetGscConnectionStatusVariables
>;
export const GetLinkedinConnectionStatusDocument = gql`
  query GetLinkedinConnectionStatus($input: GetLinkedinConnectionStatusInput!) {
    getLinkedinConnectionStatus(input: $input) {
      status
    }
  }
`;

/**
 * __useGetLinkedinConnectionStatus__
 *
 * To run a query within a React component, call `useGetLinkedinConnectionStatus` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedinConnectionStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedinConnectionStatus({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLinkedinConnectionStatus(
  baseOptions: Apollo.QueryHookOptions<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  > &
    (
      | { variables: GetLinkedinConnectionStatusVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  >(GetLinkedinConnectionStatusDocument, options);
}
export function useGetLinkedinConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  >(GetLinkedinConnectionStatusDocument, options);
}
export function useGetLinkedinConnectionStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLinkedinConnectionStatus,
    GetLinkedinConnectionStatusVariables
  >(GetLinkedinConnectionStatusDocument, options);
}
export type GetLinkedinConnectionStatusHookResult = ReturnType<
  typeof useGetLinkedinConnectionStatus
>;
export type GetLinkedinConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useGetLinkedinConnectionStatusLazyQuery
>;
export type GetLinkedinConnectionStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetLinkedinConnectionStatusSuspenseQuery
>;
export type GetLinkedinConnectionStatusQueryResult = Apollo.QueryResult<
  GetLinkedinConnectionStatus,
  GetLinkedinConnectionStatusVariables
>;
export const GetOneClickAnalysisDocument = gql`
  query GetOneClickAnalysis($input: GetOneClickAnalysisInput!) {
    getOneClickAnalysis(input: $input) {
      status
      report {
        coreWebVitals {
          cumulativeLayoutShift {
            value
          }
          firstContentfulPaint {
            value
          }
          interactive {
            value
          }
          largestContentfulPaint {
            value
          }
        }
        failedReports {
          error
          url
        }
        recommendations {
          critical {
            id
            urls
          }
          low {
            id
            urls
          }
          medium {
            id
            urls
          }
        }
        score
        urls
      }
      finishedAt
    }
  }
`;

/**
 * __useGetOneClickAnalysis__
 *
 * To run a query within a React component, call `useGetOneClickAnalysis` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClickAnalysis` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneClickAnalysis({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOneClickAnalysis(
  baseOptions: Apollo.QueryHookOptions<
    GetOneClickAnalysis,
    GetOneClickAnalysisVariables
  > &
    (
      | { variables: GetOneClickAnalysisVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetOneClickAnalysis, GetOneClickAnalysisVariables>(
    GetOneClickAnalysisDocument,
    options
  );
}
export function useGetOneClickAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneClickAnalysis,
    GetOneClickAnalysisVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetOneClickAnalysis, GetOneClickAnalysisVariables>(
    GetOneClickAnalysisDocument,
    options
  );
}
export function useGetOneClickAnalysisSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOneClickAnalysis,
    GetOneClickAnalysisVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOneClickAnalysis,
    GetOneClickAnalysisVariables
  >(GetOneClickAnalysisDocument, options);
}
export type GetOneClickAnalysisHookResult = ReturnType<
  typeof useGetOneClickAnalysis
>;
export type GetOneClickAnalysisLazyQueryHookResult = ReturnType<
  typeof useGetOneClickAnalysisLazyQuery
>;
export type GetOneClickAnalysisSuspenseQueryHookResult = ReturnType<
  typeof useGetOneClickAnalysisSuspenseQuery
>;
export type GetOneClickAnalysisQueryResult = Apollo.QueryResult<
  GetOneClickAnalysis,
  GetOneClickAnalysisVariables
>;
export const ListArticlesForCurrentUserDocument = gql`
  query listArticlesForCurrentUser(
    $filter: ListArticlesInputFilter
    $id: ModelStringKeyConditionInput
  ) {
    listArticles(filter: $filter, id: $id) {
      items {
        ...ArticleMetadataBase
        ...ArticleMetadataDetails
        ...ArticleMetadataProject
        articleDocument {
          updatedAt
        }
        articleGuidelines {
          updatedAt
        }
      }
    }
  }
  ${ArticleMetadataBase}
  ${ArticleMetadataDetails}
  ${ArticleMetadataProject}
`;

/**
 * __useListArticlesForCurrentUser__
 *
 * To run a query within a React component, call `useListArticlesForCurrentUser` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForCurrentUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForCurrentUser({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListArticlesForCurrentUser(
  baseOptions?: Apollo.QueryHookOptions<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >(ListArticlesForCurrentUserDocument, options);
}
export function useListArticlesForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >(ListArticlesForCurrentUserDocument, options);
}
export function useListArticlesForCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListArticlesForCurrentUser,
    ListArticlesForCurrentUserVariables
  >(ListArticlesForCurrentUserDocument, options);
}
export type ListArticlesForCurrentUserHookResult = ReturnType<
  typeof useListArticlesForCurrentUser
>;
export type ListArticlesForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useListArticlesForCurrentUserLazyQuery
>;
export type ListArticlesForCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useListArticlesForCurrentUserSuspenseQuery
>;
export type ListArticlesForCurrentUserQueryResult = Apollo.QueryResult<
  ListArticlesForCurrentUser,
  ListArticlesForCurrentUserVariables
>;
export const ListGscTopChangingArticlesDocument = gql`
  query ListGSCTopChangingArticles($input: ListGSCTopChangingArticlesInput!) {
    listGSCTopChangingArticles(input: $input) {
      items {
        articleId
        currentAverageGoogleRanking
        value
        previousAverageGoogleRanking
        title
      }
    }
  }
`;

/**
 * __useListGscTopChangingArticles__
 *
 * To run a query within a React component, call `useListGscTopChangingArticles` and pass it any options that fit your needs.
 * When your component renders, `useListGscTopChangingArticles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGscTopChangingArticles({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListGscTopChangingArticles(
  baseOptions: Apollo.QueryHookOptions<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  > &
    (
      | { variables: ListGscTopChangingArticlesVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  >(ListGscTopChangingArticlesDocument, options);
}
export function useListGscTopChangingArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  >(ListGscTopChangingArticlesDocument, options);
}
export function useListGscTopChangingArticlesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListGscTopChangingArticles,
    ListGscTopChangingArticlesVariables
  >(ListGscTopChangingArticlesDocument, options);
}
export type ListGscTopChangingArticlesHookResult = ReturnType<
  typeof useListGscTopChangingArticles
>;
export type ListGscTopChangingArticlesLazyQueryHookResult = ReturnType<
  typeof useListGscTopChangingArticlesLazyQuery
>;
export type ListGscTopChangingArticlesSuspenseQueryHookResult = ReturnType<
  typeof useListGscTopChangingArticlesSuspenseQuery
>;
export type ListGscTopChangingArticlesQueryResult = Apollo.QueryResult<
  ListGscTopChangingArticles,
  ListGscTopChangingArticlesVariables
>;
export const GetProjectTopicInspirationDocument = gql`
  query GetProjectTopicInspiration($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      topicInspirations
      brief {
        projectBriefMetadata {
          inputTopics
        }
      }
    }
  }
  ${ProjectMetadataBase}
`;

/**
 * __useGetProjectTopicInspiration__
 *
 * To run a query within a React component, call `useGetProjectTopicInspiration` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTopicInspiration` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTopicInspiration({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTopicInspiration(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  > &
    (
      | { variables: GetProjectTopicInspirationVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  >(GetProjectTopicInspirationDocument, options);
}
export function useGetProjectTopicInspirationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  >(GetProjectTopicInspirationDocument, options);
}
export function useGetProjectTopicInspirationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectTopicInspiration,
    GetProjectTopicInspirationVariables
  >(GetProjectTopicInspirationDocument, options);
}
export type GetProjectTopicInspirationHookResult = ReturnType<
  typeof useGetProjectTopicInspiration
>;
export type GetProjectTopicInspirationLazyQueryHookResult = ReturnType<
  typeof useGetProjectTopicInspirationLazyQuery
>;
export type GetProjectTopicInspirationSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectTopicInspirationSuspenseQuery
>;
export type GetProjectTopicInspirationQueryResult = Apollo.QueryResult<
  GetProjectTopicInspiration,
  GetProjectTopicInspirationVariables
>;
export const ListProjectLabelsDocument = gql`
  query ListProjectLabels($id: ID!) {
    getProject(id: $id) {
      ...ProjectMetadataBase
      ...ProjectMetadataLabels
    }
  }
  ${ProjectMetadataBase}
  ${ProjectMetadataLabels}
`;

/**
 * __useListProjectLabels__
 *
 * To run a query within a React component, call `useListProjectLabels` and pass it any options that fit your needs.
 * When your component renders, `useListProjectLabels` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectLabels({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListProjectLabels(
  baseOptions: Apollo.QueryHookOptions<
    ListProjectLabels,
    ListProjectLabelsVariables
  > &
    (
      | { variables: ListProjectLabelsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListProjectLabels, ListProjectLabelsVariables>(
    ListProjectLabelsDocument,
    options
  );
}
export function useListProjectLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectLabels,
    ListProjectLabelsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListProjectLabels, ListProjectLabelsVariables>(
    ListProjectLabelsDocument,
    options
  );
}
export function useListProjectLabelsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListProjectLabels,
    ListProjectLabelsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListProjectLabels, ListProjectLabelsVariables>(
    ListProjectLabelsDocument,
    options
  );
}
export type ListProjectLabelsHookResult = ReturnType<
  typeof useListProjectLabels
>;
export type ListProjectLabelsLazyQueryHookResult = ReturnType<
  typeof useListProjectLabelsLazyQuery
>;
export type ListProjectLabelsSuspenseQueryHookResult = ReturnType<
  typeof useListProjectLabelsSuspenseQuery
>;
export type ListProjectLabelsQueryResult = Apollo.QueryResult<
  ListProjectLabels,
  ListProjectLabelsVariables
>;
export const GetProjectLabelUsageCountDocument = gql`
  query GetProjectLabelUsageCount($input: GetProjectLabelUsageCountInput!) {
    getProjectLabelUsageCount(input: $input)
  }
`;

/**
 * __useGetProjectLabelUsageCount__
 *
 * To run a query within a React component, call `useGetProjectLabelUsageCount` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectLabelUsageCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectLabelUsageCount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProjectLabelUsageCount(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  > &
    (
      | { variables: GetProjectLabelUsageCountVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  >(GetProjectLabelUsageCountDocument, options);
}
export function useGetProjectLabelUsageCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  >(GetProjectLabelUsageCountDocument, options);
}
export function useGetProjectLabelUsageCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectLabelUsageCount,
    GetProjectLabelUsageCountVariables
  >(GetProjectLabelUsageCountDocument, options);
}
export type GetProjectLabelUsageCountHookResult = ReturnType<
  typeof useGetProjectLabelUsageCount
>;
export type GetProjectLabelUsageCountLazyQueryHookResult = ReturnType<
  typeof useGetProjectLabelUsageCountLazyQuery
>;
export type GetProjectLabelUsageCountSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectLabelUsageCountSuspenseQuery
>;
export type GetProjectLabelUsageCountQueryResult = Apollo.QueryResult<
  GetProjectLabelUsageCount,
  GetProjectLabelUsageCountVariables
>;
export const OnProjectCreateDocument = gql`
  subscription OnProjectCreate(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onProjectCreate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ProjectSubscriptionFull
    }
  }
  ${ProjectSubscriptionFull}
`;

/**
 * __useOnProjectCreate__
 *
 * To run a query within a React component, call `useOnProjectCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectCreate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnProjectCreate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnProjectCreate,
    OnProjectCreateVariables
  > &
    (
      | { variables: OnProjectCreateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnProjectCreate, OnProjectCreateVariables>(
    OnProjectCreateDocument,
    options
  );
}
export type OnProjectCreateHookResult = ReturnType<typeof useOnProjectCreate>;
export type OnProjectCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnProjectCreate>;
export const OnProjectUpdateDocument = gql`
  subscription OnProjectUpdate(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onProjectUpdate(userId: $userId, pk1: $pk1, filter: $filter) {
      ...ProjectSubscriptionFull
    }
  }
  ${ProjectSubscriptionFull}
`;

/**
 * __useOnProjectUpdate__
 *
 * To run a query within a React component, call `useOnProjectUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectUpdate({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnProjectUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnProjectUpdate,
    OnProjectUpdateVariables
  > &
    (
      | { variables: OnProjectUpdateVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnProjectUpdate, OnProjectUpdateVariables>(
    OnProjectUpdateDocument,
    options
  );
}
export type OnProjectUpdateHookResult = ReturnType<typeof useOnProjectUpdate>;
export type OnProjectUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnProjectUpdate>;
export const OnProjectDeleteDocument = gql`
  subscription OnProjectDelete(
    $userId: String
    $pk1: String!
    $filter: String
  ) {
    onProjectDelete(userId: $userId, pk1: $pk1, filter: $filter) {
      id
      type
    }
  }
`;

/**
 * __useOnProjectDelete__
 *
 * To run a query within a React component, call `useOnProjectDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectDelete({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnProjectDelete(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnProjectDelete,
    OnProjectDeleteVariables
  > &
    (
      | { variables: OnProjectDeleteVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnProjectDelete, OnProjectDeleteVariables>(
    OnProjectDeleteDocument,
    options
  );
}
export type OnProjectDeleteHookResult = ReturnType<typeof useOnProjectDelete>;
export type OnProjectDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnProjectDelete>;
export const OnProjectUpdateByIdDocument = gql`
  subscription OnProjectUpdateById(
    $userId: String
    $pk1: String!
    $id: String!
    $filter: String
  ) {
    onProjectUpdateById(userId: $userId, pk1: $pk1, id: $id, filter: $filter) {
      ...ProjectSubscriptionFull
    }
  }
  ${ProjectSubscriptionFull}
`;

/**
 * __useOnProjectUpdateById__
 *
 * To run a query within a React component, call `useOnProjectUpdateById` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectUpdateById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectUpdateById({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnProjectUpdateById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnProjectUpdateById,
    OnProjectUpdateByIdVariables
  > &
    (
      | { variables: OnProjectUpdateByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnProjectUpdateById,
    OnProjectUpdateByIdVariables
  >(OnProjectUpdateByIdDocument, options);
}
export type OnProjectUpdateByIdHookResult = ReturnType<
  typeof useOnProjectUpdateById
>;
export type OnProjectUpdateByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnProjectUpdateById>;
export const OnProjectDeleteByIdDocument = gql`
  subscription OnProjectDeleteById(
    $userId: String
    $pk1: String!
    $id: String!
    $filter: String
  ) {
    onProjectDeleteById(userId: $userId, pk1: $pk1, id: $id, filter: $filter) {
      id
      type
    }
  }
`;

/**
 * __useOnProjectDeleteById__
 *
 * To run a query within a React component, call `useOnProjectDeleteById` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectDeleteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectDeleteById({
 *   variables: {
 *      userId: // value for 'userId'
 *      pk1: // value for 'pk1'
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnProjectDeleteById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnProjectDeleteById,
    OnProjectDeleteByIdVariables
  > &
    (
      | { variables: OnProjectDeleteByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnProjectDeleteById,
    OnProjectDeleteByIdVariables
  >(OnProjectDeleteByIdDocument, options);
}
export type OnProjectDeleteByIdHookResult = ReturnType<
  typeof useOnProjectDeleteById
>;
export type OnProjectDeleteByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnProjectDeleteById>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...UserFull
    }
  }
  ${UserFull}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUser,
  UpdateUserVariables
>;

/**
 * __useUpdateUser__
 *
 * To run a mutation, you first call `useUpdateUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUser, { data, loading, error }] = useUpdateUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUser(
  baseOptions?: Apollo.MutationHookOptions<UpdateUser, UpdateUserVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUser, UpdateUserVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserHookResult = ReturnType<typeof useUpdateUser>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUser>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUser,
  UpdateUserVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUser,
  DeleteUserVariables
>;

/**
 * __useDeleteUser__
 *
 * To run a mutation, you first call `useDeleteUser` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUser` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUser, { data, loading, error }] = useDeleteUser({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUser(
  baseOptions?: Apollo.MutationHookOptions<DeleteUser, DeleteUserVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUser, DeleteUserVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserHookResult = ReturnType<typeof useDeleteUser>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUser>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUser,
  DeleteUserVariables
>;
export const UpdateUserArticleDataDocument = gql`
  mutation UpdateUserArticleData($input: UpdateUserArticleDataInput!) {
    updateUserArticleData(input: $input) {
      preferences
      id
    }
  }
`;
export type UpdateUserArticleDataMutationFn = Apollo.MutationFunction<
  UpdateUserArticleData,
  UpdateUserArticleDataVariables
>;

/**
 * __useUpdateUserArticleData__
 *
 * To run a mutation, you first call `useUpdateUserArticleData` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserArticleData` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserArticleData, { data, loading, error }] = useUpdateUserArticleData({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserArticleData(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserArticleData,
    UpdateUserArticleDataVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserArticleData,
    UpdateUserArticleDataVariables
  >(UpdateUserArticleDataDocument, options);
}
export type UpdateUserArticleDataHookResult = ReturnType<
  typeof useUpdateUserArticleData
>;
export type UpdateUserArticleDataMutationResult =
  Apollo.MutationResult<UpdateUserArticleData>;
export type UpdateUserArticleDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserArticleData,
  UpdateUserArticleDataVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    getUser {
      ...UserFull
    }
  }
  ${UserFull}
`;

/**
 * __useGetUser__
 *
 * To run a query within a React component, call `useGetUser` and pass it any options that fit your needs.
 * When your component renders, `useGetUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUser({
 *   variables: {
 *   },
 * });
 */
export function useGetUser(
  baseOptions?: Apollo.QueryHookOptions<GetUser, GetUserVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetUser, GetUserVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUser, GetUserVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetUser, GetUserVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUser, GetUserVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUser, GetUserVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserHookResult = ReturnType<typeof useGetUser>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSuspenseQuery
>;
export type GetUserQueryResult = Apollo.QueryResult<GetUser, GetUserVariables>;
export const ListUsersForProjectDialogDocument = gql`
  query listUsersForProjectDialog {
    listUsers {
      items {
        ...UserBase
        ...UserOrganization
      }
    }
  }
  ${UserBase}
  ${UserOrganization}
`;

/**
 * __useListUsersForProjectDialog__
 *
 * To run a query within a React component, call `useListUsersForProjectDialog` and pass it any options that fit your needs.
 * When your component renders, `useListUsersForProjectDialog` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersForProjectDialog({
 *   variables: {
 *   },
 * });
 */
export function useListUsersForProjectDialog(
  baseOptions?: Apollo.QueryHookOptions<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >(ListUsersForProjectDialogDocument, options);
}
export function useListUsersForProjectDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >(ListUsersForProjectDialogDocument, options);
}
export function useListUsersForProjectDialogSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListUsersForProjectDialog,
    ListUsersForProjectDialogVariables
  >(ListUsersForProjectDialogDocument, options);
}
export type ListUsersForProjectDialogHookResult = ReturnType<
  typeof useListUsersForProjectDialog
>;
export type ListUsersForProjectDialogLazyQueryHookResult = ReturnType<
  typeof useListUsersForProjectDialogLazyQuery
>;
export type ListUsersForProjectDialogSuspenseQueryHookResult = ReturnType<
  typeof useListUsersForProjectDialogSuspenseQuery
>;
export type ListUsersForProjectDialogQueryResult = Apollo.QueryResult<
  ListUsersForProjectDialog,
  ListUsersForProjectDialogVariables
>;
export const ListUsersForMembersPageDocument = gql`
  query listUsersForMembersPage {
    listUsersWithProjects {
      auth0 {
        role
      }
      id
      user {
        ...UserBase
        email
      }
      projectsCount
    }
  }
  ${UserBase}
`;

/**
 * __useListUsersForMembersPage__
 *
 * To run a query within a React component, call `useListUsersForMembersPage` and pass it any options that fit your needs.
 * When your component renders, `useListUsersForMembersPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersForMembersPage({
 *   variables: {
 *   },
 * });
 */
export function useListUsersForMembersPage(
  baseOptions?: Apollo.QueryHookOptions<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >(ListUsersForMembersPageDocument, options);
}
export function useListUsersForMembersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >(ListUsersForMembersPageDocument, options);
}
export function useListUsersForMembersPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListUsersForMembersPage,
    ListUsersForMembersPageVariables
  >(ListUsersForMembersPageDocument, options);
}
export type ListUsersForMembersPageHookResult = ReturnType<
  typeof useListUsersForMembersPage
>;
export type ListUsersForMembersPageLazyQueryHookResult = ReturnType<
  typeof useListUsersForMembersPageLazyQuery
>;
export type ListUsersForMembersPageSuspenseQueryHookResult = ReturnType<
  typeof useListUsersForMembersPageSuspenseQuery
>;
export type ListUsersForMembersPageQueryResult = Apollo.QueryResult<
  ListUsersForMembersPage,
  ListUsersForMembersPageVariables
>;
export const ListProjectMembersDocument = gql`
  query ListProjectMembers($projectId: String!) {
    listProjectMembers(projectId: $projectId) {
      ...ProjectMemberFull
    }
  }
  ${ProjectMemberFull}
`;

/**
 * __useListProjectMembers__
 *
 * To run a query within a React component, call `useListProjectMembers` and pass it any options that fit your needs.
 * When your component renders, `useListProjectMembers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectMembers({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListProjectMembers(
  baseOptions: Apollo.QueryHookOptions<
    ListProjectMembers,
    ListProjectMembersVariables
  > &
    (
      | { variables: ListProjectMembersVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListProjectMembers, ListProjectMembersVariables>(
    ListProjectMembersDocument,
    options
  );
}
export function useListProjectMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectMembers,
    ListProjectMembersVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListProjectMembers, ListProjectMembersVariables>(
    ListProjectMembersDocument,
    options
  );
}
export function useListProjectMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListProjectMembers,
    ListProjectMembersVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ListProjectMembers,
    ListProjectMembersVariables
  >(ListProjectMembersDocument, options);
}
export type ListProjectMembersHookResult = ReturnType<
  typeof useListProjectMembers
>;
export type ListProjectMembersLazyQueryHookResult = ReturnType<
  typeof useListProjectMembersLazyQuery
>;
export type ListProjectMembersSuspenseQueryHookResult = ReturnType<
  typeof useListProjectMembersSuspenseQuery
>;
export type ListProjectMembersQueryResult = Apollo.QueryResult<
  ListProjectMembers,
  ListProjectMembersVariables
>;
export const ListAllOrgUsersDocument = gql`
  query ListAllOrgUsers {
    listUsers {
      items {
        ...UserBase
      }
    }
  }
  ${UserBase}
`;

/**
 * __useListAllOrgUsers__
 *
 * To run a query within a React component, call `useListAllOrgUsers` and pass it any options that fit your needs.
 * When your component renders, `useListAllOrgUsers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllOrgUsers({
 *   variables: {
 *   },
 * });
 */
export function useListAllOrgUsers(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllOrgUsers,
    ListAllOrgUsersVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListAllOrgUsers, ListAllOrgUsersVariables>(
    ListAllOrgUsersDocument,
    options
  );
}
export function useListAllOrgUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllOrgUsers,
    ListAllOrgUsersVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListAllOrgUsers, ListAllOrgUsersVariables>(
    ListAllOrgUsersDocument,
    options
  );
}
export function useListAllOrgUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListAllOrgUsers,
    ListAllOrgUsersVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListAllOrgUsers, ListAllOrgUsersVariables>(
    ListAllOrgUsersDocument,
    options
  );
}
export type ListAllOrgUsersHookResult = ReturnType<typeof useListAllOrgUsers>;
export type ListAllOrgUsersLazyQueryHookResult = ReturnType<
  typeof useListAllOrgUsersLazyQuery
>;
export type ListAllOrgUsersSuspenseQueryHookResult = ReturnType<
  typeof useListAllOrgUsersSuspenseQuery
>;
export type ListAllOrgUsersQueryResult = Apollo.QueryResult<
  ListAllOrgUsers,
  ListAllOrgUsersVariables
>;
export const ListUserProjectsDocument = gql`
  query listUserProjects($userId: String!) {
    listUserProjects(userId: $userId) {
      user {
        id
        avatar
        color
        email
        firstName
        lastName
        nickname
      }
      projects {
        roleId
        project {
          id
          name
          avatar
          color
        }
      }
    }
  }
`;

/**
 * __useListUserProjects__
 *
 * To run a query within a React component, call `useListUserProjects` and pass it any options that fit your needs.
 * When your component renders, `useListUserProjects` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserProjects({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListUserProjects(
  baseOptions: Apollo.QueryHookOptions<
    ListUserProjects,
    ListUserProjectsVariables
  > &
    (
      | { variables: ListUserProjectsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListUserProjects, ListUserProjectsVariables>(
    ListUserProjectsDocument,
    options
  );
}
export function useListUserProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserProjects,
    ListUserProjectsVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListUserProjects, ListUserProjectsVariables>(
    ListUserProjectsDocument,
    options
  );
}
export function useListUserProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListUserProjects,
    ListUserProjectsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListUserProjects, ListUserProjectsVariables>(
    ListUserProjectsDocument,
    options
  );
}
export type ListUserProjectsHookResult = ReturnType<typeof useListUserProjects>;
export type ListUserProjectsLazyQueryHookResult = ReturnType<
  typeof useListUserProjectsLazyQuery
>;
export type ListUserProjectsSuspenseQueryHookResult = ReturnType<
  typeof useListUserProjectsSuspenseQuery
>;
export type ListUserProjectsQueryResult = Apollo.QueryResult<
  ListUserProjects,
  ListUserProjectsVariables
>;
export const OnUserCreateDocument = gql`
  subscription OnUserCreate($userId: String, $organizationId: String) {
    onUserCreate(userId: $userId, organizationId: $organizationId) {
      ...UserSubscriptionFull
    }
  }
  ${UserSubscriptionFull}
`;

/**
 * __useOnUserCreate__
 *
 * To run a query within a React component, call `useOnUserCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnUserCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserCreate({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOnUserCreate(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnUserCreate,
    OnUserCreateVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUserCreate, OnUserCreateVariables>(
    OnUserCreateDocument,
    options
  );
}
export type OnUserCreateHookResult = ReturnType<typeof useOnUserCreate>;
export type OnUserCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnUserCreate>;
export const OnUserUpdateDocument = gql`
  subscription OnUserUpdate($userId: String, $organizationId: String) {
    onUserUpdate(userId: $userId, organizationId: $organizationId) {
      ...UserSubscriptionFull
    }
  }
  ${UserSubscriptionFull}
`;

/**
 * __useOnUserUpdate__
 *
 * To run a query within a React component, call `useOnUserUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnUserUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserUpdate({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOnUserUpdate(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnUserUpdate,
    OnUserUpdateVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUserUpdate, OnUserUpdateVariables>(
    OnUserUpdateDocument,
    options
  );
}
export type OnUserUpdateHookResult = ReturnType<typeof useOnUserUpdate>;
export type OnUserUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnUserUpdate>;
export const OnUserDeleteDocument = gql`
  subscription OnUserDelete($userId: String, $organizationId: String) {
    onUserDelete(userId: $userId, organizationId: $organizationId) {
      id
    }
  }
`;

/**
 * __useOnUserDelete__
 *
 * To run a query within a React component, call `useOnUserDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnUserDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserDelete({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOnUserDelete(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnUserDelete,
    OnUserDeleteVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUserDelete, OnUserDeleteVariables>(
    OnUserDeleteDocument,
    options
  );
}
export type OnUserDeleteHookResult = ReturnType<typeof useOnUserDelete>;
export type OnUserDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnUserDelete>;
export const OnUserUpdateByIdDocument = gql`
  subscription OnUserUpdateById(
    $userId: String
    $id: ID!
    $organizationId: String
  ) {
    onUserUpdateById(
      userId: $userId
      id: $id
      organizationId: $organizationId
    ) {
      ...UserSubscriptionFull
    }
  }
  ${UserSubscriptionFull}
`;

/**
 * __useOnUserUpdateById__
 *
 * To run a query within a React component, call `useOnUserUpdateById` and pass it any options that fit your needs.
 * When your component renders, `useOnUserUpdateById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserUpdateById({
 *   variables: {
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOnUserUpdateById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUserUpdateById,
    OnUserUpdateByIdVariables
  > &
    (
      | { variables: OnUserUpdateByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUserUpdateById, OnUserUpdateByIdVariables>(
    OnUserUpdateByIdDocument,
    options
  );
}
export type OnUserUpdateByIdHookResult = ReturnType<typeof useOnUserUpdateById>;
export type OnUserUpdateByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnUserUpdateById>;
export const OnUserDeleteByIdDocument = gql`
  subscription OnUserDeleteById(
    $userId: String
    $id: ID!
    $organizationId: String
  ) {
    onUserDeleteById(
      userId: $userId
      id: $id
      organizationId: $organizationId
    ) {
      id
    }
  }
`;

/**
 * __useOnUserDeleteById__
 *
 * To run a query within a React component, call `useOnUserDeleteById` and pass it any options that fit your needs.
 * When your component renders, `useOnUserDeleteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUserDeleteById({
 *   variables: {
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOnUserDeleteById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUserDeleteById,
    OnUserDeleteByIdVariables
  > &
    (
      | { variables: OnUserDeleteByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUserDeleteById, OnUserDeleteByIdVariables>(
    OnUserDeleteByIdDocument,
    options
  );
}
export type OnUserDeleteByIdHookResult = ReturnType<typeof useOnUserDeleteById>;
export type OnUserDeleteByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnUserDeleteById>;
export const CreateUiViewDocument = gql`
  mutation CreateUiView($input: CreateUiViewInput!) {
    createUiView(input: $input) {
      ...UiViewFull
    }
  }
  ${UiViewFull}
`;
export type CreateUiViewMutationFn = Apollo.MutationFunction<
  CreateUiView,
  CreateUiViewVariables
>;

/**
 * __useCreateUiView__
 *
 * To run a mutation, you first call `useCreateUiView` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUiView` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUiView, { data, loading, error }] = useCreateUiView({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUiView(
  baseOptions?: Apollo.MutationHookOptions<CreateUiView, CreateUiViewVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUiView, CreateUiViewVariables>(
    CreateUiViewDocument,
    options
  );
}
export type CreateUiViewHookResult = ReturnType<typeof useCreateUiView>;
export type CreateUiViewMutationResult = Apollo.MutationResult<CreateUiView>;
export type CreateUiViewMutationOptions = Apollo.BaseMutationOptions<
  CreateUiView,
  CreateUiViewVariables
>;
export const DeleteUiViewDocument = gql`
  mutation DeleteUiView($input: DeleteUiViewInput!) {
    deleteUiView(input: $input) {
      id
    }
  }
`;
export type DeleteUiViewMutationFn = Apollo.MutationFunction<
  DeleteUiView,
  DeleteUiViewVariables
>;

/**
 * __useDeleteUiView__
 *
 * To run a mutation, you first call `useDeleteUiView` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUiView` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUiView, { data, loading, error }] = useDeleteUiView({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUiView(
  baseOptions?: Apollo.MutationHookOptions<DeleteUiView, DeleteUiViewVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUiView, DeleteUiViewVariables>(
    DeleteUiViewDocument,
    options
  );
}
export type DeleteUiViewHookResult = ReturnType<typeof useDeleteUiView>;
export type DeleteUiViewMutationResult = Apollo.MutationResult<DeleteUiView>;
export type DeleteUiViewMutationOptions = Apollo.BaseMutationOptions<
  DeleteUiView,
  DeleteUiViewVariables
>;
export const UpdateUiViewDocument = gql`
  mutation UpdateUiView($input: UpdateUiViewInput!) {
    updateUiView(input: $input) {
      ...UiViewFull
    }
  }
  ${UiViewFull}
`;
export type UpdateUiViewMutationFn = Apollo.MutationFunction<
  UpdateUiView,
  UpdateUiViewVariables
>;

/**
 * __useUpdateUiView__
 *
 * To run a mutation, you first call `useUpdateUiView` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUiView` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUiView, { data, loading, error }] = useUpdateUiView({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUiView(
  baseOptions?: Apollo.MutationHookOptions<UpdateUiView, UpdateUiViewVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUiView, UpdateUiViewVariables>(
    UpdateUiViewDocument,
    options
  );
}
export type UpdateUiViewHookResult = ReturnType<typeof useUpdateUiView>;
export type UpdateUiViewMutationResult = Apollo.MutationResult<UpdateUiView>;
export type UpdateUiViewMutationOptions = Apollo.BaseMutationOptions<
  UpdateUiView,
  UpdateUiViewVariables
>;
export const ListUiViewsFullDocument = gql`
  query ListUiViewsFull($id: String!) {
    listUiViews(id: $id) {
      items {
        ...UiViewFull
      }
      nextToken
    }
  }
  ${UiViewFull}
`;

/**
 * __useListUiViewsFull__
 *
 * To run a query within a React component, call `useListUiViewsFull` and pass it any options that fit your needs.
 * When your component renders, `useListUiViewsFull` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUiViewsFull({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListUiViewsFull(
  baseOptions: Apollo.QueryHookOptions<
    ListUiViewsFull,
    ListUiViewsFullVariables
  > &
    (
      | { variables: ListUiViewsFullVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<ListUiViewsFull, ListUiViewsFullVariables>(
    ListUiViewsFullDocument,
    options
  );
}
export function useListUiViewsFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUiViewsFull,
    ListUiViewsFullVariables
  >
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<ListUiViewsFull, ListUiViewsFullVariables>(
    ListUiViewsFullDocument,
    options
  );
}
export function useListUiViewsFullSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListUiViewsFull,
    ListUiViewsFullVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListUiViewsFull, ListUiViewsFullVariables>(
    ListUiViewsFullDocument,
    options
  );
}
export type ListUiViewsFullHookResult = ReturnType<typeof useListUiViewsFull>;
export type ListUiViewsFullLazyQueryHookResult = ReturnType<
  typeof useListUiViewsFullLazyQuery
>;
export type ListUiViewsFullSuspenseQueryHookResult = ReturnType<
  typeof useListUiViewsFullSuspenseQuery
>;
export type ListUiViewsFullQueryResult = Apollo.QueryResult<
  ListUiViewsFull,
  ListUiViewsFullVariables
>;
export const GetUiViewDocument = gql`
  query GetUiView($id: ID!) {
    getUiView(id: $id) {
      ...UiViewFull
    }
  }
  ${UiViewFull}
`;

/**
 * __useGetUiView__
 *
 * To run a query within a React component, call `useGetUiView` and pass it any options that fit your needs.
 * When your component renders, `useGetUiView` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUiView({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUiView(
  baseOptions: Apollo.QueryHookOptions<GetUiView, GetUiViewVariables> &
    ({ variables: GetUiViewVariables; skip?: boolean } | { skip: boolean })
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useQuery<GetUiView, GetUiViewVariables>(
    GetUiViewDocument,
    options
  );
}
export function useGetUiViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUiView, GetUiViewVariables>
) {
  const options = {
    ...defaultWatchQueryOptions,
    ...defaultOptions,
    ...baseOptions,
  };
  return Apollo.useLazyQuery<GetUiView, GetUiViewVariables>(
    GetUiViewDocument,
    options
  );
}
export function useGetUiViewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUiView, GetUiViewVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUiView, GetUiViewVariables>(
    GetUiViewDocument,
    options
  );
}
export type GetUiViewHookResult = ReturnType<typeof useGetUiView>;
export type GetUiViewLazyQueryHookResult = ReturnType<
  typeof useGetUiViewLazyQuery
>;
export type GetUiViewSuspenseQueryHookResult = ReturnType<
  typeof useGetUiViewSuspenseQuery
>;
export type GetUiViewQueryResult = Apollo.QueryResult<
  GetUiView,
  GetUiViewVariables
>;
export const OnUiViewCreateDocument = gql`
  subscription OnUiViewCreate($pk1: String!, $filter: String, $userId: String) {
    onUiViewCreate(pk1: $pk1, filter: $filter, userId: $userId) {
      ...UiViewSubscriptionFull
    }
  }
  ${UiViewSubscriptionFull}
`;

/**
 * __useOnUiViewCreate__
 *
 * To run a query within a React component, call `useOnUiViewCreate` and pass it any options that fit your needs.
 * When your component renders, `useOnUiViewCreate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUiViewCreate({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUiViewCreate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUiViewCreate,
    OnUiViewCreateVariables
  > &
    ({ variables: OnUiViewCreateVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUiViewCreate, OnUiViewCreateVariables>(
    OnUiViewCreateDocument,
    options
  );
}
export type OnUiViewCreateHookResult = ReturnType<typeof useOnUiViewCreate>;
export type OnUiViewCreateSubscriptionResult =
  Apollo.SubscriptionResult<OnUiViewCreate>;
export const OnUiViewUpdateDocument = gql`
  subscription OnUiViewUpdate($pk1: String!, $filter: String, $userId: String) {
    onUiViewUpdate(pk1: $pk1, filter: $filter, userId: $userId) {
      ...UiViewSubscriptionFull
    }
  }
  ${UiViewSubscriptionFull}
`;

/**
 * __useOnUiViewUpdate__
 *
 * To run a query within a React component, call `useOnUiViewUpdate` and pass it any options that fit your needs.
 * When your component renders, `useOnUiViewUpdate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUiViewUpdate({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUiViewUpdate(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUiViewUpdate,
    OnUiViewUpdateVariables
  > &
    ({ variables: OnUiViewUpdateVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUiViewUpdate, OnUiViewUpdateVariables>(
    OnUiViewUpdateDocument,
    options
  );
}
export type OnUiViewUpdateHookResult = ReturnType<typeof useOnUiViewUpdate>;
export type OnUiViewUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnUiViewUpdate>;
export const OnUiViewDeleteDocument = gql`
  subscription OnUiViewDelete($pk1: String!, $filter: String, $userId: String) {
    onUiViewDelete(pk1: $pk1, filter: $filter, userId: $userId) {
      id
    }
  }
`;

/**
 * __useOnUiViewDelete__
 *
 * To run a query within a React component, call `useOnUiViewDelete` and pass it any options that fit your needs.
 * When your component renders, `useOnUiViewDelete` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUiViewDelete({
 *   variables: {
 *      pk1: // value for 'pk1'
 *      filter: // value for 'filter'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUiViewDelete(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUiViewDelete,
    OnUiViewDeleteVariables
  > &
    ({ variables: OnUiViewDeleteVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnUiViewDelete, OnUiViewDeleteVariables>(
    OnUiViewDeleteDocument,
    options
  );
}
export type OnUiViewDeleteHookResult = ReturnType<typeof useOnUiViewDelete>;
export type OnUiViewDeleteSubscriptionResult =
  Apollo.SubscriptionResult<OnUiViewDelete>;
export const OnUiViewDeleteByIdDocument = gql`
  subscription OnUiViewDeleteById(
    $filter: String
    $id: ID!
    $pk1: String!
    $userId: String
  ) {
    onUiViewDeleteById(id: $id, pk1: $pk1, filter: $filter, userId: $userId) {
      id
    }
  }
`;

/**
 * __useOnUiViewDeleteById__
 *
 * To run a query within a React component, call `useOnUiViewDeleteById` and pass it any options that fit your needs.
 * When your component renders, `useOnUiViewDeleteById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUiViewDeleteById({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *      pk1: // value for 'pk1'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUiViewDeleteById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUiViewDeleteById,
    OnUiViewDeleteByIdVariables
  > &
    (
      | { variables: OnUiViewDeleteByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnUiViewDeleteById,
    OnUiViewDeleteByIdVariables
  >(OnUiViewDeleteByIdDocument, options);
}
export type OnUiViewDeleteByIdHookResult = ReturnType<
  typeof useOnUiViewDeleteById
>;
export type OnUiViewDeleteByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnUiViewDeleteById>;
export const OnUiViewUpdateByIdDocument = gql`
  subscription OnUiViewUpdateById(
    $filter: String
    $id: ID!
    $pk1: String!
    $userId: String
  ) {
    onUiViewUpdateById(filter: $filter, id: $id, pk1: $pk1, userId: $userId) {
      ...UiViewSubscriptionFull
    }
  }
  ${UiViewSubscriptionFull}
`;

/**
 * __useOnUiViewUpdateById__
 *
 * To run a query within a React component, call `useOnUiViewUpdateById` and pass it any options that fit your needs.
 * When your component renders, `useOnUiViewUpdateById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUiViewUpdateById({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *      pk1: // value for 'pk1'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnUiViewUpdateById(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnUiViewUpdateById,
    OnUiViewUpdateByIdVariables
  > &
    (
      | { variables: OnUiViewUpdateByIdVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnUiViewUpdateById,
    OnUiViewUpdateByIdVariables
  >(OnUiViewUpdateByIdDocument, options);
}
export type OnUiViewUpdateByIdHookResult = ReturnType<
  typeof useOnUiViewUpdateById
>;
export type OnUiViewUpdateByIdSubscriptionResult =
  Apollo.SubscriptionResult<OnUiViewUpdateById>;
