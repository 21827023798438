'use client';
import React, { FC } from 'react';
import { Error404Page } from '@/common/errors/Error404Page';

/**
 * Fallback, if a path doesn't exist
 * @returns
 */
const NotFoundPage: FC = () => <Error404Page />;

export default NotFoundPage;
